import axios from "axios";
import { observer } from "mobx-react";
import { useState } from "react";
import { useForm } from 'react-hook-form';

interface Props {
  isOpen: boolean;
  partnerUUID: string;
  onClose: () => void;
}

const PoolQNAModal = ({
  isOpen,
  partnerUUID,
  onClose
}: Props) => {
  const { register, watch, setValue, reset, handleSubmit } = useForm();

  if (!isOpen) {
    return <></>;
  }

  const onSubmit = async (data: any) => {
    const { question } = data;
    if (!question) {
      alert('내용을 입력해주세요');
      return;
    }

    await axios.post(`/partner/${partnerUUID}/qnas`, {
      question,
    });

    alert('메일이 발송되었습니다. 메일함을 확인해주세요.');
    onClose();
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3 style={{ fontWeight: 'bold' }}>
            자유롭게 남겨주세요.
            <p style={{ fontSize: 14, fontWeight: 'normal', color: 'gray' }}>
              작성하신 내용은 회사 담당자에게 메일로 전달됩니다.
            </p>
          </h3>
          <br/>
          <textarea
            id="msgCnts"
            {...register('question')}
            placeholder="내용을 입력해주세요 (최대 200자)"
            style={{ height: 150 }}
          ></textarea>
          <div className="btn">
            <button type="button" className="cancel" onClick={onClose}>취소</button>
            <a href="#" className="actv" onClick={handleSubmit(onSubmit)}>보내기</a>
          </div>
        </div>

        <button type="button" className="btn-close" onClick={onClose}>
          <span style={{ fontSize: 16 }}>닫기</span>
        </button>
      </div>
    </div>
  )
};

export default observer(PoolQNAModal);
