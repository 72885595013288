import { observer } from "mobx-react";
import { IJoinAccountModel } from "../models/JoinAccountModel";

import { EmailConfirmField, PasswordField } from '.';

interface IProps {
  model: IJoinAccountModel;
}
const JoinAccount: React.SFC<IProps> = (observer((props) => {
  const {
    name,
    email,
    password,
    checkPassword,
    phone,
    country_code,

    nameValidationMessage,
    phoneValidationMessage,
    passwordValidationMessage,
    checkPasswordValidationMessage,

    setEmailValidationMessage,
    emailValidationMessage,
    emailResponseMessage,

    setName,
    setEmail,
    setCountryCode,
    setPhone,
    setPassword,
    setCheckPassword,
    setVerified,

    sendVerifyEmail,
    confirmVerifyEmail,
    isEmailVerified
  } = props.model;

  return (
    <ul className="fm-list">
      <EmailConfirmField
        value={email}
        isVerified={isEmailVerified}
        message={emailValidationMessage || emailResponseMessage}
        onChange={(email: string) => {
          setEmailValidationMessage('');
          setEmail(email);
        }}
        onClear={() => setVerified(false)}
        onSendVerify={async () => await sendVerifyEmail(email)}
        onCheckVerified={async () => await confirmVerifyEmail(email)}
      />

      <PasswordField
        value={password}
        isError={!!passwordValidationMessage}
        message={passwordValidationMessage}
        onChange={(password: string) => setPassword(password)}
      />

      <PasswordField
        forConfirm={true}
        value={checkPassword}
        isError={!!checkPasswordValidationMessage}
        message={checkPasswordValidationMessage}
        onChange={(password: string) => setCheckPassword(password)}
      />
    </ul>
  )
}));

export default JoinAccount;
