export const ENV = process.env.PUBLIC_URL === 'second-team.com' ? 'live' : 'dev'

export const API_BASEURL = process.env.REACT_APP_BASEURL || 'https://api-dev.cpms.seeso.kr';
export const NEW_BASEURL = process.env.REACT_APP_NEWURL || 'http://localhost:3001';

export const MEMBER_WEBURL = process.env.REACT_APP_MEMBER_WEBURL ||  'http://localhost:3001';
export const PARTNER_WEBURL = process.env.REACT_APP_PARTNER_WEBURL ||  'http://localhost:3001';


export const AUTH_TOKEN_KEY = "authtoken";
export const AUTH_UUID = 'authuuid';
export const AUTH_USERTYPE = 'authusertype';

export const STATE = {
  pending: "pending",
  done: "done",
  error: "error"
};
export const STATE_LIST: Array<string> = [STATE.pending, STATE.done, STATE.error];

export const AvailabilityOptions = [
  { value: '', label: ''},
  { value: "HIGH", label: "현재 적극적으로 구직중입니다." },
  { value: "MODERATE", label: "구직중은 아니나 좋은 제안은 언제나 환영입니다." },
  { value: "LOW", label: '현재 구직의향 없습니다.'}
];

export const PARTNER_ACCOUNT_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  COMPLETED: 'COMPLETED',
  REJECTED: 'REJECTED'
};

export const EMPLOYMENT_TYPE_LABELS = {
  EMPLOYED: 'EMPLOYED',
  FREELANCE: 'FREELANCE'
};
export const EmploymentTypeOptions = [
  { value: '', label: ''},
  { value: EMPLOYMENT_TYPE_LABELS.EMPLOYED, label: "정직원" },
  { value: EMPLOYMENT_TYPE_LABELS.FREELANCE, label: "프리랜서" }
];
export const WorkingHourOptions = [
  { value: 0, label: ''},
  { value: 1, label: "10 시간 미만" },
  { value: 2, label: "10 시간 - 20 시간" },
  { value: 3, label: "20 시간 - 30 시간" },
  { value: 4, label: "30 시간 - 40 시간" },
  { value: 5, label: "40 시간 이상" },
];
export const CoworkingTimeOptions = [
  { value: 'MORNING', label: '평일 오전' },
  { value: 'AFTERNOON', label: '평일 오후' },
  { value: 'EVENING', label: '평일 저녁' },
  { value: 'NIGHT', label: '평일 늦은 밤-새벽' },
  { value: 'WEEKEND_MORNING', label: '주말 오전' },
  { value: 'WEEKEND_AFTERNOON', label: '주말 오후' },
  { value: 'WEEKEND_EVENING', label: '주말 저녁' },
  { value: 'WEEKEND_NIGHT', label: '주말 늦은 밤-새벽' },
];
export const CoworkingTimeMemberOptions = [
  { value: "MORNING", label: "평일 오전" },
  { value: "AFTERNOON", label: "평일 오후" },
  { value: "EVENING", label: "평일 저녁" },
  { value: "NIGHT", label: "평일 새벽" },
  { value: "WEEKENDS", label: "주말/휴일" }
];
export const JobTypeOptions = [
  { value: "PARTTIME", label: "파트타임" },
  { value: "FULLTIME", label: "풀타임" },
  { value: "REMOTE", label: "리모트 근무" },
  { value: "REGULAR", label: "정규직" },
  { value: "CONTRACT", label: "계약직" }
];
export const WorkTypeOptions = [
  { value: '', label: ''},
  { value: "PARTTIME", label: "파트타임" },
  { value: "FULLTIME", label: "풀타임" },
  { value: "EITHER", label: "파트타임/풀타임" }
];
export const ExpectationOptions = [
  { value: "INCOME", label: "부가수입" },
  { value: "CAREER", label: "커리어(경험)" },
  { value: "FREELANCE", label: "프리랜서로의 전향" },
  { value: "JOB", label: "이직" }
];
export const ReferenceLabels = {
  LINKEDIN: 'LINKEDIN',
  BLOG: 'BLOG',
  GITHUB: 'GITHUB',
  BEHANCE: 'BEHANCE',
  DRIBBBLE: 'DRIBBBLE',
  INSTAGRAM: 'INSTAGRAM',
  ETC: 'ETC'
}
export const ReferenceOptions = [
  { value: ReferenceLabels.LINKEDIN, label: 'Linkedin', classname: 'linkedin', viewClassname: 'Linkedin' },
  { value: ReferenceLabels.BLOG, label: 'Blog', classname: 'blog', viewClassname: 'blog' },
  { value: ReferenceLabels.GITHUB, label: 'Github', classname: 'github', viewClassname: 'github' },
  { value: ReferenceLabels.BEHANCE, label: 'Behance', classname: 'behance', viewClassname: 'behance' },
  { value: ReferenceLabels.DRIBBBLE, label: 'Dribbble', classname: 'drib', viewClassname: 'dribbble' },
  { value: ReferenceLabels.INSTAGRAM, label: 'Instagram', classname: 'insta', viewClassname: 'insta' },
  { value: ReferenceLabels.ETC, label: '직접추가', classname: 'plus' }
]

export const jobGroupList = [
  '기획자',
  '디자이너',
  '개발자',
];

export const jobList = [
  '웹 기획',
  '앱 기획',
  '프로덕트 매니징',
  '프로젝트 매니징',
  'QA 테스팅',
  '디자이너',
  '웹 디자인',
  '앱 디자인',
  '기타 디자인(BI 등)',
  '백엔드 개발',
  '프론트 개발',
  '퍼블리싱',
  'iOS 개발',
  '안드로이드 개발',
  '퍼블리셔',
  '프론트 개발',
  '풀스택 개발',
  '웹 개발',
];

export const toolList = [
  'Sketch',
  'Adobe XD',
  'Figma',
  'Zeplin',
  'Invision',
  'Ovenapp',
  'Balsamiq',
  'Jira',
  'Trello',
  'Slack',
  'Notion',
  'Github',
  'G Suite',
  'Google Analytics',
];

export const frameworkList = [
  '.NET Core',
  'amp',
  'Android   ',
  'Angular',
  'Apache',
  'Apollo',
  'ASP.NET',
  'AWS',
  'AWS/GCP',
  'Babel.js',
  'BootStrap',
  'C',
  'C#',
  'C++',
  'Canvas',
  'Celery',
  'Codeigniter',
  'CSS',
  'CSS3',
  'Dart',
  'Delphi',
  'Django',
  'Docker',
  'Dotnet Core',
  'DRF   ',
  'eGovFrameWork',
  'ElasticSearch',
  'ES6',
  'ES6+',
  'Express',
  'ExtJS   ',
  'Firebase',
  'Flask',
  'Flutter',
  'Gatsby',
  'git   ',
  'Go',
  'GraphQL',
  'HTML',
  'Javascript',
  'HTML5',
  'hybrid-app   ',
  'iReport',
  'JAVA',
  'Java / Spring',
  'java(8+)',
  'JavaScript',
  'JavaScript(ES6+)',
  'jest',
  'JPA',
  'jQuery',
  'Jqxgrid',
  'JSP   ',
  'Junit',
  'k8s',
  'Keras   ',
  'Kotlin',
  'Kubernetes   ',
  'laravel   ',
  'linux   ',
  'MariaDB',
  'Mobx',
  'MongoDB',
  'MSSQL',
  'MyBatis',
  'MySQL',
  'Nestjs',
  'Next',
  'Node',
  'Node.js / Express',
  'NoSql',
  'NuxtJS',
  'Objective C',
  'Objective C++',
  'Oracle',
  'Pandas',
  'PHP',
  'PostgreSQL',
  'pwa',
  'Python',
  'python3',
  'React',
  'React Native',
  'Redis',
  'Redux',
  'Restful API',
  'Ruby on Rails',
  'Sass',
  'SCSS',
  'Sequelize',
  'Serverless',
  'Spring',
  'Spring Boot',
  'SQL',
  'Styled Component',
  'Swift',
  'Tensorflow',
  'TestCafe',
  'TypeORM   ',
  'Typescript',
  'Vue',
  'Web app   ',
  'Web AR AFrame',
  'Webpack',
];

export const timeList = [
  '10 시간 미만',
  '10 시간 - 20 시간',
  '20 시간 - 30 시간 ',
  '30 시간 - 40 시간',
  '40 시간 이상  ',
];

export const languageList = [
  '영어',
  '중국어',
  '일본어',
  '스페인어',
  '기타',
];

export const roleInfo = {
  LEAD: '리드하는',
  SUPPORT: '서포트하는',
  JUNIOR: '주니어',
};

export const companyTypeList = [
  '스타트업',
  '중견기업',
  '대기업',
  '프리랜서',
];

export const domainList = [
  '커머스',
  '핀테크',
  'SaaS',
  'B2B',
  '플랫폼',
  '커뮤니티',
  '유틸리티',
  '솔루션',
];

export const rejectCommentList = [
  '현재 하고 있는 일들이 있어서, 프로젝트 일정을 소화하기 어려울 것 같습니다.',
  '프로젝트 작업 범위가 제가 하기에는 부담스러운 것 같습니다.',
  '제가 예상하는 금액보다 적은 것 같습니다.',
  '제안받은 프로젝트 협업 기간이 길어 부담스럽습니다.',
  '프로젝트가 흥미롭지 않습니다.',
  '제 전문 분야(자주 사용하는 언어/프레임워크)가 아닙니다.',
];

export const taxTypeMap = {
  NONE: '소득세 유형이 등록되지 않았습니다.',
  FREELANCE: '개인(사업소득)',
  OTHERS: '개인(기타소득)', // 22.04.07 - deprecated
  SOLO: '개인사업자',
  CORPORATION: '법인사업자'
};

export const lifeStyleData = {
  '억대연봉 만들기': [
    '컨설팅/자문',
    '실무',
    '어드바이징',
    '글로벌한/해외 업무',
    '빠른 테스트를 위한 프로토타이핑/MVP제품',
    '어렵고 도전적인 업무',
    '풀타임 (주 40시간 이상)',
    'N잡 (주 20시간 이상)',
  ],
  '더 나은 세상에 일조하는 삶': [
    '커피챗/업무상담',
    '컨설팅/자문',
    '직무 교육',
    '어드바이징',
    '공익적인 내용',
    'ESG 관련',
    '풀타임 (주 40시간 이상)',
    'N잡 (주 20시간 이상)',
    '파트타임 (주 10시간 이상)',
    '간단한 업무 (주 10시간 이내)',
  ],
  '인생은 끝없는 도전': [
    '컨설팅/자문',
    '직무 교육',
    '사이드/토이 프로젝트',
    '실무',
    '어드바이징',
    '글로벌한/해외 업무',
    '사회적 기업',
    '공동창업',
    '어렵고 도전적인 업무',
    '풀타임 (주 40시간 이상)',
    'N잡 (주 20시간 이상)',
    '파트타임 (주 10시간 이상)',
    '간단한 업무 (주 10시간 이내)',
  ],
  '재미있게 살고 싶다': [
    '커피챗/업무상담',
    '컨설팅/자문',
    '직무 면접',
    '직무 교육',
    '사이드/토이 프로젝트',
    '실무',
    '어드바이징',
    '공동 창업',
    '돈보다 재미',
    '풀타임 (주 40시간 이상)',
    'N잡 (주 20시간 이상)',
    '파트타임 (주 10시간 이상)',
    '간단한 업무 (주 10시간 이내)',
  ],
  '가벼운 부가수익 마련': [
    '커피챗/업무상담',
    '컨설팅/자문',
    '직무 면접',
    '사이드/토이 프로젝트',
    '글로벌한/해외 업무',
    '공익적인 내용',
    '빠른 테스트를 위한 프로토타이핑/MVP제품',
    '간단한 업무 (주 10시간 이내)',
  ],
};

export const workPreferenceList = [
  '커피챗/업무상담',
  '컨설팅/자문',
  '직무 면접',
  '직무 교육',
  '사이드/토이 프로젝트',
  '실무',
  '어드바이징',
  '글로벌한/해외 업무',
  '공익적인 내용',
  'ESG 관련',
  '빠른 테스트를 위한 프로토타이핑/MVP제품',
  '공동 창업',
  '돈보다 재미',
  '어렵고 도전적인 업무',
  '기타(추가 성격)',
  '풀타임 (주 40시간 이상)',
  'N잡 (주 20시간 이상)',
  '파트타임 (주 10시간 이상)',
  '간단한 업무 (주 10시간 이내)'
];

export const coworkingMemberChoices = [
  '',
  '혼자 진행한 프로젝트',
  '1-5명',
  '6-10명',
  '10-20명',
  '21명 이상',
]