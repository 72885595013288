import dayjs from 'dayjs';
import { PARTNER_ACCOUNT_STATUS } from '../../utils/constants';

interface Props {
  value: string;
  created: string;
  approved: string;
  comment: string;
}


const AccountStatusField = (props: Props) => {
  const joinDate = dayjs(props.created).format('YYYY. M. D');
  const approveDate = dayjs(props.approved).format('YYYY. M. D');

  if (props.value === PARTNER_ACCOUNT_STATUS.PENDING) {
    return (
      <div className="header">
        <div className="info-box">
          <h2><span>승인 대기중</span></h2>
          <p>가입 후 승인까지 <br />영업일 1~2일이 소요됩니다.</p>
          <span>가입일 {joinDate}</span>
        </div>
      </div>
    );
  }
  if (props.value === PARTNER_ACCOUNT_STATUS.APPROVED) {
    return (
      <div className="header end">
        <div className="info-box">
          <h2><span>가입 승인 완료</span></h2>
          <p>세컨팀을 빌딩하고 프로젝트를 진행해보세요.</p>
          <span>가입일 {joinDate} / 승인일 {approveDate}</span>
        </div>
      </div>
    )
  }
  if (props.value === PARTNER_ACCOUNT_STATUS.REJECTED) {
    return (
      <div className="header reject">
        <div className="info-box">
          <h2><span>가입 거절됨</span></h2>
          <div className="res-cnts">
            <strong>아래의 사유로 인해 가입이 거절되었습니다. 재검토를 요청하려면 채널톡을 이용해주세요.</strong>
            <p>{ props.comment.split('\n').map( (line, i) => <div key={ i }>{ line }</div>) }</p>
          </div>
          <span>가입일 : {joinDate}</span>
        </div>
      </div>
    )
  }

  return <></>;
}

export default AccountStatusField;
