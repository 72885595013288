import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { observer } from "mobx-react";
import { IAppStore } from "../../../stores/AppStore";

interface Props {
  isOpen: boolean;
  appStore: IAppStore;
  nickname: string;
  onDrop: (text: string) => void;
  onClose: () => void;
}

export default observer(({
  isOpen,
  appStore,
  nickname,
  onDrop: _onDrop,
  onClose,
}: Props) => {
  const { register, getValues, setValue, watch } = useForm();
  const { showLoader, hideLoader } = appStore;

  if (!isOpen) {
    return <></>;
  }

  const onDrop = () => {
    const content = getValues('content');
    
    showLoader();
    _onDrop(content);
    hideLoader();
  }

  return (
    <div className="dimd-layer">
      <div className="ly-box smtype">
        <section>
          <div className="hgroup" style={{ border: 'none' }}>
            <h1>채용 드랍</h1>
            <p>{nickname}님의 협업을 드랍하시겠습니까?</p>
            <p>채용 드랍 사유를 입력해주세요. 입력하신 내용은 멤버에게 공유됩니다.</p>
            <textarea
              title="드랍 사유 입력"
              style={{ marginTop: 16, marginBottom: -32 }}
              placeholder="100자까지 입력 가능합니다."
              {...register('content')}
              maxLength={100}
            />
          </div>

          <div className="btn type2">
            <button type="button" className="actv" onClick={onDrop}>채용 드랍</button>
          </div>
        </section>
        <button type="button" className="btn-close" onClick={onClose}><span>팝업 닫기</span></button>
      </div>
    </div>
  );
});
