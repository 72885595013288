/*
  markup : join_comp_input.html
*/

import EditPrompt from "@components/EditPrompt";
import { IAppStore } from "@stores/AppStore";
import { inject, observer } from "mobx-react";
import { JoinAccount, JoinCompany, JoinPolicy } from './components';
import JoinFooter from "./components/JoinFooter";
import JoinVisual from "./components/JoinVisual";

interface IProps {
  appStore: IAppStore;
  history: any;
}

const PartnerJoinPage = (props: IProps) => {
  const { joinStore, showLoader, hideLoader } = (props as IProps).appStore;

  const onJoin = async () => {
    try {
      showLoader();
      await joinStore.partnerSignup();
      hideLoader();

      props.history.push('/partner/join/done');
    } catch (e) {
      hideLoader();
    }
  };

  return (
    <div className="join-sign">
      <JoinVisual />
      <div className="join-w">
        <div className="inner">
          <h2 className="noline">세컨팀 회사 <br />회원가입</h2>
          <p>
            서비스 이용 적합도를 평가하는 가입 승인 절차가 있으며, <br />
            승인 여부는 영업일 1-2일 후 확인 가능합니다.
          </p>

          <div className="join-form">
            <h3>계정정보</h3>
            <JoinAccount
              model={joinStore.joinAccountForm}
            />

            <h3>회사정보</h3>
            <p>서비스 이용 적합도를 확인하기 위한 회사 정보를 입력해주세요.</p>
            <JoinCompany
              model={joinStore.joinCompanyForm}
            />

            <JoinPolicy
              model={joinStore.joinAgreementForm}
              validatedMode={joinStore.clickedJoin}
            />

            <JoinFooter
              onJoin={onJoin}
            />
          </div>
        </div>
      </div>

      <EditPrompt
        when={joinStore.isEditing && !joinStore.clickedJoin}
      />
    </div>
  )
};

export default inject("appStore")(observer(PartnerJoinPage));
