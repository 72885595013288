import { useState, useEffect } from 'react';
import { inject, observer } from "mobx-react";
import {
  useHistory,
  useParams
} from "react-router-dom";
import { IAppStore } from "../../stores/AppStore";

import partnerAPI from '../../services/partnerAPI';


interface IProps {
  appStore: IAppStore;
}
const Activate: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const history = useHistory();
  const { uidb, token } : any = useParams();

  const { joinStore } = (props as IProps).appStore;
  const { activatedCode, activateEmail } = joinStore.joinAccountForm;

  // const [ statusCode, setStatusCode ] = useState(0);
  
  console.log(uidb, token);

  useEffect(() => {
    async function checking() {
      await activateEmail(uidb, token);
    }
    checking();
  }, [ ]);

  return (
    <main id="snContent" style={{ height: '100vh' }}>
      {
        activatedCode === 0 ?
          <div />
          :
          activatedCode === 200 ?
            <div className="email-inc">
              <h1>세컨팀 회원가입</h1>
              <strong>이메일 인증이 완료되었습니다.</strong>
              <p>회원가입 화면으로 돌아가서 <em>인증확인</em> 버튼을 눌러주세요!</p>
            </div>
          : <div className="email-inc end">
              <h1>세컨팀 회원가입</h1>
              <strong>인증 시간이 만료되었습니다.</strong>
              <p>다시 회원가입해주세요!</p>
              <div className="btn">
                  <a href="#" className="actv">세컨팀 회원가입하기</a>
              </div>
            </div>
      }
    </main>
  )
}));

export default Activate;
