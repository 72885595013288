import JobCategoryForm from "@components/poll-form/JobCategoryForm";
import MemberCountForm from "@components/poll-form/MemberCountForm";
import ProjectDateForm from "@components/poll-form/ProjectDateForm";
import ProjectDescriptionForm from "@components/poll-form/ProjectDescriptionForm";
import ProjectDomainForm from "@components/poll-form/ProjectDomainForm";
import ProjectFileForm from "@components/poll-form/ProjectFileForm";
import ProjectTypeForm from "@components/poll-form/ProjectTypeForm";
import TagForm from "@components/poll-form/TagForm";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { IMemberProfileModel } from "../../profile/models/MemberProfileModel";
import { IExperienceModel } from "../models/ExperienceModel";
import { IJobModel } from "../models/JobModel";
import { ISkillModel } from "../models/SkillModel";

interface Props {
  field: any;
  profile: IMemberProfileModel;
  index: number;
  jobs: Array<IJobModel>;
  experiences: Array<IExperienceModel>;
  skills: Array<ISkillModel>;
  remove: () => void;
}

export default observer(({ field, profile, index, jobs, experiences, skills, remove }: Props) => {
  const { control, watch, getValues, setValue, register, formState: { isSubmitted } } = useFormContext();

  const isTitleErr = window.location.pathname !== '/onboarding' && isSubmitted && !watch(`episodes.${index}.title`);
  // const isDomainErr = isSubmitted && !watch(`episodes.${index}.domain`);
  // const isCountErr = isSubmitted && !watch(`episodes.${index}.coworking_member_count`);
  // const isTitleErr = false;
  const isDomainErr = false;
  const isCountErr = false;

  const experienceUUID = watch(`episodes.${index}.uuid`);
  const isOnboarding = window.location.pathname === '/onboarding';

  const fileName = profile.episodes[index]?.file?.split('/').pop() || '';

  useEffect(() => {
    if (!profile.episodes[index]?.job_categories || profile.episodes[index]?.job_categories?.length < 1) {
      setValue(`episodes.${index}.job_categories`, [{}], { shouldDirty: false });
    }
  }, []);

  return (
    <div id={experienceUUID} className={isOnboarding ? 'pjt-detail-box' : ''}>
      {/* <div
                className="dimd-layer"
                style={{ position: 'relative', overflow: 'visible', width: 'auto', height: 'auto', backgroundColor: 'transparent', marginTop: -16 }}
            >
                <button
                    type="button"
                    className="btn-close"
                    onClick={remove}
                >
                    <span>X</span>
                </button>
            </div> */}

      <div className="in-cnts poll-wrap" style={{ border: 'none', padding: 0, marginTop: isOnboarding ? 0 : 32 }}>
        <h5><label htmlFor="pjpName">프로젝트 명*</label></h5>
        <div className="comp-intu">
          <input className={isTitleErr ? 'err' : ''} type="text" id="pjpName" placeholder="프로젝트 명을 입력해주세요." {...register(`episodes.${index}.title`)} />
        </div>

        <h5><label htmlFor="pjpDm">프로젝트에서의 역할*</label></h5>
        <JobCategoryForm
          formKey={`episodes.${index}.job_categories`}
          profile={profile}
        />

        <h5><label htmlFor="jobJ">프로젝트에서 경험한것(언어 및 프레임워크, 툴, 해결한 문제, 스킬 등)*</label></h5>
        <TagForm
          formKey={`episodes.${index}._skill_set`}
          project={profile.episodes[index]}
          placeholder="텍스트 입력 후 엔터키를 눌러주세요(태그 입력)"
        />

        <div className="ques-li portfolio-input">
          <h4 className="smtit" style={{ fontSize: 14, marginTop: 12 }}>선택 입력 항목</h4>
          <h5>함께 협업한 멤버 수</h5>
          <ul style={{ margin: 0 }}>
            <li style={{ margin: 0 }}>
              <MemberCountForm
                formKey={`episodes.${index}.coworking_member_count`}
              />
            </li>
          </ul>

          <h5>프로젝트 유형</h5>
          <ul style={{ margin: 0 }}>
            <li style={{ margin: 0 }}>
              <ProjectTypeForm
                formKey={`episodes.${index}.type`}
              />
            </li>
          </ul>

          <div className="fm-w">
            <h5 style={{ marginBottom: 8 }}>시작 연도와 기간</h5>
            <ProjectDateForm
              project={profile.episodes[index]}
              yearKey={`episodes.${index}.year`}
              durationKey={`episodes.${index}.duration`}
            />
          </div>

          <div className="fm-w">
            <h5 style={{ marginBottom: 8 }}>프로젝트 분야</h5>
            <ProjectDomainForm
              formKey={`episodes.${index}.domain`}
            />
          </div>

          <div className="fm-w">
            <h5 style={{ paddingBottom: 24 }}>프로젝트 소개</h5>
            <ProjectDescriptionForm
              formKey={`episodes.${index}.description`}
            />
          </div>

          <div className="fm-w">
            <h5 style={{ marginBottom: 8 }}>프로젝트에 대해 참고할 수 있는 URL이나 파일이 있으시면, 등록해주세요.</h5>
            <ProjectFileForm
              fileKey={`episodes.${index}.file`}
              linkKey={`episodes.${index}.link`}
              fileName={fileName}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
