import { useFormContext } from "react-hook-form";
import styled from "styled-components";

interface IProps {
  formKey?: string;
}

const RadioSpan = styled.span`
  margin-top: 12px;
  width: 100%;
`;

const ProjectTypeForm = ({ formKey }: IProps) => {
  const key = formKey || 'type';
  const { register, watch } = useFormContext();

  console.log(watch(key));
  return (
    <div className="ans type2">
      <RadioSpan>
        <input type="radio" {...register(key)} id={key + '1'} value={1} defaultChecked={watch(key) === 1} />
        <label htmlFor={key + '1'}>회사 프로젝트<span>(ex. 회사에 소속되어 진행한 프로젝트)</span></label>
      </RadioSpan>
      <RadioSpan>
        <input type="radio" {...register(key)} id={key + '2'} value={2} defaultChecked={watch(key) === 2} />
        <label htmlFor={key + '2'}>클라이언트와 함께 협업한 프로젝트<span>(ex. 부업, N잡, 프리랜서)</span></label>
      </RadioSpan>
      <RadioSpan>
        <input type="radio" {...register(key)} id={key + '3'} value={3} defaultChecked={watch(key) === 3} />
        <label htmlFor={key + '3'}>개인적으로 진행한 프로젝트<span>(ex. 토이 프로젝트)</span></label>
      </RadioSpan>
    </div>
  );
}

export default ProjectTypeForm;
