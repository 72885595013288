/*
  markup : mypage_dashboard.html
*/

import CareerPoolConfirmModal from "@modals/CareerPoolConfirmModal";
import PoolQNAModal from "@modals/PoolQNAModal";
import { ICandidate } from "@models/Candidate";
import { IAppStore } from "@stores/AppStore";
import { IProfileStore } from '@stores/profileStore';
import { AUTH_USERTYPE } from "@utils/constants";
import axios from "axios";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';

import styled from 'styled-components';
import { getWindowDimensions } from '../../utils/utils';
import MySecondTeam from "./components/MySecondTeam";
import SecondTeamCard from "./components/SecondTeamCard";

interface IProps {
  appStore: IAppStore;
  history: any
}

const PoolDetailPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const history = useHistory();
  let { id }: { id: string } = useParams();
  const { profileStore, userStore } = (props as IProps).appStore;
  const { memberProfile, memberAccount, getMemberProfile, partnerProfile, getMemberAccount } = (profileStore as IProfileStore);
  const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const isMobile = windowDimensions.width < 786;

  const [isOpenQNA, setOpenQNA] = useState(false);
  const [thumbnail, setThumbnail] = useState('');
  const [owner, setOwner] = useState('');

  useEffect(() => {
    getMemberProfile();
    load();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const load = async () => {
    const { data: _data } = await axios.get(`/candidate-pools/${id}`);
    setThumbnail(_data.thumbnail);
    setOwner(_data.owner);
  }


  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };


  if ((userType === 'MEMBER' && !memberProfile) || !thumbnail) {
    return (
      <div />
    )
  }

  return (
    <>
      <div className="mypage-w">
        <div className="header-type2" style={{ overflow: 'hidden' }}>
          <SecondTeamCard
            id={id}
            windowWidth={windowDimensions.width}
            isMargin={false}
            data={memberProfile}
          />
        </div>

        <div className="conts-w2" style={{ marginTop: 32, marginBottom: isMobile ? -88 : -128 }}>
          {
            userType === 'MEMBER' &&
            <div style={{ background: '#f6f6fe', padding: 32, borderRadius: 8, display: 'flex', justifyContent: isMobile ? 'center' : 'space-between', flexDirection: isMobile ? 'column' : 'row' }}>
              <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                <img
                  src={thumbnail}
                  style={{ width: 100, height: 100, alignSelf: 'center' }}
                />

                <div style={{ marginLeft: isMobile ? 0 : 32, marginTop: isMobile ? 24 : 12, textAlign: isMobile ? 'center' : 'start' }}>
                  <h2>무엇이든 물어보세요</h2>
                  <p style={{ marginTop: 8, lineHeight: '24px', color: 'gray' }}>
                    질문을 남기시면 해당 회사의 담당자가 <br />메일로 답변을 드립니다.
                  </p>
                </div>
              </div>
              <button
                type="button"
                style={{ background: 'lightgray', marginTop: 24, padding: '8px 16px', borderRadius: 8, fontWeight: 'bold', height: 40, backgroundColor: '#685cf4', color: 'white', width: isMobile ? 100 : 88, alignSelf: 'center' }}
                onClick={() => setOpenQNA(true)}
              >
                질문하기
              </button>
            </div>
          }
        </div>

        <MySecondTeam
          id={id}
          data={memberProfile}
        />
      </div>

      <PoolQNAModal
        isOpen={isOpenQNA}
        partnerUUID={owner}
        onClose={() => setOpenQNA(false)}
      />

      <CareerPoolConfirmModal
        id={id}
        isOpen
        onClose={() => {}}
      />
    </>
  )
}));

export default PoolDetailPage;
