import { useState } from 'react';
import StartDateModal from './StartDateModal';
import StartDateCheckModal from './StartDateCheckModal';
import axios from 'axios';
import Loader from 'react-loader';
import { IAppStore } from "../../../stores/AppStore";
import { IDetailModel } from '../models/DetailModel';

interface Props {
  wage: number | null;
  uuid: string;
  appStore: IAppStore;
  projectUUID: string;
  project: IDetailModel;
}

export default ({ wage, uuid, appStore, projectUUID, project }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [isCheckOpen, setCheckOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [date, setDate] = useState('');

  const submitStartDate = async (startDate: string) => {
    setLoading(true);
    try {
      await axios.put(`/partner/${uuid}/projects/${projectUUID}/set-start-date`, {
        start_date: startDate.replaceAll('.', '-')
      });
      setOpen(false);
      
      window.location.href += "#start";
      window.location.reload();
    } catch (e) {
      alert('오류가 발생하였습니다. 다시 시도해주세요!');
    }
    setLoading(false);
  };

  return (
    <div className="pjt-adm-detail">
      <div className="pjt-def-info">
        <strong>아직 시작되지 않은 협업입니다.</strong>
        {
          isLoading ?
            <Loader loaded={false} opacity={0.5} options={{ opacity: 0.5, color: '#5539ff' }} />
            :
            <a style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>채용 시작일 지정하기</a>
        }
      </div>
      <div className="rec-info">
        <ul>
          <li>채용 시작일부터 채용이 시작됩니다.</li>
          <li>채용이 시작되면 시작일을 변경할 수 없습니다.</li>
          <li>채용 시작일로부터 1주일 단위로 정산이 진행됩니다.</li>
        </ul>
      </div>

      <StartDateModal
        isOpen={isOpen}
        appStore={appStore}
        onClose={() => setOpen(false)}
        onError={(date: string) => {
          setDate(date);
          setOpen(false);
          setCheckOpen(true);
        }}
        project={project}
        onSubmit={submitStartDate}
      />

      <StartDateCheckModal
        wage={wage}
        startDate={date}
        isOpen={isCheckOpen}
        onClose={() => setCheckOpen(false)}
        onSubmit={() => submitStartDate(date)}
      />
    </div>
  );
}
