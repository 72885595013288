import { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import onboardingAPI from "../../services/onboardingAPI";
import { useFormContext } from "react-hook-form";

interface Props extends OnboardingProps {
  title?: string;
  text?: string;
}

export default observer(({ profile, title, text }: Props) => {
  const { register, getValues, setValue, watch } = useFormContext();

  useEffect(() => {
    setValue('working_time', profile.working_time);
  }, []);

  const onChange = (type: string) => {
    const workingTime: string[] = getValues('working_time');

    if (workingTime.includes(type)) {
      setValue('working_time', workingTime.filter(v => v !== type));
    } else {
      setValue('working_time', [...workingTime, type]);
    }
  };


  return (
    <>
      <h4>{title}</h4>
      <div className="chk-box altype2">
        <span><input type="checkbox" id="firs1" defaultChecked={profile.working_time.includes('MORNING')} onClick={() => onChange('MORNING')} /><label htmlFor="firs1">평일 오전</label></span>
        <span><input type="checkbox" id="firs2" defaultChecked={profile.working_time.includes('AFTERNOON')} onClick={() => onChange('AFTERNOON')} /><label htmlFor="firs2">평일 오후</label></span>
        <span><input type="checkbox" id="firs3" defaultChecked={profile.working_time.includes('EVENING')} onClick={() => onChange('EVENING')} /><label htmlFor="firs3">평일 저녁</label></span>
        <span><input type="checkbox" id="firs4" defaultChecked={profile.working_time.includes('NIGHT')} onClick={() => onChange('NIGHT')} /><label htmlFor="firs4">평일 늦은 밤-새벽</label></span>
        <span><input type="checkbox" id="firs5" defaultChecked={profile.working_time.includes('WEEKEND_MORNING')} onClick={() => onChange('WEEKEND_MORNING')} /><label htmlFor="firs5">주말 오전</label></span>
        <span><input type="checkbox" id="firs6" defaultChecked={profile.working_time.includes('WEEKEND_AFTERNOON')} onClick={() => onChange('WEEKEND_AFTERNOON')} /><label htmlFor="firs6">주말 오후</label></span>
        <span><input type="checkbox" id="firs7" defaultChecked={profile.working_time.includes('WEEKEND_EVENING')} onClick={() => onChange('WEEKEND_EVENING')} /><label htmlFor="firs7">주말 저녁</label></span>
        <span><input type="checkbox" id="firs8" defaultChecked={profile.working_time.includes('WEEKEND_NIGHT')} onClick={() => onChange('WEEKEND_NIGHT')} /><label htmlFor="firs8">주말 늦은 밤-새벽</label></span>
      </div>
    </>
  );
});

// MORNING: 평일 오전
// AFTERNOON: 평일 오후
// EVENING: 평일 저녁
// NIGHT: 평일 늦은 밤-새벽
// WEEKEND_MORNING: 주말 오전
// WEEKEND_AFTERNOON: 주말 오후
// WEEKEND_EVENING: 주말 저녁
// WEEKEND_NIGHT: 주말 늦은 밤-새벽
