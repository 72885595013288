import { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import onboardingAPI from "../../services/onboardingAPI";
import { useFormContext } from "react-hook-form";

interface Props extends OnboardingProps {
  title?: string;
  text?: string;
}

export default observer(({ profile, title, text }: Props) => {
  const { register, getValues, setValue, watch } = useFormContext();
  
  const onYes = () => {
    setValue('working_flexibility', true);
  };

  const onNo = () => {
    setValue('working_flexibility', false);
  };

  return (
    <>
      <h4>{title}</h4>
      <div className="cho-rbox">
        <ul>
          <li><input type="radio" name="choA" id="choA1" onClick={onYes} defaultChecked={profile.working_flexibility || false} /><label htmlFor="choA1">네, 거래 가능한 시간을 추가할 수 있습니다.</label></li>
          <li><input type="radio" name="choA" id="choA2" onClick={onNo} defaultChecked={!profile.working_flexibility || false} /><label htmlFor="choA2">아니요, 거래 가능한 시간을 추가할 수 없습니다.</label></li>
        </ul>
      </div>
    </>
  );
});
