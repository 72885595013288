import axios from "axios";

const partnerAPI = {
  URL: "/partner",

  resume: async function (email: string) {
    try {
      const { data }: { data: {
        company_name: string;
      } } = await axios.get(
        `${ this.URL }/resume?email=${ email }`
      );
      return data.company_name;
    } catch (e) {
      throw e;
    }
  },
  signup: async function (params: any) {
    // /partner/signup
    // - email(EmailField)
    // - password(CharField)
    // - name(CharField)
    // - phone(CharField)
    // - company_name(CharField)
    // - business_area(CharField)
    // - in_house_resource(ArrayField: RESOURCE_CHOICES)
    // - marketing_agreement(BooleanField)

    // console.log(params);

    try {
      const { data }: { data: {} } = await axios.post(
        `${ this.URL }/signup`,
        {
          ...params
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  },
  resendActivation: async function (email: string) {
    try {
      const { data }: { data: {email: string} } = await axios.post(
        `${ this.URL }/signup/resend-activation-link`,
        {
          email
        }
      );
      return data.email;
    } catch (e) {
      throw e;
    }
  },
  activate: async function (uidb: string, token: string) {
    try {
      const data: any = await axios.get(`/auth/email/verify/${ uidb }/${ token }`);
      return data.status;
    } catch (e) {
      throw e;
    }
  }
};

export default partnerAPI;
