const MAX_LENGTH = 3000;


const IntroductionField = (props: {
  label: string,
  value?: string,
  isError?: boolean,
  onChange: (value: string) => void
}) => {
  return (
    <div className="in-cnts">
      <h3><label htmlFor="compItd">{ props.label }</label></h3>
      <div className="comp-intu">
        <textarea id="compItd"
          value={ props.value }
          style={props.isError ? {
            background: 'rgba(255,78,22,0.05)',
            border: '1px solid #ff4e16',
          } : {}}
          onChange={ (e) => {
            props.onChange(e.target.value.substring(0, MAX_LENGTH));
            if(e.target.value.length > MAX_LENGTH) {
              alert('3,000자까지 입력 가능합니다.');
            }
          }}
          placeholder="회사에 대해 소개해주세요."
        />
        <span>{ props.value?.length || 0 } / 3,000</span>
      </div>


      {/* {
        props.isError &&
        <p className="err" style={{ color: '#f00', fontSize: 12 }}>회사소개를 입력해주세요.</p>
      } */}
    </div>
  )
}
export default IntroductionField;