import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useForm } from 'react-hook-form';
import { IAppStore } from "../stores/AppStore";
import axios from 'axios';

interface Props {
  appStore: IAppStore;
  isOpen: boolean;
  onClose: () => void;
}

export default observer(({
  appStore,
  isOpen,
  onClose,
}: Props) => {
  const { showLoader, hideLoader } = appStore;
  
  const { register, watch, setValue, handleSubmit, reset, formState: { isSubmitted } } = useForm();
  const [isCurrentErr, setCurrentErr] = useState(false);
  const currentWatch = watch('password');
  const newWatch = watch('new_password');
  const checkWatch = watch('check_password');
  const isCurrentEmpty = isSubmitted && (currentWatch || '').length < 6
  const isNewErr = isSubmitted && (newWatch || '').length < 6;
  const isConfirmErr = isSubmitted && checkWatch !== newWatch;

  useEffect(() => {
    reset();
  }, [isOpen])

  if (!isOpen) {
    return <></>;
  }


  const onSubmit = async (form: any) => {
    if (form.password.length < 6 || form.new_password.length < 6 || form.new_password !== form.check_password) {
      return;
    }

    showLoader();

    try {
      await axios.post(`/auth/users/password/check`, form);
    } catch (err: any) {
      setCurrentErr(true);
      hideLoader();
      return;
    }

    setCurrentErr(false);

    try {
      await axios.post(`/auth/users/password/change`, {
        current_password: form.password,
        new_password: form.new_password,
      });
      alert('비밀번호가 변경되었습니다.')
    } catch (err: any) {
      console.log(err.response);
      const msg = err.response.data.message;
      if (msg) {
        alert(msg);
      }
    }
    
    hideLoader();
    onClose();
  }

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3>비밀번호 변경하기</h3>
          <div className="ch-pw">
            <div>
              <label htmlFor="rPw">현재 비밀번호</label>
              <span>
                <input type="password" id="rPw" placeholder="현재 비밀번호를 입력해주세요" className={isCurrentErr || isCurrentEmpty ? 'err' : ''} {...register('password')} />
                <button type="button" className="btn_pw_v"><span>비밀번호 보기</span></button>
                {
                  isCurrentErr &&
                  <p className="err">비밀번호가 일치하지 않습니다.</p>
                }

                {
                  isCurrentEmpty &&
                  <p className="err">최소 6자리 이상 입력해주세요.</p>
                }
              </span>
            </div>
            <div>
              <label htmlFor="nPw">새 비밀번호</label>
              <span>
                <input type="password" id="nPw" placeholder="최소 6자리" title="최소 6자리" className={isNewErr ? 'err' : ''} {...register('new_password')} />
                <button type="button" className="btn_pw_v"><span>비밀번호 보기</span></button>
                {
                  isNewErr &&
                  <p className="err">최소 6자리 이상 입력해주세요.</p>
                }
              </span>
            </div>
            <div>
              <label htmlFor="nPwChk">새 비밀번호 확인</label>
              <span>
                <input type="password" id="nPwChk" placeholder="최소 6자리" title="최소 6자리" className={isConfirmErr ? 'err' : ''} {...register('check_password')} />
                  <button type="button" className="btn_pw_h"><span>비밀번호 가리기</span></button>
              </span>
                {
                  isConfirmErr &&
                  <p className="err">비밀번호가 일치하지 않습니다.</p>
                }
            </div>
          </div>
          <div className="btn type2">
            <button type="button" className="cancel" onClick={onClose}>취소</button>
            <button type="button" className="actv" onClick={handleSubmit(onSubmit)}>변경하기</button>
          </div>
        </div>
        <button type="button" className="btn-close" onClick={onClose}><span>팝업 닫기</span></button>
      </div>
    </div>
  );
});
