const NDA = () => {
  return (
    <>
      <div className="partner-input">
        <h3>제1조 목적</h3>
        <p>
        본 동의사항은 당사자들이 프로젝트(아래 제2조에서 정의함) 진행 과정에서 공유하게 되는 비밀정보(아래 제2 조에서 정의함) 및 기타 관련 자료의 비밀유지를 위한 사항을 정하는 것을 목적으로 한다.
        </p>

        <br/>
        <h3>제2조 용어의 정의</h3>
        <p>
        본 동의사항에서 사용되는 용어는 아래에서 정의된 의미를 가진다.
        </p>
        
        <p>
        “프로젝트”란 세컨팀 서비스를 통해서 제의된 프로젝트 일체를 의미한다.
        </p>

        <p>
        “비밀정보”는 본 프로젝트의 제의일 이후 양 당사자가 비밀로 유지하기로 합의한 정보로서, 당사자들 사이에 체결할 프로젝트의 존재 및 내용, 아이디어, 고객정보, 재무정보, 고객정보, 영업비밀, 프로그램 및 프로그래밍 소스 등을 포함한다. 단, 본 프로젝트를 제의하기 이전에, 효력 발생 이전에 이미 정보수령자가 알고 있는 정보, 일반적으로 알려진 정보 및 본 동의사항 의무 위반 없이 정보수령자가 독자적으로 발견 또는 개발한 정보는 “비밀정보”에 해당하지 않는다.
        </p>

        <br/>
        <h3>제3조 (사용)</h3>

        <p>
        정보 제공자(회사)는 정보수령자(세컨팀 멤버자)에게 제공한 비밀정보 및 관련 자료가 적법하게 취득하였음을 보장한다. 단, 정보제공자가 정보수령자에게 제공할 비밀정보 및 관련자료의 취득의 적법성 여부가 의심되는 경우 비밀정보 및 관련자료를 제공하기 전에 정보수령자에게 정보를 수령할 지 여부 및 그 사유를 질의 할 수 있으며, 정보수령자가 이에 대하여 승낙한 경우에는 정보수령자가 그 책임을 부담한다.
        </p>

        <p>
        프로젝트를 위하여 정보제공자가 정보수령자에게 제공한 모든 비밀정보 및 관련 자료 등은 프로젝트 검토 및 이행을 위한 목적으로만 사용한다.
        </p>

        <p>
        정보수령자는 제공된 모든 비밀정보 및 관련 자료를 비밀로 유지해야 하며 정보제공자의 사전 승인없이 제3자에게 누설, 제공 또는 공개하지 않는다.
        </p>

        <br/>
        <h3>제4조 (비밀유지의무)</h3>
        <p>
        1.정보수령자는 제공된 모든 비밀정보 및 관련 자료를 비밀로 유지해야 하며 정보제공자의 사전 승인없이 제3자에게 누설, 제공 또는 공개하지 않는다.
        </p>

        <br/>
        <h3>제5조 (손해배상책임)</h3>

        <p>
        본 동의사항을 위반하여 상대방에게 손해가 발생하는 경우, 알유프리 가입자는 직접 손해인지 간접 손해인지 여부를 불문하고 발생한 손해 대하여 배상책임을 진다.
        </p>

        <br/>
        <h3>제6조(기간 및 해지)</h3>

        <p>
        본 동의사항의 해지일은 회원 탈퇴시까지 유효하다.
        </p>

        <p>
        본 계약의 당사자들은 본 계약 기간 및 본 프로젝트의 제안일기준 1년 동안 비밀 유지 의무를 준수하여야 한다.
        </p>

        <p>
        본 동의의 당사자들은 상대방 당사자가 본 계약을 위반하거나 당사자들 사이에 합의에 의하여 종료하기로 결정한 경우 해당 프로젝트에 대한 본 동의사항은 해지된다.
        </p>

        <br/><br/>
        <h4>[부칙]</h4>
        <p>
        이 약관은 2019년 8월 1일부터 유효합니다.
        </p>
      </div>
    </>
  )
}

export default NDA;
