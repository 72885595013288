import { flow, types, Instance, getParent } from "mobx-state-tree";

export const ExperienceModel = types.model('Experience', {
  id: types.number,
  label: types.string,
  job_category: types.string,
  order: types.maybeNull(types.number),
});

export interface IExperienceModel extends Instance<typeof ExperienceModel> {};
