import axios from "axios";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';

interface Props {
  isOpen: boolean;
  memberUUID: string;
  onClose: () => void;
}

const MemberMemoViewModal = ({
  isOpen,
  memberUUID,
  onClose
}: Props) => {
  const { register, watch, setValue, reset, handleSubmit } = useForm();
  const [memoList, setMemoList] = useState<any>([]);

  useEffect(() => {
    load();
  }, [isOpen]);

  const load = async () => {
    const { data } = await axios.get(`/members/${memberUUID}/notes`);
    setMemoList(data);
  };

  if (!isOpen) {
    return <></>;
  }

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3>
            메모확인
          </h3>
          <br />
        </div>

        <ul>
          {
            memoList.length < 1 &&
            <p style={{ fontSize: 15, color: 'gray' }}>작성된 메모가 없습니다.</p>
          }

          {
            memoList.map((v: any) => (
              <li style={{ width: '100%', justifyContent: 'space-between', display: 'flex', borderBottom: '1px solid lightgray', paddingBottom: 12, paddingTop: 12 }}>
                <p style={{ fontSize: 15, display: 'flex', width: '70%', textAlign: 'start', lineHeight: '34px', marginLeft: 8 }}>
                  {v.note}
                </p>

                <div style={{ display: 'flex', marginRight: 8 }}>
                  {/* <span style={{ fontSize: 14, textDecoration: 'underline', marginRight: 4 }}>수정</span> */}
                  <span
                    style={{ fontSize: 14, textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={async () => {
                      if (!window.confirm('메모를 삭제하시겠습니까?')) {
                        return;
                      }

                      await axios.delete(`/members/${memberUUID}/notes/${v.id}`);
                      alert('메모가 삭제되었습니다');

                      await load();
                    }}
                  >
                    삭제
                  </span>
                </div>
              </li>
            ))
          }
        </ul>

        <div className="btn">
          <a href="#" className="actv" onClick={onClose}>확인</a>
        </div>

        <button type="button" className="btn-close" onClick={onClose}>
          <span style={{ fontSize: 16 }}>닫기</span>
        </button>
      </div>
    </div>
  )
};

export default observer(MemberMemoViewModal);
