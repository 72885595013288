import { IIterationModel } from '../models/IterationModel';
import dayjs from 'dayjs';
import { numberWithCommas } from '../../../utils/utils';
import axios from 'axios';
import { IDetailModel } from '../models/DetailModel';
import { inject, observer } from "mobx-react";
import { StateProps } from '../../../interfaces/interfaces';

interface Props extends StateProps {
  project: IDetailModel,
  uuid: string,
  userUUID: string,
  data?: IIterationModel,
  isOpen: boolean;
  onClose: () => void;
}

const PartnerConfirmModal = ({
  project,
  appStore,
  uuid,
  userUUID,
  data,
  isOpen,
  onClose,
}: Props) => {
  const { showLoader, hideLoader } = appStore!;

  if (!isOpen || !data) {
    return <></>;
  }

  const onSubmit = async () => {
    try {
      showLoader();
      await axios.put(`/partner/${userUUID}/projects/${uuid}/iterations/${data.round}/make-payment`);
      hideLoader();

      window.location.reload();
    } catch (e) {
      alert(e.response.data.message);
    }
    // onClose();
  };

  const onCancel = () => {
    onClose();
  };

  const onEnd = () => {
    onClose();
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3>{data.round}번째 주 정산</h3>
          <p>{project.member?.name} 님이 정산을 요청하였습니다. 검토 후 정산을 진행해주세요.</p>
          <div className="sp-end2">
            <ul className="type2">
              <li>
                <i className="tit">정산 기간</i>
                <span>{dayjs(data.start_date).format('YYYY.MM.DD')} - {dayjs(data.end_date).format('YYYY.MM.DD')}</span>
              </li>
              <li>
                <i className="tit">진행 업무</i>
                <div className="prg-work">
                  {data.weekly_work_description}
                </div>
              </li>
            </ul>
          </div>
          <div className="sp-end2">
            <div className="pay-result">
              <div className="app-price">
                <em>정산 요청 주급 <span>수수료 별도</span></em>
                <strong>{numberWithCommas(data.wage || 0)}원</strong>
              </div>
              <div className="def-price">
                <i>기본 주급(선 차감 완료)</i>
                <span>{numberWithCommas(project.proposal.wage || 0)}원</span>
              </div>
              {
                data.wage > (project.proposal.wage || 0) &&
                <div className="def-price">
                  <i>추가 차감 주급</i>
                  <span>{numberWithCommas(data.wage - (project.proposal.wage || 0))}원</span>
                </div>
              }
              {
                data.wage < (project.proposal.wage || 0) &&
                <div className="def-price">
                  <i>환불 예정 주급</i>
                  <span>{(project.proposal.wage || 0) - data.wage > 0 ? '+' : ''}{numberWithCommas((project.proposal.wage || 0) - data.wage)}원</span>
                </div>
              }
              {
                data.round === 1 &&
                <>
                  <div className="app-price matching">
                    <em>세컨팀 매칭 수수료</em>
                    <strong>{numberWithCommas(project.contract_fee || 0)}원</strong>
                  </div>
                  <p className="desc-match">
                    {
                      project.contract_fee ?
                        '첫번째 정산시에만 1회 차감'
                        :
                        '매칭 수수료 무료 (맴버 재매칭)'
                    }
                  </p>
                </>
              }
            </div>
          </div>

          <div className="btn type2">
            {/* <button type="button" className="cancel" onClick={onCancel}>취소</button> */}
            <a style={{ cursor: 'pointer' }} className="actv" onClick={onSubmit}>정산하기</a>
          </div>
        </div>
        <button type="button" className="btn-close" onClick={onEnd}><span>팝업 닫기</span></button>
      </div>
    </div >
  );
};

export default inject("appStore")(observer((PartnerConfirmModal)));
