import { types, Instance } from "mobx-state-tree";
import { StateModel } from "../../../models/models";


export const JoinAgreementModel = StateModel.named('JoinAgreementModel')
  .props({
    terms: types.optional(types.boolean, false),
    privacyPolicy: types.optional(types.boolean, false),
    marketingInformation: types.optional(types.boolean, false)
  })
  .views(self => ({
    get isValid() {
      return [ self.terms, self.privacyPolicy ].every((item) => item);
    },
    get isEditing() {
      if(self.terms || self.privacyPolicy || self.marketingInformation) {
        return true;
      }
      return false;
    }
  }))
  .actions(self => ({
    reset() {
      self.terms = false;
      self.privacyPolicy = false;
      self.marketingInformation = false;
    },
    setTerms(checked: boolean) {
      self.terms = checked;
    },
    setPrivacyPolicy(checked: boolean) {
      self.privacyPolicy = checked;
    },
    setMarketingInformation(checked: boolean) {
      self.marketingInformation = checked;
    },
  }))
  .actions(self => ({
    toJSON() {
      return {
        marketing_agreement: self.marketingInformation,
      }
    }
  }));
  export interface IJoinAgreementModel extends Instance<typeof JoinAgreementModel> {};