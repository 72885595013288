import RightDirectedInput from "@components/RightDirectedInput";
import { observer } from "mobx-react";
import { useEffect, useState } from 'react';
import { useFormContext } from "react-hook-form";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';

interface Props extends OnboardingProps {
  title?: string;
}

export default observer(({ profile, title }: Props) => {
  const { register, getValues, setValue, watch, formState: { isSubmitted } } = useFormContext();
  const [isOpen, setOpen] = useState(false);
  const wageWatcher = watch('expected_annual_salary');
  const isErr = isSubmitted && isNaN(wageWatcher);

  useEffect(() => {
    setValue('expected_annual_salary', profile.expected_annual_salary);
  }, [profile]);

  const onChange = (e: any) => {
    const value = e.target.value;
    if (isNaN(value) || value.length < 1) {
      setValue('expected_annual_salary', null);
      return;
    }

    setValue('expected_annual_salary', Number(value));
  };

  return (
    <>
      <h3 style={{ marginTop: 24 }}>{title}</h3>
      <div className="price-w">
        <RightDirectedInput
          className={isErr ? 'err' : ''}
          type="text"
          placeholder="0"
          title="희망 금액 입력"
          onChange={onChange}
          value={wageWatcher}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          defaultValue={profile?.expected_annual_salary ? String(profile?.expected_annual_salary) : ''}
        />
        <span>원</span>
      </div>
      {
        isErr &&
        <p className="err" style={{ color: '#f00' }}>올바른 금액을 입력해주세요.</p>
      }
    </>
  );
});
