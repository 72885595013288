import { AUTH_UUID } from "@utils/constants";
import axios from "axios";

export interface IMemberSearchParam {
  job_category?: number,
  follow?: boolean,
  latest?: boolean,
  search?: string,
  page_size?: number,
  page?: number,
}

const membersAPI = {
  getMemberList: async () => {
    try {
      const { data }: { data: any } = await axios.get(
        `/members/dashboard`
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  getMemberSearchList: async (params: IMemberSearchParam) => {
    try {
      const { data }: { data: any } = await axios.get(
        `/members/`, { params }
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  follow: async (uuid: string) => {
    try {
      const { data }: { data: any } = await axios.post(
        `/members/${uuid}/follow`,
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  unfollow: async (uuid: string) => {
    try {
      const { data }: { data: any } = await axios.post(
        `/members/${uuid}/unfollow`,
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  getMemberEpisode: async (uuid: string) => {
    const memberUUID = window.localStorage.getItem(AUTH_UUID) || '';
    
    try {
      const { data }: { data: any } = await axios.get(
        `/members/${memberUUID}/episodes/${uuid}`,
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
}

export default membersAPI;
