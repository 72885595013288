const EmailField = (props: {
  value: string;
  showPartnerDesc?: boolean;
}) => {
  return (
    <li>
      <label htmlFor="eMail">이메일</label>
      <input type="text" id="eMail" value={ props.value } disabled={ true } />
      {/* {
        props.showPartnerDesc &&
          <p>기존 seeso.kr 유저는 새로 입력하는 정보로 대체됩니다</p>
      } */}
    </li>
  )
}

export default EmailField;