import { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import { useFormContext } from "react-hook-form";
import CalcModal from '../../pages/onboarding/components/CalcModal';
import RightDirectedInput from "@components/RightDirectedInput";

interface Props extends OnboardingProps {
  title?: string;
  text?: string;
}

export default observer(({ profile, title, text }: Props) => {
  const { register, getValues, setValue, watch, formState: { isSubmitted } } = useFormContext();
  const [isOpen, setOpen] = useState(false);
  const wageWatcher = watch('expected_hourly_wage');
  const isErr = isSubmitted && isNaN(wageWatcher);

  useEffect(() => {
    setValue('expected_hourly_wage', profile.expected_hourly_wage);
  }, [profile]);

  const onChange = (e: any) => {
    const value = e.target.value;
    if (isNaN(value) || value.length < 1) {
      setValue('expected_hourly_wage', '');
      return;
    }

    setValue('expected_hourly_wage', Number(value));
  };

  return (
    <>
      <h3>{title}</h3>
      <div className="btn-calc">
        <button type="button" onClick={() => setOpen(true)}>
          희망 연봉 입력하고 계산하기
        </button>
      </div>
      <div className="price-w">
        <RightDirectedInput
          className={isErr ? 'err' : ''}
          type="text"
          placeholder="0"
          title="희망 금액 입력"
          onChange={onChange}
          value={wageWatcher}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          defaultValue={profile?.expected_hourly_wage ? String(profile?.expected_hourly_wage) : ''}
        />
        <span>원</span>
      </div>
      {
        isErr ?
        <p className="err" style={{ color: '#f00' }}>시간당 거래 금액을 입력해주세요.</p>
        :
        <p>{text}</p>
      }

      <CalcModal
        isOpen={isOpen}
        onClose={(value) => {
          setOpen(false);

          if (value) {
            setValue('expected_hourly_wage', value);
          }
        }}
      />
    </>
  );
});
