import { flow, types, Instance, getParent } from "mobx-state-tree";

export const MetaModel = types.model({
  parent_job_category_id: types.maybeNull(types.number),
  parent_job_category_name: types.maybeNull(types.string),
  main_category: types.string,
  values: types.maybe(types.array(types.string)),
  count: types.maybe(types.number),
});

export interface IMetaModel extends Instance<typeof MetaModel> {};
