import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { rejectCommentList } from '../../../utils/constants';
import { inject, observer } from "mobx-react";
import { StateProps } from "../../../interfaces/interfaces";

interface Props extends StateProps {
  isOpen: boolean;
  onReject: (text: string) => void;
  onClose: () => void;
}

const MemberRejectModal = ({
  isOpen,
  appStore,
  onReject: _onReject,
  onClose,
}: Props) => {
  const { register, getValues, setValue, watch } = useForm();
  const { showLoader, hideLoader } = appStore!;

  if (!isOpen) {
    return <></>;
  }

  const onReject = () => {
    const comment = getValues('member_comment');
    if (!comment) {
      alert('거절 사유를 선택해주세요!');
      return;
    }

    showLoader();
    _onReject(comment);
    hideLoader();
  }

  const onCancel = () => {
    onClose();
  };

  const onEnd = () => {
    onClose();
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box smtype">
        <section>
          <div className="hgroup">
            <h1>거절 사유</h1>
            <p>선택 또는 입력하신 거절 사유는 회사에게 공유됩니다.</p>
          </div>
          <div className="cho-list">
            <ul>
              {
                rejectCommentList.map((text: string, index: number) => (
                  <li>
                    <input
                      type="radio"
                      name="rej"
                      id={`rej${index}`}
                      onClick={() => setValue('member_comment', text)}
                      checked={text === watch('member_comment')}
                    />
                    <label htmlFor={`rej${index}`}>{text}</label>
                  </li>
                ))
              }

              <li className="etc">
                <input type="radio" name="rej" id="etc" />
                <label htmlFor="etc">기타:</label>
                <input
                  type="text"
                  title="기타 이유 입력"
                  {...register('member_comment')}
                  value={rejectCommentList.includes(watch('member_comment')) ? '' : watch('member_comment')}
                  maxLength={100}
                />
              </li>
            </ul>
          </div>
          <div className="btn type2">
            <button type="button" className="actv" onClick={onReject}>
              협업 제안 거절
            </button>
          </div>
        </section>
        <button type="button" className="btn-close" onClick={onClose}><span>팝업 닫기</span></button>
      </div>
    </div>
  );
}

export default inject("appStore")(observer((MemberRejectModal)));
