

import { coworkingMemberChoices } from '../../../utils/constants';
import { IEpisodeModel } from '../models/MemberProfileModel';
import { IJobCategoryModel } from '../models';

const ExperienceCard = ({ data, rootJobs, jobs, onClick }: { data: IEpisodeModel, rootJobs: IJobCategoryModel[], jobs: IJobCategoryModel[], onClick: () => void }) => {
  const { title, domain, coworking_member_count, role, skills, experiences, frameworks, job_category, reference_type, link, file, filename } = data;

  return (
    <div className="pjt-box" onClick={onClick} style={{ cursor: 'pointer' }}>
      <h4>{title || '프로젝트명 미입력'}<span>{data.domain || '프로젝트 분야 미입력'}</span></h4>
      {
        data.coworkers.length > 0 &&
        <div className="mem-w">
          {
            data.coworkers.slice(0, 4).map(v => (
              <span><img src={v.avatar || "/images/@photo.png"} alt="" /></span>
            ))
          }

          {
            data.coworkers.length > 4 &&
            <span className="num">+{data.coworkers.length - 4}</span>
          }
        </div>
      }
      <div className="part">
        <i>역할</i>
        <p>
          {
            data.job_categories.map(v => v.sub_category).join(' / ') || '입력되지 않았습니다.'
          }
        </p>
      </div>
      <div className="mem-num">
        <div>
          <i>시작 연도 / 기간</i>
          {
            (Boolean(data.year) && Boolean(data.duration)) ?
              <span>
                {data.year}년 / {[
                  '',
                  '3개월 이내',
                  '6개월 이내',
                  '1년 이내',
                  '1년 이상',
                ]?.[data.duration!] || ''}
              </span>
              :
              <p>입력되지 않았습니다.</p>
          }
        </div>
        <div>
          <i>협업한 멤버 수</i>
          {
            Boolean(data?.coworking_member_count) ?
              <span>{coworkingMemberChoices[data?.coworking_member_count || 0]}</span>
              :
              <p>입력되지 않았습니다.</p>
          }
        </div>
      </div>
    </div>
  );
};

export default ExperienceCard;
