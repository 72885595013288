import { types, Instance, getParent, flow } from "mobx-state-tree";
import accountAPI from "../services/accountAPI";
import { PartnerAccountModel } from "../pages/account/models";
import { IAppStore } from "./AppStore";
import commonAPI from "../services/commonAPI";
import { StateModel } from "../models/models";
import { StatisticsModel } from "../models/Statistics";
import { MemberProfileModel } from "../pages/profile/models";
import profileAPI from "../services/profileAPI";
import { DetailModel } from "../pages/recruit/models/DetailModel";
import { MemberProposalModel } from "../pages/recruit/models/MemberProposalModel";
import { MemberProposalResModel } from "../pages/recruit/models/MemberProposalResModel";
import { PaginationModel } from "../pages/recruit/models/PaginationModel";
import { PartnerProposalModel } from "../pages/recruit/models/PartnerProposalModel";
import { PartnerProposalResModel } from "../pages/recruit/models/PartnerProposalResModel";
import recruitAPI from "../services/recruitAPI";

const RecruitListStore = StateModel.named('RecruitListStore')
  .props({
    project: types.maybe(DetailModel),
    projectList: types.maybe(PaginationModel),
    partnerAccount: types.maybe(PartnerAccountModel),
    member: types.maybe(MemberProfileModel),
    partnerProposal: types.maybe(PartnerProposalResModel),
    memberProposal: types.maybe(MemberProposalResModel),
    proposal: types.maybe(PartnerProposalModel),
    suggestion: types.maybe(MemberProposalModel),
    statistics: types.maybe(StatisticsModel),
  })
  .views(self => ({
    get uuid(): string {
      return (getParent(self) as IAppStore).userStore.user.uuid;
    },
    get type(): string {
      return (getParent(self) as IAppStore).userStore.user.userType;
    },
    get amount(): number {
      return (this.type === 'PARTNER' ? self.projectList?.balance : self.projectList?.total_payment) || 0;
    },
  }))
  .actions(self => ({
    getProject: flow(function* (projectUUID: string) {
      const { uuid, type } = self;

      try {
        const response = yield (type === 'PARTNER' ? recruitAPI.getPartnerProject(uuid, projectUUID) : recruitAPI.getMemberProejct(uuid, projectUUID));
        self.project = DetailModel.create(response);
      } catch (e) {
        throw e;
      }
    }),
    getStatistics: flow(function* () {
      try {
        const response = yield commonAPI.getStatistics();
        self.statistics = StatisticsModel.create(response);
      } catch(e) {
        throw e;
      }
    }),
    getProejctList: flow(function* (status?: string, page: number = 1) {
      const { uuid, type } = self;

      try {
        const response = yield (type === 'PARTNER' ? recruitAPI.getPartnerProjectList(uuid, status, page) : recruitAPI.getMemberProjectList(uuid, status, page));
        self.projectList = PaginationModel.create(response);
      } catch (e) {
        throw e;
      }
    }),
    getPartnerAccount: flow(function* (uuid?: string) {
      try {
        const response = yield accountAPI.getPartnerAccount(uuid || self.uuid);
        self.partnerAccount = PartnerAccountModel.create(response);
      } catch (e) {
        throw e;
      }
    }),
    getMember: flow(function* (uuid?: string) {
      try {
        const response = yield profileAPI.getMemberProfile(uuid || self.uuid);
        self.member = MemberProfileModel.create(response);
      } catch (e) {
        throw e;
      }
    }),
    getPartnerProposalList: flow(function* (uuid: string, status: string = 'SUBMITTED', page: number = 1) {
      try {
        const response = yield recruitAPI.getPartnerProposalList(uuid || self.uuid, status, page);
        self.partnerProposal = PartnerProposalResModel.create(response);
      } catch (e) {
        throw e;
      }
    }),
    getMemberProposalList: flow(function* (uuid: string, status: string = 'SUBMITTED', page: number = 1) {
      try {
        const response = yield recruitAPI.getMemberProposalList(uuid || self.uuid, status, page);
        self.memberProposal = MemberProposalResModel.create(response);
      } catch (e) {
        throw e;
      }
    }),
    getPartnerProposal: flow(function* (uuid: string) {
      try {
        const response = yield recruitAPI.getPartnerProposal(self.uuid, uuid);
        self.proposal = PartnerProposalModel.create(response);

        const memberRes = yield profileAPI.getMemberProfile(self.proposal.member.uuid);
        self.member = MemberProfileModel.create(memberRes);
      } catch (e) {
        throw e;
      }
    }),
    getMemberSuggestion: flow(function* (uuid: string) {
      try {
        const response = yield recruitAPI.getMemberProposal(self.uuid, uuid);
        self.suggestion = MemberProposalModel.create(response);

        // todo company fetch
        return self.suggestion;
      } catch (e) {
        throw e;
      }
    }),
    agreeNBA: flow(function* (uuid: string) {
      try {
        yield recruitAPI.agreeNBA(self.uuid, uuid);
        self.suggestion?.setNDAAgreement();
      } catch (e) {
        throw e;
      }
    }),
    updateTerminateNotified: flow(function* (uuid: string) {
      try {
        yield recruitAPI.updateTerminateNotified(self.type, self.uuid, uuid);
        // self.suggestion?.setNDAAgreement();
      } catch (e) {
        throw e;
      }
    }),
  }));

export interface IRecruitListStore extends Instance<typeof RecruitListStore> {};

export default RecruitListStore;
