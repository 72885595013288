import { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import onboardingAPI from "../../services/onboardingAPI";
import { useFormContext } from "react-hook-form";

interface Props extends OnboardingProps {
  title?: string;
  text?: string;
}

export default observer(({ profile, title, text }: Props) => {
  const { register, getValues, setValue, watch } = useFormContext();



  return (
    <>
      <h4>{title}</h4>
      <div className="cho-rbox">
        <ul>
          <li><input type="checkbox" id="offOk" {...register('office_work')} defaultChecked={profile.office_work} /><label htmlFor="offOk">오프라인 출근 가능합니다.</label></li>
          <li><input type="checkbox" id="offMOk" {...register('offline_meeting')} defaultChecked={profile.offline_meeting}/><label htmlFor="offMOk">오프라인 미팅 가능합니다.</label></li>
        </ul>
      </div>
    </>
  );
});
