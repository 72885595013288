import { Instance, types, flow } from "mobx-state-tree";
import commonAPI from "@services/commonAPI";
import { StateModel } from '@models/models';
import { StatisticsModel } from "@models/Statistics";
import membersAPI, { IMemberSearchParam } from "@services/membersAPI";
import { DashboardModel } from "@pages/members/models/DashboardModel";
import { IMemberModel, MemberModel } from "@pages/members/models/MemberModel";
import { SearchModel } from "@pages/members/models/SearchModel";

const MemberListStore = StateModel.named('MemberListStore')
  .props({
    dashboard: types.maybe(DashboardModel),
    newMemberList: types.array(MemberModel),
    newMemberPage: 2,
    search: types.maybe(SearchModel),
    statistics: types.maybe(StatisticsModel),
  })
  .views(self => ({
    
  }))
  .actions(self => ({
    getDashboard: flow(function* () {
      try {
        const response = yield membersAPI.getMemberList();
        self.dashboard = DashboardModel.create(response);
      } catch(e) {
        throw e;
      }
    }),
    getStatistics: flow(function* () {
      try {
        const response = yield commonAPI.getStatistics();
        self.statistics = StatisticsModel.create(response);
      } catch(e) {
        throw e;
      }
    }),
    getMemberList: flow(function* (params: IMemberSearchParam) {
      try {
        const response = yield membersAPI.getMemberSearchList(params);
        self.search = SearchModel.create(response);
      } catch(e) {
        throw e;
      }
    }),
    getMemberMore: flow(function* () {
      try {
        const response = yield membersAPI.getMemberSearchList({
          latest: true,
          page_size: 20,
          page: self.newMemberPage,
        });

        const members = response.results.map((data: any) => 
          MemberModel.create(data)
        );
        
        self.newMemberList.replace([...self.newMemberList, ...members]);
        self.newMemberPage += 1;
      } catch (e) {
        throw e;
      }
    }),
  }));

export interface IMemberListStore extends Instance<typeof MemberListStore> {};

export default MemberListStore;
