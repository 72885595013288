import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import DatePicker from "react-datepicker";
import styled from 'styled-components';
import "react-datepicker/dist/react-datepicker.css";
import { IAppStore } from "../../../stores/AppStore";
import { IDetailModel } from '../models/DetailModel';

interface Props {
  isOpen: boolean;
  appStore: IAppStore;
  startDate?: string;
  project: IDetailModel;
  onClose: () => void;
  onError: (date: string) => void;
  onSubmit: (date: string) => void;
}

const DateInput = styled.input`
  height: auto !important; 
  background-color: transparent !important;
  padding: 0;
  background: none;
  color: black !important;
  text-align: start;
  text-decoration: none;
`;

export default ({
  isOpen,
  appStore,
  startDate = dayjs().format('YYYY-MM-DD'),
  project,
  onClose,
  onError,
  onSubmit: _onSubmit
}: Props) => {
  const { showLoader, hideLoader } = appStore;
  const memberNickname = project?.member?.name || '';

  const { watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      date: startDate,
    }
  });

  if (!isOpen) {
    return <></>;
  }

  const onCancel = () => {
    onClose();
  };

  const onEnd = () => {
    onClose();
  };

  const onSubmit = ({ date }: any) => {
    let dateObj: dayjs.Dayjs;
    try {
      dateObj = dayjs(date);
    } catch (err) {
      alert('올바른 형식을 입력해주세요!');
      return;
    }

    const isPast = dateObj.isBefore(dayjs());

    if (isPast) {
      onError(date);
      return;
    }

    showLoader();
    _onSubmit(date);
    hideLoader();

    onClose();
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3>채용 시작일</h3>
          <p>
            {project.proposal.title}의 채용 시작일을 지정해주세요. 지정된 시작일은 {memberNickname} 에게도 알림으로 안내됩니다.
          </p>
          <div className="cho-date">
            <label htmlFor="sDate" style={{ color: 'black' }}>시작 예정일</label>
            <div className="d-w">
              <DatePicker
                selected={new Date(watch('date'))}
                onChange={(date: Date) => setValue('date', dayjs(date).format('YYYY-MM-DD'))}
                minDate={dayjs().subtract(27, 'days').toDate()}
                customInput={
                  <DateInput
                    type="text"
                    id="sDate"
                    className="dateInput"
                    value={watch('date')}
                  />
                }
              />
              <button type="button" className="cal" onClick={() => {
                // @ts-ignore
                document.querySelector('.dateInput').click();
              }}>
                <span>날짜선택</span>
              </button>
            </div>
          </div>

          <p className="ft-desc">시작일을 오늘 또는 이전 날짜로 지정하시면 프로젝트 채용이 바로 시작되며, 시작일을 수정하실 수 없습니다.</p>

          <div className="btn type2">
            {/* <button type="button" className="cancel" onClick={onCancel}>취소</button> */}
            <button type="button" className="actv" onClick={handleSubmit(onSubmit)}>시작일 지정</button>
          </div>
        </div>
        <button type="button" className="btn-close" onClick={onEnd}><span>팝업 닫기</span></button>
      </div>
    </div>
  );
}
