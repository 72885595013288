import { useFormContext } from "react-hook-form";

interface IProps {
  fileKey?: string;
  linkKey?: string;
  fileName?: string;
}

const ProjectFileForm = ({ fileKey, linkKey, fileName }: IProps) => {
  const fKey = fileKey || 'file';
  const lKey = linkKey || 'link';
  const { register, watch, setValue, handleSubmit, getValues, formState: { isSubmitted } } = useFormContext();
  const fName = (watch(fKey)?.name || fileName)?.slice(0, 28);

  const onUpload = (e: any) => {
    const file = e.target.files[0];

    if (file.size > (10 * 1024 * 1024)) {
      alert('10MB 이상 파일은 업로드하실 수 없습니다.');
      return;
    }

    setValue(fKey, file);
    setValue('_fileDelete', false);
  }

  const onDelete = async (e: any) => {
    setValue(fKey, null);
    setValue('_fileDelete', true);
  }

  return (
    <>
      <div className="sel-form">
        <div className="fm-box">
          {
            watch(lKey)?.length > 0 &&
            <span>
              <a href="#">{watch(lKey)}</a>
              <button
                type="button"
                onClick={() => {
                  setValue(lKey, '');
                }}
              >
                <span>삭제</span>
              </button>
            </span>
          }
          <div className="in-sel">
            <input
              type="text"
              {...register('_linkText')}
              placeholder={watch(lKey) ? '' : "URL 입력"}
              onBlur={() => {
                setValue(lKey, getValues('_linkText'));
                setValue('_linkText', '');
              }}
              onKeyUp={(e: any) => {
                if (e.keyCode === 13) {
                  setValue(lKey, getValues('_linkText'));
                  setValue('_linkText', '');
                }
              }}
              disabled={watch(lKey)}
            />
          </div>
        </div>
      </div>

      {
        (watch(lKey)?.length || 0) > 200 &&
        <p className="desc-r" style={{ textAlign: 'end', marginTop: 8, color: 'red', fontSize: 12 }}>
          URL은 최대 200자까지만 입력할 수 있습니다.
        </p>
      }

      <div className="int-form">
        <div className="fm-box">
          {
            (!watch('_fileDelete') && fName) &&
            <span>
              {fName}
              <button type="button" onClick={onDelete}><span>삭제</span></button>
            </span>
          }
          <div className="file-up">
            <input type="file" id="portUp" onChange={onUpload} />
            <label htmlFor="portUp">파일 업로드</label>
          </div>
        </div>
      </div>

      <p className="desc-r" style={{ textAlign: 'end', marginTop: 8, color: 'rgba(13,18,64,0.6)' }}>
        *파일은 10MB까지만 업로드할 수 있습니다.
      </p>
    </>
  );
};

export default ProjectFileForm;
