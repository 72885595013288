import { IDetailModel } from "../models/DetailModel";
import axios from 'axios';
import { inject, observer } from "mobx-react";
import { StateProps } from '../../../interfaces/interfaces';

interface Props extends StateProps {
  uuid: string,
  userUUID: string,
  cantClose: boolean,
  isCancel: boolean,
  data: IDetailModel,
  isOpen: boolean;
  onClose: () => void;
}

const PartnerProjectCloseModal = ({
  uuid,
  appStore,
  userUUID,
  cantClose,
  isCancel,
  data,
  isOpen,
  onClose,
}: Props) => {
  const isNormal = !cantClose && !isCancel;
  const { showLoader, hideLoader } = appStore!;

  if (!isOpen) {
    return <></>;
  }

  const close = async () => {
    try {
      showLoader();
      await axios.put(`/partner/${userUUID}/projects/${uuid}/finalize`);
      hideLoader();

      window.location.reload();
    } catch (e) {
      alert(Object.values(e.response.data)[0]);
    }
    // onClose();
  };

  const cancel = async () => {
    try {
      showLoader();
      await axios.put(`/partner/${userUUID}/projects/${uuid}/revoke`);
      hideLoader();

      window.location.reload();
    } catch (e) {
      alert(Object.values(e.response.data)[0]);
    }
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          { isCancel ? <h3>협업 종료 취소</h3> : <h3>협업 종료</h3>}
          {
            isCancel ?
              <>
                <p>협업 종료의 진행을 취소하시겠습니까?  <br /> 종료를 취소하시면, 협업은 계속 진행 중이었던 상태로 변경되며 종료 요청 후 진행된 주에 대한 주급이 즉시 차감됩니다.</p>
                <div className="btn type2">
                  {/* <button type="button" className="cancel">취소</button> */}
                  <button type="button" className="actv" onClick={cancel}>종료 취소</button>
                </div>
              </>
              :
              cantClose ?
                <>
                  <p>종료된 기간 중 정산되지 않은 기간이 있습니다. <br />모든 종료된 기간이 정산 완료 후 협업을 진행해주세요.</p>
                  <div className="btn type2">
                    <button type="button" className="actv" onClick={onClose}>확인</button>
                  </div>
                </>
                :
                <>
                  <p>협업 종료를 진행하시겠습니까? {data.member?.validName}님의 동의 후 협업이 종료됩니다.</p>
                  <div className="s-desc">
                    <p>협업이 종료되면 현재 진행 중인 기간은 취소되고, 이미 차감된 주급은 보유 금액으로 환불 처리됩니다.</p>
                  </div>
                  <div className="btn type2">
                    {/* <button type="button" className="cancel">취소</button> */}
                    <button type="button" className="actv" onClick={close}>종료 진행</button>
                  </div>
                </>
          }

        </div>
        <button type="button" className="btn-close" onClick={onClose}><span>팝업 닫기</span></button>
      </div>
    </div >
  );
}

export default inject("appStore")(observer((PartnerProjectCloseModal)));
