import { observer } from "mobx-react";
import { IPortfolioModel } from '../../pages/profile/models/MemberProfileModel';


const PortfolioField = observer((props: {
  portfolio: IPortfolioModel,
  onUploadFile: (file: FormData) => void;
}) => {
  const { type, name, link, setLink, setName, removeLink, removeFile } = props.portfolio;;

  const handleDelete = async() => {
    if(type === 'LINK') {
      removeLink();
    }
    if(type === 'FILE') {
      await removeFile();
    }
  }
  
  return (
    <div className="in-cnts">
      <h3><label htmlFor="portF">포트폴리오</label></h3>
      <div className="int-form">
        <input type="text" id="portF"
          value={ type ? type === 'LINK' ? link : name : '' }
          placeholder="URL을 입력해주세요."
          title="URL을 입력해주세요."
          onChange={ (e) => {
            setLink(e.target.value);
          }} />
        {
          !name && !link &&
          <div className="file-up">
            <input type="file" id="portUp"
              value={ name }
              onChange={ (e) => {
              if (e.target.files) {
                var formData = new FormData();
                if(e.target.files) {
                  formData.append("file", e.target.files[0]);
                  props.onUploadFile(formData);
                  setName(e.target.files[0].name);
                }
              }
            }}/>

            <label htmlFor="portUp">파일 업로드</label>
          </div>
        }

        {
          type && (link.length > 0 || name.length > 0) &&
          <button type="button" className="btn-del"
            onClick={ handleDelete}><span>삭제</span></button>
        }
      </div>
    </div>
  )
})
export default PortfolioField;