import { observer } from "mobx-react";
import { ISuggestForm } from "../RecruitSuggestionModifyPage";
import { useController, useFormContext } from "react-hook-form";

export default observer(({ title, data }: ISuggestForm) => {
  const { control, watch, getValues, setValue, register, formState: { errors } } = useFormContext();

  return (
    <div className="in-cnts">
      <h5>{title}</h5>
      <textarea
        id="pjtCnts"
        placeholder="세컨팀 멤버가 프로젝트에 대해 이해할 수 있도록 프로젝트 설명을 자세하게 적어주세요. ex. 프로젝트 목적 / 현재 프로젝트 진행 상황 및 계획 / 함께 프로젝트에 참여하는 멤버"
        className={errors.description ? 'err' : ''}
        {...register('description', { required: true })}
      />
    </div>
  );
});
