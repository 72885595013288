import { ICandidatePool } from "@models/CandidatePool";
import axios from "axios";
import { useEffect, useState } from "react";

const PopularCompanyList = () => {
  const [companyList, setCompanyList] = useState<ICandidatePool[]>([]);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const { data } = await axios.get('/secondteam/popular-candidate-pools');
    setCompanyList(data);
  };

  return (
    <>
      {
        companyList.map(v => (
          <li>
            <a href={`/career/pool/${v.id}`}>
              <img src={v.thumbnail} alt={v.name} />
            </a>
          </li>
        ))
      }
    </>
  );
}

export default PopularCompanyList;
