import axios from "axios";
import { useEffect, useState } from "react";
import NotionContainer from "@components/NotionContainer";
import Loader from 'react-loader';
import { CTABlackButton, CTADisabledButton, CTAPurpleButton } from "./components/CTAButton";
import CTAFooter from "./components/CTAFooter";
import { IRegularSurvey } from "@models/RegularSurvey";
import { isMobile } from "react-device-detect";
import { AUTH_USERTYPE } from "@utils/constants";

const SurveyPage = () => {
  const [notionMap, setNotionMap] = useState<any>();
  const [surveyList, setSurveyList] = useState<IRegularSurvey[]>([]);
  const survey1 = surveyList.find(v => v.id === 2);
  const survey2 = surveyList.find(v => v.id === 3);

  useEffect(() => {
    loadNotion();
    load();
  }, []);

  const loadNotion = async () => {
    const { data } = await axios.get(`https://function.second-team.com/notion-chunk?pageId=CareerSurvey-beta-_02-5259306e464b492390e298ce596df08c`);
    setNotionMap(data);
  }

  const load = async () => {
    const { data } = await axios.get(`/regular-surveys`);
    setSurveyList(data);
  }

  if (!notionMap) {
    return (
      <div className="landing-cnts">
        <Loader loaded={false} options={{ opacity: 0.5, color: '#5539ff' }} />
      </div>
    );
  }

  const onSubmit1 = async () => {
    const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';
    if (!userType) {
      window.location.href = '/login';
      return;
    }

    if (survey1?.is_subscribed) {
      await axios.delete(`/regular-surveys/2/subscription`);
      window.location.reload();
      return;
    }

    await axios.post(`/regular-surveys/2/subscription`);
    alert('채용공고 기반의 커리어 설문 신청이 완료되었습니다');
    window.location.reload();
  };

  const onSubmit2 = async () => {
    const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';
    if (!userType) {
      window.location.href = '/login';
      return;
    }
    
    if (survey2?.is_subscribed) {
      await axios.delete(`/regular-surveys/3/subscription`);
      window.location.reload();
      return;
    }

    await axios.post(`/regular-surveys/3/subscription`);
    alert('컨퍼런스 기반의 커리어 설문 신청이 완료되었습니다');
    window.location.reload();
  };

  return (
    <>
      <NotionContainer data={notionMap} />
      <CTAFooter>
        {
          survey1?.is_subscribed ?
            <CTADisabledButton>
              신청완료
            </CTADisabledButton>
            :
            <CTAPurpleButton
              onClick={onSubmit1}
            >
              {isMobile ? '채용공고 설문신청' : '채용공고 기반의 커리어 설문 신청하기'}
            </CTAPurpleButton>
        }

        {
          survey2?.is_subscribed ?
            <CTADisabledButton>
              신청완료
            </CTADisabledButton>
            :
            <CTABlackButton
              onClick={onSubmit2}
            >
              {isMobile ? '컨퍼런스 설문신청' : '컨퍼런스 기반의 커리어 설문 신청하기'}
            </CTABlackButton>
        }
      </CTAFooter>
    </>
  );
}

export default SurveyPage;