import { numberWithCommas } from '../../../utils/utils';
import { IIterationModel } from '../models/IterationModel';
import dayjs from 'dayjs';
import axios from 'axios';
import { IDetailModel } from '../models/DetailModel';

interface Props {
  data: IIterationModel,
  project: IDetailModel,
  uuid: string,
  userUUID: string,
  isOpen: boolean;
  onClose: () => void;
}

export default ({
  data,
  project,
  uuid,
  userUUID,
  isOpen,
  onClose,
}: Props) => {
  if (!isOpen) {
    return <></>;
  }

  const status = {
    PAYMENT_REQUESTED: '요청',
    PAYMENT_COMPLETED: '완료',
  }[data.status] || '검토중';

  const onCancel = async () => {
    try {
      await axios.put(`/members/${userUUID}/projects/${uuid}/iterations/${data.round}/revoke-payment-request`);
      window.location.reload();
    } catch (e) {
      alert(e.response.data.message);
    }
  };

  const onEnd = () => {
    onClose();
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3>{data.round}번째 주 정산 진행 중</h3>
          <p>요청하신 정산이 진행중입니다. {project.partner?.company_name || ''}님의 동의 후 정산이 처리됩니다.</p>
          <div className="sp-end2">
            <ul className="type2">
              <li>
                <i className="tit">정산 기간</i>
                <span>{dayjs(data.start_date).format('YYYY.MM.DD')} - {dayjs(data.end_date).format('YYYY.MM.DD')}</span>
              </li>
              <li>
                <i className="tit">진행 업무</i>
                <div className="prg-work">
                  {data.weekly_work_description}
                </div>
              </li>
            </ul>
          </div>
          <div className="sp-end2">
            <div className="week-pay">
              <span>정산 주급</span>
              <em>{numberWithCommas(data.wage)}원</em>
            </div>
          </div>
          <div className="btn type2">
            <button type="button" className="cancel" onClick={onClose}>취소</button>
            <a style={{ cursor: 'pointer' }} className="actv" onClick={onCancel}>정산 진행 취소</a>
          </div>
        </div>
        <button type="button" className="btn-close" onClick={onClose}><span>팝업 닫기</span></button>
      </div>
    </div>
  );
}
