import { inject, observer } from "mobx-react";
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { IAppStore } from "../../stores/AppStore";


interface IProps {
  appStore: IAppStore;
}
const JoinPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const { joinStore } = (props as IProps).appStore;
  const {
    reset,
  } = joinStore;

  useEffect(() => {
    reset();
  }, [ ]);

  return (
    <div className="join-sign">
      <div className="visual">
          <div className="inner">
              <img src={ "/images/join_img01.png" } alt="" />
          </div>
      </div>
      <div className="join-w arrtype">
        <div className="inner">
          <h2>세컨팀 <br />회원가입</h2>
            <div className="type-choice">
                <span className="ch-comp">
                    <input type="radio" name="typeCho" id="comp" onClick={() => {
                      window.location.href = '/partner/join/form';
                    }}
                    /><label htmlFor="comp">회사 등록</label>
                </span>
                <span className="ch-memb">
                    <input type="radio" name="typeCho" id="memb" onClick={() => {
                      window.location.href = '/member/join/form';
                    }}/><label htmlFor="memb">멤버 등록</label>
                </span>
            </div>
            <div className="btn-link">
              <Link to="/login"><button type="button" className="actv">로그인</button></Link>
            </div>
          </div>
      </div>
  </div>
  )
}));

export default JoinPage;
