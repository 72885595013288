import { PageProps } from "@interfaces/PageProps";
import { IPollStore } from "@stores/PollStore";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import PollDone from "./components/PollDone";
import PollStep1 from "./components/PollStep1";
import PollStep2 from "./components/PollStep2";
import PollStep3 from "./components/PollStep3";
import TagManager from 'react-gtm-module';

export interface PollProps {
  pollStore: IPollStore;
}

const PollPage = ({ appStore }: PageProps) => {
  const { uuid }: { uuid: string } = useParams();
  const { pollStore } = appStore;
  const { step, loadData } = pollStore;

  const form = useForm({
    defaultValues: {
      job_categories: [{}],
    }
  });

  useEffect(() => {
    load();

    TagManager.dataLayer({
      dataLayer: {
        event: 'enterPoll',
        pollUUID: uuid,
      }
    });

    const callback = () => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'leavePoll',
          pollUUID: uuid,
          pollStep: step,
        }
      });
    };

    window.addEventListener('beforeunload', callback);
    
    return () => {
      window.removeEventListener('beforeunload', callback);
    }
  }, []);

  const load = async () => {
    try {
      const { is_responded } = await loadData(uuid);
      if (is_responded) {
        alert('이미 답변한 설문입니다.');
        window.location.href = '/';
      }
    } catch (e) {
      console.log(e);
      alert('잘못된 경로입니다.');
      window.history.back();
    }
  }

  return (
    <FormProvider {...form}>
      {
        step === 0 &&
        <PollStep1
          pollStore={pollStore}
        />
      }

      {
        step === 1 &&
        <PollStep2
          pollStore={pollStore}
        />
      }

      {
        step === 2 &&
        <PollStep3
          pollStore={pollStore}
        />
      }

      {
        step === 3 &&
        <PollDone
          pollStore={pollStore}
        />
      }
    </FormProvider>
  )
};

export default inject('appStore')(observer(PollPage));
