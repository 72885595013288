import { observer } from "mobx-react";
import { ISuggestForm } from "../RecruitSuggestionModifyPage";
import { useController, useFormContext } from "react-hook-form";

export default observer(({ title, data }: ISuggestForm) => {
  const { setValue, watch, formState: { isSubmitted } } = useFormContext();
  const isError = isSubmitted && !watch('duration');

  const onChange = (e: any) => {
    const value = e.target.value;
    if (isNaN(value)) {
      return;
    }

    const num = Number(value);
    setValue('duration', num, { shouldDirty: true });
  };

  return (
    <div className="in-cnts">
      <h5>{title}</h5>
      <div className="price-w">
        <input
          className={isError ? 'err' : ''}
          type="text"
          id="price"
          placeholder="숫자만 입력해주세요."
          title="숫자만 입력해주세요."
          onChange={onChange}
          value={watch('duration')}
        />
        <span>주</span>
      </div>

      {
        isError &&
          <p className="err">희망 협업 기간을 입력해주세요.</p>
      }
    </div>
  );
});
