import { observer } from "mobx-react";
import Loader from 'react-loader';
import { IAppStore } from "../stores/AppStore";

interface Props {
  children?: any;
  appStore: IAppStore;
}

export default observer(({ appStore, children }: Props) => {
  const { isLoading } = appStore;

  return (
    <>

      {
        isLoading &&
        <h1 style={{ alignItems: 'center', top: '50%', position: 'fixed', left: '50%', zIndex: 1004 }}>
          <Loader
            loaded={!isLoading}
            opacity={0.5}
            options={{ opacity: 0.5, color: '#5539ff' }}
          />
        </h1>
      }

      {
        isLoading &&
        <div
          style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: 'white', zIndex: 1000 }}
        />
      }

      {children}
    </>
  );
});
