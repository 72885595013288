/*
  markup : onboarding_member.html
*/
import { OnboardingProps } from '../OnboardingPage';
import { observer } from "mobx-react";
import ProfileImageForm from "../../../components/profile-new-form/ProfileImageForm";
import NicknameForm from "../../../components/profile-new-form/NicknameForm";
import EmploymentTypeForm from "../../../components/profile-new-form/EmploymentTypeForm";
import KeywordForm from "../../../components/profile-new-form/KeywordForm";
import IntroductionForm from "../../../components/profile-new-form/IntroductionForm";
import RegidentForm from "../../../components/profile-new-form/RegidentForm";
import SNSForm from "../../../components/profile-new-form/SNSForm";
import LanguageForm from '@components/profile-new-form/LanguageForm';

interface Props extends OnboardingProps {
}

export default observer(({ profile, account, tab }: Props) => {
  if (tab !== 2) {
    return <></>;
  }

  return (
    <div style={{ display: tab === 2 ? 'block' : 'none' }}>
      <div className="top-info">
        <ProfileImageForm
          profile={profile}
          account={account}
          title="프로필 이미지를 등록해주세요."
        />
      </div>
      <div className="in-cnts">
        <NicknameForm
          profile={profile}
          account={account}
          title="이름 대신 사용할 닉네임을 입력해주세요."
          text="협업 최종 매칭 이전에는 이름 대신 닉네임이 공개됩니다."
        />
      </div>
      <div className="in-cnts">
        <EmploymentTypeForm
          profile={profile}
          account={account}
          title="현재 소속을 알려주세요."
        />
      </div>
      <div className="in-cnts">
        <KeywordForm
          profile={profile}
          account={account}
          title={`키워드로 자신을 소개해주세요!`}
        />
      </div>
      <div className="in-cnts">
        <IntroductionForm
          profile={profile}
          account={account}
          title={'자신에 대한 소개를 적어주세요.'}
        />
      </div>
      <div className="in-cnts">
        <LanguageForm
          title="사용 가능한 외국어가 있나요?"
          profile={profile}
          account={account}
        />
      </div>
      <div className="in-cnts">
        <RegidentForm
          title="현재 거주지가 어디이신가요?"
          profile={profile}
          account={account}
        />
      </div>
      <div className="in-cnts">
        <SNSForm
          profile={profile}
          account={account}
          title={`자신의 이력이나 포트폴리오를 확인할 수 있는 URL을 남겨주세요!`}
        />
      </div>
    </div>
  );
});
