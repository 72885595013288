/*
  markup : mypage_company_new.html
*/

import { useEffect } from 'react';
import { inject, observer } from "mobx-react";
import { IAppStore } from "../../stores/AppStore";
// import { IAccountStore } from '../account/AccountStore';
import { IProfileStore } from '../../stores/profileStore';

import {
  Link, useParams
} from 'react-router-dom';

import {
  JobTypeOptions, MEMBER_WEBURL
} from '../../utils/constants';
import dayjs from 'dayjs';


interface IProps {
  appStore: IAppStore;
}

const PartnerProfilePage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const { accountStore, profileStore } = (props as IProps).appStore;
  const { partnerProfile, getPartnerProfile } = (profileStore as IProfileStore);
  const { uuid }: { uuid: string } = useParams();

  useEffect(() => {
    getPartnerProfile(uuid);
  }, []);

  if (!partnerProfile) {
    return (
      <div />
    );
  }

  return (
    <>
      <div className="mypage-cnts">
        <div className="top-info">
          <div className="profile">
            <div className="photo">
              <span style={{ backgroundImage: `url(${partnerProfile.avatar ? partnerProfile.avatar : "/images/@photo.png"})` }}></span>
            </div>
            <h2>{partnerProfile.company_name}</h2>
            <div className="cate">
              <span>{partnerProfile.industry}</span>
              <a href={partnerProfile.homepage || '#'} target='_blank' className="homp">홈페이지</a>
            </div>
          </div>
          {
            Boolean(uuid) ||
              <div className="in-btn">
                <Link to="/partner/profile/edit">프로필 수정</Link>
              </div>
          }
        </div>
        <div className="myp-incnts">
          <div className="in-cnts">
            <h3>회사소개</h3>
            <div className="comp-intu">
              <p>{partnerProfile.introduction.split('\n').map((line, i) => <div key={i}>{line}</div>)}</p>
            </div>
          </div>
          <div className="in-cnts">
            <h3>서비스 소개</h3>
            <div className="comp-intu">
              <p>{partnerProfile.service_description}</p>
            </div>
          </div>
          {/* <div className="in-cnts">
                <h3>협업 가능한 근무조건</h3>
                <div className="cate-w">
                  {
                    partnerProfile.job_type.map( (job, i) =>
                      <span key={ i }>#{ JobTypeOptions.find(op => op.value === job)?.label }</span>
                    )
                  }
                </div>
            </div> */}
          <div className="in-cnts">
            <h3>테크스택</h3>
            <dl>
              <dt>협업툴</dt>
              <dd>
                {
                  partnerProfile.coworking_tools.map((tool, i) =>
                    <span key={i}>{tool}</span>
                  )
                }
              </dd>
              <dt>개발언어 및 프레임워크</dt>
              <dd>
                {
                  partnerProfile.frameworks.map((framework, i) =>
                    <span key={i}>{framework}</span>
                  )
                }
              </dd>
            </dl>
          </div>
          <div className="in-cnts">
            <h3>기본정보</h3>
            <dl>
              {/* <dt>홈페이지</dt>
                    <dd>
                      {
                        partnerProfile.website &&
                        <a href={ partnerProfile.website } target="_blank" title="새창열림">{ partnerProfile.website }</a>
                      }
                        
                    </dd> */}
              <dt>이메일</dt>
              <dd>
                {
                  partnerProfile.contact_email &&
                  <a href={`mailto:${partnerProfile.contact_email}`}>{partnerProfile.contact_email}</a>
                }
              </dd>
              <dt>설립연도</dt>
              <dd>
                {
                  partnerProfile.established_at &&
                  <span>{partnerProfile.established_at}년</span>
                }
              </dd>
            </dl>
          </div>
          <div className="in-cnts">
            <h3>협업 히스토리</h3>
            <div className="pjt-history">
              {
                partnerProfile.project_history.length < 1 &&
                <p>없음</p>
              }
              {
                partnerProfile.project_history.slice(0, 2).map(v => (
                  <div>
                    <p>{v.title}</p>
                    <em><span>{v.member.job_category}</span>{v.member.nickname}</em>
                    <span className="date">{dayjs(v.date_matched).format('YYYY.MM.DD')} - {v.end_date ? dayjs(v.end_date).format('YYYY.MM.DD') : '진행중'}</span>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}));

export default PartnerProfilePage;
