import axios from "axios";
import { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import { useChannelIOApi } from 'react-channel-plugin';
import {
  AUTH_TOKEN_KEY,
  AUTH_UUID,
  AUTH_USERTYPE,
  MEMBER_WEBURL
} from '@utils/constants';
import NewPortfolioModal from "@modals/NewPortfolioModal";

export default withRouter(({ history }) => {
  const { showChannelButton } = useChannelIOApi();

  useEffect(() => {
    const { pathname } = history.location;
    const token = window.localStorage.getItem(AUTH_TOKEN_KEY) || '';
    const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';

    if ((pathname === '/' || pathname === '/login') && token) {
      if (userType === 'MEMBER') {
        window.location.href = `${MEMBER_WEBURL}?token=${token}`;
      } else if (userType === 'PARTNER' && pathname !== '/') {
        window.location.href = '/';
      }
    }

    // check entering to join when user has token
    if (/join/.test(pathname)) {
      if (token) {
        window.location.href = '/';
      }
    }

    // check auth required page
    checkAuthPage();

    // check onboarding
    if (!/onboarding|account/.test(pathname)) {
      checkOnboarding();
    }

    // check pending
    if (!/account/.test(pathname)) {
      checkPending();
    }

    // set-up channel-talk
    showChannelButton();

    // set-up scroll to top
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => {
      unlisten();
    }
  }, []);

  // check user completed onboarding
  const checkOnboarding = async () => {
    const uuid = window.localStorage.getItem(AUTH_UUID) || '';
    const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';

    if (!uuid || userType !== 'MEMBER') {
      window.localStorage.removeItem('onboarding');
      return;
    }

    // const { data } = await axios.get(`members/${uuid}/profile`);
    // if (!data.profile_completed) {
    //   // window.location.href = '/onboarding';
    //   window.localStorage.setItem('onboarding', 'OK');
    // } else {
    //   window.localStorage.removeItem('onboarding');
    // }
  };

  // check partner accepted register
  const checkPending = async () => {
    const uuid = window.localStorage.getItem(AUTH_UUID) || '';
    const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';

    if (!uuid || userType !== 'PARTNER') {
      window.localStorage.removeItem('pending');
      return;
    }

    const { data } = await axios.get(`partner/${uuid}`);
    if (data.status === 'PENDING') {
      window.location.href = '/partner/account';
      window.localStorage.setItem('pending', 'OK');
    } else {
      window.localStorage.removeItem('pending');
    }
  }

  const checkAuthPage = () => {
    const { pathname } = history.location;

    const uuid = window.localStorage.getItem(AUTH_UUID);
    const pageList = [
      'member/account',
    ];

    if (!uuid && pageList.find(v => pathname.includes(v))) {
      window.location.href = '/login';
    }
  }

  return <></>;
});
