import { types, Instance } from "mobx-state-tree";

export const CalcCountModel = types.model({
  request_withdrawal: types.maybeNull(types.number),
  withdrawal: types.maybeNull(types.number),
  all: types.maybeNull(types.number),
  deposit: types.maybeNull(types.number),
  payment: types.maybeNull(types.number),
})
.views(self => ({
  
}));

export interface ICalcCountModel extends Instance<typeof CalcCountModel> {};
