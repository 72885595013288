import { useState } from 'react';
import { observer } from "mobx-react";

import {
  ReferenceLabels,
  ReferenceOptions
} from '../../utils/constants';
import { IReferenceModel, ReferenceModel } from '../../pages/profile/models/MemberProfileModel';


const DEFAULT = [
  ReferenceLabels.LINKEDIN,
  ReferenceLabels.DRIBBBLE,
  ReferenceLabels.GITHUB
];
const ReferencesField = observer((props: {
  value: IReferenceModel[],
  onAdd: (reference: IReferenceModel) => void,
  onRemove: (reference: IReferenceModel) => void
}) => {
  const valueLabels = props.value.map(v => v.label);
  DEFAULT.map(label => {
    if(!valueLabels.includes(label)) {
      const newReference = ReferenceModel.create({
        label: label
      })
      props.onAdd(newReference);
    }
  })

  return (
    <div className="in-cnts">
      <h3>추가정보</h3>
      <dl className="form in-sns">
        <Reference
          isDefault={ true}
          reference={ props.value.find(m => m.label === ReferenceLabels.LINKEDIN) }
          onRemove={ props.onRemove } />
        
        <Reference
          isDefault={ true}
          reference={ props.value.find(m => m.label === ReferenceLabels.DRIBBBLE) }
          onRemove={ props.onRemove } />
        
        <Reference
          isDefault={ true}
          reference={ props.value.find(m => m.label === ReferenceLabels.GITHUB) }
          onRemove={ props.onRemove } />

        {
          props.value
          .filter((reference: IReferenceModel) => !DEFAULT.includes(reference.label))
          .map( (reference, i) => 
            <Reference
              key={ i }
              reference={ reference }
              onRemove={ props.onRemove } />
          )
        }

      </dl>
      <div className="add-form">
        <div className="inner">
          {
            Object.values(ReferenceLabels)
              .filter(label => !valueLabels.includes(label))
              .map( (label, i) => {
                const option = ReferenceOptions.find(option => option.value === label);
                const blankOption = { value: ReferenceLabels.ETC, label: '직접추가', classname: 'plus' };
                return <AddButton
                  key={ i }
                  label={ option ? option.label : blankOption.label }
                  className={ option ? option.classname : blankOption.classname }
                  onClick={ () => {
                    const newReference = ReferenceModel.create({
                      label: option ? option.value : blankOption.value,
                      display: option ? option.label : blankOption.label,
                    });
                    props.onAdd(newReference);
                  }} />
              })
          }
        </div>
      </div>
    </div>
  )
})
export default ReferencesField;

const Reference = (observer((props: {
  isDefault?: boolean;
  reference?: IReferenceModel;
  onRemove: (reference: IReferenceModel) => void;
}) => {
  if(!props.reference) {
    return <div />
  }
  const { label, link, setLink } = props.reference;
  const option = ReferenceOptions.find(op => op.value === label);
  const className = option ? option.classname : '';
  const displayLabel = option ? option.label : '';

  if(props.reference.label !== ReferenceLabels.ETC) {
    return (
      <>
        <dt>
          <label htmlFor={ label } className={`sns ${ className }`}>{ displayLabel }</label>
        </dt>
        <dd>
          <input type="text" id={ label } value={ link }
            placeholder={`${ displayLabel } URL을 입력해주세요` }
            onChange={ (e) => setLink(e.target.value) } />
          <button type="button" className="btn-del" onClick={() => {
            if(props.isDefault) {
              setLink('');
            } else {
              props.reference && props.onRemove(props.reference);
            }
          } }>
            <span>삭제</span></button>
        </dd>
      </>
    );
  } else {
    <>
      <dt><input type="text" placeholder="직접입력" title="직접입력" /></dt>
      <dd>
          <input type="text" id="dribbble" placeholder="URL을 입력해주세요" title="URL을 입력해주세요" />
          <button type="button" className="btn-del">
            <span>삭제</span></button>
      </dd>
    </>
  } 

  return (
    <button type="button" className={ className }
      onClick={ () => props.reference?.setId(-1)}>{ displayLabel }</button>
  )
}))

const AddButton = (props: {
  label: string,
  className: string,
  onClick: () => void
}) => <button type="button" className={ props.className } onClick={ props.onClick }>{ props.label }</button>
