import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IJoinAccountModel } from "@pages/join/models/JoinAccountModel";
import { IJobModel } from '@pages/onboarding/models/JobModel';
import constantAPI from "@services/constantAPI";
import { IMemberAccountModel } from "@pages/account/models/MemberAccountModel";
import { IMemberProfileModel } from "@pages/profile/models";
import styled from "styled-components";

interface IProps {
  formKey?: string;
  account?: IMemberAccountModel;
  profile?: IMemberProfileModel;
}

const DeleteButton = styled.button`
  &::before {
    background: #5539ff;
  }

  &::after {
    background: #5539ff;
  }
`;

const JobCategoryForm: React.SFC<IProps> = (observer(({ formKey, account, profile }) => {
  const [jobList, setJobList] = useState<IJobModel[]>([]);;
  const [rootJobList, setRootJobList] = useState<IJobModel[]>([]);

  const key = formKey || 'job_categories';
  const { control, watch, getValues, setValue, register, formState: { isSubmitted }, reset } = useFormContext();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    // control,
    name: key,
  });

  const isErrJob = window.location.pathname !== '/onboarding' && isSubmitted && Boolean(watch(key).find((v: any) => !v.main_category || !v.sub_category));

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const jobList = await constantAPI.getCategoryList();
    const rootJobList = await constantAPI.getCategoryRootList();

    setJobList(jobList);
    setRootJobList(rootJobList);

    if (account) {
      setValue(
        key,
        JSON.parse(JSON.stringify(account.job_categories.toJSON())),
        { shouldDirty: false }
      );
    }
  }

  const onAdd = () => {
    append({});
  };

  const onRemove = (index: number) => {
    remove(index);
  };

  if (jobList.length < 1 || rootJobList.length < 1) {
    return <></>;
  }

  return (
    <div className="join-form">


      <div className="job-sug" style={{ borderBottom: 0 }}>
        {
          fields.map(({ main_category, sub_category }: any, idx) => {
            const mainWatch = watch(`${key}.${idx}.main_category`);
            const subWatch = watch(`${key}.${idx}.sub_category`);

            const searchedJob = jobList.filter(v => v.parent === mainWatch && (!subWatch || v.name.includes(subWatch)));

            return (
              <div className="sel-w sel-form">
                <select
                  id="pjtLev"
                  {...register(`${key}.${idx}.main_category`)}
                  style={{
                    color: mainWatch ? '#0d1240' : '#999',
                    minWidth: 100,
                    width: '49%',
                    height: 60,
                    fontSize: 14,
                    padding: 16,
                  }}
                >
                  {
                    Boolean(mainWatch) ||
                    <option value={''}>분야를 선택해주세요.</option>
                  }
                  {
                    rootJobList.sort((a, b) => a.order - b.order).map(v => (
                      <option value={v.name}>{v.name}</option>
                    ))
                  }
                </select>
                <div
                  className="fm-box"
                  style={{
                    width: '49%',
                    minWidth: 'auto',
                    marginRight: 0,
                    paddingRight: 0,
                    height: 60,
                    lineHeight: '60px',
                  }}
                >
                  <div
                    className={`in-sel ${watch(`${key}.${idx}._focus`) ? 'selected' : ''}`}
                    style={{
                      marginRight: 0,
                      height: '100%',
                      lineHeight: '50px',
                      marginTop: '4px !important',
                    }}
                    onClick={() => {
                      if (!mainWatch) {
                        alert('분야를 먼저 선택해주세요');
                        return;
                      }
                    }}
                  >
                    <input
                      type="text"
                      {...register(`${key}.${idx}.sub_category`)}
                      placeholder={mainWatch ? "역할을 입력해주세요" : '분야를 먼저 선택해주세요'}
                      autoComplete="off"
                      disabled={!mainWatch}
                      style={{
                        width: '100%',
                        backgroundColor: 'transparent',
                        padding: '4px 8px 0 8px',
                        height: '100%',
                        color: mainWatch ? '#5539ff' : 'auto',
                      }}
                      onFocus={() => {
                        setValue(`${key}.${idx}._focus`, true);
                      }}
                      onBlur={() => {
                        setTimeout(() => {
                          setValue(`${key}.${idx}._focus`, false);
                        }, 200);
                      }}
                      onKeyUp={(e: any) => {
                        if (e.keyCode === 13) {
                          setValue(`${key}.${idx}._focus`, false);
                        }
                      }}
                    />

                    {
                      idx > 0 &&
                        <DeleteButton type="button" className="btn-del" onClick={(e: any) => {
                          onRemove(idx);
                          e.stopPropagation();
                        }}>
                          <span style={{ background: '#5539ff' }}>삭제</span>
                        </DeleteButton>
                    }

                    <div className="opt-li" style={{ width: '100%' }}>
                      {
                        searchedJob.length < 1 &&
                        <ul>
                          <li>{subWatch}</li>
                        </ul>
                      }

                      <ul>
                        {
                          searchedJob.map(v => (
                            <li onClick={() => setValue(`${key}.${idx}.sub_category`, v.name)}>{v.name}</li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                </div>


                {/* <select
                {...register(`job_categories.${idx}.sub_category`)}
                disabled={!mainWatch}
              >
                {
                  Boolean(mainWatch) ||
                  <option value={''}>역할</option>
                }
                {
                  jobList.filter(v => v.parent === mainWatch).map(v => (
                    <option value={v.name}>{v.name}</option>
                  ))
                }
              </select> */}

                {/* <button
                  type="button"
                  style={{
                    opacity: idx > 0 ? 1 : 0,
                  }}
                  onClick={() => onRemove(idx)}
                >
                  <span>해당 직무 삭제</span>
                </button> */}
              </div>
            );
          })
        }
        {
          isErrJob &&
          <p className="err" style={{ color: 'red' }}>역할을 입력해주세요.</p>
        }

        <div className="link pjt-more" style={{ textAlign: 'end' }}>
          <a style={{ cursor: 'pointer', background: 'none', textDecoration: 'underline', marginTop: 8, width: '100%' }} onClick={onAdd}>추가하기</a>
        </div>
        {/* <button type="button" className="j-add" onClick={onAdd}>추가하기</button> */}
      </div>
    </div>
  );
}));

export default JobCategoryForm;
