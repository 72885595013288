import NotionContainer from "@components/NotionContainer";
import { ICandidatePool } from "@models/CandidatePool";
import { AUTH_USERTYPE } from "@utils/constants";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Loader from 'react-loader';
import styled from "styled-components";

const PoolContainer = styled.div`
  max-width: 720px;
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 10%;
  padding-left: 30px;
  padding-right: 30px;
  overflow: hidden;
`;

const PoolLogo = styled.img`
  width: 128px;
  height: 128px;
  border-ㅖradius: 10%;
`;

const PoolList = styled.ul`
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
  margin-top: -16px;

  /* overflow-x: scroll; */
  /* justify-content: space-around; */
  li {
    display: inline;
    /* margin-right: 24px; */
    cursor: pointer;
    margin: 16px 16px 0 0;
    /* min-width: 256px; */
    /* width: 256px; */
    /* text-align: center; */
    font-size: 15px;
  }

  @media only screen and (max-width: 786px) {
    display: flex;
    flex-direction: column;
    margin-top: 8px;

    li {
      width: 80%;
      margin: 8px auto;
    }
  }
`;

const PoolListPage = () => {
  const [notionMap, setNotionMap] = useState<any>();
  const [poolList, setPoolList] = useState<ICandidatePool[]>([]);
  const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';

  useEffect(() => {
    loadNotion();
    load();
  }, []);

  const loadNotion = async () => {
    const { data } = await axios.get(`https://function.second-team.com/notion-chunk?pageId=03ef4073deae4990b874b249f07e142a`);
    setNotionMap(data);
  }

  const load = async () => {
    const { data } = await axios.get(`/candidate-pools`);
    setPoolList(data);
  }

  if (!notionMap || poolList.length < 1) {
    return (
      <div className="landing-cnts">
        <Loader loaded={false} options={{ opacity: 0.5, color: '#5539ff' }} />
      </div>
    );
  }

  const onClickPool = (id: number) => {
    window.location.href = `/career/pool/${id}`;
  }

  return (
    <>
      <NotionContainer data={notionMap} />

      <PoolContainer>
        <h1 style={{ marginBottom: 24 }}>
          모집중
        </h1>

        <PoolList>
          {
            poolList.sort((a, b) => dayjs(b.open_at).diff(dayjs(a.open_at))).filter(v => dayjs(v.open_at) <= dayjs()).map(v => (
              <li style={{ border: '1px solid #D6D6DA', padding: 24, borderRadius: 16, minHeight: 224 }}>
                <div
                  onClick={() => onClickPool(v.id)} 
                  style={{ cursor: 'pointer' }}
                >
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}>
                    <PoolLogo
                      src={v.thumbnail}
                      alt=""
                      style={{
                        width: 50,
                        height: 50,
                      }}
                    />
                    <div style={{
                      marginLeft: 12,
                    }}>
                      <strong style={{ paddingTop: 8 }}>
                        {v.name}
                      </strong>
                      <p style={{ paddingTop: 8, fontStyle: 'italic' }}>
                        {v.owner_description}
                      </p>
                    </div>
                  </div>

                  <p style={{ marginTop: 20, minHeight: 156, lineHeight: 1.5 }}>
                    {v.description}
                  </p>
                </div>

                {
                  (!v.is_registrable && userType) &&
                  <div
                    style={{ marginTop: 12, background: '#EBFFF1', color: '#119C2B', borderRadius: 6, padding: 8, width: 64, textAlign: 'center' }}
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      window.location.href = `/career/pool/${v.id}/list`;
                    }}
                  >
                    <span>
                      커뮤니티
                    </span>
                  </div>
                }
              </li>
            ))
          }
        </PoolList>

        {
          // poolList.filter(v => dayjs(v.open_at) > dayjs()).length > 0 &&
          <>
            {/* <h1 style={{ marginBottom: 16, marginTop: 54 }}>
              오픈임박
            </h1>
            <p style={{ marginBottom: 24 }}>
              다음 오픈은 8/19(금)에 오픈됩니다.
            </p> */}
            {/* <p style={{ marginBottom: 24 }}>
              다음 오픈은 {dayjs(poolList.filter(v => dayjs(v.open_at) > dayjs())[0].open_at).format('MM/DD(ddd)')}에 오픈됩니다.
            </p>

            <PoolList>
              {
                poolList.filter(v => dayjs(v.open_at) > dayjs()).map(v => (
                  <li style={{ cursor: 'auto' }}>
                    <div style={{ border: '1.5px solid lightgray', borderRadius: '10%', width: 128, height: 128 }}>
                      <PoolLogo
                        src={v.thumbnail}
                        alt=""
                        style={{ filter: 'blur(4px)', width: 124.5, height: 124.5 }}
                      />
                    </div>
                    <p style={{ paddingTop: 8, width: 124.5, textAlign: 'center' }}>
                      {dayjs(v.open_at).format('MM/DD(ddd)')}
                    </p>
                  </li>
                ))
              }
            </PoolList> */}
          </>
        }
      </PoolContainer>
    </>
  );
}

export default PoolListPage;
