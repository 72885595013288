import { Link } from "react-router-dom";

interface IProps {
  onJoin: () => void;
}

const JoinFooter = ({ onJoin }: IProps) => {
  return (
    <div className="btn fx-type">
      <Link to="/join" className="sub">
        이전
      </Link>
      
      <button className="actv" onClick={onJoin}>
        가입하기
      </button>
    </div>
  )
};

export default JoinFooter;

