import axios from "axios";

const onboardingAPI = {
  getProfile: async (uuid: string) => {
    try {
      const { data } = await axios.get(`/members/${uuid}/profile`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  getAccount: async (uuid: string) => {
    try {
      const { data } = await axios.get(`/members/${uuid}`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  getConstants: async () => {
    try {
      const { data } = await axios.get(`/constants/all`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  updateProfile: async (uuid: string, form: any) => {
    try {
      const { data } = await axios.put(`/members/${uuid}/profile`, form);
      return data;
    } catch (e) {
      throw e;
    }
  },
  checkNicknameDuplication: async (params: object) => {
    try {
      const { data } = await axios.get(`/members/nickname/is-duplicate`, { params });
      return data;
    } catch (e) {
      throw e;
    }
  },
  sendAuthCode: async (uuid: string, phoneNumber: string) => {
    try {
      const { data } = await axios.post(`auth/${uuid}/phone-number/send-verification-code`, {
        phone_number: phoneNumber,
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
  checkAuthCode: async (uuid: string, phone: string, code: string) => {
    try {
      const { data } = await axios.post(`auth/${uuid}/phone-number/verify`, {
        phone_number: phone,
        code,
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
};

export default onboardingAPI;
