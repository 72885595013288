/*
  markup : pay_list.html
*/

import { useEffect, useState } from "react";
import { inject, observer } from "mobx-react"
import { IAppStore } from "../../stores/AppStore";
import Pagination from "../../components/Pagination";
import CalcEmpty from "../../components/empty/CalcEmpty";
import CalcMemberRow from './components/CalcMemberRow';
import { numberWithCommas } from "@utils/utils";
import DepositModal from "./components/DepositModal";

interface IProps {
  appStore: IAppStore;
  history: any
}

const MemberCalcPage: React.SFC<IProps> = inject('appStore')(observer((props) => {
  const { calcStore } = (props as IProps).appStore;
  const { uuid, payment, getPayment } = calcStore;
  const [tab, setTab] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const paymentList = (payment?.results || []).filter(v => tab === 0 || [['PAYMENT', 'REQUEST_WITHDRAWAL'], ['WITHDRAWAL']][tab - 1].includes(v.type));

  useEffect(() => {
    if (tab > 0) {
      getPayment({
        type: ['PAYMENT', 'WITHDRAWAL'][tab - 1],
      });
      return;
    }

    getPayment();
  }, [tab]);

  return (
    <>
      <div className="sub-wrap">
        <div className="top-box">
          <h2>
            정산 및 입금 <a href='https://seesorufree.notion.site/87a9314fe3c241bc85174801b387aff0' target="_blank" style={{ textDecoration: 'underline' }}>가이드</a>
          </h2>
          {/* <div className="btn-guide">
            <a href="https://seesorufree.notion.site/87a9314fe3c241bc85174801b387aff0" target="_blank">충전 및 정산 안내</a>
          </div> */}
        </div>
        <div className="pay-wrap">
          <div className="pay-box">
            <div className="pay">
              <i>누적 주급</i>
              <em>{numberWithCommas(payment?.total_payment || 0)}원</em>
            </div>
            <div className="pay">
              <i>지급 가능 정산 금액</i>
              <em>{numberWithCommas(payment?.balance || 0)}원</em>
            </div>
            <button
              type="button"
              onClick={() => setOpen(true)}
            >
              내 계좌로 지급받기
            </button>
          </div>
          <div className="cnts-hgroup2">
            {/* <h3>정산 내역</h3> */}
            <div className="tab">
              <ul>
                <li onClick={() => setTab(0)} style={{ cursor: 'pointer' }} className={tab === 0 ? 'selected' : ''}><a>전체</a></li>
                <li onClick={() => setTab(1)} style={{ cursor: 'pointer' }} className={tab === 1 ? 'selected' : ''}><a>정산</a></li>
                <li onClick={() => setTab(2)} style={{ cursor: 'pointer' }} className={tab === 2 ? 'selected' : ''}><a>지급</a></li>
              </ul>
              {/* <ul>
                <li onClick={() => setTab(0)} style={{ cursor: 'pointer' }} className={tab === 0? 'selected' : ''}><a>전체({payment?.count || 0})</a></li>
                <li onClick={() => setTab(1)} style={{ cursor: 'pointer' }} className={tab === 1 ? 'selected' : ''}><a>정산({(payment?.counts.request_withdrawal || 0) + (payment?.counts.payment || 0)})</a></li>
                <li onClick={() => setTab(2)} style={{ cursor: 'pointer' }} className={tab === 2 ? 'selected' : ''}><a>지급({payment?.counts.withdrawal || 0})</a></li>
              </ul> */}
            </div>
          </div>
          <div className="pay-list">
            <div className="viewport">
              <table>
                <caption>정산 내역</caption>
                <colgroup>
                  <col style={{ width: 125 }} />
                  <col style={{ width: 71 }} />
                  <col style={{ width: 184 }} />
                  <col style={{ width: 164 }} />
                  <col style={{ width: 128 }} />
                  <col style={{ width: 128 }} />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">날짜</th>
                    <th scope="col">분류</th>
                    <th scope="col">상세</th>
                    <th scope="col">적용수수료</th>
                    <th scope="col"></th>
                    <th scope="col">금액</th>
                    <th scope="col">잔여 금액</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    paymentList.map((v: any) => (
                      <CalcMemberRow
                        data={v}
                      />
                    ))
                  }
                </tbody>
              </table>
            </div>
            <span className="scroll"></span>
          </div>

          {
            paymentList.length > 0 ?
              <Pagination
                size={20}
                totalCount={payment?.count || 0}
                onChange={(page: number) => {
                  if (tab > 0) {
                    getPayment({
                      type: ['PAYMENT', 'WITHDRAWAL'][tab - 1],
                    });
                    return;
                  }

                  getPayment({ page });
                }}
              />
              :
              <CalcEmpty />
          }
        </div>
      </div>

      <DepositModal
        uuid={uuid}
        data={payment!}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </>
  )
}));

export default MemberCalcPage;