import { ICoWorker } from "@models/CoWorker";
import profileAPI from "@services/profileAPI";
import { useEffect, useState } from "react";
import { IMemberProfileModel } from "../models";
import CoWorkerCard from "./CoWorkerCard";

interface Props {
  uuid?: string;
  profile: IMemberProfileModel;
}

const CoworkerReview = ({ uuid, profile }: Props) => {
  const [coWorkerList, setCoWorkerList] = useState<ICoWorker[]>([]);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const { results } = await profileAPI.getCoWorkerList(uuid || profile.uuid);
    setCoWorkerList(results);
  };


  return (
    <>
      <div className="tit-w">
        <h3>함께한 동료들의 평가</h3>
        <div className="chk-st">
          <span className="c1" style={{ color: 'rgba(13, 18, 64, 0.6)' }}>동료 평가</span>
          <span className="c2" style={{ color: 'rgba(13, 18, 64, 0.6)' }}>협업 확인</span>
          <span className="c3" style={{ color: 'rgba(13, 18, 64, 0.6)' }}>레퍼런스 체크 가능</span>
        </div>
      </div>
      <div className="refchk-w">
        {
          coWorkerList.length > 0 ?
            coWorkerList.map(v => (
              <CoWorkerCard
                data={v}
              />
            ))
            :
            <div className="pf-box">
              <p>아직 함께한 동료들의 평가가 없습니다.</p>
            </div>
        }
      </div>
    </>
  );
}

export default CoworkerReview;
