import { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import onboardingAPI from "../../services/onboardingAPI";
import { useFormContext } from "react-hook-form";

interface Props extends OnboardingProps {
  title?: string;
  text?: string;
}

export default observer(({ profile, title, text }: Props) => {
  const { register, getValues, setValue, watch } = useFormContext();

  useEffect(() => {
    setValue('expectations', profile.expectations || []);
  }, []);

  const onCheck = (value: string) => {
    const expectationList = getValues('expectations') || [];
    if (expectationList.includes(value)) {
      setValue('expectations', expectationList.filter((v: any) => v !== value));
    } else {
      setValue('expectations', expectationList.concat(value));
    }
  };

  return (
    <>
      <h4>{title}</h4>
      <div className="cho-rbox">
        <ul>
          <li><input type="checkbox" id="newMem1" onClick={() => onCheck('INCOME')} defaultChecked={profile.expectations.includes('INCOME')} /><label htmlFor="newMem1">부가수입</label></li>
          <li><input type="checkbox" id="newMem2" onClick={() => onCheck('CAREER')} defaultChecked={profile.expectations.includes('CAREER')} /><label htmlFor="newMem2">커리어 (경험)</label></li>
          <li><input type="checkbox" id="newMem3" onClick={() => onCheck('FREELANCE')} defaultChecked={profile.expectations.includes('FREELANCE')} /><label htmlFor="newMem3">프리랜서로의 전향</label></li>
          <li><input type="checkbox" id="newMem4" onClick={() => onCheck('JOB')} defaultChecked={profile.expectations.includes('JOB')} /><label htmlFor="newMem4">이직</label></li>
        </ul>
      </div>
    </>
  );
});
