import axios from 'axios';

const messageAPI = {
  getMemberMessageList: async (uuid: string) => {
    try {
      const { data } = await axios.get(`/members/${uuid}/messages`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  getPartnerMessageList: async (uuid: string) => {
    try {
      const { data } = await axios.get(`/partner/${uuid}/messages`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  getMemberMessage: async (uuid: string, proposalUUID: string) => {
    try {
      const { data } = await axios.get(`/members/${uuid}/proposals/${proposalUUID}/messages`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  getPartnerMessage: async (uuid: string, proposalUUID: string) => {
    try {
      const { data } = await axios.get(`/partner/${uuid}/proposals/${proposalUUID}/messages`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  sendMemberMessage: async (uuid: string, proposalUUID: string, content: string) => {
    try {
      const { data } = await axios.post(`/members/${uuid}/proposals/${proposalUUID}/send-message`, {
        content,
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
  sendPartnerMessage: async (uuid: string, proposalUUID: string, content: string) => {
    try {
      const { data } = await axios.post(`/partner/${uuid}/proposals/${proposalUUID}/send-message`, {
        content
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
};

export default messageAPI;
