const NameField = (props: {
  value: string;
  isError: boolean;
  showPublicOpen: boolean;
  publicOpen?: boolean;
  onChangeName: (name: string) => void;
  onChangePublic?: (opened: boolean) => void;
}) => {
  return (
    <li>
      <label htmlFor="name">이름</label>
      <input
        type="text"
        id="name"
        className={ props.isError ? 'err' : ''}
        value={ props.value }
        onChange={ (e) => props.onChangeName(e.target.value) } />
      {/* {
        props.showPublicOpen &&
        <p>
          <input
            type="checkbox"
            id="nOpen"
            checked={ props.publicOpen } 
            onChange={ () => props.onChangePublic !== undefined && props.onChangePublic(!props.publicOpen)} />
          <label htmlFor="nOpen">이름 비공개 설정 (비공개로 설정 시, 프로필에서 이름 대신 닉네임이 보여집니다.)</label>
        </p>
      } */}
      {
        props.isError &&
          <p className="err">이름을 입력해주세요.</p>
      }
      
    </li>
  )
}

export default NameField;
