import { AUTH_TOKEN_KEY, AUTH_USERTYPE, NEW_BASEURL } from "@utils/constants";

const ClubPage = () => {
  // const url = "http:"
  const token = window.localStorage.getItem(AUTH_TOKEN_KEY) || 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMDQ3LCJ1c2VybmFtZSI6ImRkYW5nZ2xlLnlAZ21haWwuY29tIiwiaWF0IjoxNzEwODQ0MTA5LCJleHAiOjE3MTE0NDg5MDksIm9yaWdfaWF0IjoxNzEwODQ0MTA5LCJpc19wbSI6ZmFsc2UsImlzX3J1ZnJlZSI6dHJ1ZSwiaXNfY2xpZW50Ijp0cnVlLCJuYW1lIjoiXHVjNzc0XHViM2Q5XHVhZGZjIiwicGhvbmUiOiIwMTA5MTA1NDUxNCIsImVtYWlsIjoiZGRhbmdnbGUueUBnbWFpbC5jb20iLCJ1dWlkIjoiZmU1MjY2MTctMzk1Yy00ZDQ1LTg3NjUtODQ2NDJjZDBmYTQyIiwicnVmcmVlX2lkIjoiUlU0NzkiLCJjbGllbnRfaWQiOiIiLCJjbGllbnRfbmFtZSI6IiIsImlzX2ludGVybiI6ZmFsc2V9.LpskP_YJmcjTW2yba79Nv3WC9mSu7kO4VKd9pWlnH6U';
  const userType = window.localStorage.getItem(AUTH_USERTYPE) || 'MEMBER';
  

  return (
    <iframe
      src={`${NEW_BASEURL}/clubs?token=${token}&userType=${userType}`}
      className="secondteam-new"
      style={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        borderWidth: 0,
      }}
    />
  );
};

export default ClubPage;
