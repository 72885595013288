import { observer } from "mobx-react";
import { ISuggestForm } from "../RecruitSuggestionModifyPage";
import { useFormContext } from "react-hook-form";
import { numberWithCommas } from "../../../utils/utils";

const regex = /[^0-9]/g;

export default observer(({ title, data, proposal }: ISuggestForm) => {
  const { setValue, watch, formState: { isSubmitted } } = useFormContext();
  const isError = isSubmitted && !watch('wage');

  const onChange = (e: any) => {
    const value = e.target.value.replaceAll(regex, '');
    if (isNaN(value)) {
      return;
    }

    const num = Number(value);
    setValue('wage', num, { shouldDirty: true });
    setValue('_wage', numberWithCommas(value));
  };

  return (
    <div className="in-cnts">
      <h5>{title}</h5>
      <p><em>{data.nickname}</em>님의 희망 시급은 <em>{numberWithCommas(data.expected_hourly_wage || 0)}</em>이므로, <em>10</em>시간 기준 최소 주급은 <em>{numberWithCommas((data.expected_hourly_wage || 0) * 10)}</em> 입니다.</p>
      <div className="price-w">
        <input
          className={isError ? 'err' : ''}
          type="text"
          id="price"
          placeholder="숫자만 입력해주세요."
          title="숫자만 입력해주세요."
          value={watch('_wage') || watch('wage')}
          onChange={onChange}
          onClick={() => {
            if (proposal) {
              alert('주급을 변경하시겠습니까? 변경된 주급은 다음 주차부터 적용됩니다.');
            }
          }}
        />
        <span>원</span>
      </div>

      {
        isError &&
          <p className="err">희망 시급 입력해주세요.</p>
      }
    </div>
  );
});
