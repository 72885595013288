import { types, Instance } from "mobx-state-tree";

export const ChatModel = types.model({
  content: types.string,
  user_name: types.string,
  created_at: types.string,
})
.views(self => ({
  
}));

export interface IChatModel extends Instance<typeof ChatModel> {};
