import axios from 'axios';

const regularSurveyAPI = {
    getRegularSurveyList: async () => {
        try {
            const { data }: { data: any } = await axios.get(
                `/regular-surveys`
            );

            return data;
        } catch (e) {
            throw e;
        }
    },
    postSubscription: async (regularSurveyID: number) => {
        try {
            const { data }: { data: any } = await axios.post(
                `/regular-surveys/${regularSurveyID}/subscription`
            );

            return data;
        } catch (e) {
            throw e;
        }
    },
    deleteSubscription: async (regularSurveyID: number) => {
        try {
            const { data }: { data: any } = await axios.delete(
                `/regular-surveys/${regularSurveyID}/subscription`
            );

            return data;
        } catch (e) {
            throw e;
        }
    }
}

export default regularSurveyAPI;
