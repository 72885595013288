import { checkLogin } from "@utils/utils";
import { PollProps } from "../PollPage";

const PollDone = ({
  pollStore
}: PollProps) => {
  const onCheck = () => {
    console.log(pollStore.uuid);
    
    if (checkLogin()) {
      window.location.href = `/member/profile#${pollStore.uuid}`;
    } else {
      window.location.href = '/login';
    }
  }

  return (
    <div className="poll-wrap end">
      <h2>설문을 완료해주셔서 <br />감사합니다 🎉</h2>
      <div className="cnts">
        <p>작성주신 포트폴리오는 로그인 후 확인이 가능하며, 작성주신 포트폴리오를 분석하여 희망하신 업무 유형에 맞는 제안을 드릴 예정입니다.</p>
        <p>아직 회원이 아니시라면! {pollStore.data?.email} 주소로  회원가입 후 포트폴리오를 확인하실 수 있습니다.</p>
      </div>
      <div className="btn">
        <a href="#" className="actv" onClick={onCheck}>
          내 포트폴리오 확인하기
        </a>
      </div>
    </div>
  )
}

export default PollDone;
