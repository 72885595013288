import dayjs from "dayjs";
import {numberWithCommas} from "../../../utils/utils";
import {IPartnerProposalModel} from "../models/PartnerProposalModel";

interface Props {
  wage: number | null;
  startDate: string,
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export default ({
  wage,
  startDate,
  isOpen,
  onClose,
  onSubmit: _onSubmit,
}: Props) => {
  if (!isOpen) {
    return <></>;
  }

  const onCancel = () => {
    onClose();
  };

  const onEnd = () => {
    onClose();
  };

  const onSubmit = () => {
    _onSubmit();
    onClose();
  };

  const total_rounds = Math.ceil((dayjs().diff(dayjs(startDate), 'day') + 1) / 7)

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <strong>입력된 시작일이 오늘 또는 이전 날짜입니다. </strong>
          <p>협업은 진행 중 상태로 설정되며, 이미 진행된 주에 대한 주급이 즉시 차감됩니다. 진행 중 상태인 프로젝트 채용의 시작일은 수정하실 수 없습니다.</p>
          <div className="sp-end">
            <ul className="type2">
              <li>
                <i className="tit">채용 시작일</i>
                <span>{dayjs(startDate).format('YYYY.MM.DD')}</span>
              </li>
              <li>
                <i className="tit">진행된 주</i>
                <span>{total_rounds}주</span>
              </li>
              <li>
                <i className="tit">주급</i>
                <span>{numberWithCommas(wage)}원</span>
              </li>
              <li>
                <i className="tit">총 차감 금액</i>
                <span>{ wage && numberWithCommas(wage * total_rounds) }원</span>
              </li>
            </ul>
          </div>
          <div className="btn type2">
            <div className="btn type2">
              <button type="button" className="cancel" onClick={onCancel}>취소</button>
              <button type="button" className="actv" onClick={onSubmit}>시작일 지정</button>
            </div>
          </div>
          <button type="button" className="btn-close" onClick={onEnd}><span>팝업 닫기</span></button>
        </div>
      </div>
    </div>
  );
}
