/*
  markup : member_recu_detail.html
*/
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { IRecruitListStore } from '@stores/RecruitListStore';
import { IAppStore } from "../../stores/AppStore";
import Logo from "../../components/Logo";
import { useParams } from "react-router-dom";
import dayjs from 'dayjs';
import { numberWithCommas } from "../../utils/utils";
import { useForm } from "react-hook-form";
import axios from 'axios';
import {
  AUTH_UUID,
} from '../../utils/constants';
import MemberRejectModal from './components/MemberRejectModal';
import Loader from 'react-loader';
import PaymentNoticeModal from "./components/PaymentNoticeModal";
import Linkify from 'react-linkify';

interface IProps {
  appStore: IAppStore;
}


const RecruitMemberSuggestPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const { uuid }: { uuid: string } = useParams();
  const memberUUID = window.localStorage.getItem(AUTH_UUID) || '';

  const { recruitListStore, userStore } = (props as IProps).appStore;
  const { getMemberSuggestion, suggestion, getMember, member, agreeNBA } = (recruitListStore as IRecruitListStore);
  const { register, watch } = useForm();
  const isAgreeTerms = suggestion?.nda_agreement || false;
  const isAgreeCheck = watch('ch1') && watch('ch2') && watch('ch3') && watch('ch4');
  const isAgree = isAgreeCheck && isAgreeTerms;

  const [isOpenReject, setOpenReject] = useState(false);
  const [isOpenPayNotice, setOpenPayNotice] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!memberUUID) {
      window.location.href = '/login?refer=' + window.location.href.replace(window.location.origin, '');
      return;
    }

    getMember(memberUUID);
    getMemberSuggestion(uuid);
  }, []);

  if (!suggestion) {
    return <></>;
  }

  const { partner } = suggestion;
  const submitDate = dayjs(suggestion.date_submitted);
  const modifyDate = dayjs(suggestion.date_modified);

  const onReject = async (comment: string) => {
    setLoading(true);
    try {
      await axios.post(`/members/${memberUUID}/proposals/${uuid}/reject`, {
        member_comment: comment,
      });
      window.location.reload();
    } catch (e) {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    if (!isAgreeTerms) {
      alert('비밀유지 조항 약관에 동의해주세요.');
      return;
    } else if (!isAgreeCheck) {
      alert('체크항목을 확인해주세요.');
      return;
    }

    await axios.post(`/members/${memberUUID}/proposals/${uuid}/accept`);
    window.location.reload();
  };

  const onReject2 = async (comment: string) => {
    setLoading(true);
    try {
      await axios.post(`/members/${memberUUID}/proposals/${uuid}/decline`, {
        declinature_comment: comment,
      });
      window.location.reload();
    } catch (e) {
      setLoading(false);
    }
  };

  const onAgree = async () => {
    setLoading(true);
    try {
      await axios.post(`/members/${memberUUID}/proposals/${uuid}/match`);
      const { project } = await getMemberSuggestion(uuid);
      window.location.href = `/recruits/${project}`;
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="sub-wrap">
        <div className="top-box">
          <h2>{suggestion.title}</h2>
          <p>
            제안일: {submitDate.format('YYYY.MM.DD hh:mm')}
            <br />

            {
              Boolean(suggestion.date_modified) &&
              `최종 수정일: ${modifyDate.format('YYYY.MM.DD hh:mm')}`
            }
          </p>
        </div>
        <div className="pjt-detail">
          <div
            className="company-info"
            onClick={() => window.open(`/partners/${suggestion.partner.uuid}`)}
            style={{ cursor: 'pointer' }}
          >
            <Logo
              data={suggestion.partner}
            />
            <strong>{partner.company_name}</strong>
            <div className="btn-view">
              <a><span>회사정보 보기</span></a>
            </div>
          </div>
          <div className="inner">
            <div className="pjt-info">
              {
                Boolean(suggestion?.contract_method) &&
                <>
                  <h4>계약 방식</h4>
                  <div className="pjt-cnts">
                    <p>
                      {
                        suggestion?.contract_method === 'SECONDTEAM' ?
                          '주급제 정산(세컨팀을 통해 주급제 정산 방식)' :
                          '단순 매칭(고객사가 멤버와 직접 계약 및 정산)'
                      }
                    </p>
                  </div>
                </>
              }

              <h4>제안 유형</h4>
              <div className="pjt-cnts">
                <p>
                  {
                    [
                      '없습니다',
                      '전문성 기반의 짧은 업무',
                      '아웃소싱 프로젝트 의뢰',
                      '프로젝트성 주급 계약',
                      '이직 헤드헌팅',
                      '흥미로운 일',
                    ][suggestion?.type || 0]
                  }
                </p>
              </div>

              <h4>계약 정보</h4>
              <div className="in-cnts no-line">
                <h5>희망 거래 시간</h5>
                <p>{suggestion.working_hours}시간 / 1주일</p>
              </div>
              <div className="in-cnts no-line">
                <h5>주급</h5>
                <p>{numberWithCommas(suggestion.wage || 0)}원</p>
                {/* <p style={{ fontSize: 13, opacity: 0.7 }}>실 지급되는 금액은 수수료와 세금이 제외됩니다.</p> */}
                <span style={{ color: '#5539ff', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setOpenPayNotice(true)}>실제 지급 금액 알아보기</span>
                <PaymentNoticeModal
                  isOpen={isOpenPayNotice}
                  onClose={() => setOpenPayNotice(false)}
                />
              </div>
              <div className="in-cnts no-line">
                <h5>희망 협업 기간</h5>
                <p>{suggestion.duration}주</p>
              </div>

              {
                isAgreeTerms ||
                <>
                  <h4>프로젝트 정보</h4>
                  <div className="agree-cnts">
                    <p>프로젝트 정보를 확인하시려면, <a href="/terms/nda" target="_blank"><em>비밀유지 조항 약관</em></a>에 동의해주세요.</p>
                    <div style={{ cursor: 'pointer', opacity: isAgreeTerms ? 0.5 : 1 }} onClick={() => agreeNBA(suggestion.uuid)}>
                      <p>비밀유지 조항 약관을 확인했으며, 동의합니다.</p>
                    </div>
                  </div>
                </>
              }

              {
                isAgreeTerms &&
                <>
                  <h4>프로젝트 정보</h4>
                  <div className="in-cnts">
                    <h5>프로젝트 설명</h5>
                    <div className="pjt-cnts">
                      <Linkify
                        componentDecorator={(
                          decoratedHref: string,
                          decoratedText: string,
                          key: number
                        ) => (
                          <a href={decoratedHref} key={key} target="_blank">
                            {decoratedText}
                          </a>
                        )}
                      >
                        <p>
                          {suggestion.description}
                        </p>
                      </Linkify>
                    </div>
                  </div>
                  <div className="in-cnts">
                    <h5>주요업무</h5>
                    <div className="pjt-cnts">
                      <Linkify
                        componentDecorator={(
                          decoratedHref: string,
                          decoratedText: string,
                          key: number
                        ) => (
                          <a href={decoratedHref} key={key} target="_blank">
                            {decoratedText}
                          </a>
                        )}
                      >
                        <p>
                          {suggestion.work_description}
                        </p>
                      </Linkify>
                    </div>
                  </div>

                  {
                    member?.job_categories?.[0]?.parent === '개발자' &&
                    <div className="in-cnts">
                      <h5>언어/프레임워크</h5>
                      <div className="cate-w">
                        {
                          suggestion.frameworks.map(v => (
                            <span>{v}</span>
                          ))
                        }
                      </div>
                    </div>
                  }
                  <div className="in-cnts">
                    <h5>협업 시 사용하는 툴</h5>
                    <div className="cate-w">
                      {
                        suggestion.tools.map(v => (
                          <span>{v}</span>
                        ))
                      }
                    </div>
                  </div>
                  <div className="in-cnts">
                    <h5>관련 자료</h5>
                    <div className="cate-w">
                      {
                        suggestion.references.map(({ link, file, name }) => (
                          <a href={link || file || ''} target="_blank" className="dir-go">{name || link || ''}</a>
                        ))
                      }
                    </div>
                  </div>
                </>
              }
            </div>

            {/* <Loader loaded={false} options={{ opacity: 0.5, color: '#5539ff' }} > */}
              <aside>
                <div className="send-message">
                  <p>프로젝트 내용에 대해 궁금한게 있거나, 제안받은 거래 시간, 주급, 기간에 대해서 논의가 필요한가요?</p>
                  <a href={`/messages?project=${suggestion.uuid}`}>메세지 보내기</a>
                </div>
                <div className="condi-box">

                  <h4 className={suggestion.status === 'MATCHED' ? 'ok' : ''}>
                    {
                      suggestion.status === 'SUBMITTED' &&
                      '대기중'
                    }

                    {
                      suggestion.status === 'ARRANGING' &&
                      '조율중'
                    }

                    {
                      suggestion.status === 'REJECTED' &&
                      '거절'
                    }
                    {
                      suggestion.status === 'MATCHED' &&
                      '채용확정'
                    }
                  </h4>


                  <div className="in-cnts">

                    {
                      suggestion.status === 'SUBMITTED' &&
                      <>
                        <strong>{Math.max(submitDate.add(36, 'hour').diff(dayjs(), 'hour'), 0)}시간 후 응답 기간 만료</strong>
                        <p>✌️ 프로젝트 내용, 계약조건 등에 대해 좀 더 의견을 나누고 싶으면 '<strong>관심 있어요</strong>' 버튼을 눌러주세요. 기간 내에 응답이 없을 경우 자동 거절됩니다</p>
                        <ul className="chk-li">
                          <li>
                            <input type="checkbox" id="ch1" {...register('ch1', { required: true })} />
                            <label htmlFor="ch1">별도 계약서 작성없이 세컨팀 서비스를 통해서 됩니다.</label>
                          </li>
                          <li>
                            <input type="checkbox" id="ch2" {...register('ch2', { required: true })} />
                            <label htmlFor="ch2"><strong>주당 계약한 시간을 프로젝트에 투입하는 계약입니다.</strong></label>
                          </li>
                          <li>
                            <input type="checkbox" id="ch3" {...register('ch3', { required: true })} />
                            <label htmlFor="ch3">거래 시간, 주급, 기간은 <strong>진행중 상호 합의에 따라 변경이 가능</strong>합니다.</label>
                          </li>
                          <li>
                            <input type="checkbox" id="ch4" {...register('ch4', { required: true })} />
                            <label htmlFor="ch4">비용은 한 주가 지난뒤 <strong>정산요청을 하면, 회사의 확인 후</strong> 크레딧으로 지급됩니다.</label>
                          </li>
                        </ul>
                        <div className="in-btn">
                          <button type="button" className="def" onClick={() => setOpenReject(true)}>다음 기회에</button>
                          <button
                            type="button"
                            style={isAgree ? {} : {
                              backgroundColor: 'rgba(13,18,64,0.05)',
                              color: 'rgba(13,18,64,0.4)',
                            }}
                            onClick={onSubmit}
                          >
                            관심 있어요
                          </button>
                        </div>
                      </>
                    }

                    {
                      (suggestion.status === 'ARRANGING' && suggestion.phase === 'ACCEPTED') &&
                      <>
                        <strong>채용 확정 대기중</strong>
                        <p>회사가 채용 확정한 이후 {userStore?.user?.nickname || ''}님이 동의하셔야지만 채용이 확정됩니다. 메세지를 통해 궁금하신 점을 물어보세요!</p>
                      </>
                    }

                    {
                      suggestion.status === 'REJECTED' &&
                      <strong>거절된 제안입니다</strong>
                    }

                    {
                      suggestion.status === 'MATCHED' &&
                      <>
                        <strong>채용 확정되신 걸 축하합니다!</strong>
                        <p>협업 관리에서 협업을 관리해보세요!</p>
                      </>
                    }

                    {
                      (suggestion.status === 'ARRANGING' && suggestion.phase === 'MATCHING_OFFERED') &&
                      <>
                        <strong>채용 확정 동의 대기중</strong>
                        <p>{userStore?.user?.nickname || ''}님이 아래 계약 정보를 동의할 시, 채용이 확정되며, 이름과 이메일 정보가 회사에게 공유됩니다. 채용 확정 동의 전 회사와 조율한 계약 정보를 한번 더 확인해주세요. 계약 정보 수정이 필요할 경우, 회사에게 말씀 부탁드립니다.</p>
                        <div className="in-btn">
                          <button type="button" className="def" onClick={() => setOpenReject(true)}>다음 기회에</button>
                          <button type="button" onClick={onAgree}>채용 확정 동의</button>
                        </div>
                      </>
                    }
                  </div>

                </div>
                <div className="btn-sticky">
                  {
                    suggestion.status === 'MATCHED' &&
                    <a href={`/recruits/${suggestion.project!}`}>세컨컴퍼니에서 보기</a>
                  }
                </div>
              </aside>
            {/* </Loader> */}
          </div>
        </div>
      </div>

      <MemberRejectModal
        isOpen={isOpenReject}
        onReject={suggestion.status === 'SUBMITTED' ? (text: string) => onReject(text) : (text: string) => onReject2(text)}
        onClose={() => setOpenReject(false)}
      />
    </>
  );
}));

export default RecruitMemberSuggestPage;
