import { useState, useEffect } from "react"
import axios from "axios";
import NotionContainer from "@components/NotionContainer";
import Loader from 'react-loader';

const Faq = () => {
  const [faq, setFAQ] = useState([]);
  const [idx, setIdx] = useState(0);
  const [notionMap, setNotionMap] = useState<any>();

  useEffect(() => {
    loadFAQ();
    loadNotion();
  }, []);

  const loadFAQ = async () => {
    const { data } = await axios.get('/constants/faq/', {
      params: {
        category: 'RECOMMEND',
      }
    });
    console.log(data);
    setFAQ(data);
  };
  
  const loadNotion = async () => {
    const { data } = await axios.get(`https://function.second-team.com/notion-chunk?pageId=611e59790d5d41edbc628a8b16e2c03e`);
    setNotionMap(data);
  };

  if (!notionMap) {
    return (
      <div className="landing-cnts">
        <Loader loaded={false} options={{ opacity: 0.5, color: '#5539ff' }} />
      </div>
    );
  }

  return (
    <div style={{ marginTop: 52 }}>
      <NotionContainer data={notionMap} />
    </div>
  );

  return (
    <div className="recommend-w">
      <div className="top-v">
        <div className="inner">
          <p>어떤 사람이 필요한지 알려만 주세요. <br />가장 적합한 전문가를 찾아서 소개해드립니다.</p>
          <a href="https://forms.gle/XZ8HfTPQnrAF2QXw7" target="_blank">전문가 소개 받기</a>
        </div>
      </div>
      <div className="progrs">
        <div className="inner">
          <h3>전문가 소개는 <br />이렇게 진행됩니다</h3>
          <ul>
            <li>
              <strong>1. 전문가 추천 받기</strong>
              <p>
                어떤 사람이 필요한지 알려주시면, 상담을 통해 조건에 적합한 전문가들을 추천해드립니다. 채용, N잡, 컨설팅 등 다양한 목적을 전제로 추천이 가능하며, 각 전문가의 역량과 업무 경험은 함께 드리는 프로필에서 확인하실 수 있습니다.
              </p>
            </li>
            <li>
              <strong>2. 만남 일정 잡기</strong>
              <p>
                만나보고 싶은 전문가가 있다면, 바로 미팅 날짜를 주선해드립니다. 만남의 기본 방식은 온라인 화상 미팅이며, 날짜가 확정 되면 비용이 청구됩니다.
              </p>
            </li>
            <li>
              <strong>3. 전문가와 만남 진행</strong>
              <p>
                주선하신 날짜에 전문가와 만남을 갖습니다.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="faq-w">
        <div className="inner">
          <h3>자주 묻는 질문</h3>
          <ul>
            {
              faq.map(({ question, answer }, index) => (
                <li className={idx === index ? 'selected' : ''} onClick={() => setIdx(index)}>
                  <a>{question}</a>
                  <div className="ans">
                    <p>{answer}</p>
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Faq;