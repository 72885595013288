import { types, Instance } from "mobx-state-tree";

export const ProposalModel = types.model({
  uuid: types.string,
  title: types.string,
  wage: types.maybeNull(types.number),
  duration: types.maybeNull(types.number),
  working_hours: types.maybeNull(types.number),
  date_matched: types.maybeNull(types.string),
});

export interface IProposalModel extends Instance<typeof ProposalModel> {};
