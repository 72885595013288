import { Instance, types } from "mobx-state-tree";

export const Question = types.model({
  id: types.number,
  text: types.string,
  short_description: types.string,
  required: types.boolean,
  order: types.maybeNull(types.number),
});

export interface IQuestion extends Instance<typeof Question> {};
