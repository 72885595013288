import { IQuestion } from "@models/Question";
import { useFormContext } from "react-hook-form";
import Linkify from "react-linkify";

interface Props {
  data: IQuestion;
  idx: number;
}

const PollYesOrNo = ({ data, idx }: Props) => {
  const { register } = useFormContext();

  return (
    <li>
      <Linkify
        componentDecorator={(
          decoratedHref: string,
          decoratedText: string,
          key: number
        ) => (
          <a href={decoratedHref} style={{ textDecoration: 'underline', color: '#5539ff' }} key={key} target="_blank">
              {decoratedText}
          </a>
        )}
      >
        <p>
          {data.text}
        </p>
      </Linkify>
      <div className="ans">
        <span><input type="radio" id={`q${idx}y`} {...register(`answers.${idx}`)} value={1} /><label htmlFor={`q${idx}y`}>네</label></span>
        <span><input type="radio" id={`q${idx}N`} {...register(`answers.${idx}`)} value={0} /><label htmlFor={`q${idx}N`}>아니요</label></span>
      </div>
    </li>
  );
};

export default PollYesOrNo;
