import { IMemberModel } from "../models/MemberModel";
import { Link } from 'react-router-dom';
import { observer } from "mobx-react";

interface Props {
  data: IMemberModel;
}

export default observer(({ data }: Props) => {
  const { uuid, nickname, avatar, job_categories, roles, portfolio, experiences, company_name, is_following, get_offer, employment_public_open, employment_type, toggleFollow } = data;

  const onSuggest = () => {
    window.location.href = `/recruits/suggest?member=${uuid}`;
  };

  return (
    <div key={uuid} className="member-box">
      <div className="profile">
        <span className="photo" style={{ backgroundImage: `url(${avatar || 'images/@photo.png'})` }}></span>
        <div className="info">
          <div className="name">
            <h3>{nickname}</h3>
            <span>
              {/* {job_categories[0]?.parent_name} ·  */}
              {
                  employment_public_open ?
                    employment_type === 'FREELANCE' ?
                      '프리랜서'
                      :
                      company_name || '회사'
                    :
                    '소속 비공개'
                }
            </span>
          </div>
          <p>
            {roles.filter(v => v).slice(0, 3).join(', ')}
            {
              roles.length > 3 &&
              ` +${roles.length - 3}`
            }
          </p>
        </div>
      </div>
      <div className="use-pjt">
        <h4>포트폴리오</h4>
        <p>
          {portfolio.slice(0, 3).join(', ')}
          {portfolio.length > 3 && ' 등'}
        </p>
        <div className="mb-btn">
          <button
            type="button"
            className={`btn-like ${is_following ? 'act' : ''}`}
            onClick={toggleFollow}
          >
            <span>좋아요</span>
          </button>
          <a><Link to={`/members/${uuid}`} target="_blank">프로필</Link></a>
          <a
            style={{
              cursor: get_offer ? 'pointer' : 'auto',
              opacity: get_offer ? 1 : 0.5,
              background: get_offer ? 'auto' : 'rgba(13,18,64,.05)',
              color: get_offer ? 'auto' : 'rgba(13,18,64,.4)',
            }}
            className="actv"
            onClick={get_offer ? onSuggest : () => {}}
          >
            <span>협업</span> {get_offer ? '제안하기' : '제안 OFF'}
          </a>
        </div>
      </div>
    </div>
  );
});
