import { useFormContext } from "react-hook-form";
import styled from "styled-components";

interface IProps {
  formKey?: string;
}

const RadioSpan = styled.span`
  margin-top: 12px;
  width: 100%;
`;

const MemberCountForm = ({ formKey }: IProps) => {
  const key = formKey || 'coworking_member_count';
  const { register, watch } = useFormContext();

  const watcher = watch(key);

  return (
    <div className="ans type2" key={key}>
      <RadioSpan>
        <input type="radio" {...register(key)} id={key + '1'} value={1} defaultChecked={watcher === 1} />
        <label htmlFor={key + '1'}>혼자 진행한 프로젝트</label>
      </RadioSpan>
      <RadioSpan>
        <input type="radio" {...register(key)} id={key + '2'} value={2} defaultChecked={watcher === 2} />
        <label htmlFor={key + '2'}>1-5명</label>
      </RadioSpan>
      <RadioSpan>
        <input type="radio" {...register(key)} id={key + '3'} value={3} defaultChecked={watcher === 3} />
        <label htmlFor={key + '3'}>6-10명</label>
      </RadioSpan>
      <RadioSpan>
        <input type="radio" {...register(key)} id={key + '4'} value={4} defaultChecked={watcher === 4} />
        <label htmlFor={key + '4'}>11-20명</label>
      </RadioSpan>
      <RadioSpan>
        <input type="radio" {...register(key)} id={key + '5'} value={5} defaultChecked={watcher === 5} />
        <label htmlFor={key + '5'}>21명 이상</label>
      </RadioSpan>
    </div>
  );
}

export default MemberCountForm;
