/*
  markup : rec_sug.html
*/
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { IRecruitListStore } from '@stores/RecruitListStore';
import { IAppStore } from "../../stores/AppStore";
import Avatar from "../../components/Avatar";
import { numberWithCommas } from "../../utils/utils";
import SuggestMemberCard from "./components/SuggestMemberCard";
import Linkify from 'react-linkify';

interface IProps {
  appStore: IAppStore;
}


const RecruitPartnerSuggestPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const { uuid }: { uuid: string } = useParams();
  const { recruitListStore } = (props as IProps).appStore;
  const { getPartnerProposal, member, proposal } = (recruitListStore as IRecruitListStore);

  useEffect(() => {
    getPartnerProposal(uuid);
  }, []);

  if (!proposal || !member) {
    return <></>;
  }

  const totalFee = (proposal?.wage || 0) * (proposal?.duration || 0);

  return (
    <>
      <div className="member-wrap">
        <div className="top-box in-prev">
          <div className="btn-prev">
            {/* <a href="javascript:history.back()"><span>이전 페이지</span></a> */}
          </div>
          <h2><b>{member.nickname}</b>의 협업 제안서</h2>
        </div>
        <div className="pjt-info">
          <h3>{proposal.title}</h3>
          <SuggestMemberCard
            uuid={proposal.member.uuid}
            member={member!}
            projectCount={proposal.current_project_count || 0}
          />

          {
            Boolean(proposal?.contract_method) &&
            <>
              <h4>계약 방식</h4>
              <div className="pjt-cnts">
                <p>
                  {
                    proposal?.contract_method === 'SECONDTEAM' ?
                      '주급제 정산(세컨팀을 통해 주급제 정산 방식)' :
                      '단순 매칭(고객사가 멤버와 직접 계약 및 정산)'
                  }
                </p>
              </div>
            </>
          }

          <h4>제안 유형</h4>
          <div className="pjt-cnts">
            <p>
              {
                [
                  '없습니다',
                  '전문성 기반의 짧은 업무',
                  '아웃소싱 프로젝트 의뢰',
                  '프로젝트성 주급 계약',
                  '이직 헤드헌팅',
                  '흥미로운 일',
                ][proposal?.type || 0]
              }
            </p>
          </div>
          <h4>프로젝트 정보</h4>
          <div className="in-cnts">
            <h5>프로젝트 설명</h5>
            <div className="pjt-cnts">
              <Linkify
                componentDecorator={(
                  decoratedHref: string,
                  decoratedText: string,
                  key: number
                ) => (
                  <a href={decoratedHref} key={key} target="_blank">
                    {decoratedText}
                  </a>
                )}
              >
                <p>
                  {proposal.description}
                </p>
              </Linkify>
            </div>
          </div>
          <div className="in-cnts">
            <h5>주요업무</h5>
            <div className="pjt-cnts">
              <Linkify
                componentDecorator={(
                  decoratedHref: string,
                  decoratedText: string,
                  key: number
                ) => (
                  <a href={decoratedHref} key={key} target="_blank">
                    {decoratedText}
                  </a>
                )}
              >
                <p>
                  {proposal.work_description}
                </p>
              </Linkify>
            </div>
          </div>
          {
            member?.job_categories?.[0]?.parent === '개발자' &&
            <div className="in-cnts">
              <h5>언어/프레임워크</h5>
              <div className="cate-w">
                {
                  proposal.frameworks.map(v => (
                    <span>{v}</span>
                  ))
                }
              </div>
            </div>
          }
          <div className="in-cnts">
            <h5>협업 시 사용하는 툴</h5>
            <div className="cate-w">
              {
                proposal.tools.map(v => (
                  <span>{v}</span>
                ))
              }
            </div>
          </div>

          <div className="in-cnts">
            <h5>관련 자료</h5>
            <div className="cate-w">

              {
                proposal.references.map(({ link, file, name }) => (
                  <a href={link || file || ''} target="_blank" className="dir-go">{name || link || ''}</a>
                ))
              }
            </div>
          </div>
          <h4>계약 정보</h4>
          <div className="in-cnts no-line">
            <h5>희망 협업 기간</h5>
            <p>{proposal.duration || 0}주</p>
          </div>
          <div className="in-cnts no-line">
            <h5>희망 거래 시간</h5>
            <p>{proposal.working_hours || 0}시간 / 1주일</p>
          </div>
          <div className="in-cnts no-line">
            <h5>주급</h5>
            <p>{numberWithCommas(proposal.wage || 0)}원 (수수료 별도)</p>
            <p className="desc">프로젝트 예상 총 비용은 <em>{numberWithCommas(totalFee)}원</em>(수수료 별도) 입니다.</p>
          </div>
          {
            proposal.status !== 'REJECTED' &&
            <div className="btn-sticky">
              <a href={`/recruits/suggest?proposal=${proposal.uuid}`}>수정</a>
            </div>
          }
        </div>
      </div>
    </>
  );
}));

export default RecruitPartnerSuggestPage;
