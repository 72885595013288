import { Instance, types } from "mobx-state-tree";
import { Question } from "./Question";

export const Questionnaire = types.model({
  id: types.number,
  display_title: types.string,
  introduction: types.string,
  description: types.string,
  questions: types.array(Question),
  main_category: types.maybe(types.string),
  sub_category: types.maybe(types.string),
  regular_survey: types.number,
});

export interface IQuestionnaire extends Instance<typeof Questionnaire> {};
