/*
  markup : onboarding_member_project.html
*/
import { observer } from "mobx-react";
import { OnboardingProps } from '../OnboardingPage';
import ProjectForm from "../../../components/profile-new-form/ProjectForm";

interface Props extends OnboardingProps {
}

export default observer(({ profile, account, tab }: Props) => {
  if (tab !== 3) {
    return <></>;
  }

  return (
    <div style={{ display: tab === 3 ? 'block' : 'none' }}>
      <div className="in-cnts">
        <ProjectForm
          title="어떤 프로젝트를 진행했었나요?"
          profile={profile}
          account={account}
        />
      </div>
    </div>
  );
});
