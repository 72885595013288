import { ICoWorker } from "@models/CoWorker";
import portfolioAPI from "@services/portfolioAPI";
import profileAPI from "@services/profileAPI";
import { AUTH_USERTYPE } from "@utils/constants";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { IMemberProfileModel } from "../models/MemberProfileModel";
import CoWorkerConfigCard from "./CoWorkerConfigCard";
import TopWorker from "./TopWorker";

interface Props {
  uuid?: string;
  data: IMemberProfileModel;
}

const MySecondTeam = ({
  uuid,
  data,
}: Props) => {
  const [coWorkerList, setCoWorkerList] = useState<ICoWorker[]>([]);
  const [coworkerUpdatedAt, setCoworkerUpdatedAt] = useState<string>('');
  const [secondteamUpdatedAt, setSecondteamUpdatedAt] = useState<string>('');
  const topWorkerList = coWorkerList.filter(v => v.is_my_secondteam);
  const topCount = topWorkerList.length;
  
  const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const { results, coworker_updated_at, secondteam_updated_at } = await profileAPI.getCoWorkerList(uuid || data.uuid);
    setCoWorkerList(results);
    setCoworkerUpdatedAt(coworker_updated_at || '');
    setSecondteamUpdatedAt(secondteam_updated_at || '');
  }


  return (
    <div className="conts-w2">
      <h3>
        세컨팀
        {
          Boolean(secondteamUpdatedAt) &&
          <span>업데이트 {dayjs(secondteamUpdatedAt).format('YYYY.MM.DD')}</span>
        }
      </h3>
      <p className="c-desc">세컨팀은 함께한 동료 중에서도 {data.nickname || '멤버'}님이 뽑은 ‘함께 일하고 싶은 동료 top 5’입니다!</p>

      <div className="member-list">
        <span className="mem-c">{topCount}/5명</span>
        <div className="p-list">
          {
            topWorkerList.length > 0 &&
            <div className="viewport">
              <ul>
                {
                  topWorkerList.map(v => (
                    <TopWorker
                      data={v}
                    />
                  ))
                }
              </ul>
            </div>
          }
        </div>

        {
          (topWorkerList.length < 1 && coWorkerList.length > 0 && userType === 'MEMBER') &&
          <div className="no-member">
            <p>함께한 동료 목록에서 <br /><span>세컨팀</span>을 선택하여 추가하세요</p>
          </div>
        }
      </div>

      {
        (topWorkerList.length < 1 && (userType === 'PARTNER' || coWorkerList.length < 1)) &&
        <div className="with-member">
          <div className="no-info">
            <p>아직 {data.nickname || '멤버'}님이 세컨팀 동료를 추가하지 않았습니다. </p>
          </div>
        </div>
      }

      <div className="tit-w">
        <h3>
          함께한 동료
          {
            Boolean(coworkerUpdatedAt) &&
            <span>업데이트 {dayjs(coworkerUpdatedAt).format('YYYY.MM.DD')}</span>
          }
        </h3>
        {
          coWorkerList.length > 0 &&
          <div className="chk-st">
            <span className="c1" style={{ color: 'rgba(13, 18, 64, 0.6)' }}>동료 평가</span>
            <span className="c2" style={{ color: 'rgba(13, 18, 64, 0.6)' }}>협업 확인</span>
            <span className="c3" style={{ color: 'rgba(13, 18, 64, 0.6)' }}>레퍼런스 체크 가능</span>
          </div>
        }
      </div>
      <div className="with-member">
        {
          coWorkerList.length > 0 ?
            <ul>
              {
                coWorkerList.sort((a: ICoWorker, b: ICoWorker) => (a.pk || 0) - (b.pk || 0)).map(v => (
                  <CoWorkerConfigCard
                    data={v}
                    topCount={topCount}
                    refresh={load}
                  />
                ))
              }
            </ul>
            :
            <div className="no-info">
              <p>아직 {data.nickname || '멤버'}님이 프로젝트를 <br />함께 한 동료 정보를 추가하지 않았습니다. </p>
            </div>
        }
      </div>

      {/* <div className="btn-more">
        <a href="#">더보기</a>
      </div> */}
    </div>
  );
}

export default MySecondTeam;
