import { useEffect } from "react";
import { observer } from "mobx-react";
import { ISuggestForm } from "../RecruitSuggestionModifyPage";
import { useController, useFormContext, useFieldArray } from "react-hook-form";

export default observer(({ title, data, proposal }: ISuggestForm) => {
  const { control, watch, getValues, setValue, register } = useFormContext();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: 'references',
  });

  useEffect(() => {
    for (const item of (proposal?.references || [])) {
      append({
        type: item.type,
        link: item.link,
        name: item.name,
      });
    }
  }, []);

  const onAdd = () => {
    append({
      type: 'LINK',
      link: '',
    });
  };

  const onRemove = (index: number) => {
    remove(index);
  };

  const onUpload = (idx: number, e: any) => {
    const file = e.target.files[0];
    
    if (file.size > (10 * 1024 * 1024)) {
      alert('10MB 이상 파일은 업로드하실 수 없습니다.');
      return;
    }

    const fileName = file.name;
    const filePath = URL.createObjectURL(file);
    
    setValue(`references.${idx}.type`, 'FILE');
    setValue(`references.${idx}.link`, file);
    setValue(`references.${idx}.name`, fileName);
  };

  const renderFileRow = ({ id }: any, idx: number) => {
    const isEmpty = watch(`references.${idx}.link`, '')?.length < 1;
    const isFile = watch(`references.${idx}.type`, '') === 'FILE';
    const fileName = watch(`references.${idx}.name`, '');

    return (
      <div className="int-form" key={id}>
        {
          isFile ?
            <input
              type="text"
              id="portF"
              value={fileName}
              disabled
            />
          :
            <input
              type="text"
              id="portF"
              placeholder="URL을 입력해주세요."
              title="URL을 입력해주세요."
              {...register(`references.${idx}.link`)}
            />
        }

        {
          isEmpty &&
            <div className="file-up">
              <input type="file" id="portUp" onChange={(e: any) => onUpload(idx, e)} />
              <label htmlFor="portUp">파일 업로드</label>
            </div>
        }

        {
          isEmpty ||
            <button
              type="button"
              className="btn-del"
              onClick={() => onRemove(idx)}
            >
              <span>삭제</span>
            </button>
        }
      </div>
    );
  }

  return (
    <div className="in-cnts">
      <h5>{title}</h5>

      {
        fields.map((v, idx) => renderFileRow(v, idx))
      }

      <div className="btn-add" onClick={onAdd} style={{ cursor: 'pointer' }}>
        <button type="button"><span>관련자료 추가</span></button>
      </div>
    </div>
  );
});
