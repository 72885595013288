import { useFormContext } from "react-hook-form";

interface IProps {
  formKey?: string;
}

const ProjectDomainForm = ({ formKey }: IProps) => {
  const key = formKey || 'domain';
  const { register, watch, setValue, handleSubmit, getValues, formState: { isSubmitted } } = useFormContext();

  return (
    <input type="text" id="cate" {...register(key)} placeholder="예.: 커머스, 툴, 플랫폼 공공" title="예.: 커머스, 툴, 플랫폼 공공" />
  );
};

export default ProjectDomainForm;
