import { ICandidatePool } from '@models/CandidatePool';
import axios from 'axios';
import { useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import Loader from 'react-loader';

interface Props {
  isOpen: boolean;
  data: ICandidatePool;
  onClose: () => void;
}

interface IProps {
  formKey: string;
  title: string;
}

const CheckBoxArea = ({ formKey, title }: IProps) => {
  const { register } = useFormContext();

  return (
    <li style={{ marginTop: 32 }}>
      <label htmlFor="pgWork" className="tit" style={{ whiteSpace: 'break-spaces', lineHeight: 1.5, marginBottom: 8 }}>{title}</label>
      <ul className="chk-li" style={{ display: 'inline-flex', justifyContent: 'space-between' }}>
        {/* <li style={{ fontSize: 12 }}>매우그렇다</li> */}
        <li>
          <input type="radio" {...register(formKey)} id={formKey + 1} value={1} />
          <label htmlFor={formKey + 1} style={{ color: 'black' }}>1</label>
        </li>
        <li>
          <input type="radio" {...register(formKey)} id={formKey + 2} value={2} />
          <label htmlFor={formKey + 2} style={{ color: 'black' }}>2</label>
        </li>
        <li>
          <input type="radio" {...register(formKey)} id={formKey + 3} value={3} />
          <label htmlFor={formKey + 3} style={{ color: 'black' }}>3</label>
        </li>
        <li>
          <input type="radio" {...register(formKey)} id={formKey + 4} value={4} />
          <label htmlFor={formKey + 4} style={{ color: 'black' }}>4</label>
        </li>
        <li>
          <input type="radio" {...register(formKey)} id={formKey + 5} value={5} />
          <label htmlFor={formKey + 5} style={{ color: 'black' }}>5</label>
        </li>
        {/* <li style={{ fontSize: 12 }}>전혀아니다</li> */}
      </ul>
    </li>
  );
}

const PoolRegistrationModal = ({ isOpen, data, onClose }: Props) => {
  const form = useForm();
  const { register, watch, setValue, reset, handleSubmit } = form;
  const [isLoading, setLoading] = useState(false);

  const onCancel = () => {
    reset({});
    onClose();
  };

  const onSubmit = async (form: any) => {
    if (isLoading) {
      return;
    }
    console.log(form);

    const { motivation, question, can_coffee_chat } = form;
    if (!motivation) {
      alert('지원동기를 입력해주세요.');
      return;
    }

    for (const [k, v] of Object.entries(form)) {
      if (v === null || v === undefined) {
        alert('모든 질문에 응답해주세요.');
        return;
      }
    }

    // if (!candidateId || isNaN(candidateId)) {
    //   alert('회사를 선택해주세요.');
    //   return;
    // }

    // API 연동
    setLoading(true);
    try {
      await axios.post(`/candidate-pools/${data.id}/candidates`, {
        motivation: motivation.slice(0, 199),
        question: question.slice(0, 199),
        can_coffee_chat,
        collaboration_hope: Number(form.collaboration_hope),
        culture_attractiveness: Number(form.culture_attractiveness),
        join_hope: Number(form.join_hope),
        product_attractiveness: Number(form.product_attractiveness),
        vision_attractiveness: Number(form.vision_attractiveness),
        work_hope: Number(form.work_hope),
      });
      alert('신청이 완료되었습니다');
      window.location.reload();
      // window.location.href = `/career/pool/${data.id}/list`;
    } catch (err: any) {
      if (err.response.data[0]) {
        alert(err.response.data[0]);
        return;
      }

      setLoading(false);
      alert('일시적 오류가 발생했습니다. 다시 시도해주세요.');
      return;
    }

    setLoading(false);
    onClose();
  }

  if (!isOpen || !data) {
    return <></>;
  }

  return (
    <FormProvider {...form}>
      <div className="dimd-layer">
        {
          isLoading &&
          <Loader loaded={false} options={{ opacity: 0.5, color: '#5539ff' }} />
        }

        <div className="ly-box alert-box smtype">
          <div className="inner">
            <h3>인재풀 참여하기</h3>
            {/* <p>
            프로젝트에 관심을 갖거나 협업을 희망하는 이유를 적어주세요!
          </p> */}
            <div className="sp-end2">
              <ul className="type2">
                {/* <li>
                <label htmlFor="pgWork" className="tit">회사</label>
                <select
                  id="pgWork"
                  title="회사 선택"
                  style={{ display: 'block', width: '100%', fontSize: 15 }}
                  {...register('candidateId')}
                >
                  <option>회사 선택</option>
                  <option value={1}>시소</option>
                  <option value={2}>데이탄소프트</option>
                </select>
              </li>
              <br/> */}
                <p>

                </p>
                <li>
                  <label htmlFor="pgWork" className="tit">지원동기</label>
                  <textarea
                    id="pgWork"
                    style={{ height: 150, color: '#0D1240' }}
                    placeholder="입력해주세요 (최대 200자)"
                    maxLength={200}
                    {...register('motivation')}
                  />
                </li>

                <li style={{ marginBottom: -32, marginTop: 16 }}>
                  ❶ 전혀 아니다, ❸ 보통, ❺ 매우 그렇다
                </li>
                <CheckBoxArea
                  formKey={'vision_attractiveness'}
                  title={`1. ${data.name}의 비전에 공감합니다.`}
                />

                <CheckBoxArea
                  formKey={'product_attractiveness'}
                  title={`2. ${data.name}의 서비스/제품을 좋아합니다.`}
                />

                <CheckBoxArea
                  formKey={'culture_attractiveness'}
                  title={`3. ${data.name}의 문화에 관심이 있습니다.`}
                />

                <CheckBoxArea
                  formKey={'join_hope'}
                  title={`4. 이직할 때 ${data.name}의 입사를 고려해보고 싶습니다.`}
                />

                <CheckBoxArea
                  formKey={'collaboration_hope'}
                  title={`5. 개인적으로 ${data.name}와/과 프로젝트를 함께 해보고 싶습니다.`}
                />

                <CheckBoxArea
                  formKey={'work_hope'}
                  title={`6. ${data.name}와/과 함께할 수 있는 방향을 같이 모색하고 싶습니다.`}
                />

                <li style={{ width: '100%', display: 'inline-flex', alignItems: 'center', justifyContent: 'start', marginTop: 24, marginBottom: 24 }}>
                  <strong style={{ color: 'black' }}>☕ 담당자와 커피챗을 희망합니다</strong>
                  <input type="checkbox" id="ch1" {...register('can_coffee_chat')} />
                  <label htmlFor="ch1" style={{ marginTop: -22, marginLeft: 4 }} />
                </li>

                <li>
                  <label htmlFor="pgWork" className="tit">
                    담당자에게 물어보고 싶은 질문(선택 입력)
                  </label>
                  <textarea
                    id="pgWork"
                    style={{ height: 98, color: '#0D1240' }}
                    placeholder="입력해주세요 (최대 200자)"
                    maxLength={200}
                    {...register('question')}
                  />
                </li>
              </ul>
            </div>
            {/* <p style={{ fontSize: 14, opacity: 0.8 }}>
            신청 결과는 세컨팀 가입 메일로 개별 연락을 드립니다
          </p> */}
            <div className="btn type2">
              {/* <button type="button" className="cancel" onClick={onCancel}>취소</button> */}
              <a
                style={{ cursor: 'pointer' }} 
                className="actv"
                onClick={handleSubmit(onSubmit)}
              >
                인재풀 참여하기
              </a>
            </div>
          </div>
          <button type="button" className="btn-close" onClick={onClose}><span>팝업 닫기</span></button>
        </div>
      </div >
    </FormProvider>
  );
};

export default PoolRegistrationModal;
