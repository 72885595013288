import { useState, useRef } from 'react';
import { observer } from "mobx-react";

import { useOutside } from '../../utils/hooks';


const FrameworkField = (observer((props: {
  value: string[],
  options: string[],
  onChange: (value: string, selected: boolean) => void
}) => {
  const optionRef = useRef<HTMLUListElement>(null);
  const [ search, setSearch ] = useState('');
  const [ showOptions, setShowOptions ] = useState(false);

  useOutside(optionRef, showOptions, () => {
    setShowOptions(false);
  });
  
  return (
    <>
      <dt><label htmlFor="fW">개발언어 및 <br />프레임워크</label></dt>
      <dd>
        <div className="fm-box">
          {
            props.value.map( (tool, i) =>
              <Tool key={ i } onClick={ () => {
                setShowOptions(false);
                props.onChange(tool, false);
              }}>
                { tool }
              </Tool>
            )
          }
          <div className={`in-sel ${ showOptions ? 'selected' : ''}`}>{/* <!-- 옵션열림 "selected" class로 컨트롤 --> */}
            <input type="text"
              id="fW"
              value={ search }
              onClick={ () => setShowOptions(true) }
              onChange={ (e) => setSearch(e.target.value) } />
            <div className="opt-li">
              <ul ref={ optionRef }>
                {
                  props.options
                    .filter(op => !props.value.includes(op))
                    .filter(op => op.toLowerCase().includes(search.toLowerCase()))
                    .map((option, i) =>
                    <ToolOption key={ i } onClick={ () => {
                      setShowOptions(false);
                      setSearch('');
                      props.onChange(option, true);
                    }} >
                      { option }
                    </ToolOption>
                  )
                }
              </ul>
            </div>
          </div>
        </div>
      </dd>
    </>
  )
}))
export default FrameworkField;



/*
*** Sub Components
*/
const Tool = (props: {
  children: any,
  onClick: () => void
}) => <span>{ props.children }<button type="button" onClick={ props.onClick }><span>삭제</span></button></span>;

const ToolOption = (props: {
  children: any,
  onClick: () => void
}) => <li onClick={ props.onClick }>{ props.children }</li>;