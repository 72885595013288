import { IMemberProfileModel } from "../models/MemberProfileModel";

interface Props {
  isMargin: boolean;
  windowWidth: number;
  data: IMemberProfileModel;
}

const SecondTeamCard = ({ isMargin, windowWidth, data }: Props) => {
  const isTablet = windowWidth < 1280 && windowWidth >= 786;
  const isMobile = windowWidth < 786;

  return (
    <div
      className="viewport"
      style={{
        overflow: 'visible',
        marginRight: isMargin && !isTablet ? -1 * (isMobile ? (windowWidth - 311) * 1.5 : (windowWidth - 720 / 2)) : 'auto',
        // transition: 'all 1s ease-in-out',
      }}
    >
      <div className="pf-info">
        <div className="secondteam">
          <h2><b>{data.nickname || '멤버'}</b>의 세컨팀 멤버</h2>
          <p>세컨팀 멤버는 한 회사의 동료가 아니라, 개인의 입장에서 다양한 회사나 <br />프로젝트 경험을 하면서 함께 일했던 동료들을 모아놓은 곳입니다.</p>
        </div>
      </div>
    </div>
  );
}

export default SecondTeamCard;
