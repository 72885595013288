import { Instance, types } from "mobx-state-tree";

export const WorkPreference = types.model({
  pk: types.number,
  category: types.string,
  label: types.string,
  order: types.number,
});

export interface IWorkPreference extends Instance<typeof WorkPreference> {};
