/*
  markup : member_recu_status.html
*/
import { observer } from "mobx-react";
import Avatar from "../../../components/Avatar";
import { IMemberProposalModel } from "../models/MemberProposalModel";
import dayjs from 'dayjs';

interface Props {
  data: IMemberProposalModel;
}

export default observer(({ data }: Props) => {
  const submitDate = dayjs(data.date_submitted);

  return (
    <div className="inbox">
      <div className="tit">
        <div>
          <h4>{data.title}</h4>
          <p style={{ fontSize: 12.5, color: 'gray', marginTop: 4 }}>
            {
              [
                '없습니다',
                '전문성 기반의 짧은 업무',
                '아웃소싱 프로젝트 의뢰',
                '프로젝트성 주급 계약',
                '이직 헤드헌팅',
                '흥미로운 일',
              ][data?.type || 0]
            }
          </p>
        </div>
        {
          data.status === 'SUBMITTED' &&
          <span>대기중</span>
        }

        {
          data.status === 'ARRANGING' &&
          <span className="ing">조율중</span>
        }

        {
          data.status === 'REJECTED' &&
          <span className="rej">거절</span>
        }

        {
          data.status === 'MATCHED' &&
          <span className="ok">채용확정</span>
        }
      </div>
      <div className="profile">
        <Avatar
          data={data.partner}
        />
        <div className="info">
          <div className="name">
            <strong>{data.partner.company_name}</strong>
          </div>
        </div>

        <div className="btn-view">
          <a href={`/recruits/member/suggest/${data.uuid}`} className="suj">협업제안서</a>
        </div>
      </div>
      <div className="desc-box">
        <p>제안일: {submitDate.format('YYYY.MM.DD HH:mm')}</p>
      </div>
      <div className="in-btn">
        <a href={`/messages?project=${data.uuid}`} className="cmt">
          <span>댓글</span>
          {
            data.has_unread_messages &&
            <i><span>신규 댓글 있음</span></i>
          }
        </a>

        {
          data.status === 'SUBMITTED' &&
          <p>{Math.max(submitDate.add(36, 'hour').diff(dayjs(), 'hour'), 0)}시간 후 응답 기간 만료</p>
        }

        {
          data.status === 'MATCHED' &&
          <p className="actv">
            <a href="/recruits">세컨컴퍼니에서 보기</a>
            {/* <span>협업을 시작해보세요!</span> */}
          </p>
        }

        {
          (data.status === 'ARRANGING' && data.phase === 'ACCEPTED') &&
          <p>채용 확정 대기중</p>
        }

        {
          (data.status === 'ARRANGING' && data.phase === 'MATCHING_OFFERED') &&
          <p className="actv">
            <a href={`/recruits/member/suggest/${data.uuid}`} target="_blank">최종 동의하기</a>
          </p>
        }

        {
          data.phase === 'CANCELED' ?
            <p>드랍된 제안입니다.</p>
            :
            data.status === 'REJECTED' &&
            <p>거절한 제안입니다.</p>
        }

      </div>
    </div>
  );
});
