
import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css";
import 'katex/dist/katex.min.css';

import { NotionRenderer } from "react-notion";
import styled from 'styled-components';

const Container = styled.div`
  font-family: 'Pretendard';
  padding: 64px 30px 128px 30px;

  ul, ol, li {
    list-style: revert;
  }
  hr {
    display: block;
  }
  details {
    width: 100%;
  }
  .notion-inline-code {
    color: unset;
  }
  /* width: 100%; */
  max-width: 720px !important;
  margin: 0 auto;
`;



const NotionContainer = ({ data }: { data: any }) => {
  return (
    <Container>
      <NotionRenderer
        blockMap={data}
      />
    </Container>
  );
};

export default NotionContainer;
