/*
  markup : mypage_member_modify.html
*/

import { inject, observer } from "mobx-react";
import { useEffect } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import { FormProvider, useForm } from "react-hook-form";
import AvailabilityForm from '../../components/profile-new-form/AvailabilityForm';
import EmploymentTypeForm from '../../components/profile-new-form/EmploymentTypeForm';
import ExpectationForm from '../../components/profile-new-form/ExpectationForm';
import IntroductionForm from '../../components/profile-new-form/IntroductionForm';
import KeywordForm from '../../components/profile-new-form/KeywordForm';
import LanguageForm from '../../components/profile-new-form/LanguageForm';
import NicknameForm from '../../components/profile-new-form/NicknameForm';
import OfferForm from '../../components/profile-new-form/OfferForm';
import OfflineForm from '../../components/profile-new-form/OfflineForm';
import PriceForm from '../../components/profile-new-form/PriceForm';
import ProfileImageForm from '../../components/profile-new-form/ProfileImageForm';
import RegidentForm from '../../components/profile-new-form/RegidentForm';
import SNSForm from '../../components/profile-new-form/SNSForm';
import TimeAddTypeForm from '../../components/profile-new-form/TimeAddTypeForm';
import TimeForm from '../../components/profile-new-form/TimeForm';
import TimeZoneForm from '../../components/profile-new-form/TimeZoneForm';
import { IAppStore } from "../../stores/AppStore";
import { IProfileStore } from '../../stores/profileStore';
import { handleMemberProfile } from "../../utils/utils";
import SalaryForm from "@components/profile-new-form/SalaryForm";

interface IProps {
  appStore: IAppStore;
  history: any
}

const MemberProfileFormPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const history = useHistory();
  const { profileStore, showLoader, hideLoader } = (props as IProps).appStore;
  const { memberProfile: profile, memberAccount: account, getMemberProfile: getProfile, getMemberAccount: getAccount, updateProfile } = (profileStore as IProfileStore);
  const form = useForm();
  const { setError, handleSubmit } = form;

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    await getProfile();
    await getAccount();

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  if (!profile || !account) {
    return <></>;
  }

  const onCancel = () => {
    history.goBack();
  };

  const onSave = async (data: object) => {
    const [form, json, referenceFiles] = handleMemberProfile(data);

    try {
      showLoader();
  
      let profile: any;
      if (form) {
        profile = await updateProfile(form);
      }
  
      if (json) {
        profile = await updateProfile(json);
      }

      hideLoader();

      const hashName = window.location.hash.replace('#', '');
      window.location.href = '/member/profile#' + hashName;
    } catch (err) {
      hideLoader();
      return;
    }
  };

  return (
    <FormProvider {...form}>
      <div className="mypage-cnts modify">
        <div className="myp-incnts">
          <h3 className="tit">소개</h3>
          <div className="top-info">
            <ProfileImageForm
              profile={profile}
              account={account}
              title="프로필이미지"
            />
          </div>
          <div className="in-cnts">
            <NicknameForm
              profile={profile}
              account={account}
              title="닉네임 *"
              text="채용 매칭시 닉네임으로 회사에게 노출됩니다."
            />
          </div>
          <div className="in-cnts">
            <EmploymentTypeForm
              profile={profile}
              account={account}
              title="소속 *"
            />
          </div>
          <div className="in-cnts">
            <KeywordForm
              profile={profile}
              title="나를 소개하는 키워드 3가지"
            />
          </div>
          <div className="in-cnts">
            <IntroductionForm
              profile={profile}
              account={account}
              title="소개"
            />
          </div>
          <div className="in-cnts">
            <LanguageForm
              title="외국어"
              profile={profile}
              account={account}
            />
          </div>

          <div className="in-cnts">
            <RegidentForm
              title="현재거주지"
              profile={profile}
              account={account}
            />
          </div>

          <div className="in-cnts">
            <SNSForm
              title="포트폴리오 및 이력"
              profile={profile}
              account={account}
            />
          </div>

          <h3 className="tit">거래 시간</h3>
          <div className="in-cnts">
            <TimeForm
              title="거래 가능 시간 *"
              profile={profile}
              account={account}
            />
          </div>
          <div className="in-cnts">
            <TimeAddTypeForm
              title="프로젝트 상황에 따라, 거래 가능한 시간을 추가할 수 있나요?"
              profile={profile}
              account={account}
            />
          </div>
          <div className="in-cnts">
            <TimeZoneForm
              title="거래 가능한 시간대 (한국 기준)"
              profile={profile}
              account={account}
            />
          </div>
          <div className="in-cnts">
            <PriceForm
              title="시간당 거래 금액 *"
              text="실제 시간당 거래 금액은 세컨팀 회사와 협의 후 결정됩니다."
              profile={profile}
              account={account}
            />
          </div>

          <h3 className="tit">맞춤 정보</h3>
          <div className="in-cnts">
            <OfferForm
              title="협업 제안받기"
              profile={profile}
              account={account}
            />
          </div>
          <div className="in-cnts">
            <OfflineForm
              title="오프라인 출근 및 미팅 가능 여부"
              profile={profile}
              account={account}
            />
          </div>
          <div className="in-cnts">
            <AvailabilityForm
              title="구직의향"
              profile={profile}
              account={account}
            />
            <SalaryForm
              title="구직시 희망 연봉"
              profile={profile}
              account={account}
            />
          </div>
          <div className="in-cnts">
            <ExpectationForm
              title="세컨팀 멤버로 기대하는 바"
              profile={profile}
              account={account}
            />
          </div>

          <div className="btn sticky">
            <div className="inner">
              <a style={{ cursor: 'pointer ' }} onClick={onCancel} className="sub cel">취소</a>
              <a style={{ cursor: 'pointer ' }} onClick={handleSubmit(onSave)} className="actv save">저장</a>
              {/* <a href="#" className="wtype cel">임시저장</a> */}
            </div>
          </div>

        </div>
      </div>

      <Prompt
        when={form.formState.isDirty}
        message="변경 내용을 삭제하시겠어요?
        지금 페이지를 나가면 변경 내용이 삭제됩니다." />
    </FormProvider>
  )
}));

export default MemberProfileFormPage;
