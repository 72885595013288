import { useForm } from 'react-hook-form';

interface Props {
  isOpen: boolean;
  onClose: (result?: number) => void;
}

export default ({
  isOpen,
  onClose,
}: Props) => {
  const { register, getValues, formState: { errors } } = useForm();

  if (!isOpen) {
    return <></>;
  }

  const onCalc = () => {
    const price = getValues('price');
    onClose(Math.floor(parseInt(price) / 2080));
  }

  const onCancel = () => {
    onClose();
  }

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3>시급 계산하기</h3>
          <div className="h-pay">
            <label htmlFor="hPay">희망연봉</label>
            <div>
              <input
                type="number"
                placeholder="30,000,000"
                {...register('price', {
                  validate: {
                    min: value => parseInt(value) >= 1000000,
                    max: value => parseInt(value) <= 1000000000,
                  }
                })}
              />
              <span>원</span>
            </div>
            <p className="err">
              {
                errors.min &&
                  '1,000,000원 이상 입력해주세요'
              }

              {
                errors.max &&
                '1,000,000,000원 이하로 입력해주세요'
              }
            </p>
          </div>
          <div className="btn" onClick={onCalc}>
            <a style={{ cursor: 'pointer' }} className="actv">계산하기</a>
          </div>
        </div>
        <button type="button" className="btn-close" onClick={onCancel}><span>팝업 닫기</span></button>
      </div>
    </div>
  );
}
