const JoinVisual = () => {
  return (
    <div className="visual">
      <div className="inner">
        <img src="/images/join_img01.png" alt="" />
      </div>
    </div>
  );
}

export default JoinVisual;
