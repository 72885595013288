interface Props {
  onClear: () => void;
}

export default ({ onClear }: Props) => {
  return (
    <div className="no-result">
      <p>검색한 세컨팀 멤버를 찾을 수가 없습니다. <br /> 다른 조건으로 검색 해보시겠습니까?</p>
      <button type="button" className="btn-reset" onClick={onClear}>검색초기화</button>
    </div>
  );
}
