import ProjectCard from "@components/ProjectCard";
import { INewProject } from "@models/NewProject";
import constantAPI from "@services/constantAPI";
import axios from "axios";
import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import Loader from "react-loader";

const SearchBar = styled.input`
  width: 100%;
  max-width: 600px;
  height: 56px;
  border-radius: 6px;
  border: 1px solid #EBEBEB;
  padding: 0 20px;
  margin-top: 32px;
  margin-bottom: 64px;

  @media only screen and (max-width: 786px) {
    max-width: 90%;
  }
  
  outline: none;
  position: relative;
`;

const SearchButton = styled.button`
  width: 40px;
  height: 40px;
  background: rgba(85, 57, 255, 0.05);
  border-radius: 2px;
  position: relative;
  right: 48px;
  top: 15px;
  background: url('/images/search-icon.png') center no-repeat;
  background-color: rgba(85, 57, 255, 0.05);
  font-size: 16px;

  @media only screen and (max-width: 786px) {
    top: 40px;
  }
`;

const pageSize = 20;
const ProjectPage = () => {
  const [tab, setTab] = useState(1);
  const [count, setCount] = useState(0);
  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [projectList, setProjectList] = useState<INewProject[]>([]);
  const { register, watch, reset } = useForm();
  const queryWatcher = watch('query');
  const [page, setPage] = useState(1);

  useEffect(() => {
    load();
    loadProjectList();
  }, []);

  const load = async () => {
    const data = await constantAPI.getCategoryRootList();
    setCategoryList(data);
  };

  const loadProjectList = async () => {
    const { data } = await axios.get('/members/experiences', {
      params: {
        page: 1,
        page_size: pageSize,
      }
    });
    setProjectList(data.results);
    setCount(data.count);
  };

  const onChangeTab = async (name: string, tab: number) => {
    setTab(tab);
    reset({});

    const { data } = await axios.get('/members/experiences', {
      params: {
        category: name,
        page: 1,
        page_size: pageSize,
      }
    });
    setProjectList(data.results);
    setCount(data.count);
    setPage(1);
  }

  const onSearch = async () => {
    let params = {
      q: queryWatcher,
      page: 1,
      page_size: pageSize,
    };

    if (tab > 1) {
      // @ts-ignore
      params['category'] = categoryList[tab - 2].name;
    }

    const { data } = await axios.get('/members/experiences', {
      params,
    });

    setProjectList(data.results);
    setCount(data.count);
    setPage(1);
  }

  const onMore = async () => {
    let params = {
      page: page + 1,
      page_size: pageSize,
    }
    if (queryWatcher) {
      // @ts-ignore
      params['q'] = queryWatcher;
    }

    if (tab > 1) {
      // @ts-ignore
      params['category'] = categoryList[tab - 2].name;
    }

    const { data } = await axios.get('/members/experiences', {
      params,
    });

    setProjectList([...projectList, ...data.results]);
    setPage(page + 1);
  };

  return (
    <>
      <link rel="stylesheet" href={'/project.css'} />
      <div className="recommend-w members-project">
        <div className="progrs" style={{ textAlign: 'center', marginTop: -64, paddingBottom: isMobile ? 'auto' : 0 }}>
          <h1 style={{ marginTop: 72, fontSize: isMobile ? 28 : 36 }}>#{count + 1}번째 프로젝트를 기다립니다.</h1>
          <p style={{ marginTop: 12, color: '#8E9496', fontSize: 18 }}>한번의 프로젝트 등록만으로, N잡부터 이직제안까지 기회가 열립니다.</p>

          <div className="tab">
            <ul style={{ paddingTop: 0, overflowX: isMobile ? 'scroll' : 'auto' }}>
              <li
                style={{ marginTop: 0 }}
                onClick={() => {
                  setTab(1);
                  loadProjectList();
                }}
                className={tab === 1 ? 'selected' : ''}>
                <a style={isMobile ? { padding: 0, minWidth: '15vw', justifyContent: 'center' } : {}} href="#">
                  전체
                </a>
              </li>

              {
                categoryList.map((v: any, idx: number) => (
                  <li
                    style={{ marginTop: 0 }}
                    onClick={() => onChangeTab(v.name, idx + 2)}
                    className={tab === idx + 2 ? 'selected' : ''}
                  >
                    <a style={isMobile ? { padding: 0, minWidth: '17vw', justifyContent: 'center' } : {}} href="#">{v.name}</a>
                  </li>
                ))
              }
            </ul>
          </div>


          <div style={{ margin: '0 auto', display: isMobile ? 'flex' : 'block' }}>
            <SearchBar
              {...register('query')}
              placeholder="검색어를 입력해주세요."
            />

            <SearchButton
              onClick={onSearch}
            />
          </div>
        </div>
      </div>

      <div className="landing-cnts-v3" style={{ marginTop: -188 }}>
        <div className="fav-pjt">
          <InfiniteScroll
            className="ms-list"
            style={{ overflow: 'hidden' }}
            scrollableTarget="fav-pjt"
            dataLength={projectList.length} //This is important field to render the next data
            next={onMore}
            hasMore={projectList.length < count}
            loader={
              <Loader loaded={false} options={{ opacity: 0.5, color: '#5539ff', position: 'relative', top: '100px' }} />
            }
          // endMessage={
          //   <p style={{ textAlign: 'center' }}>
          //     <b>Yay! You have seen it all</b>
          //   </p>
          // }
          // scrollThreshold={0.5}
          // below props only if you need pull down functionality
          // refreshFunction={() => { }}
          // pullDownToRefresh
          // pullDownToRefreshThreshold={50}
          // pullDownToRefreshContent={
          //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
          // }
          // releaseToRefreshContent={
          //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
          // }
          >

            {
              projectList.map((v, idx) => (
                <ProjectCard
                  key={`${idx}`}
                  data={v}
                />
              ))
            }
          </InfiniteScroll>

          {
            projectList.length < 1 &&
            <p style={{ width: '100%', textAlign: 'center', margin: '0 auto', fontSize: 20 }}>검색 결과 없음</p>
          }
        </div>
      </div>
    </>
  );
};

export default ProjectPage;
