import styled from 'styled-components';

const RightDirectedInput = styled.input`
  ::placeholder {
    float: right;
    line-height: 56px;
  }
`;

export default RightDirectedInput;
