import { IDetailModel } from "../models/DetailModel";
import axios from 'axios';
import { inject, observer } from "mobx-react";
import { StateProps } from "../../../interfaces/interfaces";

interface Props extends StateProps {
  data: IDetailModel,
  userUUID: string,
  uuid: string,
  isOpen: boolean;
  onClose: () => void;
}

const MemberProjectCloseModal = ({
  data,
  userUUID,
  appStore,
  uuid,
  isOpen,
  onClose,
}: Props) => {
  const { showLoader, hideLoader } = appStore!;

  if (!isOpen) {
    return <></>;
  }

  const onSubmit = async () => {
    try {
      showLoader();
      await axios.put(`/members/${userUUID}/projects/${uuid}/agree-to-finalize`);
      hideLoader();
      
      window.location.reload();
    } catch (err) {

    }
    // onClose();
  };

  const onCancel = () => {
    onClose();
  };

  const onEnd = () => {
    onClose();
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3>협업 종료</h3>
          <p>{data.partner?.validName} 님이 협업 종료를 요청하였습니다. 프로젝트 협업 종료에 동의하시겠습니까?</p>
          <div className="s-desc">
            <p>협업이 종료되면 현재 진행 중인 기간은 취소되고 정산 요청하실 수 없습니다.</p>
          </div>
          <div className="btn type2">
            {/* <button type="button" className="cancel">취소</button> */}
            <button type="button" className="actv" onClick={onSubmit}>종료 동의</button>
          </div>
          <button type="button" className="btn-close" onClick={onEnd}><span>팝업 닫기</span></button>
        </div>
      </div>
    </div>
  );
}

export default inject("appStore")(observer((MemberProjectCloseModal)));
