import { IMemberProfileModel } from "@pages/profile/models";
import axios from "axios";
import { observer } from "mobx-react";
import { useState } from "react";
import { useForm } from 'react-hook-form';

interface Props {
  isOpen: boolean;
  memberUUID: string;
  onClose: () => void;
}

const MemberMemoModal = ({
  isOpen,
  memberUUID,
  onClose
}: Props) => {
  const { register, watch, setValue, reset, handleSubmit } = useForm();

  if (!isOpen) {
    return <></>;
  }

  const onSubmit = async (form: any) => {
    await axios.post(`/members/${memberUUID}/notes`, form);
    alert('메모가 추가되었습니다.');
    reset({});
    onClose();
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3>
            메모추가
          </h3>
          <br/>
          <textarea
            id="msgCnts"
            {...register('note')}
            placeholder="내용을 입력해주세요 (최대 200자)"
          ></textarea>
          <div className="btn">
            <button type="button" className="cancel" onClick={onClose}>취소</button>
            <a href="#" className="actv" onClick={handleSubmit(onSubmit)}>저장</a>
          </div>
        </div>

        <button type="button" className="btn-close" onClick={onClose}>
          <span style={{ fontSize: 16 }}>닫기</span>
        </button>
      </div>
    </div>
  )
};

export default observer(MemberMemoModal);
