import portfolioAPI from "@services/portfolioAPI";
import { AUTH_USERTYPE } from "@utils/constants";
import { useEffect, useState } from "react";
import { IEpisodeModel } from "../models/MemberProfileModel";

interface Props {
  data?: IEpisodeModel;
}

const PortfolioBanner = ({
  data,
}: Props) => {
  const [likeCount, setLikeCount] = useState(data?.like_count || 0);
  const [superLikeCount, setSuperLikeCount] = useState(data?.super_like_count || 0);
  const [shareCount, setShareCount] = useState(data?.share_count || 0);
  
  useEffect(() => {
    setLikeCount(data?.like_count || 0);
    setSuperLikeCount(data?.super_like_count || 0);
    setShareCount(data?.share_count || 0);
  }, [data]);

  if (!data) {
    return <></>;
  }

  const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';

  const onLike = async () => {
    if (!userType) {
      alert('회원만 가능합니다');
      return;
    }

    await portfolioAPI.postLike(data.uuid);
    setLikeCount(likeCount + 1);
  };

  const onSuperLike = async () => {
    if (!userType) {
      alert('회원만 가능합니다');
      return;
    }

    await portfolioAPI.postSuperLike(data.uuid);
    setSuperLikeCount(superLikeCount + 1);
  };

  const onShare = async (e: any) => {
    navigator.clipboard.writeText(window.location.href);

    if (!userType) {
      alert('포트폴리오의 URL이 클립보드에 복사되었습니다.');
      return;
    }

    await portfolioAPI.postShare(data.uuid);
    setShareCount(shareCount + 1);

    alert('포트폴리오의 URL이 클립보드에 복사되었습니다.');
  };

  return (
    <div className="add-info-share">
      <button type="button" className="like" onClick={onLike}>멋진 프로젝트네요<span style={{ width: 38 }}>{likeCount}</span></button>
      <button type="button" className="with" onClick={onSuperLike}>함께 일해보고 싶네요<span style={{ width: 38 }}>{superLikeCount}</span></button>
      <button type="button" className="share" onClick={onShare}>공유하기<span style={{ width: 38 }}>{shareCount}</span></button>
    </div>
  );
}

export default PortfolioBanner;
