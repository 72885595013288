/*
  markup : mypage_member_price.html
*/
import { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { IProfileStore } from '../../stores/profileStore';
import { IAppStore } from "../../stores/AppStore";
import Pagination from "../../components/Pagination";
import MembershipExtendModal from "../../components/profile-new-form/MembershipExtendModal";


interface IProps {
  appStore: IAppStore;
}


const PartnerMembershipPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const { profileStore } = (props as IProps).appStore;
  const { partnerProfile, getPartnerProfile } = (profileStore as IProfileStore);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    // getMemberProposalList(uuid);
  }, []);


  return (
    <>
      <div className="sub-wrap">
        <div className="top-box">
          <h2>나의 요금제</h2>
        </div>
        <div className="mem-price">
          <div className="info-box">
            <strong>현재 베이직 요금제 이용중입니다.</strong>
            <div className="in-btn">
              <a href="#">요금제 살펴보기</a>
            </div>
          </div>
          <div className="info-box">
            <strong>요금제 업데이트 신청 감사합니다!</strong>
            <p>요금제 결제 및 이용 관련해서 별도로 메일을 통해 안내드리고 있습니다.</p>
            <div className="in-btn">
              <a href="#">요금제 업데이트 취소</a>
            </div>
          </div>
          <div className="info-box">
            <strong>현재 프리미엄 요금제 이용중 <b>D-123</b></strong>
            <p>2021. 12. 12 - 2022. 12.11</p>
            <div className="in-btn">
              {
                true ?
                  <a className="disb">프리미엄 요금제 연장 신청</a>
                :
                  <a className="disb" style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>프리미엄 요금제 연장 신청</a>
              }
            </div>
            <p className="desc">프리미엄 요금제 연장신청은 종료일 30일 전부터 가능합니다.</p>
          </div>
          <div className="link">
            <a href="#">요금제 정책 및 혜택 확인하기</a>
          </div>
          <div className="update-cnts">
            <h3>요금제 업데이트 내역</h3>
            <div className="viewport">
              <table>
                <caption>요금제 업데이트 내역</caption>
                <thead>
                  <tr>
                    <th scope="col">결제일</th>
                    <th scope="col">내역</th>
                    <th scope="col">기간</th>
                    <th scope="col">결제금액</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>2021.07.21</td>
                    <td>프리미엄</td>
                    <td>2021.12.12 - 2022.12.11</td>
                    <td>22,000,000원</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="no-update">
              <p>업데이트한 요금제 내역이 없습니다.</p>
            </div>
          </div>
        </div>
      </div>

      <MembershipExtendModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </>
  );
}));

export default PartnerMembershipPage;
