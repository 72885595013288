import Avatar from '../../../components/Avatar';
import { IChatModel } from '../models/ChatModel';
import dayjs from 'dayjs';
import { IMessageMemberModel, IMessagePartnerModel } from '../models/MessageModel';
import Linkify from 'react-linkify';

interface Props {
  data: IChatModel;
  member?: IMessageMemberModel | undefined;
  partner?: IMessagePartnerModel | undefined;
  isMe: boolean;
  isMember: boolean;
}

export default ({ data, member, partner, isMember, isMe = false }: Props) => {
  return (
    <div className={`in-mesg ${isMe ? 'comp' : ''}`}>
      <Avatar
        data={
          isMe ?
            isMember ?
              member
            : 
              partner
          :
            isMember ?
              partner
            :     
              member
        }
      />
      <div>
        <em>{data.user_name}</em>
        <Linkify
          componentDecorator={(
            decoratedHref: string,
            decoratedText: string,
            key: number
          ) => (
            <a href={decoratedHref} key={key} target="_blank">
              {decoratedText}
            </a>
          )}
        >
          <p>
            {data.content}
          </p>
        </Linkify>
        {/* <p>{data.content}</p> */}
        <span className="time">
          {dayjs(data.created_at).format('MM.DD HH:mm')}
        </span>
      </div>
    </div>
  );
};