import { types, Instance, flow, getParent } from "mobx-state-tree";

import { IAppStore } from './AppStore';

import { StateModel } from '../models/models';
import { MemberAccountModel, PartnerAccountModel } from '../pages/account/models';
import accountAPI from '../services/accountAPI';
import { MemberProfileModel } from "../pages/profile/models";
import profileAPI from "../services/profileAPI";
import { IWorkPreference, WorkPreference } from "@models/WorkPreference";
import constantAPI from "@services/constantAPI";


const AccountStore = StateModel.named('AccountStore')
  .props({
    memberAccount: types.maybe(MemberAccountModel),
    memberProfile: types.maybe(MemberProfileModel),
    partnerAccount: types.maybe(PartnerAccountModel),
    workPreferenceList: types.array(WorkPreference)
  })
  .views(self => ({
    get uuid(): string {
      return (getParent(self) as IAppStore).userStore.user.uuid;
    }
  }))
  .actions(self => ({
    getMemberAccount: flow(function* getMemberAccount() {
      if(!self.uuid) {
        return;
      }
      try {
        const response = yield accountAPI.getMemberAccount(self.uuid);
        self.memberAccount = MemberAccountModel.create(response);
      } catch(e) {
        throw e;
      }
    }),
    getMemberProfile: flow(function* getMemberProfile(uuid?: string) {
      if(!self.uuid && !uuid) {
        return;
      }
      try {
        const response = yield profileAPI.getMemberProfile(uuid || self.uuid);
        self.memberProfile = MemberProfileModel.create(response);
      } catch(e) {
        throw e;
      }
    }),
    getPartnerAccount: flow(function* getPartnerAccount() {
      if(!self.uuid) {
        return;
      }
      try {
        const response = yield accountAPI.getPartnerAccount(self.uuid);
        self.partnerAccount = PartnerAccountModel.create(response);
      } catch(e) {
        throw e;
      }
    }),
    getWorkPreference: flow(function* () {
      try {
        const responseList = yield constantAPI.getWorkPreferenceList();
        self.workPreferenceList = responseList.map((data: IWorkPreference) => (
          WorkPreference.create(data)
        ));
      } catch (e) {
        throw e;
      }
    }),
  }));
export interface IAccountStore extends Instance<typeof AccountStore> {};

export default AccountStore;
