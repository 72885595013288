import { numberWithCommas } from '../../../utils/utils';
import { IIterationModel } from '../models/IterationModel';
import dayjs from 'dayjs';
import { IDetailModel } from '../models/DetailModel';

interface Props {
  data: IIterationModel;
  userType: string;
  project: IDetailModel;
  isOpen: boolean;
  onClose: () => void;
}

export default ({
  data,
  userType,
  project,
  isOpen,
  onClose,
}: Props) => {
  if (!isOpen) {
    return <></>;
  }

  const onEnd = () => {
    onClose();
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3>{data.round}번째 주 정산</h3>

          {
            userType === 'MEMBER' ?
              <p>{dayjs(data.date_payment_requested).format('YYYY.MM.DD')} 정산이 처리되었습니다 🙌</p>
              :
              <p>{project.member?.name} 님에게 {dayjs(data.date_payment_requested).format('YYYY.MM.DD')} 정산이 완료되었습니다 🙌</p>
          }

          <div className="sp-end2">
            <ul className="type2">
              <li>
                <i className="tit">정산 기간</i>
                <span>{dayjs(data.start_date).format('YYYY.MM.DD')} - {dayjs(data.end_date).format('YYYY.MM.DD')}</span>
              </li>
              <li>
                <i className="tit">진행 업무</i>
                <div className="prg-work">
                  {data.weekly_work_description}
                </div>
              </li>
            </ul>
          </div>
          <div className="sp-end2">
            {
              userType === 'MEMBER' ?
                <div className="pay-result">
                  <div className="app-price">
                    <em>정산 주급</em>
                    <strong>{numberWithCommas(data.wage)}원</strong>
                  </div>
                </div>
                :
                <div className="pay-result">
                  <div className="app-price">
                    <em>정산 요청 주급 <span>수수료 별도</span></em>
                    <strong>{numberWithCommas(data.wage)}원</strong>
                  </div>
                  <div className="def-price">
                    <i>기본 주급(선 차감 완료)</i>
                    <span>{numberWithCommas(project.proposal.wage || 0)}원</span>
                  </div>
                  {
                    data.wage > (project.proposal.wage || 0) &&
                    <div className="def-price">
                      <i>추가 차감 주급</i>
                      <span>{numberWithCommas(data.wage - (project.proposal.wage || 0))}원</span>
                    </div>
                  }
                  {
                    (project.proposal.wage || 0) > data.wage &&
                    <div className="def-price">
                      <i>환불 주급</i>
                      <span>{numberWithCommas((project.proposal.wage || 0) - data.wage)}원</span>
                    </div>
                  }
                  {
                    data.round === 1 &&
                    <>
                      <div className="app-price matching">
                        <em>세컨팀 매칭 수수료</em>
                        <strong>{numberWithCommas(project.contract_fee || 0)}원</strong>
                      </div>
                      <p className="desc-match">
                        {
                          project.contract_fee ?
                            '첫번째 정산시에만 1회 차감'
                            :
                            '매칭 수수료 무료 (맴버 재매칭)'
                        }
                      </p>
                    </>
                  }
                </div>
            }
          </div>
          <div className="btn type2">
            <a style={{ cursor: 'pointer' }} className="actv" onClick={onClose}>확인</a>
          </div>
        </div>
        <button type="button" className="btn-close" onClick={onEnd}><span>팝업 닫기</span></button>
      </div>
    </div>
  );
}

