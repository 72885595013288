import EditPrompt from "@components/EditPrompt";
import { IAppStore } from "@stores/AppStore";
import { inject, observer } from "mobx-react";
import { FormProvider, useForm } from "react-hook-form";
import { JoinAccount, JoinPolicy } from './components';
import JoinFooter from "./components/JoinFooter";
import JoinMember from "./components/JoinMember";
import JoinVisual from "./components/JoinVisual";
import { useLocation } from 'react-router-dom';
import { useEffect } from "react";
import { useQuery } from "@utils/hooks";
import TagManager from 'react-gtm-module';

interface IProps {
  appStore: IAppStore;
  history: any;
}

const MemberJoinPage = (props: IProps) => {
  const location = useLocation();
  const isSimpleJoin = location.pathname === '/event-join';
  const isRecommendJoin = location.pathname === '/recommend-join';
  const query = useQuery();

  const { joinStore, userStore, showLoader, hideLoader } = (props as IProps).appStore;
  const form = useForm({
    defaultValues: {
      job_categories: [{}],
    }
  } as any);
  const { handleSubmit, setValue } = form;

  useEffect(() => {
    checkEventJoin();
    checkRecommendJoin();
  }, []);

  const checkRecommendJoin = () => {
    if (!isRecommendJoin) {
      return;
    }

    const recommender = query.get('recommender');
    if (!recommender) {
      window.location.href = '/member/join/form';
      return;
    }

    // todo
  };

  const checkEventJoin = () => {
    if (!isSimpleJoin) {
      return;
    }

    const targetId = query.get('targetId');
    // front2 - 흥미로운 일을 찾는 프론트
    // web1 - N잡을 희망하는 웹 개발자
    // back1 - N잡을 희망하는 백엔드

    switch (targetId) {
      case 'front2':
        setValue('work_preferences', [5]);
        setValue('job_categories', [{
          main_category: '개발',
          sub_category: '프론트엔드',
        }]);
        break;
      case 'web1':
        setValue('work_preferences', [3]);
        setValue('job_categories', [{
          main_category: '개발',
          sub_category: '웹',
        }]);
        break;
      case 'back1':
        setValue('work_preferences', [3]);
        setValue('job_categories', [{
          main_category: '개발',
          sub_category: '백엔드',
        }]);
        break;
      default:
        window.location.href = '/member/join/form';
        break;
    }
  };

  const onJoin = async (data: any) => {
    try {
      showLoader();

      const { work_preferences, job_categories } = data;

      await joinStore.memberSignup({
        work_preferences: work_preferences.map((v: any) => parseInt(v)),
        job_categories,
      });

      const targetId = query.get('targetId');
      // TagManager.dataLayer({
      //   dataLayer: {
      //     event: 'event-join',
      //     targetId,
      //     email: joinStore.joinAccountForm.email,
      //   },
      // });

      await userStore.loginForm.setEmail(joinStore.joinAccountForm.email);
      await userStore.loginForm.setPassword(joinStore.joinAccountForm.password);

      const { user }: any = await userStore.loginForm.login();
      const { onboarding_needed } = user;

      hideLoader();

      if (onboarding_needed) {
        window.location.href = '/onboarding';
        return;
      }

      window.location.href = '/';
    } catch (e) {
      console.log(e);
      console.log(data);
      hideLoader();
    }
  };

  return (
    <FormProvider {...form}>
      <div className="join-sign">
        <JoinVisual />
        <div className="join-w">
          <div className="inner">
            <h2 className="noline">세컨팀 멤버 <br />회원가입</h2>
            <div className="join-form">
              <JoinAccount
                model={joinStore.joinAccountForm}
              />

              {
                isSimpleJoin ||
                <JoinMember
                  model={joinStore.joinAccountForm}
                />
              }

              <JoinPolicy
                model={joinStore.joinAgreementForm}
                validatedMode={joinStore.clickedJoin}
              />

              <JoinFooter
                onJoin={handleSubmit(onJoin)}
              />
            </div>
          </div>
        </div>

        <EditPrompt
          when={joinStore.isEditing}
        />
      </div>
    </FormProvider>
  )
};

export default inject("appStore")(observer(MemberJoinPage));
