import { useState } from 'react';
import { coworkingMemberChoices, MEMBER_WEBURL } from '../../../utils/constants';
import { IEpisodeModel } from '../models/MemberProfileModel';
import { IJobCategoryModel } from '../models';


const ProjectCard = ({ data, rootJobs, jobs, isMine, isMarginLeft, isMarginRight, windowWidth }: { data: IEpisodeModel, rootJobs: IJobCategoryModel[], jobs: IJobCategoryModel[], isMine: boolean, isMarginLeft: boolean, isMarginRight: boolean, windowWidth: number }) => {
  const [isShowMenu, setShowMenu] = useState(false);
  const { title, domain, coworking_member_count, role, skills, experiences, frameworks, job_category, reference_type, link, file, filename } = data;
  const isTablet = windowWidth < 1280 && windowWidth >= 786;
  const isMobile = windowWidth < 786;

  return (
    <div
      className="viewport"
      style={{
        overflow: 'visible',
        marginLeft: isMarginLeft && !isTablet ? -1 * (isMobile ? (windowWidth - 311) * 1.5 : (windowWidth - 720 / 2)) : 'auto',
        marginRight: isMarginRight && !isTablet ? -1 * (isMobile ? (windowWidth - 311) * 1.5 : (windowWidth - 720 / 2)) : 'auto',
        // transition: 'all 1s ease-in-out'
      }}
    >
      <div className="pf-info" style={{ padding: isMobile ? 0 : 'auto' }}>
        <div className="inbox">
          <div className="inner">
            <h2>{title || '프로젝트명 미입력'}<span>{data.domain || '프로젝트 분야 미입력'}</span></h2>
            {
              data.coworkers.length > 0 &&
              <div className="mem-w">
                {
                  data.coworkers.slice(0, 4).map(v => (
                    <span><img src={v.avatar || "/images/@photo.png"} alt="" /></span>
                  ))
                }

                {
                  data.coworkers.length > 4 &&
                  <span className="num">+{data.coworkers.length - 4}</span>
                }
              </div>
            }
            <div className="part">
              <i>역할</i>
              <p>
                {
                  data.job_categories.map(v => v.sub_category).join(' / ') || '입력되지 않았습니다.'
                }
              </p>
            </div>
            <div className="mem-num">
              <div style={{ marginRight: 32 }}>
                <i>시작 연도 / 기간</i>
                {
                  (Boolean(data.year) && Boolean(data.duration)) ?
                    <span>
                      {data.year}년 / {[
                        '',
                        '3개월 이내',
                        '6개월 이내',
                        '1년 이내',
                        '1년 이상',
                      ]?.[data.duration!] || ''}
                    </span>
                    :
                    <p>입력되지 않았습니다.</p>
                }
              </div>
              <div>
                <i>협업한 멤버 수</i>
                {
                  Boolean(data?.coworking_member_count) ?
                    <span>{coworkingMemberChoices[data?.coworking_member_count || 0]}</span>
                    :
                    <p>입력되지 않았습니다.</p>
                }
              </div>
            </div>
          </div>

          {
            isMine &&
            <div className={`pf-modify ${isShowMenu ? 'selected' : ''}`}>
              <button type="button" onClick={() => setShowMenu(!isShowMenu)}><span>설정</span></button>
              <div>
                <a href={`${MEMBER_WEBURL}/me/projects/${data.uuid}`}>프로젝트 수정</a>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
