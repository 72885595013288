import { AUTH_UUID } from '@utils/constants';
import axios from 'axios';

const portfolioAPI = {
  postLike: async (uuid: string) => {
    const memberUUID = window.localStorage.getItem(AUTH_UUID) || '';
    const { data } = await axios.post(
      `/members/${memberUUID}/episodes/${uuid}/like`,
    );

    return data;
  },
  postSuperLike: async (uuid: string) => {
    const memberUUID = window.localStorage.getItem(AUTH_UUID) || '';
    const { data } = await axios.post(
      `/members/${memberUUID}/episodes/${uuid}/super-like`,
    );

    return data;
  },
  postShare: async (uuid: string) => {
    const memberUUID = window.localStorage.getItem(AUTH_UUID) || '';
    const { data } = await axios.post(
      `/members/${memberUUID}/episodes/${uuid}/share`,
    );

    return data;
  },
  getCoWorkerList: async (memberUUID: string, uuid: string) => {
    const { data } = await axios.get(
      `/members/${memberUUID}/episodes/${uuid}/coworkers`,
    );

    return data;
  },
  postCoWorker: async (uuid: string, params: any) => {
    const memberUUID = window.localStorage.getItem(AUTH_UUID) || '';

    const { data } = await axios.post(
      `/members/${memberUUID}/episodes/${uuid}/coworkers`,
      params,
    );

    return data;
  },
};

export default portfolioAPI;
