import PasswordResetPage from '@pages/account/PasswordResetPage';
import MemberCalcPage from '@pages/calc/MemberCalcPage';
import PartnerCalcPage from '@pages/calc/PartnerCalcPage';
import MemberListPage from '@pages/members/MemberListPage';
import MessagePage from '@pages/message/MessagePage';
import OnboardingPage from '@pages/onboarding/OnboardingPage';
import { Route, Switch } from 'react-router';

import MemberProfilePage from '@pages/profile/MemberProfilePage';
import PartnerMembershipPage from '@pages/profile/PartnerMembershipPage';
import RecruitMemberStatusPage from '@pages/recruit/RecruitMemberStatusPage';
import RecruitMemberSuggestPage from '@pages/recruit/RecruitMemberSuggestPage';
import RecruitPartnerStatusPage from '@pages/recruit/RecruitPartnerStatusPage';
import RecruitPartnerSuggestPage from '@pages/recruit/RecruitPartnerSuggestPage';
import Login from '@pages/user/Login';

import How from '@pages/app/How';
import MemberAccountPage from '@pages/account/MemberAccountPage';
import PartnerAccountPage from '@pages/account/PartnerAccountPage';
import Main from '@pages/app/Main';
import JoinPage from '@pages/join/JoinPage';
import MemberJoinPage from '@pages/join/MemberJoinPage';
import PartnerJoinDonePage from '@pages/join/PartnerJoinDonePage';
import PartnerJoinPage from '@pages/join/PartnerJoinPage';
import PollPage from '@pages/polls/PollPage';
import MemberProfileFormPage from '@pages/profile/MemberProfileFormPage';
import MemberProjectFormPage from '@pages/profile/MemberProjectFormPage';
import PartnerProfileFormPage from '@pages/profile/PartnerProfileFormPage';
import PartnerProfilePage from '@pages/profile/PartnerProfilePage';
import RecruitDetailPage from '@pages/recruit/RecruitDetailPage';
import RecruitListPage from '@pages/recruit/RecruitListPage';
import RecruitSuggestionModifyPage from '@pages/recruit/RecruitSuggestionModifyPage';
import NDA from '@pages/terms/NDA';
import Privacy from '@pages/terms/Privacy';
import Terms from '@pages/terms/Terms';
import Faq from '@pages/app/Faq';
import MemberSkillPage from '@pages/members/MemberSkillPage';
import MemberProjectPage from '@pages/members/MemberProjectPage';
import PoolPage from '@pages/career/PoolPage';
import SurveyPage from '@pages/career/SurveyPage';
import PoolListPage from '@pages/career/PoolListPage';
import PoolDetailPage from '@pages/career/PoolDetailPage';
import ProjectPage from '@pages/app/ProjectPage';
import ExplorerPage from '@pages/app/ExplorerPage';
import EpisodeDetailPage from '@pages/profile/EpisodeDetailPage';
import ClubPage from '@pages/clubs/ClubPage';
import ClubDetailPage from '@pages/clubs/ClubDetailPage';
import ClubDonePage from '@pages/clubs/ClubDonePage';
import CompanyPage from '@pages/companies/CompanyPage';
import CandidatePage from '@pages/candidates/CandidatePage';
import CandidateDetailPage from '@pages/candidates/CandidateDetailPage';
import Logout from '@pages/user/Logout';

const Routes = () => {
  return (
    <Switch>
      <Route path="/">
        <Switch>
          <Route path="/" exact={true} component={Main} />
          <Route path="/how" exact={true} component={How} />
          
          <Route path="/find" exact={true} component={Faq} />
          <Route path="/login" exact={true} component={Login} />
          <Route path="/join" exact={true} component={JoinPage} />
          <Route path="/event-join" exact={true} component={MemberJoinPage} />
          <Route path="/recommend-join" exact={true} component={MemberJoinPage} />
          <Route path="/projects" exact={true} component={ProjectPage} />
          <Route path="/explorer" exact={true} component={ExplorerPage} />
          
          <Route
            path="/member/join/form"
            exact={true}
            component={MemberJoinPage}
          />
          <Route
            path="/partner/join/form"
            exact={true}
            component={PartnerJoinPage}
          />
          <Route
            path="/partner/join/done"
            exact={true}
            component={PartnerJoinDonePage}
          />

          <Route path="/terms" exact={true} component={Terms} />
          <Route path="/terms/nda" exact={true} component={NDA} />
          <Route path="/terms/privacy" exact={true} component={Privacy} />

          <Route
            path="/member/profile/edit"
            exact={true}
            component={MemberProfileFormPage}
          />
          <Route
            path="/member/project/edit"
            exact={true}
            component={MemberProjectFormPage}
          />
          <Route
            path="/member/profile"
            exact={true}
            component={MemberProfilePage}
          />
          <Route
            path="/skills"
            exact={true}
            component={MemberProjectPage}
          />
          {/* <Route
            path="/skills"
            exact={true}
            component={MemberSkillPage}
          /> */}
          <Route
            path="/partner/profile/edit"
            exact={true}
            component={PartnerProfileFormPage}
          />
          <Route
            path="/partner/profile"
            exact={true}
            component={PartnerProfilePage}
          />

          <Route
            path="/member/account"
            exact={true}
            component={MemberAccountPage}
          />
          <Route
            path="/partner/account"
            exact={true}
            component={PartnerAccountPage}
          />
          <Route
            path="/password/reset"
            exact={true}
            component={PasswordResetPage}
          />

          <Route
            path="/membership"
            exact={true}
            component={PartnerMembershipPage}
          />

          <Route path="/members" exact={true} component={MemberListPage} />
          <Route
            path="/members/:uuid"
            exact={true}
            component={MemberProfilePage}
          />
          <Route
            path="/projects/:uuid"
            exact={true}
            component={EpisodeDetailPage}
          />
          <Route
            path="/partners/:uuid"
            exact={true}
            component={PartnerProfilePage}
          />

          <Route path="/recruits" exact={true} component={RecruitListPage} />
          <Route
            path="/recruits/suggest"
            exact={true}
            component={RecruitSuggestionModifyPage}
          />
          <Route
            path="/recruits/member"
            exact={true}
            component={RecruitMemberStatusPage}
          />
          <Route
            path="/recruits/partner"
            exact={true}
            component={RecruitPartnerStatusPage}
          />
          <Route
            path="/recruits/member/suggest/:uuid"
            exact={true}
            component={RecruitMemberSuggestPage}
          />
          <Route
            path="/recruits/partner/suggest/:uuid"
            exact={true}
            component={RecruitPartnerSuggestPage}
          />
          <Route
            path="/recruits/:uuid"
            exact={true}
            component={RecruitDetailPage}
          />

          <Route
            path="/calc/partner"
            exact={true}
            component={PartnerCalcPage}
          />
          <Route path="/calc/member" exact={true} component={MemberCalcPage} />

          <Route path="/onboarding" exact={true} component={OnboardingPage} />

          <Route path="/messages" exact={true} component={MessagePage} />

          <Route path="/openForm/:uuid" exact={true} component={PollPage} />

          <Route path="/career/survey" exact component={SurveyPage} />
          <Route path="/career/pool" exact component={PoolListPage} />
          <Route path="/career/pool/:id" exact component={PoolPage} />
          <Route path="/career/pool/:id/list" exact component={PoolDetailPage} />
          
          <Route path="/clubs" exact component={ClubPage} />
          <Route path="/clubs/:id/done" exact component={ClubDonePage} />
          <Route path="/clubs/:id" exact component={ClubDetailPage} />
          <Route path="/companies" exact component={CompanyPage} />
          <Route path="/candidates" exact component={CandidatePage} />
          <Route path="/candidates/:id" exact component={CandidateDetailPage} />
          
          <Route path="/logout" exact component={Logout} />
        </Switch>
      </Route>
    </Switch>
  );
};
export default Routes;
