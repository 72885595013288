import { types, Instance } from "mobx-state-tree";
import { IterationModel } from './IterationModel';

const MemberModel = types.model({
  uuid: types.string,
  avatar: types.maybeNull(types.string),
  name: types.string,
  nickname: types.string,
})

export const PaymentModel = types.model({
  uuid: types.string,
  type: types.string,
  amount: types.number,
  balance: types.maybeNull(types.number),
  member: types.maybeNull(MemberModel),
  project_title: types.maybeNull(types.string),
  iteration: types.maybeNull(IterationModel),
  round: types.maybeNull(types.number),
  commision: types.maybeNull(types.number),
  tax_type: types.maybeNull(types.string),
  created_at: types.maybeNull(types.string),
})
.views(self => ({
  
}));

export interface IPaymentModel extends Instance<typeof PaymentModel> {};
