import { flow, types, getRoot, Instance } from "mobx-state-tree";

import { StateModel } from '../../../models/models';
import { IAppStore } from '../../../stores/AppStore';
import userAPI from '../../../services/userAPI';
import { ILoginResponse } from '../../../interfaces/userTypes';
import partnerAPI from "../../../services/partnerAPI";

// const EMAIL_NOT_VERIFIED_CODE = 400;
export const LoginForm = StateModel.named('LoginForm')
.props({
    email: types.string,
    password: types.string,
    isError: types.optional(types.boolean, false),
    isVerifiedEmail: types.optional(types.boolean, true),
    token: types.optional(types.string, ''),

    isActivated: types.optional(types.boolean, false)
  })
  .actions(self => ({
    reset() {
      self.email = '';
      self.password = '';
      self.isError = false;
      self.isVerifiedEmail = true;
      self.token = '';
      self.isActivated = false;
    },
    setEmail(email: string) {
      self.email = email;
    },
    setPassword(password: string) {
      self.password = password;
    },
    toggleIsVerifiedEmail() {
      self.isVerifiedEmail = !self.isVerifiedEmail;
    },
    checkFieldValid() {
      if(!self.email || !self.password) {
        self.isError = true;
      }
    }
  }))
  .actions(self => ({
    login: flow(function* login() {
      self.checkFieldValid();
      self.setPending();

      if(!self.email || !self.password) {
        throw new Error();
      }

      try {
        const data: ILoginResponse = yield userAPI.login(self.email, self.password);
        const { token, user } = data;
        const userStore = (getRoot(self) as IAppStore).userStore;

        self.token = token;
        userStore.user.setToken(token);
        userStore.user.setUserId(user.id);
        userStore.user.setUsername(user.name);
        userStore.user.setEmail(user.email);
        userStore.user.setUserType(user.user_type);
        userStore.user.setUuid(user.uuid);

        userStore.user.seveTokenToCookie();
        
        self.setDone();

        return data;
      } catch (e) {
        // if(axios.isAxiosError(e) && e.response && e.response.status === EMAIL_NOT_VERIFIED_CODE) {
        //   self.isVerifiedEmail = false;
        // } else {
        //   self.isError = true;
        // }
        self.isError = true;
        throw e;
      }
    }),
    resendActivation: flow(function* login() {
      try {
        yield partnerAPI.resendActivation(self.email);
      } catch (e) {
        throw e;
      }
    }),
    activate: flow(function* activate(uidb: string, token: string) {
      try {
        yield partnerAPI.activate(uidb, token);
        self.isActivated = true;
      } catch (e) {
        throw e;
      }
    })
  }));
export interface ILoginForm extends Instance<typeof LoginForm> {};