interface Props {
  nickname: string;
  isOpen: boolean;
  onClose: () => void;
}

export default ({
  nickname,
  isOpen,
  onClose,
}: Props) => {
  if (!isOpen) {
    return <></>;
  }

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3>수고 많으셨습니다!</h3>
          <p>
            {nickname || '멤버'}님의 프로필이 완성되었습니다. <br />
            지금 완성된 프로필을 확인해보세요.
          </p>
          <div className="btn">
            <a href="/member/profile" className="actv">확인</a>
          </div>
        </div>
        <button type="button" className="btn-close" onClick={() => window.location.href = '/member/profile'}>
          <span>팝업 닫기</span>
        </button>
      </div>
    </div>
  );
}
