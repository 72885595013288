import { useState, useEffect } from 'react';
import { sortBy } from 'lodash';
import { observer } from "mobx-react";

import { IJobCategoryModel } from '../../pages/profile/models';
import profileAPI from '../../services/profileAPI';

interface Option {
  id: number;
  name: string;
  order: number;
  parent: string;
}
const JobCategoryField = observer((props: {
  value: IJobCategoryModel[],
  onChange: (value: IJobCategoryModel, selected: boolean) => void
}) => {
  const [ options, setOptions ] = useState<Option[]>([]);
  const [ rootOptions, setRootOptions ] = useState<Option[]>([]);

  const [ selectedRoot, setSelectedRoot ] = useState<string>(props.value.length > 0 ? props.value[0].parent : '');
  const [ searchText, setSearchText ] = useState<string>('');
  const [ showOptions, setShowOptions ] = useState<boolean>(false);

  useEffect(() => {
    async function fetch() {
      const jobsRoot = await profileAPI.getConstants('jobs/root/');
      const jobsAll = await profileAPI.getConstants('jobs/all/');
      setOptions(jobsAll);
      setRootOptions(jobsRoot);
    }
    fetch();
  }, []);

  return (
    <div className="in-cnts">
      <h3><label htmlFor="job2">직군 / 직무 <i className="ness"><span>필수</span></i></label></h3>
      <div className="sel-form">
        <select id="job2"
          value={ selectedRoot }
          onChange={(e) => {
            setShowOptions(false);
            setSearchText('');
            setSelectedRoot(e.target.value)
          }} >
            <option>직군 선택</option>
            {
              sortBy(rootOptions, ['order'])
                .map(rootOptions => <option key={ rootOptions.id } value={rootOptions.name}>{ rootOptions.name }</option>)
            }
        </select>

        <div className="fm-box">
          {
            selectedRoot ? 
            <>
              {
                props.value.map( (job, i) =>
                  <span>{ job.name }
                    <button type="button" onClick={ () => props.onChange(job, false) }><span>삭제</span></button>
                  </span>
                )
              }
              <div className={ `in-sel ${ showOptions ? 'selected' : ''}` }>{/* <!-- 옵션열림 "selected" class로 컨트롤 --> */}
                <input
                  type="text" value={ searchText }
                  onFocus={ () => setShowOptions(true) }
                  onChange={ (e) => {
                    setSearchText(e.target.value);
                  }} />
                <div className="opt-li">
                  <ul>
                    {
                      options
                        .filter(op => op.parent === selectedRoot)
                        .filter(op => !props.value.map(v => v.name).includes(op.name))
                        .filter(op => op.name.includes(searchText))
                        .map(option => <li key={ option.id } onClick={ () => {
                          props.onChange(option, true);
                          setShowOptions(false);
                        } }>{option.name}</li>)
                    }
                  </ul>
                </div>
              </div>
            </> : <p>직군 먼저 선택해주세요</p>
          }
        </div>
      </div>
    </div>
  )
});
export default JobCategoryField;