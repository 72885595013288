/*
  markup : settle.html
*/
import { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import { IAppStore } from "../../stores/AppStore";
import { IRecruitListStore } from '@stores/RecruitListStore';
import { useParams } from 'react-router-dom';
import { numberWithCommas } from '../../utils/utils';
import dayjs from 'dayjs';
import MemberStartGuide from './components/MemberStartGuide';
import PartnerStartGuide from './components/PartnerStartGuide';
import MemberNotStartGuide from './components/MemberNotStartGuide';
import PartnerNotStartGuide from './components/PartnerNotStartGuide';
import ChargeModal from './components/ChargeModal';
import PartnerProjectCloseModal from './components/PartnerProjectCloseModal';
import MemberProjectCloseModal from './components/MemberProjectCloseModal';
import { IIterationModel } from './models/IterationModel';
import PartnerConfirmModal from './components/PartnerConfirmModal';
import MemberConfirmModal from './components/MemberConfirmModal';
import RecruitProfile from './components/RecruitProfile';
import PaymentNowModal from './components/PaymentNowModal';
import PaymentCompleteModal from './components/PaymentCompleteModal';
import MemberTerminateWarningModal from "./components/MemberTerminateWarningModal";
import MemberTerminateNoticeModal from "./components/MemberTerminateNoticeModal";
import PartnerTerminateWarningModal from "./components/PartnerTerminateWarningModal";
import PartnerTerminateNoticeModal from "./components/PartnerTerminateNoticeModal";
import DocumentNoticeModal from './components/DocumentNoticeModal';
import PartnerStartGuideInput from './components/PartnerStartGuideInput';

interface IProps {
  appStore: IAppStore;
}


const RecruitDetailPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const { uuid }: { uuid: string } = useParams();
  const { recruitListStore } = (props as IProps).appStore;
  const { uuid: userUUID, type: userType, project, getProject, updateTerminateNotified } = (recruitListStore as IRecruitListStore);
  const [isOpenCharge, setOpenCharge] = useState(false);
  const [isOpenClose, setOpenClose] = useState(false);
  const [isOpenCancel, setOpenCancel] = useState(false);
  const [isOpenConfirm, setOpenConfirm] = useState(false);
  const [isOpenNow, setOpenNow] = useState(false);
  const [isOpenComplete, setOpenComplete] = useState(false);
  const [isOpenTerminateWarning, setOpenTerminateWarning] = useState(false);
  const [isOpenTerminateNotice, setOpenTerminateNotice] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [isOpenDocument, setOpenDocument] = useState(false);

  const [currentIteration, setCurrentIteration] = useState<IIterationModel | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getProject(uuid);

    if (window.location.hash === '#start') {
      setOpenDocument(true);
    }
  }, []);

  if (!project) {
    return <></>;
  }

  // 상태 관련
  const isTerminating = project.status !== 'DONE' && project.is_finalized;

  if (firstRender) {
    if (project?.is_terminated) {
      setOpenTerminateNotice(true);
      updateTerminateNotified(uuid);
    }
    else if (project?.rat_count && project?.rat_count >= 1) {
      setOpenTerminateWarning(true);
    }
    setFirstRender(false);
  }

  const { proposal, balance, statusString, target } = project;

  const createStartGuide = () => {
    if (userType === 'MEMBER') {
      return (
        <MemberStartGuide
          date={project.start_date!}
        />
      );
    }
    return (
      <PartnerStartGuide
        date={project.start_date!}
        wage={proposal.wage}
        appStore={props.appStore}
        uuid={userUUID}
        projectUUID={uuid}
        project={project}
      />
    );
  };

  const createNotStartGuide = () => {
    if (userType === 'MEMBER') {
      return <MemberNotStartGuide />
    }
    return (
      <PartnerNotStartGuide
        wage={proposal.wage}
        appStore={props.appStore}
        uuid={userUUID}
        projectUUID={uuid}
        project={project}
      />
    );
  };

  const createMenu = (v: IIterationModel) => {
    if (userType === 'PARTNER') {
      if (v.status === 'PAYMENT_COMPLETED') {
        return (
          <>
            <div className="w-price">
              <i>정산 주급</i>
              <em>{numberWithCommas(v.wage || 0)}원</em>
            </div>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setCurrentIteration(v);
                setOpenComplete(true);
              }}
            >
              정산 완료
            </span>
            <button
              type="button"
              onClick={() => {
                setCurrentIteration(v);
                setOpenComplete(true);
              }}
            >
              <span>정산 팝업 열기</span>
            </button>
          </>
        );
      } else if (v.status === 'PAYMENT_REQUESTED') {
        return (
          <>
            <div className="w-price">
              <i>정산 요청 주급</i>
              <em>{numberWithCommas(v.wage || 0)}원</em>
            </div>
            <span
              className="p-type"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setCurrentIteration(v);
                setOpenConfirm(true);
              }}
            >
              정산 검토
            </span>
            <button
              type="button"
              onClick={() => {
                setCurrentIteration(v);
                setOpenConfirm(true);
              }}
            >
              <span>정산 팝업 열기</span>
            </button>
          </>
        );
      }

      return (<span>정산 미요청</span>);
    }

    if (userType === 'MEMBER') {
      if (v.status === 'PAYMENT_COMPLETED') {
        return (
          <>
            <div className="w-price">
              <i>정산 요청 주급</i>
              <em>{numberWithCommas(v.wage || 0)}원</em>
            </div>
            <span
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                setCurrentIteration(v);
                setOpenComplete(true);
              }}
            >
              정산 완료
              {/* <a
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setCurrentIteration(v);
                  setOpenComplete(true);
                }}
              >
                자세히 보기
              </a> */}
            </span>
            <button
              type="button"
              onClick={() => {
                setCurrentIteration(v);
                setOpenComplete(true);
              }}
            >
              <span>정산 팝업 열기</span>
            </button>
          </>
        );
      } else if (v.status === 'PAYMENT_REQUESTED') {
        return (
          <>
            <div className="w-price">
              <i>정산 요청 주급</i>
              <em>{numberWithCommas(v.wage || 0)}원</em>
            </div>
            <span
              style={{
                backgroundColor: 'white',
                border: '1px solid #5539ff',
                color: '#5539ff',
                cursor: 'pointer',
              }}
              onClick={() => {
                setCurrentIteration(v);
                setOpenNow(true);
              }}
            >
              정산 요청중
            </span>
            <button
              type="button"
              onClick={() => {
                setCurrentIteration(v);
                setOpenNow(true);
              }}
            >
              <span>정산 팝업 열기</span>
            </button>
          </>
        );
      } else if (v.status === 'FINISHED') {
        return (
          <>
            <span
              className="p-type"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setCurrentIteration(v);
                setOpenConfirm(true);
              }}
            >
              정산 요청
            </span>
            <button
              type="button"
              onClick={() => {
                setCurrentIteration(v);
                setOpenConfirm(true);
              }}
            >
              <span>정산 팝업 열기</span>
            </button>
          </>
        )
      }

      return (
        <span><i>{dayjs(v.end_date).add(1, 'day').format('YYYY.MM.DD')}</i>정산가능</span>
      );
    }

    return <></>;
  };

  const isNoDate = !Boolean(project.start_date);
  const isStarted = Boolean(project.start_date) && dayjs(project.start_date).isBefore(dayjs());
  const iterations: IIterationModel[] = project.iterations as any[] as IIterationModel[];

  return (
    <>
      <div className="sub-wrap">
        <div className="top-box">
          <h2><em>{statusString}</em>{proposal.title}</h2>
          {
            isStarted &&
            <p>
              {
                dayjs().isAfter(dayjs(project.start_date)) ?
                  `시작일 ${project.start_date}` + (project.end_date ? ` 종료일 ${project.end_date}` : '')
                  :
                  `시작 예정일 ${project.start_date}`
              }
            </p>
          }

          {
            // 신규 파트너 시작일 지정 UI
            (!isStarted && !isNoDate && userType === 'PARTNER') &&
            <PartnerStartGuideInput
              date={project.start_date!}
              wage={proposal.wage}
              appStore={props.appStore}
              uuid={userUUID}
              projectUUID={uuid}
              project={project}
            />
          }

          <div className="btn-guide">
            <a href="https://seesorufree.notion.site/77c4aad3cb264c43adf622876ecb93a4" target="_blank">협업 가이드</a>
          </div>
        </div>

        <div className="project-recruit">
          {
            isNoDate &&
            createNotStartGuide()
          }

          {
            (!isNoDate && !isStarted) &&
            createStartGuide()
          }

          {
            isStarted && (
              userType === 'MEMBER' ?
                <div className="total-price type2">
                  <div className="price-info">
                    <div>
                      <p>누적 정산금액 {numberWithCommas(project.total_payment || 0)}원</p>
                    </div>
                  </div>
                  <p><a href={`/calc/${userType.toLowerCase()}`}>보유 금액 및 정산 내역</a></p>
                </div>
                :
                <div className="total-price">
                  <div className="price-info">
                    <div>
                      <i>정산금액</i>
                      <em>{numberWithCommas(project.total_payment || 0)}<span>원</span></em>
                    </div>
                    <div>
                      <i>현재 보유 금액</i>
                      <em>{numberWithCommas(balance || 0)}<span>원</span></em>
                    </div>
                  </div>
                  <p><a href={`/calc/${userType.toLowerCase()}`}>보유 금액 및 정산 내역</a></p>
                </div>
            )
          }

          {
            (isStarted && iterations.length > 0) &&
            <div className="admin-sp">
              <div className="hgroup">
                <h3>채용 관리</h3>
                <div>
                  {
                    project.status === 'DONE' &&
                    (
                      project.is_terminated
                        ? <p className="end">보유 금액 부족으로 자동 종료된 협업입니다.</p>
                        : <p className="end">협업 종료</p>
                    )
                  }

                  {
                    isTerminating &&
                    <>
                      <p>협업 종료 {userType === 'MEMBER' ? '요청' : '진행 중'}</p>
                      <span
                        className={userType === 'MEMBER' ? 'end' : 'cel'}
                        style={{ cursor: 'pointer' }}
                        onClick={async () => {
                          if (userType === 'MEMBER') {
                            setOpenClose(true);
                          } else {
                            setOpenCancel(true);
                          }
                        }}
                      >
                        {userType === 'MEMBER' ? '동의' : '취소'}
                      </span>
                    </>
                  }

                  {
                    (project.status !== 'DONE' && !project.is_finalized && userType === 'PARTNER') &&
                    <span
                      className="end"
                      onClick={() => setOpenClose(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      협업 종료
                    </span>
                  }
                </div>
              </div>

              <div className="weekly-adj">
                {
                  (project.status === 'IN_PROGRESS' && !isTerminating) &&
                  <p className="pre-str">{iterations.length + 1}번째 주는 <em>{dayjs(iterations?.[0].end_date).add(1, 'day').format('YYYY.MM.DD')}</em>에 자동 시작됩니다.</p>
                }
                {
                  iterations.map((v: IIterationModel, index: number) => (
                    <div className="w-inner" key={v.id.toString()}>
                      <div className="h-w">
                        <strong>{v.round}번째 주</strong>
                        <span>{dayjs(v.start_date).format('YYYY.MM.DD')} - {dayjs(v.end_date).format('YYYY.MM.DD')}</span>
                      </div>

                      <div className="condi">
                        {
                          index === 0 && project.status === 'IN_PROGRESS' ?
                            <p className="tw">
                              {
                                userType === 'MEMBER' ?
                                  `현재 진행중인 주로 ${dayjs(v.end_date).format('YYYY.MM.DD')} 이후 정산 가능합니다.`
                                  :
                                  '현재 진행중인 주입니다'
                              }
                            </p>
                            :
                            createMenu(v)
                        }
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          }

          {/* 마크업 수정 없이 보더 처리용 */}
          <div className="admin-sp" style={{ marginBottom: 32 }} />

          <RecruitProfile
            data={project}
            userType={userType}
            userUUID={userUUID}
          />
        </div>
      </div>

      <ChargeModal
        isOpen={isOpenCharge}
        onClose={() => setOpenCharge(false)}
      />

      <PartnerProjectCloseModal
        data={project}
        uuid={uuid}
        cantClose={Boolean(iterations.slice(1).find(v => v.date_payment_completed === null))}
        isCancel={isOpenCancel}
        userUUID={userUUID}
        isOpen={userType === 'PARTNER' && (isOpenClose || isOpenCancel)}
        onClose={() => {
          setOpenCancel(false);
          setOpenClose(false);
        }}
      />

      <MemberProjectCloseModal
        data={project}
        userUUID={userUUID}
        uuid={uuid}
        isOpen={userType === 'MEMBER' && isOpenClose}
        onClose={() => setOpenClose(false)}
      />

      <PartnerConfirmModal
        project={project}
        uuid={uuid}
        userUUID={userUUID}
        data={currentIteration!}
        isOpen={userType === 'PARTNER' && isOpenConfirm}
        onClose={() => setOpenConfirm(false)}
      />

      <MemberConfirmModal
        project={project}
        uuid={uuid}
        userUUID={userUUID}
        data={currentIteration!}
        isOpen={userType === 'MEMBER' && isOpenConfirm}
        onClose={() => setOpenConfirm(false)}
      />

      <PaymentNowModal
        project={project}
        isOpen={isOpenNow}
        uuid={uuid}
        userUUID={userUUID}
        data={currentIteration!}
        onClose={() => setOpenNow(false)}
      />

      <PaymentCompleteModal
        isOpen={isOpenComplete}
        data={currentIteration!}
        userType={userType}
        project={project}
        onClose={() => setOpenComplete(false)}
      />

      <MemberTerminateWarningModal
        data={project}
        iteration={project.iterations[0]}
        isOpen={userType === 'MEMBER' && project.status === 'IN_PROGRESS' && isOpenTerminateWarning && project?.rat_count === 2}
        onClose={() => setOpenTerminateWarning(false)}
      />

      <MemberTerminateNoticeModal
        endDate={project.end_date!}
        isOpen={userType === 'MEMBER' && !project?.terminate_notice_to_member && isOpenTerminateNotice}
        onClose={() => setOpenTerminateNotice(false)}
      />

      <PartnerTerminateWarningModal
        data={project}
        iteration={project.iterations[0]}
        balance={balance || 0}
        isOpen={userType === 'PARTNER' && project.status === 'IN_PROGRESS' && isOpenTerminateWarning}
        onClose={() => setOpenTerminateWarning(false)}
      />

      <PartnerTerminateNoticeModal
        endDate={project.end_date!}
        isOpen={userType === 'PARTNER' && !project.terminate_notice_to_partner && isOpenTerminateNotice}
        onClose={() => setOpenTerminateNotice(false)}
      />

      <DocumentNoticeModal
        isOpen={isOpenDocument}
        onClose={() => setOpenDocument(false)}
      />
    </>
  );
}));

export default RecruitDetailPage;
