import { useEffect } from "react";
import { observer } from "mobx-react";
import { IPartnerProfileModel } from "../../profile/models";
import { IPartnerProposalModel } from "../models/PartnerProposalModel";
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { numberWithCommas, parseWithCommas } from "../../../utils/utils";
import { IAppStore } from "../../../stores/AppStore";

interface Props {
  uuid: string;
  appStore: IAppStore;
  data: IPartnerProposalModel;
  isOpen: boolean;
  onClose: () => void;
}

export default observer(({
  uuid,
  appStore,
  data,
  isOpen,
  onClose,
}: Props) => {
  const { showLoader, hideLoader } = appStore;

  if (!isOpen) {
    return <></>;
  }
  
  const { watch, setValue, handleSubmit } = useForm();
  useEffect(() => {
    setValue('working_hours', data.working_hours);
    setValue('_working_hours', numberWithCommas(data.working_hours));
    setValue('wage', data.wage);
    setValue('_wage', numberWithCommas(data.wage));
    setValue('duration', data.duration);
    setValue('_duration', numberWithCommas(data.duration));
  }, [isOpen]);

  const onCancel = () => {
    onClose();
  };

  const onEnd = () => {
    onClose();
  };

  const onSubmit = async (form: any) => {
    try {
      showLoader();
      await axios.post(`/partner/${uuid}/proposals/${data.uuid}/offer`, {
        working_hours: parseInt(form.working_hours),
        wage: parseInt(form.wage),
        duration: parseInt(form.duration),
      });
      hideLoader();

      data.submit();
    } catch (err: any) {
      console.log(err.response);
      const msg = err.response.data.message;
      if (msg) {
        alert(msg);
      }
    }

    onClose();
  }

  const onChangeWorkingHours = (e: any) => {
    const [num, text] = parseWithCommas(e.target.value);
    setValue('working_hours', num);
    setValue('_working_hours', text);
  };

  const onChangeWage = (e: any) => {
    const [num, text] = parseWithCommas(e.target.value);
    setValue('wage', num);
    setValue('_wage', text);
  };

  const onChangeDuration = (e: any) => {
    const [num, text] = parseWithCommas(e.target.value);
    setValue('duration', num);
    setValue('_duration', text);
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box smtype">
        <section className="recu-chk-wrap">
          <h1>채용 확인</h1>
          <p>{data.member.nickname}님과 최종적으로 조율된 계약정보를 확인해주세요. 채용 확정을 하시면 멤버에게 문자로 알림이 가며, {data.member.nickname}님이 동의를 하면, 협업이 확정됩니다.</p>
          <div className="recu-chk">
            <ul>
              <li>
                <label htmlFor="term">희망 거래 시간</label>
                <div className="price-w">
                  <input
                    type="text"
                    id="price"
                    placeholder="숫자만 입력해주세요."
                    title="숫자만 입력해주세요."
                    value={watch('_working_hours')}
                    onChange={onChangeWorkingHours}
                  />
                  <span>시간/일주일</span>
                </div>
              </li>
              <li>
                <label htmlFor="price">주급</label>
                <div className="price-w">
                  <input
                    type="text"
                    id="price"
                    placeholder="숫자만 입력해주세요."
                    title="숫자만 입력해주세요."
                    value={watch('_wage')}
                    onChange={onChangeWage}
                  />
                  <span>원</span>
                </div>
              </li>
              <li>
                <label htmlFor="count">희망 협업 기간</label>
                <div className="price-w">
                  <input
                    type="text"
                    id="price"
                    placeholder="숫자만 입력해주세요."
                    title="숫자만 입력해주세요."
                    value={watch('_duration')}
                    onChange={onChangeDuration}
                  />
                  <span>주</span>
                </div>
              </li>
            </ul>
            {/* <p><em>오샐리</em>님이 한 스프린트 기간 <em>{'선택한 스프린트 단위 기간'}</em> 동안 사용할 수 있는 시간은 총 <em>{'NN'}</em>시간이며, 최소 스프린트 단위 비용은 <em>{'NN, NNN, NNN'}</em>원(VAT별도) 입니다.</p> */}
          </div>
          <div className="btn type2">
            <button type="button" className="cancel" onClick={onCancel}>취소</button>
            <button type="button" className="actv" onClick={handleSubmit(onSubmit)}>채용 확정</button>
          </div>
        </section>
        <button type="button" className="btn-close" onClick={onClose}><span>팝업 닫기</span></button>
      </div>
    </div>
  );
});
