import { types, Instance } from "mobx-state-tree";

const JobModel = types.model({
  parent__name: types.maybeNull(types.string),
  values: types.array(types.string),
})

export const TargetModel = types.model({
  uuid: types.string,
  name: types.maybeNull(types.string),
  company_name: types.maybeNull(types.string),
  job_categories: types.array(JobModel),
  industry: types.maybeNull(types.string),
  avatar: types.maybeNull(types.string),
})
.views(self => ({
  get validName() {
    return self.name || self.company_name;
  },
  get validAvatar() {
    return self.avatar || 'images/@photo.png';
  }
}));

export interface ITargetModel extends Instance<typeof TargetModel> {};
