import { observer } from "mobx-react";
import { IJoinCompany } from '../models';
import { TextField } from '.';


interface IProps {
  model: IJoinCompany;
}
const JoinCompany: React.SFC<IProps> = (observer((props) => {
  const {
    company,
    homepage,
    introduction,
    industry,

    isCompanyFieldEror,
    isIndustryFieldError,
    isHomepageFieldError,
    isIntroductionFieldError,

    setCompany,
    setHompage,
    setIntroduction,
    setIndustry,
  } = props.model;

  return (
    <>
      <ul className="fm-list">
        <TextField
          label="회사명*"
          value={company}
          placeholder="(주)시소"
          isError={ isCompanyFieldEror }
          onChange={(value: string) => setCompany(value)}
        />
        <TextField
          label="산업 분야*"
          value={industry}
          placeholder="산업 분야를 선택해주세요."
          isError={ isIndustryFieldError }
          onChange={(value: string) => setIndustry(value)}
        />
        <TextField
          label="홈페이지*"
          value={homepage}
          placeholder="URL을 입력해주세요."
          isError={ isHomepageFieldError }
          onChange={(value: string) => setHompage(value)}
        />
        <li>
          <label htmlFor="compInt">회사소개<i className="ness"><span>필수</span></i></label>
          <textarea
            id="compInt"
            style={{
              border: isIntroductionFieldError ? '1px solid #ff4e16' : 'none',
            }}
            placeholder="회사소개를 입력해주세요."
            onChange={(e: any) => setIntroduction(e.target.value)} maxLength={3000}
          />
          <span className="txt-count">{introduction?.length || 0} / 3,000</span>
        </li>
      </ul>
    </>
  )
}));

export default JoinCompany;
