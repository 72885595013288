interface Props {
  date: string;
}

export default ({ date }: Props) => {
  return (
    <div className="pjt-adm-detail">
      <div className="pjt-def-info type2">
        <strong>{date} 에 협업이 시작합니다.</strong>
        <p>협업이 시작되면, 여기서 채용 관리를 할 수 있습니다. </p>
      </div>
      <div className="rec-info">
        <ul>
          <li>매주 진행한 업무 내용을 공유하고 정산을 요청할 수 있습니다.</li>
          <li>정산 요청한 주의 정산 처리 여부를 확인할 수 있습니다.</li>
        </ul>
      </div>
    </div>
  );
}
