import classNames from 'classnames';

const regExp = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i;
const EmailField = (props: {
  isError: boolean,
  value: string,
  onChange: (value: string) => void
}) => {
  return (
    <li>
      <label htmlFor="eMail">이메일</label>
      <input
        className={ classNames({ err: props.isError })}
        type="text"
        id="eMail"
        placeholder="example@seeso.kr"
        value={ props.value }
        onChange={ (e) => props.onChange(e.target.value.toLowerCase()) } />

        {
          (props.value.length > 0 && !regExp.test(props.value)) &&
            <p className="err">
              이메일 형식이 아닙니다
            </p>
        }
    </li>
  )
}

export default EmailField;