/*
  markup : mypage_dashboard.html
*/

import { inject, observer } from "mobx-react";
import { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { IAppStore } from "@stores/AppStore";
import { IProfileStore } from '@stores/profileStore';
import { IEpisodeModel } from './models/MemberProfileModel';
import { useHistory, useParams } from 'react-router-dom';

import ProfileBanner from '@components/ProfileBanner';
import { PieChart } from 'react-minimal-pie-chart';
import styled from 'styled-components';
import profileAPI from '@services/profileAPI';
import {
  AvailabilityOptions,
  CoworkingTimeOptions,
  ExpectationOptions,
  WorkingHourOptions,
} from '@utils/constants';
import { checkLogin, getWindowDimensions, numberWithCommas } from '../../utils/utils';
import CoWorker from './components/CoWorker';
import CoworkerReview from './components/CoWorkerReview';
import ExperienceCard from './components/ExperienceCard';
import MemberCard from './components/MemberCard';
import PortfolioBanner from './components/PortfolioBanner';
import ProjectCard from './components/ProjectCard';
import { IJobCategoryModel } from './models';
import SecondTeamCard from "./components/SecondTeamCard";
import MySecondTeam from "./components/MySecondTeam";
import PoolSuggestionModal from "@pages/career/components/PoolSuggestionModal";
import { useQuery } from "@utils/hooks";
import MemberMemoModal from "@modals/MemberMemoModal";
import MemberMemoViewModal from "@modals/MemberMemoViewModal";

interface IProps {
  appStore: IAppStore;
  history: any
}

const Swipeable = styled(SwipeableViews)`
  .react-swipeable-view-container > div {
    overflow: visible !important;
  }

  overflow-x: visible !important;
`;

const SNSSpan = styled.span`
  &:before {
    content: none !important;
  }
`;

const MemberProfilePage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const history = useHistory();
  const { uuid }: { uuid: string } = useParams();
  const query = useQuery();
  const isMyPage = !Boolean(uuid);
  const { profileStore, userStore } = (props as IProps).appStore;
  const { memberProfile, memberAccount, getMemberProfile, partnerProfile, getMemberAccount } = (profileStore as IProfileStore);

  const [isSuggesting, setSuggesting] = useState(false);
  const [canCoffeeChat, setCoffeechat] = useState(false);
  const [isOpenMemoCreation, setOpenMemoCreation] = useState(false);
  const [isOpenMemoViewer, setOpenMemoViewer] = useState(false);

  const [rootJobs, setRootJobs] = useState<IJobCategoryModel[]>([]);
  const [jobs, setJobs] = useState<IJobCategoryModel[]>([]);

  let [swipeIndex, setSwipeIndex] = useState(1);
  let prevIndex = 0;

  const currentEpisode = memberProfile?.episodes?.[swipeIndex - 2];
  const currentJob = jobs.find(v => v.id === currentEpisode?.job_category);
  let currentURL = currentEpisode?.link || currentEpisode?.file || '';
  const isFile = Boolean(currentEpisode?.file);
  const isLink = Boolean(currentEpisode?.link);
  const isImage = isFile && /(\.(bmp|gif|jpg|jpeg|png))$/i.test(currentEpisode?.file || '');

  const [isExpandRole, setExpandRole] = useState(false);
  const [isExpandSkill, setExpandSKill] = useState(false);
  const [isExpandType, setExpandType] = useState(false);

  // const [result, loading, error] = useScrapper({
  //   url: currentURL
  // });

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  if (currentURL && !currentURL.startsWith('http')) {
    currentURL = `http://${currentURL}`;
  }

  const getJobInfo = async () => {
    const jobsRoot = await profileAPI.getConstants('jobs/root/');
    const jobsAll = await profileAPI.getConstants('jobs/all/');

    setRootJobs(jobsRoot);
    setJobs(jobsAll);
  }

  useEffect(() => {
    if (!checkLogin()) {
      window.location.href = '/login';
      return;
    }

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    // 접근 불가 처리 후 메인 화면으로 리다이렉트
    if (userStore.user.userType === 'MEMBER' && !isMyPage) {
      alert('멤버 프로필을 보실 수 있는 권한이 없습니다. 멤버 프로필은 회사 계정만 확인하실 수 있습니다.');
      window.history.back();
      return;
    }

    async function fetch() {
      await getMemberProfile(uuid);
      await getMemberAccount(uuid);
      await getJobInfo();

      if (!isMyPage) {
        await profileStore.getPartnerProfile();
      }
    }
    fetch();

    if (memberProfile && !memberProfile.is_active) {
      alert('확인할 수 없는 사용자입니다.');
      history.goBack();
      return;
    }

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!memberProfile) {
      return;
    }
    console.log('통과');

    loadProjectByHash();

    window.onhashchange = function () {
      console.log('hash~', window.location.hash);
      loadProjectByHash();
    };

    if (query.get('suggesting')) {
      setSuggesting(true);
    }

    if (query.get('coffeechat')) {
      setCoffeechat(true);
    }
  }, [memberProfile]);

  const setIndex = (index: number) => {
    prevIndex = swipeIndex;
    swipeIndex = index;
    setSwipeIndex(index);

    console.log(prevIndex, swipeIndex);

    const [baseUrl, currentHash] = window.location.href.split('#');

    if (prevIndex !== swipeIndex && (swipeIndex === 0 || swipeIndex === 1)) {
      window.history.pushState({}, '', baseUrl);
      return;
    }

    const newHash = memberProfile?.episodes?.[swipeIndex - 2]?.uuid;
    if (newHash && newHash !== currentHash) {
      console.log('newHash');
      console.log(newHash);
      // @ts-ignore
      window.history.pushState({}, '', baseUrl + '#' + newHash);
    }
  }

  const loadProjectByHash = () => {
    const hashName = window.location.hash.slice(1);
    console.log('hashName');
    console.log(hashName);

    if (currentEpisode?.uuid === hashName) {
      console.log('같음');
      return;
    }

    if (!hashName) {
      setIndex(1);
      return;
    }

    const episodes: IEpisodeModel[] = memberProfile?.episodes || [];

    let index = -1;
    for (let i = 0, len = episodes.length; i < len; i += 1) {
      if (episodes[i].uuid === hashName) {
        index = i;
      }
    }

    console.log('find');
    console.log(memberProfile);
    console.log(episodes);
    console.log(hashName);
    console.log(index);

    if (index >= 0) {
      setIndex(index + 2);
    }
    return;
  };

  if (!memberProfile) {
    return (
      <div />
    )
  }

  return (
    <>
      <div className="mypage-w">
        <div className="header-type2" style={{ overflow: 'hidden' }}>
          {
            memberProfile.episodes.length < 1 ?
              <div className="prg-pag" style={{ height: 54 }}>
                {
                  swipeIndex === 1 &&
                  <button type="button" className="prev" style={{ zIndex: 1 }} onClick={() => setIndex(swipeIndex - 1 >= 0 ? swipeIndex - 1 : 0)}>
                    세컨팀
                  </button>
                }

                {
                  swipeIndex === 0 &&
                  <button type="button" className="next" style={{ zIndex: 1 }} onClick={() => setIndex(swipeIndex < memberProfile.episodes.length + 1 ? swipeIndex + 1 : memberProfile.episodes.length - 1)}>
                    대시보드
                  </button>
                }
              </div>
              :
              <div className="prg-pag" style={{ height: 54 }}>
                {
                  swipeIndex > 0 &&
                  <button type="button" className="prev" style={{ zIndex: 1 }} onClick={() => setIndex(swipeIndex - 1 >= 0 ? swipeIndex - 1 : 0)}>
                    {
                      swipeIndex === 2 ?
                        <><span><img src={memberProfile?.avatar || '/images/@photo.png'} style={{ width: 28, height: 28 }} alt="" /></span>대시보드</>
                        :
                        swipeIndex === 1 ?
                          <>세컨팀</>
                          :
                          <>포트폴리오</>
                    }
                  </button>
                }

                {
                  swipeIndex < memberProfile.episodes.length + 1 &&
                  <button type="button" className="next" style={{ zIndex: 1 }} onClick={() => setIndex(swipeIndex < memberProfile.episodes.length + 1 ? swipeIndex + 1 : memberProfile.episodes.length - 1)}>
                    {
                      swipeIndex === 0 ?
                        '대시보드'
                        :
                        '포트폴리오'
                    }
                  </button>
                }

                {
                  swipeIndex > 1 &&
                  <div className="prg-paging" style={{ zIndex: 2 }}>
                    {
                      memberProfile.episodes.map((v, index) => {
                        const isSelected = swipeIndex - 2 === index;
                        return (
                          <a
                            style={{ cursor: 'pointer', margin: 0, padding: 5, background: 'transparent', justifyContent: 'center' }}
                            className={isSelected ? 'selected' : ''}
                            onClick={() => setIndex(index + 2)}
                          >
                            <div style={{ width: isSelected ? 22 : 6, height: 6, background: isSelected ? '#fff' : 'rgba(255,255,255,0.4)', borderRadius: 3, }} />
                          </a>
                        );
                      })
                    }
                  </div>
                }
              </div>
          }

          <Swipeable
            index={swipeIndex}
            onChangeIndex={setIndex}
            enableMouseEvents
          >
            <SecondTeamCard
              windowWidth={windowDimensions.width}
              isMargin={swipeIndex === 1}
              data={memberProfile}
            />

            <MemberCard
              uuid={uuid}
              data={memberProfile}
              userStore={userStore}
              windowWidth={windowDimensions.width}
              isMine={isMyPage}
              isMarginLeft={swipeIndex === 0}
              isMarginRight={swipeIndex === 2}
              setSuggesting={setSuggesting}
              setOpenMemoCreation={setOpenMemoCreation}
              setOpenMemoViewer={setOpenMemoViewer}
            />

            {
              memberProfile.episodes.map((v, index) => (
                <ProjectCard
                  data={v}
                  rootJobs={rootJobs}
                  jobs={jobs}
                  windowWidth={windowDimensions.width}
                  isMine={isMyPage}
                  isMarginLeft={swipeIndex === index + 1}
                  isMarginRight={swipeIndex === index + 3}
                />
              ))
            }
          </Swipeable>
        </div>

        {
          swipeIndex === 0 &&
          <MySecondTeam
            uuid={uuid}
            data={memberProfile}
          />
        }

        <div className="dashboard-w" style={{ justifyContent: memberProfile.isShowProfileBanner ? 'auto' : 'center' }}>

          {
            swipeIndex === 1 &&
            <>
              <ProfileBanner
                data={memberProfile}
              />

              <div className="dashboard-cnts">
                <div className="conts-w2">
                  <h3>자기소개</h3>
                  <div className="pf-box">
                    {
                      memberProfile.introduction ?
                        <div className="introduce">
                          <p>{memberProfile.introduction.split('\n').map((line, i) => <div key={i}>{line}</div>)}</p>
                        </div>
                        :
                        <p style={{ marginTop: 8 }}>입력되지 않았습니다.</p>
                    }

                    <div className="add-info">
                      <h4>추가정보</h4>
                      <div className="sns-info">
                        {
                          (memberProfile.references && memberProfile.references.filter(({ link }) => link).length > 0) ?
                            memberProfile.references.filter(({ link }) => link).map(({ display, label, link }) => (
                              <SNSSpan
                                style={{ cursor: 'pointer' }}
                                className={label.toLowerCase().replace('bbb', 'bb').replace('etc', 'plus') || 'no'}
                                onClick={() => {
                                  window.open(link.includes('http') ? link : `http://${link}`);
                                }}
                              >
                                {display || '추가정보'}
                              </SNSSpan>
                            ))
                            :
                            <p style={{ fontSize: 14, marginTop: 8 }}>입력되지 않았습니다.</p>
                        }
                      </div>
                    </div>
                  </div>
                  <h3>전문</h3>
                  {/* <h3>전문<span>업데이트 2022.02.01</span></h3> */}
                  <div className="box-wrap">
                    <div className="pf-box">
                      <h4>역할</h4>
                      <div className="skill-list">
                        <ul>
                          {
                            memberProfile.roles.filter(v => v).length === 0 ?
                              <p style={{ marginTop: 8 }}>입력되지 않았습니다.</p>
                              :
                              memberProfile.roles.filter(v => v).slice(0, isExpandRole ? 99 : 10).map(role => (
                                <li key={role}>{role}</li>
                              ))
                          }
                        </ul>

                        {
                          (memberProfile.roles.length > 9 && !isExpandRole) &&
                          <a style={{ cursor: 'pointer' }} className="more" onClick={() => setExpandRole(true)}>
                            더보기
                          </a>
                        }
                      </div>
                      <hr />
                      <h4>경험</h4>
                      <div className="skill-list">
                        <ul>
                          {
                            memberProfile.skills.filter(v => v).length === 0 ?
                              <p style={{ marginTop: 8 }}>입력되지 않았습니다.</p>
                              :
                              memberProfile.skills.filter(v => v).slice(0, isExpandSkill ? 99 : 10).map(skill => (
                                <li key={skill}>{skill}</li>
                              ))
                          }
                        </ul>

                        {
                          (memberProfile.skills.length > 9 && !isExpandSkill) &&
                          <a style={{ cursor: 'pointer' }} className="more" onClick={() => setExpandSKill(true)}>
                            더보기
                          </a>
                        }
                      </div>
                      <hr />

                      <h4>외국어</h4>
                      <div className="skill-list">
                        <ul>
                          {
                            memberProfile.languages.length === 0 ?
                              <p style={{ marginTop: 8 }}>입력되지 않았습니다.</p>
                              :
                              memberProfile.languages.map(v => <li>{v}</li>)
                          }
                        </ul>
                      </div>
                    </div>
                    <div className="pf-box">
                      {
                        memberProfile.jobString === '개발자' &&
                        <>
                          <h4>언어 및 프레임워크</h4>
                          <div className="skill-list">
                            <ul>
                              {
                                memberProfile.frameworks.map((v, i) => <li key={i}>{v}</li>)
                              }
                            </ul>
                          </div>
                          <hr />
                        </>
                      }

                      <h4>프로젝트 유형</h4>
                      <div className="donut-w">
                        {
                          memberProfile.types.filter(v => v.type).length < 1 ?
                            <p style={{ marginTop: 8 }}>입력되지 않았습니다.</p>
                            :
                            <>
                              <div className="donut-gh" style={{ textAlign: 'center' }}>
                                <PieChart
                                  lineWidth={70}
                                  style={{
                                    alignSelf: 'center',
                                    width: 120,
                                    height: 120,
                                  }}
                                  data={
                                    [
                                      {
                                        "title": "",
                                        "value": memberProfile.types.find(v => v.type === 1)?.count || 0,
                                        "color": "#523AF5",
                                      },
                                      {
                                        "title": "",
                                        "value": memberProfile.types.find(v => v.type === 2)?.count || 0,
                                        "color": " #AFFCCA",
                                      },
                                      {
                                        "title": "",
                                        "value": memberProfile.types.find(v => v.type === 3)?.count || 0,
                                        "color": "#F4B799",
                                      },
                                    ]
                                  }
                                />
                              </div>

                              <ul>
                                <li className="part-t gh1"><span style={{ borderColor: '#523AF5', minWidth: 40, width: 40 }}>회사</span><p>회사 프로젝트{' '}<em>{memberProfile.types.find(v => v.type === 1)?.count || 0}건</em></p></li>
                                <li className="part-t gh2"><span style={{ borderColor: '#AFFCCA', minWidth: 40, width: 40 }}>협업</span><p>클라이언트와 협업한 프로젝트{' '}<em>{memberProfile.types.find(v => v.type === 2)?.count || 0}건</em></p></li>
                                <li className="part-t gh3"><span style={{ borderColor: '#F4B799', minWidth: 40, width: 40 }}>개인</span><p>개인적으로 진행한 프로젝트{' '}<em>{memberProfile.types.find(v => v.type === 3)?.count || 0}건</em></p></li>
                              </ul>
                            </>
                        }
                      </div>
                      <hr />

                      <h4>프로젝트 분야</h4>
                      <div className="skill-list">
                        <ul>
                          {
                            memberProfile.projectAreaList.filter(v => v).length === 0 ?
                              <p style={{ marginTop: 8 }}>입력되지 않았습니다.</p>
                              :
                              memberProfile.projectAreaList.filter(v => v).slice(0, isExpandType ? 99 : 10).map(preference => (
                                <li key={preference}>{preference}</li>
                              ))
                          }
                        </ul>

                        {
                          (memberProfile.role_preferences.length > 9 && !isExpandType) &&
                          <a style={{ cursor: 'pointer' }} className="more" onClick={() => setExpandType(true)}>
                            더보기
                          </a>
                        }
                      </div>
                    </div>
                  </div>
                  <h3>포트폴리오</h3>
                  {/* <h3>경험<span>업데이트 2022.02.01</span></h3> */}

                  {
                    isMyPage &&
                    <div className="pjt-add">
                      <p>포트폴리오 하나를 추가하실 때마다 <br />나의 프로필 노출도가 증가한답니다 😙</p>
                      <a href="/member/project/edit">새 포트폴리오 추가하기</a>
                    </div>
                  }

                  {
                    memberProfile.episodes.length === 0 ?
                      <div className="pf-box">
                        <p>입력되지 않았습니다.</p>
                      </div>
                      :
                      memberProfile.episodes.map((v, index) => (
                        <ExperienceCard
                          data={v}
                          rootJobs={rootJobs}
                          jobs={jobs}
                          onClick={() => setIndex(index + 2)}
                        />
                      ))
                  }

                  <CoworkerReview
                    uuid={uuid}
                    profile={memberProfile}
                  />

                  <h3>희망 제안 정보</h3>
                  {/* <h3>채용정보<span>업데이트 2022.02.01</span></h3> */}
                  <div className="rec-info">
                    {
                      (isMyPage || partnerProfile?.has_project_hiring_permission) &&
                      <div className="pf-box">
                        <div className="rec-list">
                          <ul>
                            {/* <li>
                              <i>구직의향</i>
                              {
                                Boolean(memberProfile.availability) ?
                                  <p>{AvailabilityOptions.find((v: any) => v.value === memberProfile.availability || '')?.label || ''}</p>
                                  :
                                  <p style={{ marginTop: 8 }}>입력되지 않았습니다.</p>
                              }
                            </li> */}
                            <li>
                              <i>금액</i>
                              {
                                Boolean(memberProfile.expected_hourly_wage) ?
                                  <p>
                                    희망 시급 :{' '}
                                    <em className="pay">
                                      {numberWithCommas(memberProfile.expected_hourly_wage)}원
                                    </em>
                                  </p>
                                  :
                                  <p style={{ marginTop: 8 }}>희망 시급 : 입력되지 않았습니다.</p>
                              }
                              {
                                Boolean(memberProfile.expected_annual_salary) ?
                                  <p>
                                    희망 연봉 :{' '}
                                    <em className="pay">
                                      {numberWithCommas(memberProfile.expected_annual_salary)}원
                                    </em>
                                  </p>
                                  :
                                  <p style={{ marginTop: 8 }}>희망 연봉 : 입력되지 않았습니다.</p>
                              }
                            </li>
                            {/* <li>
                              <i>거래 가능 시간 (1주 기준)</i>
                              {
                                Boolean(memberProfile.working_hour_range_per_week) ?
                                  <div className="time-box">
                                    {WorkingHourOptions.find((v: any) => v.value === memberProfile.working_hour_range_per_week)?.label}
                                    {
                                      Boolean(memberProfile.working_hours_per_week) &&
                                      <>
                                        {' '}({memberProfile.working_hours_per_week}시간)
                                      </>
                                    }
                                  </div>
                                  :
                                  <p style={{ marginTop: 8 }}>입력되지 않았습니다.</p>
                              }
                            </li> */}
                            {/* <li>
                              <i>협업 선호 시간대 (한국 기준*)</i>
                              <div className="time-list">
                                {
                                  memberProfile.working_time.length > 0 ?
                                    <ul>
                                      {
                                        memberProfile.working_time.map(time => (
                                          <li
                                            key={time}
                                            style={{ marginTop: 'auto', lineHeight: '38px', marginBottom: 8 }}
                                          >
                                            {CoworkingTimeOptions.find((v: any) => v.value === time)?.label}
                                          </li>
                                        ))
                                      }
                                    </ul>
                                    :
                                    <p style={{ marginTop: 8 }}>입력되지 않았습니다.</p>
                                }
                              </div>
                            </li> */}
                          </ul>
                          {/* <p className={`posb ${memberProfile.working_flexibility ? 'check' : ''}`} style={{ marginTop: -12 }}>
                            거래시간 추가 {memberProfile.working_flexibility ? '가능' : '불가능'}
                          </p>
                          <p className={`posb ${memberProfile.offline_meeting || memberProfile.office_work ? 'check' : ''}`}>
                            {
                              Boolean(memberProfile.offline_meeting && memberProfile.office_work) &&
                              '오프라인 미팅 및 출근 가능'
                            }

                            {
                              Boolean(!memberProfile.offline_meeting && !memberProfile.office_work) &&
                              '오프라인 미팅 및 출근 불가능'
                            }

                            {
                              Boolean(!memberProfile.offline_meeting && memberProfile.office_work) &&
                              '오프라인 출근 가능'
                            }

                            {
                              Boolean(memberProfile.offline_meeting && !memberProfile.office_work) &&
                              '오프라인 미팅 가능'
                            }
                          </p> */}
                        </div>
                      </div>
                    }
                    <div
                      className="pf-box"
                      style={!(isMyPage || partnerProfile?.has_project_hiring_permission) ? {
                        marginLeft: 0,
                        width: '100%',
                      } : {}}
                    >
                      <h4>희망 제안 유형</h4>
                      <div className="time-list">
                        <ul>
                          {
                            (memberAccount?.work_preferences.length || 0) > 0 ?
                              memberAccount?.work_preferences.map(v => (
                                <li key={v}>
                                  {
                                    [
                                      '전문성 기반의 짧은 업무',
                                      '아웃소싱 프로젝트 의뢰',
                                      '프로젝트성 주급 계약',
                                      '이직 헤드헌팅',
                                      '흥미로운 일',
                                    ][v - 1]
                                  }
                                </li>
                              ))
                              :
                              <p
                                style={{
                                  marginTop: 4,
                                }}
                              >
                                아직 선택되지 않았습니다.
                              </p>
                          }
                        </ul>
                      </div>
                      <h4>희망 프로젝트 역할</h4>
                      <div className="time-list">
                        {
                          (memberAccount?.job_categories.length || 0) > 0 ?
                            <ul>
                              {
                                memberAccount?.job_categories.map(v => (
                                  <li key={v.sub_category}>{v.sub_category}</li>
                                ))
                              }
                            </ul>
                            :
                            <p
                              style={{
                                marginTop: 4,
                              }}
                            >
                              아직 선택되지 않았습니다.
                            </p>
                        }
                      </div>
                      {/* <h4>세컨팀 멤버로 기대하는 바</h4>
                      <div className="time-list">
                        <ul>
                          {
                            memberProfile.expectations.length === 0 ?
                              <p style={{ marginTop: 8 }}>입력되지 않았습니다.</p>
                              :
                              memberProfile.expectations.map(exp => (
                                <li key={exp}>{ExpectationOptions.find(v => v.value === exp)?.label || ''}</li>
                              ))
                          }
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </div>

        {
          swipeIndex > 1 &&
          <div className="conts-w2" style={{ marginTop: 0 }}>
            <h3>프로젝트 정보</h3>
            {/* <h3>프로젝트 정보<span>업데이트 2022.02.01</span></h3> */}
            {/* <div className="pf-box">
              <h4>프로젝트 소개</h4>
              <p>
                {
                  currentEpisode?.title
                }
              </p>
            </div> */}

            {
              (currentJob?.parent === '개발자' || currentJob?.name === '개발자') &&
              <div className="pf-box">
                <h4>언어 및 프레임워크</h4>
                <div className="skill-list">
                  <ul>
                    {
                      currentEpisode?.frameworks.map(v => (
                        <li key={v}>{v}</li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            }

            <div className="pf-box">
              <h4>경험</h4>
              <div className="skill-list">
                <ul>
                  {
                    (currentEpisode?.skill_set?.length || 0) < 1 &&
                    <p style={{ marginTop: 8 }}>입력되지 않았습니다.</p>
                  }
                  {
                    currentEpisode?.skill_set.map(v => (
                      <li key={v.name}>{v.name}</li>
                    ))
                  }
                </ul>
              </div>
            </div>
            <div className="pf-box">
              <h4>프로젝트 유형</h4>
              {
                Boolean(currentEpisode?.type) ?
                  <div className="part-t">
                    <span
                      style={{
                        width: 40,
                        minWidth: 40,
                        borderColor: [
                          '',
                          '#523AF5',
                          '#AFFCCA',
                          '#F4B799',
                        ][currentEpisode?.type || 0]
                      }}
                    >
                      {
                        [
                          '',
                          '회사',
                          '협업',
                          '개인',
                        ][currentEpisode?.type || 0]
                      }
                    </span>
                    <p>
                      {
                        [
                          '',
                          '회사에 소속되어 진행한 프로젝트입니다.',
                          '클라이언트와 협업하여 진행된 프로젝트입니다.',
                          '회사 또는 클라이언트 없이 개인적으로 진행된 프로젝트입니다.',
                        ][currentEpisode?.type || 0]
                      }
                    </p>
                  </div>
                  :
                  <p style={{ marginTop: 8 }}>입력되지 않았습니다.</p>
              }
              <h4>프로젝트 소개</h4>
              <p>{currentEpisode?.description ? currentEpisode?.description?.split('\n').map(v => <p>{v}<br /></p>) : '입력되지 않았습니다.'}</p>
            </div>
            <div className="pf-box">
              <h4>프로젝트 관련 자료</h4>
              <div className="data-thumb">
                {
                  Boolean(currentURL) ?
                    <>
                      <a href={currentEpisode?.link?.startsWith('http') ? currentEpisode?.link : `http://${currentEpisode?.link}`} target="_blank" className="dir-go" >
                        {
                          Boolean(currentEpisode?.link) &&
                          <span>{currentEpisode?.link}</span>
                        }
                      </a>

                      <a href={currentEpisode?.file || ''} target="_blank" className="dir-go" style={{ marginTop: 4 }}>
                        {
                          isFile &&
                          <span>{currentEpisode?.file?.split('/').pop()}</span>
                        }
                      </a>
                    </>
                    :
                    <span>입력되지 않았습니다.</span>
                }
              </div>
            </div>
            <CoWorker
              data={currentEpisode}
              profile={memberProfile}
              isMyPage={isMyPage}
            />

            <PortfolioBanner
              data={currentEpisode}
            />
          </div>
        }
      </div>

      <PoolSuggestionModal
        isSuggesting={isSuggesting}
        canCoffeeChat={canCoffeeChat}
        data={memberProfile}
        onClose={() => {
          setSuggesting(false)
          setCoffeechat(false);
        }}
      />

      <MemberMemoModal
        isOpen={isOpenMemoCreation}
        memberUUID={uuid}
        onClose={() => setOpenMemoCreation(false)}
      />

      <MemberMemoViewModal
        isOpen={isOpenMemoViewer}
        memberUUID={uuid}
        onClose={() => setOpenMemoViewer(false)}
      />
    </>
  )
}));

export default MemberProfilePage;
