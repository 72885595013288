import { Instance, types } from 'mobx-state-tree';

export enum PublicLogType {
  RECOMMENDED = 'recommended',
  OFFERED = 'offered',
  CANDIDATE_REGISTERED = 'candidate_registered',
}

export const PublicLog = types.model({
  company_avatar: types.string,
  company_name: types.string,
  member_nickname: types.string,
  type: types.string,
});

export interface IPublicLog extends Instance<typeof PublicLog> {};
