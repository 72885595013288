import axios from 'axios';

export interface IPaymentSearchParam {
  type?: string;
  page?: number;
  page_size?: number;
}

export default {
  getPartnerPayment: async (uuid: string, params: IPaymentSearchParam) => {
    const { type, page, page_size } = params;

    try {
      let url = `/partner/${uuid}/payments?`;

      if (!type) {

      } else if (type === 'PAYMENT') {
        url += 'type=PREPAYMENT&type=PREPAYMENT_REFUND&type=DIFFERENCE_PAYMENT&type=DIFFERENCE_REFUND&type=CONTRACT_FEE';
      } else {
        url += `type=${type}`;
      }

      const { data } = await axios.get(url, {
        params: {
          page,
          page_size,
        }
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
  getMemberPayment: async (uuid: string, params: IPaymentSearchParam) => {
    try {
      const { data } = await axios.get(`/members/${uuid}/payments`, {
        params
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
  requestWithDrawl: async (uuid: string, form: any) => {
    try {
      const { data } = await axios.post(`/member/${uuid}/payments/request-withdrawl`, form);
      return data;
    } catch (e) {
      throw e;
    }
  },
};
