import { useEffect } from "react";

const Logout = () => {

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  return <></>;
}

export default Logout;
