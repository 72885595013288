import { observer } from "mobx-react-lite";

interface Props {
  nickname: string;
  isOpen: boolean;
}

const NewPortfolioModal = ({
  nickname,
  isOpen,
}: Props) => {
  if (!isOpen) {
    return <></>;
  }

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3>새 포트폴리오를 확인해보세요.</h3>
          <p>
            커리어 설문에 응답하셨군요. <br/>
            응답을 분석한 뒤 포트폴리오를 만들어 {nickname}님의 프로필에 추가하였습니다.
            지금 새 포트폴리오를 확인해보세요.
          </p>
          <div className="btn">
            <a href="/member/profile" className="actv">확인</a>
          </div>
        </div>
        <button type="button" className="btn-close" onClick={() => window.location.href = '/member/profile'}>
          <span>포트폴리오 확인하기</span>
        </button>
      </div>
    </div>
  )
};

export default observer(NewPortfolioModal);
