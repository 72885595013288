import { useState, useEffect } from 'react';
import { IIterationModel } from '../models/IterationModel';
import dayjs from 'dayjs';
import { numberWithCommas } from '../../../utils/utils';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { IDetailModel } from '../models/DetailModel';
import { inject, observer } from "mobx-react";
import { StateProps } from "../../../interfaces/interfaces";

interface Props extends StateProps {
  project: IDetailModel,
  uuid: string,
  userUUID: string,
  data?: IIterationModel,
  isOpen: boolean;
  onClose: () => void;
}

const regex = /[^0-9]/g;

const MemberConfirmModal = ({
  project,
  appStore,
  uuid,
  userUUID,
  data,
  isOpen,
  onClose,
}: Props) => {
  const { register, watch, setValue, handleSubmit } = useForm();
  const [isNotice, setNotice] = useState(false);
  const { showLoader, hideLoader } = appStore!;

  useEffect(() => {
    if (!data) return;

    setValue('wage', data.wage);
    setValue('_wage', numberWithCommas(data.wage));
    setNotice(false);
  }, [data]);

  if (!isOpen || !data) {
    return <></>;
  }

  const onChange = (e: any) => {
    if (!isNotice) {
      alert('정산 비용의 변경은 반드시 회사와 협의후 작성해주셔야 합니다.');
      setNotice(true);
    }

    const value = e.target.value.replaceAll(regex, '');
    if (isNaN(value)) {
      return;
    }

    const num = Number(value);
    setValue('wage', num);
    setValue('_wage', numberWithCommas(value));
  };

  const onSubmit = async (body: object) => {
    try {
      showLoader();
      await axios.put(`/members/${userUUID}/projects/${uuid}/iterations/${data.round}/request-payment`, body);
      hideLoader();
      window.location.reload();
    } catch (e) {
      alert(e.response.data.message);
    }
    // onClose();
  };

  const onCancel = () => {
    onClose();
  };

  const onEnd = () => {
    onClose();
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3>{data.round}번째 주 정산 요청</h3>
          <p>{data.round}번째 주의 정산을 요청하시겠습니까? 정산 요청시, {project.partner?.company_name || ''}님의 동의 후 정산이 처리 됩니다. </p>
          <div className="sp-end2">
            <ul className="type2">
              <li>
                <i className="tit">정산 기간</i>
                <span>{dayjs(data.start_date).format('YYYY.MM.DD')} - {dayjs(data.end_date).format('YYYY.MM.DD')}</span>
              </li>
              <li>
                <label htmlFor="pgWork" className="tit">진행 업무</label>
                <textarea
                  id="pgWork"
                  style={{ height: 98, color: '#0D1240' }}
                  placeholder="한 주간 진행한 업무 내용을 간단히 기록해주세요."
                  {...register('weekly_work_description')}
                />
              </li>
            </ul>
          </div>
          <div className="sp-end2">
            <label htmlFor="wkPrice" className="tit">정산 주급<span style={{ fontWeight: 'normal' }}>금액 수정 가능합니다.⁤</span></label>
            <div className="price-w">
              <input
                type="text"
                id="sPr"
                placeholder="00,000,000"
                value={watch('_wage')}
                onChange={onChange}
              />
              <span>원</span>
            </div>
          </div>
          <div className="btn type2">
            <button type="button" className="cancel" onClick={onCancel}>취소</button>
            <a style={{ cursor: 'pointer' }} className="actv" onClick={handleSubmit(onSubmit)}>정산 요청</a>
          </div>
        </div>
        <button type="button" className="btn-close" onClick={onEnd}><span>팝업 닫기</span></button>
      </div>
    </div >
  );
};

export default inject("appStore")(observer((MemberConfirmModal)));
