import { types, Instance } from "mobx-state-tree";
import { MemberProposalModel } from "./MemberProposalModel";
import { PartnerCountModel } from "./ProposalCountModel";

export const MemberProposalResModel = types.model({
  counts: PartnerCountModel,
  count: types.number,
  next: types.maybeNull(types.string),
  previous: types.maybeNull(types.string),
  results: types.array(MemberProposalModel)
});

export interface IMemberProposalResModel extends Instance<typeof MemberProposalResModel> {};
