const Privacy = () => {
  return (
    <>
      <div className="partner-input">
        <h1 style={{ textAlign: 'center' }}>개인정보 수집 및 사용</h1><br/>
        (주)시소에서 운영하는 서비스는 정보통신망이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’) 등에 따라 회원의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여
        다음과 같이 개인정보처리방침을 수립·공개합니다.
        <br />
        <br /><strong>제1조 개인정보의 수집목적</strong>
        <br />(주)시소(이하 "회사" 또는 "사이트")는 다음의 목적을 위하여 개인정보를 수집하며, 다음의 목적 이외의 용도로는 이용되지 않습니다.
        <br />
        <br />1. 회원 관리
        <br />가입의사 확인, 회원제 서비스 제공에 따른 본인 인증, 고객문의에 대한 응답, 새로운 정보의 소개 및 고지사항 전달
        <br />
        <br />2. 서비스 제공 및 정산
        <br />본인인증, 채용 매칭, 콘텐츠 제공 및 맞춤 서비스 위한 개인식별, 회원간의 상호 연락, 구매 및 요금 결제, 부정 이용방지와 비인가 사용방지
        <br />
        <br />3. 서비스 개발 및 마케팅ㆍ광고 활용
        <br />맞춤 서비스 제공, 서비스 안내 및 이용권유, 서비스 개선 및 신규 서비스 개발을 위한 통계 및 접속빈도 파악, 통계학적 특성에 따른 광고, 이벤트 정보 및 참여기회 제공
        <br />
        <br />4. 서비스 고도화를 위한 데이터 분석
        <br />
        <br /><strong>제 2조 (수집하는 개인정보 항목 및 방법)</strong>
        <br />
        <br />1. 수집하는 개인정보 항목
        <br />1) 회원가입시 수집하는 항목
        <br />개인회원 필수항목 : 이름, 비밀번호, 전화번호, 이메일주소, 거주 국가 정보, 소속, 직무/직군, 직무 스킬, 참여 프로젝트 이력, 포트폴리오 링크
        <br />기업회원 필수항목 : 이름, 비밀번호, 전화번호, 이메일주소, 회사명
        <br />2) 정산시 수집하는 항목
        <br />필수 항목: 본인 계좌정보(은행, 계좌번호), 주민등록번호(근거 : 소득세법)
        <br />3) 인터넷 서비스 이용과정에서 자동으로 수집되는 항목
        <br />IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 광고 ID, 접속 환경
        <br />
        <br />2. 수집방법
        <br />홈페이지, 서비스 이용, 요금 정산, 고객센터 문의
        <br />
        <br /><strong>제 3조(개인정보의 수집 및 보유기간)</strong>
        <br />
        <br />"사이트"는 회원가입일로부터 서비스를 제공하는 기간 동안에 한하여 이용자의 개인정보를 보유 및 이용하게 됩니다. 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집 및 이용목적이 달성되거나
        이용기간이 종료한 경우 개인정보를 지체 없이 파기합니다.
        <br />단, 다음의 경우에 대해서는 각각 명시한 이유와 기간 동안 보존합니다.
        <br />1. 상법 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 법령에서 규정한 보존기간 동안 거래내역과 최소한의 기본정보를 보유합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용합니다.
        <br />1) 계약 또는 청약철회 등에 관한 기록: 5년
        <br />2) 대금결제 및 재화 등의 공급에 관한 기록: 5년
        <br />3) 소비자의 불만 또는 분쟁처리에 관한 기록: 3년
        <br />4) 부정이용 등에 관한 기록: 5년
        <br />5) 웹사이트 방문기록(로그인 기록, 접속기록): 1년
        <br />2. "회사" 혹은 "서비스" 관련하여 진행중인 사항이 존재할 경우 종료처리를 위해 필요한 기간까지 기본정보를 보유합니다.
        <br />1) 관계 법령 위반에 따른 수사․조사 등이 진행중인 경우에는 해당 수사․조사 종료시까지
        <br />2) 홈페이지 이용에 따른 채권․채무관계 잔존시에는 해당 채권․채무관계 정산시까지
        <br />3) '예외 사유' 시에는 보유기간까지 해당
        <br />
        <br /><strong>제 4조 (개인정보의 제공 및 처리위탁)</strong>
        <br />"회사"는 개인정보를 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이 동 범위를 초과하여 이용하지 않습니다.
        <br />
        <br />1. 처리위탁
        <br />"회사"는 회사는 원활한 서비스 제공을 위하여 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.
        <table>
          <colgroup>
            <col width="*"/>
          </colgroup>
          <tbody>
            <tr>
              <td>아마존 웹 서비스(영어: Amazon Web Services; AWS)</td>
              <td>호스팅 서비스 제공, 이메일 발송</td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />2. 개인정보 제공 및 이용
        <br />다음의 경우에는 합당한 절차를 통하여 개인정보를 제공 또는 이용할 수 있습니다.
        <br />
        <br />1) 매각, 인수합병
        <br />서비스 제공자의 권리, 의무가 승계 또는 이전되는 경우 이를 반드시 사전에 고지하며 이용자의 개인정보에 대한 동의철회의 선택권을 부여합니다.
        <br />
        <br />2) 기업 회원에게 제공되는 경우
        <br />프로필 조회를 허용한 이용자에 한하여 기업회원은 조회할 수 있습니다.
        <br />
        <br />3) 예외 사유
        <br />관계법령에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
        <br />
        <br /><strong>제 5조(개인정보의 파기)</strong>
        <br />
        <br />1. "회사"는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
        <br />2. 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로
        옮기거나 보관장소를 달리하여 보존합니다.
        <br />3. 개인정보 파기의 절차 및 방법은 다음과 같습니다.
        <br />1) 파기절차
        <br />"회사"는 파기 사유가 발생한 개인정보를 선정하고, "회사" 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
        <br />2) 파기방법
        <br />"회사"는 전자적 파일 형태로 기록․저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록․저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
        <br />
        <br /><strong>제 6조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)</strong>
        <br />
        <br />"회사"는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 '쿠기(cookie)'를 사용합니다.
        <br />쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.
        <br />
        <br />1. 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
        <br />2. 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
        <br />3. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
        <br />
        <br /><strong>제 7조(개인정보의 안전성 확보조치) </strong>
        <br />
        <br />"회사"는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
        <br />1. 관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등
        <br />2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치
        <br />3. 물리적 조치 : 호스팅 서버 위치한 곳 등의 접근통제
        <br />
        <br /><strong>제 8조(아동의 개인정보 보호)</strong>
        <br />
        <br />"회사"는 "서비스" 특성으로 볼 때 만14세 미만의 아동은 프로젝트 이력 등록을 통한 N잡 활동을 할 수 없다고 판단하여 회원가입을 받지 않습니다.
        <br />
        <br /><strong>제 9조(정보주체와 법정대리인의 권리․의무 및 행사방법)</strong>
        <br />
        <br />1. 정보주체는 "회사"에 대해 언제든지 개인정보 열람․정정․삭제․처리정지 요구 등의 권리를 행사할 수 있습니다.
        <br />2. 제1항에 따른 권리 행사는 "회사"에 대해 개인정보보호법 시행령 제41조제1항에 따라 서면, 전자우편 등을 통하여 하실 수 있으며, "회사"는 이에 대해 지체없이 조치하겠습니다.
        <br />3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
        <br />4. 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
        <br />5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
        <br />6. "회사"는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
        <br />
        <br /><strong>제 10조(개인정보 처리 및 민원서비스)</strong>
        <br />
        <br />"회사"는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
        <br />
        <br />▶ 개인정보 보호책임자
        <br />성명 : 김은성
        <br />직책 : 이사
        <br />연락처 : partner@second-team.com
        <br />
        <br /><strong>제 11조(권익침해 구제방법)</strong>정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.
        <br />
        <br />아래의 기관은 "회사"와는 별개의 기관으로서, "회사"의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다
        <br />
        <br />▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)
        <br />- 소관업무 : 개인정보 침해사실 신고, 상담 신청
        <br />- 홈페이지 : privacy.kisa.or.kr
        <br />- 전화 : (국번없이) 118
        <br />- 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터
        <br />
        <br />▶ 개인정보 분쟁조정위원회
        <br />- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
        <br />- 홈페이지 : www.kopico.go.kr
        <br />- 전화 : (국번없이) 1833-6972
        <br />- 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
        <br />
        <br />▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)
        <br />
        <br />▶ 경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)
        <br />
        <br /><strong>[부칙]</strong>
        <br />
        <br />이 개인정보 처리방침은 2021년 9월 10일부터 적용됩니다.
        <br />
      </div>
    </>
  )
}

export default Privacy;
