/*
  markup : project_recruit_member.html
*/
import { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import { IAppStore } from "../../stores/AppStore";
import { IRecruitListStore } from '@stores/RecruitListStore';
import { numberWithCommas } from '../../utils/utils';
import RecruitEmpty from '../../components/empty/RecruitEmpty';
import Pagination from '../../components/Pagination';
import Avatar from '../../components/Avatar';
import dayjs from "dayjs";

interface IProps {
  appStore: IAppStore;
}

const RecruitListPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const { recruitListStore } = (props as IProps).appStore;
  const { projectList: project, amount, type, getProejctList } = (recruitListStore as IRecruitListStore);

  const [tab, setTab] = useState(0);
  const isEmpty = (project?.results.length || 0) < 1;
  const projectList = (project?.results || []).filter((v: any) => tab === 0 || v.status === ['PENDING', 'IN_PROGRESS', 'DONE'][tab - 1]);
  const statusMap = [
    undefined,
    'PENDING',
    'IN_PROGRESS',
    'DONE',
  ];
  

  useEffect(() => {
    window.scrollTo(0, 0);
    getProejctList();
  }, []);

  useEffect(() => {
    if (tab > 0) {
      window.location.hash = statusMap[tab]?.toLowerCase() || '';
    } else {
      window.location.hash = '';
    }

    getProejctList(statusMap[tab]);
  }, [tab]);

  return (
    <>
      <div className="sub-wrap">
        <div className="top-box">
          <h2 style={{ cursor: 'pointer' }}>
            협업 <a href={
              type === 'PARTNER' ?
                'https://seesorufree.notion.site/972eff66366b4927b7b13ec16a085c95'
                :
                'https://seesorufree.notion.site/fbde498e7cb248a4be5f952bb63c9677'
            } target="_blank" style={{ textDecoration: 'underline' }}>가이드</a>
          </h2>

          {
            type === 'PARTNER' ?
              <>
                <p>시작일을 정하면 주 단위의 채용이 시작됩니다.</p>
                <div style={{ lineHeight: 2 }}>주별 정산요청을 확인하면, 실제 비용 정산이 이뤄집니다.</div>
              </>
              :
              <>
                <p>시작일이 정해지면 주 단위의 채용이 시작됩니다.</p>
                <div style={{ lineHeight: 2 }}>주별 정산요청을 하면, 회사의 확인후 정산이 이뤄집니다.</div>
              </>
          }


          <p>
            <a href={`/calc/${type.toLowerCase()}`}>
              {type === 'MEMBER' ? '누적 정산 금액' : '현재 보유 금액'} {numberWithCommas(amount)}원<span>보유 금액 및 정산 내역 보기</span>
            </a>
          </p>
          {/* <div className="btn-guide">
            <a href="https://seesorufree.notion.site/77c4aad3cb264c43adf622876ecb93a4" target="_blank">협업 가이드</a>
          </div> */}
        </div>
        <div className="project-recruit">
          {
            isEmpty ||
            <div className="cnts-hgroup">
              <h3>내 협업</h3>
              <div className="tab">
                <ul>
                  <li className={tab === 0 ? 'selected' : ''} style={{ cursor: 'pointer' }} onClick={() => setTab(0)}><a>전체({project?.count})</a></li>
                  <li className={tab === 1 ? 'selected' : ''} style={{ cursor: 'pointer' }} onClick={() => setTab(1)}><a>진행예정({project?.counts.pending})</a></li>
                  <li className={tab === 2 ? 'selected' : ''} style={{ cursor: 'pointer' }} onClick={() => setTab(2)}><a>진행 중({project?.counts.in_progress})</a></li>
                  <li className={tab === 3 ? 'selected' : ''} style={{ cursor: 'pointer' }} onClick={() => setTab(3)}><a>종료({project?.counts.done})</a></li>
                </ul>
              </div>
            </div>
          }

          {
            isEmpty ?
              <RecruitEmpty />
              :
              <ul>
                {
                  projectList.map(({ uuid, title, partner, member, status, start_date, dateDetail }) => (
                    <li>
                      <a href={`/recruits/${uuid}`}>
                        <div className="info-w">
                          <div className="info">
                            <strong>{title}</strong>
                            <span>{dateDetail}</span>
                          </div>
                        </div>
                        <div className="prog-condi">
                          <div className="nick">
                            <Avatar
                              data={member || partner}
                            />
                            <div className="info">
                              <em>{type === 'MEMBER' ? partner?.company_name : member?.name}</em>
                            </div>
                          </div>
                          <div className="condi">

                            {
                              status !== 'DONE' && dayjs().isAfter(dayjs(start_date)) ?
                                <span className="ing">진행중</span>
                                :
                                <>
                                  {status === 'PENDING' && <span>진행 예정</span>}
                                  {status === 'IN_PROGRESS' && <span className="ing">진행중</span>}
                                  {status === 'DONE' && <span className="end">종료</span>}
                                </>
                            }
                          </div>
                        </div>
                      </a>
                    </li>
                  ))
                }
              </ul>
          }

          <Pagination
            totalCount={project?.count || 0}
            size={20}
            onChange={(num) => {
              getProejctList(statusMap[tab], num)
            }}
          />
        </div>
      </div>
    </>
  );
}));

export default RecruitListPage;
