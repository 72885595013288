import { observer } from "mobx-react";
import { ISuggestForm } from "../RecruitSuggestionModifyPage";
import { useController, useFormContext } from "react-hook-form";
import { timeList } from "../../../utils/constants";

export default observer(({ title, data }: ISuggestForm) => {
  const { setValue, watch, formState: { isSubmitted } } = useFormContext();
  const isError = isSubmitted && !watch('working_hours');

  const onChange = (e: any) => {
    const value = e.target.value;
    if (isNaN(value)) {
      return;
    }

    const num = Number(value);
    setValue('working_hours', num, { shouldDirty: true });
  };

  return (
    <div className="in-cnts">
      <h5>{title}</h5>
      <p><em>{data.nickname}</em>님의 1주일 기준 거래 가능한 시간은 <em>{data.working_hours_per_week ? `${data.working_hours_per_week || 0}시간` : timeList[(data.working_hour_range_per_week || 1) - 1]}</em>입니다.</p>
      <div className="price-w">
        <input
          className={isError ? 'err' : ''}
          type="text"
          id="price"
          placeholder="숫자만 입력해주세요."
          title="숫자만 입력해주세요."
          onChange={onChange}
          value={watch('working_hours')}
        />
        <span>시간 / 일주일</span>
      </div>

      {
        isError &&
          <p className="err">희망 거래 시간을 입력해주세요.</p>
      }
    </div>
  );
});
