import { AUTH_USERTYPE } from "@utils/constants";
import { Link } from "react-router-dom";

const SEESO_NOTION = 'https://seesorufree.notion.site/b8c011f0949145e295924757775ec0e2';
const RECRUIT_NOTION = 'https://seesorufree.notion.site/b8c011f0949145e295924757775ec0e2';
const SEND_MAIL_URL = 'mailto:partner@second-team.com';

const Footer = () => {
  const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';

  return (
    <footer style={{ backgroundColor: 'white' }}>
      <div className="ftr-inner">
        <em className="logo"><span>SECOND TEAM</span></em>
        <ul>
          {/* <li>
            <em><a>세컨팀 멤버스</a></em>
            <span><Link to={'/members'}>멤버 찾기</Link></span>
          </li> */}
          {/* <li>
            <em><a>포트폴리오</a></em>
            <span><a href={SEND_MAIL_URL}>협업 사례</a></span>
          </li> */}
          <li>
            <em><a>회사 소개</a></em>
            <span><a href="https://seeso.kr" target="_blank">seeso 홈페이지</a></span>
            <span><a href={SEND_MAIL_URL}>제휴 및 제안</a></span>
          </li>
          <li>
            <em><a href="/terms" target="_blank">서비스 이용약관</a></em>
            <em><a href="/terms/privacy" target="_blank">개인정보 처리 방침</a></em>
          </li>
        </ul>
        <div className="c-info">
          <p>(주)시소 (대표이사:박병규)</p>
          <address>제주특별자치도 제주시 중앙로217 제주벤쳐마루 3층 & 서울특별시 성동구 성수일로3길 5-12, 2층</address>
          <p>통신판매업신고번호: 2019-제주이도2-0078 | 사업자등록번호: 171-88-00464 개인정보보호관리자 : 김은성 | 직업정보제공사업 신고번호 : J1630120220001호</p>
          <p>Copyright © seeso Inc.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;

/*
  markup : layout.html > footer 태그
*/