import axios from "axios";

const joinAPI = {
  memberSignup: async function (params: any) {

    try {
      const { data }: { data: {} } = await axios.post(
        `/members/signup`,
        {
          ...params
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  },

  partnerSignup: async function (params: any) {
    /*
      - email(string): 이메일
      - password(string): 패스워드
      - name(string): 이름
      - phone(string): 핸드폰 번호
      - company_name(string): 회사 이름
      - industry(string): 서비스분야
      - in_house_resource(string[]: RESOURCE_CHOICES): 현재 회사 내 IT 멤버 구성
      - marketing_agreement(boolean): 마케팅 정보 수신 동의
    */

    try {
      const { data }: { data: {} } = await axios.post(
        `/partner/signup`,
        {
          ...params
        }
      );

      return data;
    } catch (e) {
      throw e;
    }
  },

  verifyInviteCode: async function(code: string) {
    try {
      const { data }: { data: any } = await axios.get(
        `/members/signup/verify-invite-code/${ code }`
      );
      console.log(data);
      return data;
    } catch (e) {
      throw e;
    }
  },

  verifyEmail: async function(email: string) {
    try {
      const { data }: { data: any } = await axios.get(
        `/auth/email/${email}/is-verified`
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
};

export default joinAPI;
