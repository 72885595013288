import { observer } from "mobx-react";
import { Prompt } from "react-router-dom";

interface IProps {
  when: boolean;
}

const EditPrompt = ({ when }: IProps) => {
  return (
    <Prompt
      when={when}
      message="변경 내용을 삭제하시겠어요? 지금 페이지를 나가면 변경 내용이 삭제됩니다."
    />
  );
}

export default observer(EditPrompt);
