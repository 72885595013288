import { AUTH_UUID } from '@utils/constants';
import axios from 'axios';

export interface IConstantsAll {
  frameworks: {
    id: number;
    name: string;
    order: number;
  }[];
  tools: {
    id: number;
    name: string;
    order: number;
  }[];
}

const profileAPI = {
  getConstants: async function (consType: 'all' | 'jobs/all/' | 'jobs/root/') {
    /*
      GET /constants/all: 언어/프레임워크, 스킬셋, 협업툴, 직군/직무 데이터셋 리스트
      GET /constants/jobs/all: 직군/직무 리스트
      GET /constants/jobs/root: 직군 리스트
    */
    try {
      const { data }: { data: any } = await axios.get(`/constants/${consType}`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  getMemberProfile: async function (uuid: string) {
    try {
      const { data }: { data: any } = await axios.get(
        `/members/${uuid}/profile`,
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  createMemberProfilePortfolioFile: async function (
    uuid: string,
    formData: FormData,
  ) {
    console.log('createMemberProfilePortfolioFile');
    try {
      const { data }: { data: any } = await axios.post(
        `/members/${uuid}/profile/portfolio-file`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  removeMemberProfilePortfolioFile: async function (uuid: string) {
    console.log('removeMemberProfilePortfolioFile');
    try {
      const { data }: { data: any } = await axios.delete(
        `/members/${uuid}/profile/portfolio-file`,
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  updateMemberProfile: async function (uuid: string, parameters: any) {
    try {
      const { data }: { data: any } = await axios.patch(
        `/members/${uuid}/profile`,
        parameters,
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  updateMemberAvatar: async function (uuid: string, formData: FormData) {
    try {
      const { data }: { data: any } = await axios.patch(
        `/members/${uuid}/profile`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return data;
    } catch (e) {
      throw e;
    }
  },

  getPartnerProfile: async function (uuid: string) {
    try {
      const { data }: { data: any } = await axios.get(
        `/partner/${uuid}/profile`,
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  updatePartnerAccount: async function (uuid: string, parameters: any) {
    try {
      const { data }: { data: any } = await axios.put(
        `/partner/${uuid}/profile`,
        parameters,
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  updatePartnerAvatar: async function (uuid: string, formData: FormData) {
    try {
      const { data }: { data: any } = await axios.put(
        `/partner/${uuid}/profile`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  createMemberProjectFile: async (
    uuid: string,
    projectUUID: string,
    file: any,
  ) => {
    const formData = new FormData();
    formData.append('file', file);
    
    try {
      const { data }: any = await axios.post(
        `members/${uuid}/episodes/${projectUUID}/file`,
        formData,
      );

      return data;
    } catch (e) {
      throw e;
    }
  },
  removeMemberProjectFile: async (uuid: string, projectUUID: string) => {
    try {
      const { data }: any = await axios.delete(
        `members/${uuid}/episodes/${projectUUID}/file`,
      );

      return data;
    } catch (e) {
      throw e;
    }
  },
  createMemberProject: async (uuid: string, project: any) => {
    try {
      const { data }: any = await axios.post(
        `members/${uuid}/episodes`,
        project,
      );

      return data;
    } catch (e) {
      throw e;
    }
  },
  updateMemberProject: async (
    uuid: string,
    projectUUID: string,
    project: any,
  ) => {
    try {
      const { data }: any = await axios.put(
        `members/${uuid}/episodes/${projectUUID}`,
        project,
      );

      return data;
    } catch (e) {
      throw e;
    }
  },
  deleteMemberProject: async (uuid: string, projectUUID: string) => {
    try {
      const { data }: any = await axios.delete(
        `members/${uuid}/episodes/${projectUUID}`,
      );

      return data;
    } catch (e) {
      throw e;
    }
  },
  getCoWorkerList: async (memberUUID: string) => {
    try {
      const { data }: any = await axios.get(
        `members/${memberUUID}/coworkers`,
      );

      return data;
    } catch (e) {
      throw e;
    }
  },
  postCoWorkerToSecondTeam: async (id: number) => {
    const memberUUID = window.localStorage.getItem(AUTH_UUID) || '';
    const { data } = await axios.post(
      `/members/${memberUUID}/coworkers/${id}/add-to-secondteam`,
    );

    return data;
  },
  deleteCoWorkerFromSecondTeam: async (id: number) => {
    const memberUUID = window.localStorage.getItem(AUTH_UUID) || '';
    const { data } = await axios.post(
      `/members/${memberUUID}/coworkers/${id}/remove-from-secondteam`,
    );

    return data;
  },
};

export default profileAPI;
