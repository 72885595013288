import { useEffect, useState } from 'react';
import Tag from '@models/Tag';
import { AUTH_USERTYPE } from '@utils/constants';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import 'tippy.js/animations/scale.css';
import 'tippy.js/dist/tippy.css';

const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';

interface Data {
  id: number;
  tab: number;
  title: string;
  introduction: string;
  projectName: string;
  tags: string[];
  color: string;
  user: string;
}

const dataSet: Data[] = [
  {
    id: 1,
    tab: 1,
    title: '서울에서 재택근무 중인 인공지능 전문 UX 디자이너입니다.',
    introduction: `
    UX 디자인 (서비스 기획) 뿐만 아니라 사용자 조사 (UX Research), UI 디자인 등 하나의 서비스를 런칭하는데 필요한 전체 프로세스를 경험해 왔고 진행하고 있습니다. 도메인 지식이 중요한 UX라는 분야에서 특히 인공지능과 관련된 제품과 서비스를 기획, 디자인하고 연구해왔으며, 앞으로도 꾸준하게 AI 분야에 대한 UX를 진행하려고 합니다. 학부 과정에서 시각 디자인을, 석사 과정에서 human-ai-interaction을 전공하였으며 현재 ai전문 회사에서 일하고 있습니다.
    `,
    projectName: 'AI Stages',
    tags: ['서비스 정책 설계', '사용자 리서치', '프로덕트 디자인'],
    color: '#F74',
    user: 'e6f53134-56a9-4c37-980a-a27cfca4c30b',
  },
  {
    id: 2,
    tab: 1,
    title: '음악에 관심이 많은 ML 엔지니어입니다!',
    introduction: `
    - ML Software Engineer
    - (전) Software Engineer
    - PyTorch / TensorFlow AI 모델링 및 모바일/클라우드로 배포
    - AWS/GCP 클라우드와 Kubernetes를 활용한 Scalable한 AI 서빙
    - MLFlow/Kubeflow/DataHub 등 외부 솔루션 활용 MLOps 구축
    - 이외 React를 활용한 Web FE, NodeJS를 활용한 Web BE, React Native와 Swift, Kotlin을 통한 App FE 개발을 할 수 있습니다.
    `,
    projectName: `BANJU: 'AI' 기반 코드반주 학습 서비스`,
    tags: ['프로덕트 릴리즈', '애자일 방식의 개발 경험', '과학기술정보통신부장관상 수상'],
    color: '#09f',
    user: 'bdab9594-3de7-4025-8e48-74ebc22279f5',
  },
  {
    id: 3,
    tab: 1,
    title: '캐나다 토론토에서 데이터사이언티스트로 일하고 있습니다.',
    introduction: `데이터 사이언티스트의 실전노트 책(2022년 5월 출간 예정) 저자이며, 클래스101 데이터 과학 실전 업무 강의를 제작했습니다.
    토론토대학교에서 응용통계학 학부 졸업, 요크 대학교에서 응용통계학 석사 졸업 후 현재 5년차 데이터 사이언티스트로 머신러닝 모델, 통계 모델, 데이터 분석 프리랜서 업무 가능 합니다. 파이썬, R, 아마존 클라우드 웹 서비스 전문 입니다.`,
    projectName: `S&P 500 주가 예측 모델 데이터 파이프라인 구축`,
    tags: ['데이터 사이언티스트', '파이썬', '머신러닝'],
    color: '#2A9',
    user: '3cd01a33-ffea-4fa9-b9bf-3aabb5972056',
  },
  {
    id: 4,
    tab: 2,
    title: '주로 프론트엔드 개발을 합니다.',
    introduction: `
    1. 주로 프론트엔드 개발을 합니다.
    - Vue.js, Nuxt.js
    - React, Next.js, React Native 등..
    2. 회사에서는 풀스택으로 일한 터라 백엔드 기술도 원활하게 다룰 줄 압니다.
    - Node express, nest.js
    - Serveless.js(AWS Lambda)
    - PHP Laravel
    - MySQL 등
    3. Infra
    - AWS EC2, Lightsail, Route53, API Gateway, Lambda, S3 등
    `,
    projectName: `블록체인 지갑 앱`,
    tags: ['리액트 네이티브를 통한 iOS/Android 빌드'],
    color: '#F74',
    user: 'dfac1dfa-32bf-482d-884a-78b3f3b0184b',
  },
  {
    id: 5,
    tab: 2,
    title: '블록체인 기업에서 디자인 / 기획 팀장을 맡고 있습니다.',
    introduction: `
      메인넷, 탈중앙화 거래소, 지갑, 브릿지, 블록체인 기반 게임 등의 서비스를 총괄 기획하고 디자인했습니다. 그 외, 브랜드 디자인 및 디자인 시스템을 구축하였습니다.
    `,
    projectName: `신규 DEX 런칭`,
    tags: ['탈중앙화 암호화폐 거래소'],
    color: '#504BFF',
    user: 'a8c332f6-bea1-4a62-be82-890df1fee8e1',
  },
  {
    id: 6,
    tab: 3,
    title: '네이버와 쿠팡에서 약 10년간 기획자/ 프로덕트 매니저로 일했습니다.',
    introduction: `
    - PC 및 모바일 데이터 기반 UX 전략 및 전자 상거래 플랫폼에 대한 전문성을 가지고 있습니다. 
    - 약 5년간 애자일 환경에서 5~13명으로 구성된 4개의 교차 기능 팀을 이끌며 전략 계획부터 구현에 이르기까지 전체 제품 수명 주기를 관리했으며, A/B 테스트 및 사용성 테스트에 대한 다수의 경험 및 스킬을 보유하고 있습니다. 
    - Figma를 사용한 프로토타이핑이 가능합니다.
    `,
    projectName: `쿠팡 장바구니 개편 프로젝트`,
    tags: ['애자일', '프로덕트매니징', '스크럼'],
    color: '#2A9',
    user: '3baad4a7-1f26-41c8-8993-cc6ab24290e9',
  },
  {
    id: 7,
    tab: 3,
    title: '서울에서 서비스 기획을 하고 있는 쏭입니다.',
    introduction: `
    웹과 앱 기획에 대한 다양한 경험이 있습니다. 주로 B2C 플랫폼을 기획하고 운영했으며 현재는 B2B 메신저 플랫폼과 홈페이지 기획업무를 담당하고 있습니다. PM, 서비스기획, 정책기획 등의 업무 진행이 가능합니다.
    `,
    projectName: `카카오워크`,
    tags: ['Jira', 'Figma', 'Wiki'],
    color: '#09f',
    user: 'aaf3bd9b-0167-43cd-a053-18f70c817037',
  },
  {
    id: 8,
    tab: 3,
    title: '아우름플래닛(라이너)을 공동창업하며 CTO로 4년...',
    introduction: `
    아우름플래닛(라이너)을 공동창업하며 CTO로 4년,
    비바리퍼블리카(토스)에서 개발로 3년,
    그 외 4곳의 조직에서 개발/Product Owner/CEO Staff로 4년을 경험했습니다.
    `,
    projectName: `토스`,
    tags: ['MVP', 'CPU / 메모리 사용량 관리', '크래시 로그 분석'],
    color: '#F74',
    user: 'e57ceb0c-351f-40c8-8fe6-a209b233f266',
  },
  {
    id: 9,
    tab: 4,
    title: '안드로이드 팀 테크 리드 역함을 겸하고 있는 안드로이드 개발자 입니다.',
    introduction: `
    디지털노마드를 꿈꾸는 미니멀리스트 안드로이드 개발자로 디지털노마드, 미니멀리즘, 애자일에 관심이 많습니다. 사이드 프로젝트를 이것저것 진행하고 있으며 안드로이드 개발을 깊게 하는 것을 목표로 하고 있습니다. 스타트업의 자유로움속에서 서비스의 성장에만 집중하여 일하는 분위기가 좋아서 계속 스타트업에서 일하고 있습니다.
    `,
    projectName: `알라미`,
    tags: ['Java 프로젝트 Kotlin으로 전환', '애자일 방식의 협업 프로세스 구축', '스크럼 개발 프로세스 구축'],
    color: '#2A9',
    user: '8a423a4c-c3de-4c74-931b-bb72c78180c6',
  },
  {
    id: 10,
    tab: 4,
    title: '탑 커머스 회사 재직중',
    introduction: `
    전 Tmap Mobilty, NHN Soft, 외국계 스타트업의 커리어가 있는 7년차 개발자입니다.
    스타트업~ 대기업의 경험이 모두 있으며,
    마케팅, 커머스,모빌리티 세 가지가 가장 커리어와 적합한 키워드인 것 같습니다.
    온라인 플랫폼에서 자바 및 Spring에 대한 강의자로써 활동중이기도 합니다.
    `,
    projectName: `티맵 안심대리`,
    tags: ['애자일식 개발', '프로덕트 릴리즈',],
    color: '#504BFF',
    user: '23281aaf-a608-48ae-be2b-636e741bda00',
  },
];

function ProjectCard({ onClick, data, color }: { onClick: () => void, data: Data, color: string }) {
  return (
    <div className="inbox c1" onClick={onClick}>
      <a href="#">
        <h3>
          
        </h3>
        <h4 style={{ marginLeft: 40, marginTop: -32, fontSize: 18 }}>
          {data.title}
        </h4>
        <div className="cnts">
          <p
            style={{ color: color }}
            dangerouslySetInnerHTML={{ __html: data.introduction.split('\n').slice(1).join('<br />').trimStart() }}
          >
          </p>
          {/* <ul>
            <li>스캐터랩 ML Software Engineer</li>
            <li>(전) 가우디오랩 Software Engineer</li>
            <li>SW마에스트로 11기 인증 프로젝트 (Banju : AI기반 코드반주 학습 서비스)</li>
            <li>PyTorch / TensorFlow AI 모델링 및 모바일/클라우드로 배포</li>
            <li>AWS/GCP 클라우드와 Kubernetes를 활용한 Scalable한 AI 서빙</li>
            <li>MLFlow/Kubeflow/DataHub 등 외부 솔루션 활용 MLOps 구축</li>
            <li>이외 React를 활용한 Web FE, NodeJS를 활용한 Web BE, React Native와 Swift, Kotlin을 통한 App FE 개발을 할 수 있습니다.</li>
          </ul> */}
        </div>
        <div className="pjt">
          <em>PROJECT</em>
          <strong>{data.projectName}</strong>
          <div className="hash-pj">
            {
              data.tags.map((v, idx) => (
                <span className={['ai', 'ml', 'node'][idx]}>{v}</span>
              ))
            }
            {/* <span className="ai">AI Data</span>
            <span className="ml">ML Flow</span>
            <span className="node">NodeJS</span> */}
          </div>
        </div>
      </a>
    </div>
  );
}

const MemberProjectPage = () => {
  const [isLoaded, setLoaded] = useState<Boolean>(false);
  const [tagList, setTagList] = useState<Tag[]>([]);
  const [tab, setTab] = useState(1);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const { data } = await axios.get('/tags/');
    setTagList(data);

    setTimeout(() => {
      setLoaded(true);
    }, 5000);
  };

  return (
    <>
      <link rel="stylesheet" href={'/project.css'} />

      <div className="members-project" style={{ marginTop: 128 }}>
        <div className="hgroup">
          <h2 style={{ fontSize: 32 }}>✌️트렌딩 스킬</h2>
          <p style={{ paddingTop: 12, fontSize: 18, fontWeight: 500 }}>국내 정상급 인재들의 스킬을 가장 쉽게 활용해보세요!</p>
        </div>
        <div className="tab">
          <ul>
            {/* <li onClick={() => setTab(0)} className={tab === 0 ? 'selected' : ''}><a style={isMobile ? { padding: 0, minWidth: '15vw', justifyContent: 'center' } : {}} href="#">전체</a></li> */}
            <li onClick={() => setTab(1)} className={tab === 1 ? 'selected' : ''}><a style={isMobile ? { padding: 0, minWidth: '15vw', justifyContent: 'center' } : {}} href="#">AI</a></li>
            <li onClick={() => setTab(2)} className={tab === 2 ? 'selected' : ''}><a style={isMobile ? { padding: 0, minWidth: '17vw', justifyContent: 'center' } : {}} href="#">블록체인</a></li>
            <li onClick={() => setTab(3)} className={tab === 3 ? 'selected' : ''}><a style={isMobile ? { padding: 0, minWidth: '20vw', justifyContent: 'center' } : {}} href="#">네카라쿠배</a></li>
            <li onClick={() => setTab(4)} className={tab === 4 ? 'selected' : ''}><a style={isMobile ? { padding: 0, minWidth: '17vw', justifyContent: 'center' } : {}} href="#">Etc</a></li>
          </ul>
        </div>
        <div className="ms-list">
          {
            dataSet.sort(() => 0.5 - Math.random()).filter(v => tab === 0 || tab === v.tab).map((v, idx) => (
              <ProjectCard
                key={idx}
                color={['#F74', '#09f', '#2A9', '#504BFF'][Math.floor(Math.random() * 4)]}
                onClick={() => {
                  if (!userType) {
                    alert('가입된 계정으로만 조회가 가능합니다.');
                    return;
                  }

                  if (userType !== 'PARTNER') {
                    alert('멤버 프로필을 보실 수 있는 권한이 없습니다. 멤버 프로필은 회사 계정만 확인하실 수 있습니다.');
                    return;
                  }

                  window.open(`https://second-team.com/members/${v.user}`, "_blank");
                }}
                data={v}
              />
            ))
          }
        </div>
      </div>
    </>
  );
}

export default MemberProjectPage;
