import { observer } from "mobx-react";

interface Props {
  data?: any;
}

export default observer(({ data }: Props) => {
  const url = `url(${data?.avatar || '/images/@photo.png'})`;

  return (
    <span
      className="thumb"
      style={{
        backgroundImage: url,
      }}>
    </span>
  );
});
