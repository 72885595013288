import { flow, types, Instance, getParent } from "mobx-state-tree";

export const SkillModel = types.model('Skill', {
  id: types.number,
  label: types.string,
  job_category: types.string,
  order: types.maybeNull(types.number),
});

export interface ISkillModel extends Instance<typeof SkillModel> {};
