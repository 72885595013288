import { types, Instance, getParent, flow } from "mobx-state-tree";
import { MemberAccountModel } from "@pages/account/models";
import { IAppStore } from "@stores/AppStore";
import { StateModel } from "@models/models";
import { MemberProfileModel } from "@pages/profile/models";
import { ExperienceModel } from "@pages/onboarding/models/ExperienceModel";
import { IJobModel, JobModel } from "@pages/onboarding/models/JobModel";
import { SkillModel } from "@pages/onboarding/models/SkillModel";
import onboardingAPI from "@services/onboardingAPI";

const OnboardingStore = StateModel.named('Onboarding')
  .props({
    profile: types.maybe(MemberProfileModel),
    account: types.maybe(MemberAccountModel),
    jobs: types.array(JobModel),
    skills: types.array(SkillModel),
    experiences: types.array(ExperienceModel),
  })
  .views(self => ({
    get uuid(): string {
      return (getParent(self) as IAppStore).userStore.user.uuid;
    },
  }))
  .actions(self => ({
    getProfile: flow(function* () {
      try {
        const response = yield onboardingAPI.getProfile(self.uuid);
        self.profile = MemberProfileModel.create(response);
      } catch(e) {
        throw e;
      }
    }),
    getAccount: flow(function* () {
      try {
        const response = yield onboardingAPI.getAccount(self.uuid);
        self.account = MemberAccountModel.create(response);
      } catch(e) {
        throw e;
      }
    }),
    getConstants: flow(function* () {
      try {
        const response = yield onboardingAPI.getConstants();
        
        const jobs = response.jobs.map((v: any) => JobModel.create(v));
        self.jobs.replace(jobs);

        const experiences = response.experiences.map((v: any) => ExperienceModel.create(v));
        self.experiences.replace(experiences);

        const skills = response.skills.map((v: any) => SkillModel.create(v));
        self.skills.replace(skills);
      } catch(e) {
        throw e;
      }
    }),
    updateProfile: flow(function* (form: object) {
      try {
        const response = yield onboardingAPI.updateProfile(self.uuid, form);
        self.profile = MemberProfileModel.create(response);
      } catch (e) {
        throw e;
      }

      return self.profile;
    })
  }));

export interface IOnboardingStore extends Instance<typeof OnboardingStore>{};

export default OnboardingStore;
