import { useState, useEffect } from 'react';

interface Props {
  totalCount: number;
  size: number;
  onChange: (page: number) => void;
}

export default ({
  totalCount,
  size,
  onChange,
}: Props) => {
  const [page, setPage] = useState(1);
  const [step, setStep] = useState(1);

  const maxPage = Math.ceil(totalCount / size);
  const maxStep = Math.ceil(maxPage / 5) - 1;
  const currentPage = page + ((step - 1) * 5);

  useEffect(() => {
    onChange(currentPage);
  }, [page, step]);

  useEffect(() => {
    setPage(1);
  }, [totalCount, size]);
  
  const onPrev = () => {
    if (step - 1 < 1) {
      return;
    }

    setStep(step - 1);
    setPage(1);
  }

  const onNext = () => {
    if (step - 1 >= maxStep) {
      return;
    }

    setStep(step + 1);
    setPage(1);
  };

  const onClick = (num: number) => {
    setPage(num);
    window.scrollTo(0, 0);
  };

  const createPage = (num: number) => {
    const pageNum = num + ((step - 1) * 5);

    if (pageNum > maxPage) {
      return <></>;
    }

    if (pageNum === currentPage) {
      return <strong onClick={() => onClick(num)}>{pageNum}</strong>
    }

    return <a style={{ cursor: 'pointer' }} onClick={() => onClick(num)}>{pageNum}</a>
  }

  if (totalCount <= 0) {
    return <></>;
  }

  return (
    <div className="paging">
      {
        step > 1 &&
          <a style={{ cursor: 'pointer' }} onClick={onPrev} className="btn-prev disb"><span>이전 목록</span></a>
      }
      <span>
        {createPage(1)}
        {createPage(2)}
        {createPage(3)}
        {createPage(4)}
        {createPage(5)}
      </span>
      {
        step < maxStep &&
          <a style={{ cursor: 'pointer' }} onClick={onNext} className="btn-next"><span>다음 목록</span></a>
      }
    </div>
  );
}
