import JobCategoryForm from "@components/poll-form/JobCategoryForm";
import MemberCountForm from "@components/poll-form/MemberCountForm";
import ProjectDateForm from "@components/poll-form/ProjectDateForm";
import ProjectDomainForm from "@components/poll-form/ProjectDomainForm";
import ProjectTypeForm from "@components/poll-form/ProjectTypeForm";
import TagForm from "@components/poll-form/TagForm";
import ProjectFileForm from "@components/poll-form/ProjectFileForm";
import pollAPI from "@services/PollAPI";
import { useFormContext } from "react-hook-form";
import { PollProps } from "../PollPage";
import ProjectDescriptionForm from "@components/poll-form/ProjectDescriptionForm";
import { getParent } from "mobx-state-tree";
import { IAppStore } from "@stores/AppStore";
import axios from "axios";

const PollStep3 = ({
  pollStore
}: PollProps) => {
  const { register, watch, setValue, handleSubmit, getValues, formState: { isSubmitted } } = useFormContext();
  const isErrTitle = isSubmitted && !Boolean(watch('title'));
  const isErrExperience = isSubmitted && !Boolean(watch('experiences'));

  const { hideLoader, showLoader } = getParent(pollStore) as IAppStore;

  const data = pollStore.data?.questionnaire;
  const email = pollStore.data?.email;
  const candidateSearchId = pollStore.data?.candidate_search_id;
  const candidateSearchResultId = pollStore.data?.candidate_search_result_id;

  const isInculdedToSearching = pollStore.data?.candidate_search_id;
  const answers = watch('answers');

  const onNext = async (data: any) => {
    console.log(data);
    if (!validate(data)) {
      window.scrollTo(0, 0);
      return;
    }

    const finalData = makeData(data);
    console.log(finalData);

    try {
      showLoader();

      if (isInculdedToSearching) {
        await axios.put(`/candidate-searches/${candidateSearchId}/results/${candidateSearchResultId}/accept-meeting`, {
          accept_meeting: Boolean(parseInt(data.accept_meeting)),
        });
      }

      const { portfolio } = await pollAPI.postPoll(pollStore.data?.uuid || '', finalData);

      hideLoader();

      pollStore.setUUID(portfolio[0].uuid);
      pollStore.next();
    } catch (err: any) {
      alert(err.response.data.message);
      hideLoader();
    }
  };

  // 필수값 검사
  const validate = (data: any) => {
    const { title, job_categories, experiences } = data;
    const isValidJobCategory = Boolean(job_categories.find((v: any) => v.main_category && v.sub_category));

    return title && isValidJobCategory && experiences;
  }

  // 데이터 전처리 (API 스펙에 맞게 가공)
  const makeData = (_data: any) => {
    const experiences = typeof (_data.experiences) === 'string' ? [{ name: _data.experiences }] : _data.experiences.map((v: string) => ({ name: v }));
    const manualExperiences = (_data._skill_set || []).map((v: string) => ({ name: v }));

    return {
      answers: data?.questions.map((v, idx) => ({
        question: v.id,
        answer: _data.answers[idx] === '1',
      })),
      file: _data.file,
      portfolio: [{
        title: _data.title,
        description: _data.description,
        year: parseInt(_data.year),
        duration: parseInt(_data.duration),
        job_categories: _data.job_categories,
        experiences: [...experiences, ...manualExperiences],
        domain: _data.domain,
        type: parseInt(_data.type),
        coworking_member_count: parseInt(_data.coworking_member_count),
        reference_type: _data.reference_type,
        link: _data.link,
      }]
    };
  }

  const onSkip = async (_data: any) => {
    const finalData = {
      answers: data?.questions.map((v, idx) => ({
        question: v.id,
        answer: _data.answers[idx] === '1',
      })),
    };

    try {
      showLoader();

      if (isInculdedToSearching) {
        await axios.put(`/candidate-searches/${candidateSearchId}/results/${candidateSearchResultId}/accept-meeting`, {
          accept_meeting: Boolean(parseInt(_data.accept_meeting)),
        });
      }

      const { portfolio } = await pollAPI.postPoll(pollStore.data?.uuid || '', finalData);
      hideLoader();

      if (portfolio?.[0]) {
        pollStore.setUUID(portfolio[0].uuid);
      }
      pollStore.next();
    } catch (err: any) {
      if (err?.response?.data?.message) {
        alert(err.response.data.message);
      }

      hideLoader();
    }
  }

  return (
    <div className="poll-wrap">
      <div className="p-step">
        <span className="check">Step 1</span>
        <span className="check">Step 2</span>
        <strong>Step 3</strong>
      </div>
      <h2>
        {
          data?.regular_survey === 2 ?
            '프로필 업데이트하기'
            :
            '포트폴리오 만들기 🦄'
        }
      </h2>
      <div className="cnts type2">
        <p>WOW🔥 질문드린 항목 중 <em>{answers?.filter((v: string) => v === '1')?.length || 0}</em>개의 경험이 이미 있으시군요!</p>
        <p>
          {
            data?.regular_survey === 2 ?
              '더 빠르고 정확한 커리어 제안을 위해서, 이번 설문과정에서 생각난 프로젝트가 있다면 남겨주세요.'
              :
              `${email} 님에게 해당 경험을 만들어준 프로젝트나 답변을 하면서 생각난 대표 프로젝트를 알려주세요.`
          }
        </p>
        <p
          className="pay"
          style={{ textDecoration: 'underline', marginTop: 4, cursor: 'pointer', opacity: 0.8 }}
          onClick={handleSubmit(onSkip)}
        >
          이 단계 건너뛰기
        </p>
      </div>

      <div className="portfolio-input">
        <div className="fm-w">
          <label htmlFor="pjtN" className="tit">프로젝트 이름을 입력해주세요<i className="ness"><span>필수</span></i></label>
          <input type="text" id="pjtN" placeholder="프로젝트 이름" {...register('title')} />

          {
            isErrTitle &&
            <p className="err" style={{ color: 'red' }}>프로젝트 이름을 입력해주세요.</p>
          }
        </div>

        <div className="fm-w">
          <label htmlFor="pjtLev" className="tit">프로젝트에서 담당하신 역할은 무엇이셨나요?<i className="ness"><span>필수</span></i></label>
          <JobCategoryForm />
        </div>

        <div className="fm-w">
          <em className="tit">프로젝트에서 경험하신 것을 모두 선택해주세요<i className="ness"><span>필수</span></i></em>
          <div className="chk-li">
            {
              data?.questions.filter((v, idx) => watch('answers')[idx] === '1' && v.short_description).map((v, idx) => (
                <span><input type="checkbox" {...register('experiences')} id={`chk${idx}`} value={v.short_description} /><label htmlFor={`chk${idx}`}>{v.short_description}</label></span>
              ))
            }
          </div>
          {
            isErrExperience &&
            <p className="err" style={{ color: 'red' }}>하나 이상 선택해주세요.</p>
          }
        </div>

        <div className="fm-w">
          <label htmlFor="addChk" className="tit">위에 선택하신 것 외에 경험하신것이 있으시다면 추가해주세요.</label>
          <TagForm
            placeholder="텍스트 입력 후 엔터키를 눌러주세요(태그 입력)"
          />
        </div>
      </div>

      <div className="portfolio-input">
        <h3>여기서부터는 선택 입력 항목입니다.</h3>
        {/* <p className="desc">포트폴리오 페이지에서 입력 또는 수정하실 수 있습니다.</p> */}
        <div className="ques-li">
          <ul style={{ marginTop: 30 }}>
            <li>
              <p style={{ marginBottom: 8, fontWeight: 'bold' }}>프로젝트에서 함께 협업한 멤버수는 얼마나 되었나요?</p>
              <MemberCountForm />
            </li>
            <li>
              <p style={{ marginBottom: 8, fontWeight: 'bold' }}>어떤 유형의 프로젝트였나요?</p>
              <ProjectTypeForm />
            </li>
          </ul>
        </div>

        <div className="fm-w">
          <label htmlFor="date" className="tit">프로젝트에 참여한 연도와 기간을 알려주세요</label>
          <ProjectDateForm />
        </div>

        <div className="fm-w">
          <label htmlFor="cate" className="tit">어떤 분야의 프로젝트였나요?</label>
          <ProjectDomainForm />
        </div>

        <div className="fm-w">
          <label htmlFor="cnts" className="tit">프로젝트에 대한 소개를 입력해주세요</label>
          <ProjectDescriptionForm />
        </div>

        <div className="fm-w">
          <em className="tit">프로젝트에 대해 참고할 수 있는 URL이나 파일이 있으시면, 등록해주세요.</em>
          <ProjectFileForm />
        </div>

      </div>

      <div className="btn type2">
        <a href="#" className="sub" onClick={pollStore.prev}>이전</a>
        <a href="#" className="actv" onClick={handleSubmit(onNext)}>제출하기</a>
      </div>
    </div>
  );
}

export default PollStep3;
