import { useState } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import { useFormContext } from "react-hook-form";
import { numberWithCommas } from "../../utils/utils";

interface Props extends OnboardingProps {
  title?: string;
}

export default observer(({ profile, title }: Props) => {
  const { register, getValues, setValue, watch } = useFormContext();

  return (
    <>
      <h4><label htmlFor="compItd">{title}</label></h4>
      <div className="comp-intu">
        <textarea
          {...register('introduction')}
          maxLength={3000}
          id="compItd"
          defaultValue={profile.introduction}
          placeholder="서울과 네덜란드 암스테르담에서 프리랜서로 활동하고있는 UI 디자이너이자 기획자 입니다. 협업 툴과 리모트워크에 관심이 많으며, 현재 퍼블리싱을 공부하고 있습니다."
        />
        <span>{numberWithCommas((watch('introduction') || '').length)} / 3,000</span>
      </div>
    </>
  );
});
