import { types, Instance } from "mobx-state-tree";
import { StateModel } from '@models/models';
import {
  UserModel,
  LoginForm
} from '@pages/user/models';


// const FINAL_STEP = 3;
const UserStore = StateModel.named('UserStore')
  .props({
    user: types.optional(UserModel, { userId: -1 }),
    loginForm: types.optional(LoginForm, { email: '', password: '' }),
  });
export interface IUserStore extends Instance<typeof UserStore> {};

export default UserStore;
