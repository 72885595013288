import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IJoinAccountModel } from "@pages/join/models/JoinAccountModel";
import { IJobModel } from '@pages/onboarding/models/JobModel';
import constantAPI from "@services/constantAPI";
import { IMemberAccountModel } from "@pages/account/models/MemberAccountModel";

interface IProps {
  account?: IMemberAccountModel;
}

const JobCategoryForm: React.SFC<IProps> = (observer(({ account }) => {
  const [jobList, setJobList] = useState<IJobModel[]>([]);;
  const [rootJobList, setRootJobList] = useState<IJobModel[]>([]);

  const { control, watch, getValues, setValue, register, formState: { isSubmitted }, reset } = useFormContext();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    // control,
    name: 'job_categories',
  });

  const isErrJob = isSubmitted && Boolean(watch('job_categories').find((v: any) => !v.main_category || !v.sub_category));

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const jobList = await constantAPI.getCategoryList();
    const rootJobList = await constantAPI.getCategoryRootList();

    setJobList(jobList);
    setRootJobList(rootJobList);

    if (account) {
      setValue(
        'job_categories',
        JSON.parse(JSON.stringify(account.job_categories.toJSON())),
        { shouldDirty: false }
      );
      // for (const category of account.job_categories) {
      //   append({
      //     main_category: category.main_category,
      //     sub_category: category.sub_category,
      //   });
      // }
    }
  }

  const onAdd = () => {
    append({});
  };

  const onRemove = (index: number) => {
    remove(index);
  };

  return (
    <div className="job-sug" style={{ borderBottom: 0 }}>
      {
        fields.map(({ main_category, sub_category }: any, idx) => {
          const mainWatch = watch(`job_categories.${idx}.main_category`);
          const subWatch = watch(`job_categories.${idx}.sub_category`);

          const searchedJob = jobList.filter(v => v.parent === mainWatch && (!subWatch || v.name.includes(subWatch)));

          return (
            <div className="sel-form">
              <select {...register(`job_categories.${idx}.main_category`)}>
                {
                  Boolean(mainWatch) ||
                  <option value={''}>분야</option>
                }
                {
                  rootJobList.map(v => (
                    <option value={v.name}>{v.name}</option>
                  ))
                }
              </select>

              <div
                className="fm-box"
                style={{
                  width: '50%',
                  marginRight: 8,
                }}
              >
                <div className={`in-sel ${watch(`job_categories.${idx}._focus`) ? 'selected' : ''}`}>
                  <input
                    type="text"
                    {...register(`job_categories.${idx}.sub_category`)}
                    placeholder="역할"
                    disabled={!mainWatch}
                    onFocus={() => {
                      setValue(`job_categories.${idx}._focus`, true);
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        setValue(`job_categories.${idx}._focus`, false);
                      }, 200);
                    }}
                  />

                  <div className="opt-li" style={{ width: '100%' }}>
                    {
                      searchedJob.length < 1 &&
                      <ul>
                        <li>일치되는 항목 없음.</li>
                      </ul>
                    }

                    <ul>
                      {
                        searchedJob.map(v => (
                          <li onClick={() => setValue(`job_categories.${idx}.sub_category`, v.name)}>{v.name}</li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
              </div>


              {/* <select
                {...register(`job_categories.${idx}.sub_category`)}
                disabled={!mainWatch}
              >
                {
                  Boolean(mainWatch) ||
                  <option value={''}>역할</option>
                }
                {
                  jobList.filter(v => v.parent === mainWatch).map(v => (
                    <option value={v.name}>{v.name}</option>
                  ))
                }
              </select> */}

              <button
                type="button"
                style={{
                  opacity: idx > 0 ? 1 : 0,
                }}
                onClick={() => onRemove(idx)}
              >
                <span>해당 직무 삭제</span>
              </button>
            </div>
          );
        })
      }
      {
        isErrJob &&
        <p className="err" style={{ color: 'red' }}>역할을 입력해주세요.</p>
      }
      <button type="button" className="j-add" onClick={onAdd}>추가하기</button>
    </div>
  );
}));

export default JobCategoryForm;
