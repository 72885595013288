import { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import onboardingAPI from "../../services/onboardingAPI";
import { useFormContext } from "react-hook-form";

interface Props extends OnboardingProps {
  title?: string;
  text?: string;
}

export default observer(({ profile, title, text }: Props) => {
  const { register, getValues, setValue, watch } = useFormContext();
  
  return (
    <>
      <h4>{title}</h4>
      <div className="cho-rbox">
        <ul>
          <li><input type="radio" name="availability" id="jobH1" onClick={() => setValue('availability', 'HIGH')} defaultChecked={profile.availability === 'HIGH'} /><label htmlFor="jobH1">현재 적극적으로 구직중입니다.</label></li>
          <li><input type="radio" name="availability" id="jobH2" onClick={() => setValue('availability', 'MODERATE')} defaultChecked={profile.availability === 'MODERATE'} /><label htmlFor="jobH2">구직중은 아니나 좋은 제안은 언제나 환영입니다.</label></li>
          <li><input type="radio" name="availability" id="jobH3" onClick={() => setValue('availability', 'LOW')} defaultChecked={profile.availability === 'LOW'} /><label htmlFor="jobH3">현재 구직의향 없습니다.</label></li>
        </ul>
      </div>
    </>
  );
});
