import { types, Instance } from "mobx-state-tree";
import { type } from "os";

export const MessageMemberModel = types.model({
  avatar: types.maybeNull(types.string),
  nickname: types.maybe(types.string),
  is_active: types.maybe(types.boolean),
});

export const MessagePartnerModel = types.model({
  avatar: types.maybeNull(types.string),
  company_name: types.maybe(types.string),
  is_active: types.maybe(types.boolean),
});

export const MessageModel = types.model({
  uuid: types.string,
  title: types.string,
  recent_message: types.string,
  member: types.maybe(MessageMemberModel),
  partner: types.maybe(MessagePartnerModel),
  updated_at: types.maybeNull(types.string),
  status: types.string,
  has_unread_messages: types.maybe(types.boolean),
})
.views(self => ({
  
}));

export interface IMessageMemberModel extends Instance<typeof MessageMemberModel> {};
export interface IMessagePartnerModel extends Instance<typeof MessagePartnerModel> {};
export interface IMessageModel extends Instance<typeof MessageModel> {};
