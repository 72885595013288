import { useEffect, useState } from 'react';
import Loader from 'react-loader';

const EmailConfirmField = (props: {
  value: string,
  isVerified: boolean,
  message: string,
  onChange: (value: string) => void,
  onSendVerify: () => void,
  onCheckVerified: () => void,
  onClear: () => void,
}) => {
  const [waiting, setWaiting] = useState(false);
  const [minutes, setMinutes] = useState(10);
  const [seconds, setSeconds] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const [isPending, setIsPending] = useState(false);  // 이메일 인증 발송 시, 백앤드 응닶값 지연 처리를 위한 상태
  const isShowTimer = !props.message && !props.isVerified && waiting && !isPending;

  const btnLabel =
    props.isVerified ? '인증 완료' : waiting ? minutes === 0 && seconds === 0 ? '재전송' : '인증 확인' : '이메일 인증';


  useEffect(() => {
    if (waiting) {
      const countdown = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(countdown);
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [waiting, minutes, seconds, props.isVerified]);

  const handleResend = async () => {


    try {
      setIsPending(true);
      await props.onSendVerify();
      setIsPending(false);
      setMinutes(10);
      setSeconds(0);
      setWaiting(true);
    } catch (e) {
      setIsPending(false);
      setWaiting(false);
    }
  }

  const handleClick = async () => {
    setLoading(true);

    try {
      if (btnLabel === '이메일 인증' || btnLabel === '재전송') {
        await handleResend();
      } else if (btnLabel === '인증 확인') {
        await props.onCheckVerified();
      } else if (btnLabel === '인증 완료') {
        setWaiting(false);
        // setIsConfirm(true);
      }
    } catch (e) { }

    setLoading(false);
  }

  const handleClear = async () => {
    setWaiting(false);
    setIsPending(false);
    setMinutes(10);
    setSeconds(0);
    props.onChange('');
    props.onClear();
  };

  return (
    <li>
      <label htmlFor="eMail">이메일</label>
      <div className="e-mail">
        <input type="text" id="eMail" placeholder="example@seeso.kr"
          className={props.message ? 'err' : ''}
          value={props.value}
          onChange={(e) => {
            setWaiting(false);
            props.onChange(e.target.value.toLowerCase());
          }}
          disabled={isPending || props.isVerified} />

        {
          isLoading ||
            <button
              type="button"
              onClick={handleClick}
              disabled={isPending || props.value.length === 0 || props.isVerified}
            >
              {btnLabel}
            </button>
        }
        
        <Loader loaded={false} opacity={0.5} options={{ opacity: 0.5, color: '#5539ff', scale: 0.5 }}/>

        <span className="time">
          {
            isShowTimer &&
            `${minutes}:${seconds}`
          }

          {
            isShowTimer &&
            <button type="button" onClick={handleResend}>재전송</button>
          }

          {
            props.isVerified &&
            <button type="button" onClick={handleClear}>초기화</button>
          }
        </span>
      </div>
      {
        props.message ?
          <p className="err">{props.message}</p>
          : btnLabel === '재전송' &&
          <p className="err">인증 시간이 만료되었습니다. 재전송 버튼을 누르세요.</p>
      }
    </li>
  )
}

export default EmailConfirmField;