import { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import onboardingAPI from "../../services/onboardingAPI";
import { useFormContext } from "react-hook-form";

interface Props extends OnboardingProps {
  title?: string;
  text?: string;
}

export default observer(({ profile, title, text }: Props) => {
  const { register, getValues, setValue, watch, formState: { isSubmitted } } = useFormContext();
  const [isNameErr, setNameErr] = useState(false);
  const isErr = isSubmitted && !watch('nickname')?.length;

  useEffect(() => {
    checkNicknameDuplication();
  }, [watch('nickname')]);

  const checkNicknameDuplication = async () => {
    const name = getValues('nickname');
    if (!name || profile.nickname === name) {
      return;
    }

    const { is_duplicate } = await onboardingAPI.checkNicknameDuplication({ nickname: name });
    setNameErr(is_duplicate);
  };
  

  return (
    <>
      <h4>{title}</h4>
      <div className="comp-intu">
        <input
          type="text"
          className={isErr ? 'err' : ''}
          id="nick"
          {...register('nickname', { setValueAs: (value: string) => value.replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi, '') })}
          value={watch('nickname')}
          placeholder="특수문자 제외 2자 이상"
          defaultValue={profile.nickname}
        />
        {
          isErr ?
          <p className="err">닉네임을 입력해주세요.</p>
          :
            (getValues('nickname') && profile.nickname !== getValues('nickname')) ?
              isNameErr ?
                <p className="err">이미 사용중인 닉네임입니다.</p>
              :
                <p className="ok">사용 가능한 닉네임입니다.</p>
            :
              <p>{text}</p>
          }
      </div>
    </>
  );
});
