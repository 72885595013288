/*
  markup : login.html
*/

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";

import {
  EmailField,
  PasswordField
} from './components';
import { IAppStore } from "../../stores/AppStore";
import { useQuery } from '../../utils/hooks';
import NewPortfolioModal from '@modals/NewPortfolioModal';
import { MEMBER_WEBURL, PARTNER_WEBURL } from '@utils/constants';

interface IProps {
  appStore: IAppStore;
  history: any
}

const Login: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const query = useQuery();
  const { userStore } = (props as IProps).appStore;
  const { loginForm, user } = userStore;
  const [isOpenSurvey, setOpenSurvey] = useState(false);
  const {
    email,
    isError,
    isActivated,
    password,
    reset,
    setEmail,
    setPassword,
    login
  } = loginForm;

  useEffect(() => {
    reset();
  }, []);

  const onLogin = async () => {
    try {
      const { user, token }: any = await login();
      if (user.user_type === 'MEMBER') {
        window.location.href = `${MEMBER_WEBURL}?token=${token}`;
        return;
      } else if (user.user_type === 'PARTNER') {
        window.location.href = `${PARTNER_WEBURL}?token=${token}`;
        return;
      }

      props.history.push('/');
    } catch (e: any) {
      if (e.response.data.code === 'ACCOUNT_400_EMAIL_NOT_VERIFIED') {
        alert('가입된 계정이 아닙니다. 회원가입을 해주세요. ');
      }

      if (e.response.data.code === 'ACCOUNT_400_INVALID_CREDENTIALS') {
        alert('이메일 또는 비밀번호를 다시 확인해주세요.');
      }
    }
  }

  return (
    <>
      <div className="join-sign">
        <div className="visual">
          <div className="inner">
            <img src="images/join_img03.png" alt="" />
          </div>
        </div>

        <div className="join-w">
          <div className="inner">
            <h2>세컨팀 <br />로그인</h2>
            <div className="join-form">

              {isActivated &&
                <div className="chk-inc">
                  <p>이메일 인증이 완료되었습니다.</p>
                </div>}

              <ul className="fm-list">
                <EmailField
                  isError={isError}
                  value={email}
                  onChange={(email: string) => setEmail(email)} />

                <PasswordField
                  isError={isError}
                  value={password}
                  showErrorMessage={true}
                  onChange={(password: string) => setPassword(password)} />
              </ul>

              <div className="btn">
                <a href="#" className="actv" onClick={onLogin}>
                  로그인
                </a>
                <Link to="/join">회원가입</Link>
              </div>
              <a
                href="/password/reset"
                style={{
                  display: 'block',
                  fontSize: 13,
                  textDecoration: 'underline',
                  color: '#bdbdbd',
                  cursor: 'pointer',
                  marginTop: 26,
                }}
              >
                비밀번호를 잃어버렸어요
              </a>
            </div>
          </div>
        </div>
      </div>

      <NewPortfolioModal
        nickname={'닉네임'}
        isOpen={isOpenSurvey}
      />
    </>
  )
}));

export default Login;
