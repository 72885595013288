import { IWorkPreference } from '@models/WorkPreference';
import { IJobModel } from '@pages/onboarding/models/JobModel';
import { API_BASEURL } from '@utils/constants';
import axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';

// constants API 캐싱 전용 axios Instance
const axiosInstance = axios.create({
  baseURL: API_BASEURL,
  Accept: 'application/json',
  headers: {
    'Cache-Control': 'no-cache',
  },
  adapter: cacheAdapterEnhancer(
    axios.defaults.adapter!,
    { enabledByDefault: false },
  ),
} as any);

const constantAPI = {
  getAll: async () => {
    const { data }: { data: any } = await axiosInstance.get(
      `/constants/all`,
      {
        cache: true,
        forceUpdate: false,
      }
    );

    return data;
  },
  getWorkPreferenceList: async (): Promise<IWorkPreference[]> => {
    const { work_preferences } = await constantAPI.getAll();
    return work_preferences || [];
  },
  getJobAllList: async (): Promise<IJobModel[]> => {
    const { jobs } = await constantAPI.getAll();
    return jobs;
  },
  getJobRootList: async (): Promise<IJobModel[]> => {
    const { jobs } = await constantAPI.getAll();
    return jobs.filter((v: any) => v.parent.length === 0);
  },
  getJobList: async (): Promise<IJobModel[]> => {
    const { jobs } = await constantAPI.getAll();
    return jobs.filter((v: any) => v.parent.length > 0);
  },
  getCategoryRootList: async (): Promise<IJobModel[]> => {
    const { categories } = await constantAPI.getAll();
    return categories.filter((v: any) => v.parent.length === 0);
  },
  getCategoryList: async (): Promise<IJobModel[]> => {
    const { categories } = await constantAPI.getAll();
    return categories.filter((v: any) => v.parent.length > 0);
  },
  getMemberCount: async (): Promise<number> => {
    const { data } = await axios.get('/members/count/next');
    return data?.count || 0;
  },
  getTotalStatistic: async(): Promise<any> => {
    const { data } = await axios.get('/secondteam/statistic/total');
    return data;
  },
};

export default constantAPI;
