import { observer } from "mobx-react";
import { ISuggestForm } from "../RecruitSuggestionModifyPage";
import { useController, useFormContext } from "react-hook-form";
import { useState } from "react";
import { toolList } from "../../../utils/constants";

export default observer(({ title, data }: ISuggestForm) => {
  const { register, getValues, setValue, watch, control, formState: { isSubmitted } } = useFormContext();
  const [isFocus, setFocus] = useState(false);
  const isErr = isSubmitted && !watch('tools').length;

  const toolWatcher = watch('_tool') || '';
  const tool = useController({
    name: 'tools',
    control,
    defaultValue: [],
  });
  // @ts-ignore
  const toolValue: string[] = tool?.field?.value || [];
  const searchedTool = toolList.filter(v => v.toLowerCase().includes(toolWatcher.toLowerCase()));

  const onSelect = (name: string) => {
    const values: string[] = getValues('tools') || [];
    const results = Array.from(new Set([...values, name]));
    tool.field.onChange(results);

    setValue('_tool', '');
  };

  const remove = (name: string) => {
    const values: string[] = getValues('tools') || [];
    const results = values.filter(v => v !== name);
    tool.field.onChange(results);
  };

  return (
    <div className="in-cnts">
      <h4>{title}</h4>

      <div className="sel-form">
        <div className="fm-box" style={
          isErr ? {
            background: 'rgba(255,78,22,0.05)',
            border: '1px solid #ff4e16',
          } : {}
        }>
          {
            toolValue.map((v: string) => (
              <span>{v}<button type="button" onClick={() => remove(v)}><span>삭제</span></button></span>
            ))
          }
          <div className={`in-sel ${isFocus ? 'selected' : ''}`}>
            <input
              type="text"
              placeholder="사용 가능한 툴을 입력해주세요."
              {...register('_tool')}
              onBlur={() => setTimeout(() => {
                setFocus(false);
                setValue('_tool', '');
              }, 200)}
              onFocus={() => setFocus(true)}
              autoComplete={'off'}
            />

            {
              isFocus &&
              <div className="opt-li">
                {
                  searchedTool.length < 1 &&
                  <ul>
                    <li>일치되는 항목 없음.</li>
                  </ul>
                }

                <ul>
                  {
                    searchedTool.map(v => (
                      <li className="selected" onClick={() => onSelect(v)}>{v}</li>
                    ))
                  }
                </ul>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
});
