/*
  markup : mypage_member_modify.html
*/

import EditPrompt from '@components/EditPrompt';
import JobCategoryForm from "@components/poll-form/JobCategoryForm";
import MemberCountForm from "@components/poll-form/MemberCountForm";
import ProjectDateForm from "@components/poll-form/ProjectDateForm";
import ProjectDescriptionForm from "@components/poll-form/ProjectDescriptionForm";
import ProjectDomainForm from "@components/poll-form/ProjectDomainForm";
import ProjectFileForm from "@components/poll-form/ProjectFileForm";
import ProjectTypeForm from "@components/poll-form/ProjectTypeForm";
import TagForm from "@components/poll-form/TagForm";
import membersAPI from '@services/membersAPI';
import profileAPI from '@services/profileAPI';
import { inject, observer } from "mobx-react";
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { IAppStore } from "../../stores/AppStore";
import { IProfileStore } from '../../stores/profileStore';
import { makeProjectData } from "../../utils/utils";
import { IEpisodeModel } from './models/MemberProfileModel';


interface IProps {
  appStore: IAppStore;
  history: any
}

const MemberProjectFormPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const history = useHistory();
  const { profileStore, showLoader, hideLoader } = (props as IProps).appStore;
  const { memberProfile: profile, getMemberProfile: getProfile, getMemberAccount: getAccount, updateProfile, updateProject, createProject } = (profileStore as IProfileStore);
  const [data, setData] = useState<IEpisodeModel>();

  const form = useForm<any>({
    defaultValues: {
      job_categories: [{}],
    }
  });
  const { register, setError, watch, handleSubmit, setFocus, setValue, formState: { isSubmitted, isDirty } } = form;

  const isOnboarding = window.location.pathname === '/onboarding';
  const isTitleErr = window.location.pathname !== '/onboarding' && isSubmitted && !watch('title');
  const projectHash = window.location.hash.replace('#', '');

  useEffect(() => {
    load();
    getProfile();
  }, []);

  const load = async () => {
    if (projectHash) {
      const data = await membersAPI.getMemberEpisode(projectHash);
      setData(data);

      for (const [k, v] of Object.entries(data)) {
        setValue(k, v);
      }
    }
  }

  if (!profile || !(data || !projectHash)) {
    return <></>;
  }

  const onCancel = () => {
    history.goBack();
  };

  const validate = (episode: any) => {
    if (!episode.title) {
      setFocus('title');
      return false;
    }

    if (!episode.job_categories.find((v: any) => v.main_category && v.sub_category)) {
      setFocus('job_categories.0.sub_category');
      return false;
    }

    if (episode._skill_set.length < 1) {
      setFocus('_skill_set_text');
      return false;
    }

    return true;
  }

  const onSave = async (_data: any) => {
    try {
      showLoader();

      const projectHash = window.location.hash.replace('#', '');
      let project: any = data;
      let episodeUUID = '';

      if (project) {
        if (!validate(_data)) {
          // alert('미입력된 항목이 있습니다.');
          console.log('멤버 프로젝트 저장 - 미입력된 항목 감지');
          hideLoader();
          return;
        }

        const finalData = makeProjectData(_data);
        await updateProject(project.uuid, finalData);

        episodeUUID = projectHash;
      } else {
        if (!validate(_data)) {
          alert('미입력된 항목이 있습니다.');
          hideLoader();
          return;
        }

        const finalData = makeProjectData(_data);
        console.log('=== finalData ===');
        console.log(finalData);
        project = await createProject(finalData);

        episodeUUID = project.uuid;
      }

      // 파일 관련
      const { file, _fileDelete } = _data;

      if (file && typeof (file) !== 'string') {
        // 파일 첨부있음
        await profileAPI.createMemberProjectFile(profile.uuid, episodeUUID, file);
      } else if (_fileDelete) {
        // 파일 삭제 있음
        await profileAPI.removeMemberProjectFile(profile.uuid, episodeUUID);
      }

      hideLoader();

      const hashName = window.location.hash.replace('#', '');
      window.location.href = '/member/profile#' + hashName;
    } catch (err) {
      hideLoader();
      console.log(err);
      return;
    }
  };

  return (
    <FormProvider {...form}>
      <div className="sub-wrap" style={{ paddingBottom: 128 }}>
        <div className="top-box">
          <h2>포트폴리오 추가/수정하기</h2>
        </div>
        <div className="myp-incnts wtype2">
          <h3 className="tit">포트폴리오</h3>
          <div className="in-cnts poll-wrap" style={{ border: 'none', padding: 0, marginTop: isOnboarding ? 0 : 32 }}>
            <h5><label htmlFor="pjpName">프로젝트 명*</label></h5>
            <div className="comp-intu">
              <input
                className={isTitleErr ? 'err' : ''}
                {...register('title')}
                type="text"
                id="pjpName"
                placeholder="프로젝트 명을 입력해주세요."
              />
            </div>

            <h5><label htmlFor="pjpDm">프로젝트에서의 역할*</label></h5>
            <JobCategoryForm
            // formKey={`episodes.${index}.job_categories`}
            />

            <h5><label htmlFor="jobJ">프로젝트에서 경험한것(언어 및 프레임워크, 툴, 해결한 문제, 스킬 등)*</label></h5>
            <TagForm
              // formKey={`episodes.${index}._skill_set`}
              project={data ? profile.episodes[0] : undefined}
              placeholder="텍스트 입력 후 엔터키를 눌러주세요(태그 입력)"
            />

            <div className="ques-li portfolio-input">
              <h4 className="smtit" style={{ fontSize: 14, marginTop: 12 }}>선택 입력 항목</h4>
              <h5>함께 협업한 멤버 수</h5>
              <ul style={{ margin: 0 }}>
                <li style={{ margin: 0 }}>
                  <MemberCountForm />
                </li>
              </ul>

              <h5>프로젝트 유형</h5>
              <ul style={{ margin: 0 }}>
                <li style={{ margin: 0 }}>
                  <ProjectTypeForm />
                </li>
              </ul>

              <div className="fm-w">
                <h5 style={{ marginBottom: 8 }}>시작 연도와 기간</h5>
                <ProjectDateForm
                  project={data}
                />
              </div>

              <div className="fm-w">
                <h5 style={{ marginBottom: 8 }}>프로젝트 분야</h5>
                <ProjectDomainForm />
              </div>

              <div className="fm-w">
                <h5 style={{ paddingBottom: 24 }}>프로젝트 소개</h5>
                <ProjectDescriptionForm />
              </div>

              <div className="fm-w">
                <h5 style={{ marginBottom: 8 }}>프로젝트에 대해 참고할 수 있는 URL이나 파일이 있으시면, 등록해주세요.</h5>
                <ProjectFileForm
                  fileName={data?.file?.split('/').pop() || ''}
                />
              </div>
            </div>
          </div>

          <div className="btn sticky">
            <div className="inner">
              <a style={{ cursor: 'pointer' }} onClick={onCancel} className="sub cel">취소</a>
              <a style={{ cursor: 'pointer' }} onClick={handleSubmit(onSave)} className="actv save">저장</a>
            </div>
          </div>

          {/* 프로젝트 삭제 버튼 */}
          <p
            style={{
              display: 'block',
              fontSize: 13,
              textDecoration: 'underline',
              color: '#bdbdbd',
              cursor: 'pointer',
              marginTop: 26,
            }}
            onClick={async () => {
              if (window.confirm('정말로 삭제하시겠습니까?')) {
                await profileAPI.deleteMemberProject(profile.uuid, projectHash);
                window.location.href = '/member/profile';
              }
            }}
          >
            삭제하기
          </p>
        </div>
      </div>

      <EditPrompt
        when={isDirty}
      />
    </FormProvider>
  )
}));

export default MemberProjectFormPage;
