import { flow, types, Instance } from "mobx-state-tree";
import jwtDecode from "jwt-decode";
import axios from "axios";

import { StateModel } from '../../../models/models';
import userAPI from '../../../services/userAPI';
import accountAPI from '../../../services/accountAPI';
import { IProfileResponse, IAuthToken } from '../../../interfaces/userTypes';


import {
  AUTH_TOKEN_KEY,
  AUTH_UUID,
  AUTH_USERTYPE
} from '../../../utils/constants';
import { IMemberAccountModel } from "../../account/models/MemberAccountModel";
import { IPartnerAccountModel } from "../../account/models/PartnerAccountModel";
import profileAPI from "../../../services/profileAPI";
import { IMemberProfileModel } from "../../profile/models/MemberProfileModel";
import { IPartnerProfileModel } from "../../profile/models";

const UNAUTHORIZED_CODE = 401;

export const UserModel = StateModel.named('UserModel')
  .props({
    userId: types.number,
    username: types.optional(types.string, ''),
    nickname: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    token: types.optional(types.string, ''),
    userType: types.optional(types.string, ''),
    uuid: types.optional(types.string, ''),
    hasUnreadMessages: types.optional(types.boolean, false),
  })
  .actions(self => ({
    setUsername(username: string) {
      self.username = username;
    },
    setNickname(nickname: string) {
      self.nickname = nickname;
    },
    setEmail(email: string) {
      self.email = email;
    },
    setUserId(userId: number) {
      self.userId = userId;
    },
    setToken(token: string) {
      self.token = token;
    },
    setUserType(userType: string) {
      self.userType = userType;
    },
    setUuid(uuid: string) {
      self.uuid = uuid;
    },
    setUnread(value: boolean) {

    },
    setTokenFromCookie() {
      const token = window.localStorage.getItem(AUTH_TOKEN_KEY) || '';
      const uuid = window.localStorage.getItem(AUTH_UUID) || '';

      if(token) {
        const decoded = jwtDecode(token) as IAuthToken;

        self.token = token;
        self.userId = decoded.user_id;
        self.username = decoded.name;
        self.email = decoded.username;
        if(uuid) {
          self.uuid = uuid;
        }
        axios.defaults.headers.common["Authorization"] = `Bearer ${ token }`;
      }
    },
    seveTokenToCookie() {
      if(self.token) {
        window.localStorage.setItem(AUTH_TOKEN_KEY, self.token);
      }
      if(self.uuid) {
        window.localStorage.setItem(AUTH_UUID, self.uuid);
        window.localStorage.setItem(AUTH_USERTYPE, self.userType);
      }
    },
    removeTokenFromCookie() {
      window.localStorage.removeItem(AUTH_TOKEN_KEY);
      window.localStorage.removeItem(AUTH_UUID);
      window.localStorage.removeItem(AUTH_USERTYPE);
    }
  }))
  .actions(self => ({
    getProfile: flow(function* getProfile() {
      if(self.userId < 0)
        return;
      
      self.setPending();
      try {
        const profile: IProfileResponse = yield userAPI.getUserByID(self.userId);
        self.setDone();
        self.setUsername(profile.name);
      } catch(e) {
        if(axios.isAxiosError(e) && e.response && e.response.status === UNAUTHORIZED_CODE) {
          self.removeTokenFromCookie();
        }
      }
    }),
    getUser: flow(function* () {
      let user: IMemberAccountModel | IPartnerAccountModel;
      let profile: IMemberProfileModel | IPartnerProfileModel;

      if (!self.userType) {
        return [{}, {}];
      }

      if (self.userType === 'MEMBER') {
        user = yield accountAPI.getMemberAccount(self.uuid);
        profile = yield profileAPI.getMemberProfile(self.uuid);
      } else {
        user = yield accountAPI.getPartnerAccount(self.uuid);
        profile = yield profileAPI.getPartnerProfile(self.uuid);
      }

      self.setUsername(user.name);
      if (self.userType === 'MEMBER') {
        self.setNickname((profile as IMemberProfileModel).nickname || profile.company_name || '');
      } else {
        self.setNickname(profile.company_name || (profile as IMemberProfileModel).nickname || '');
      }

      self.setUnread(profile.has_unread_messages);

      return [user, profile];
    }),
    logout: () => {
      self.setToken('');
      self.setUserId(-1);
      self.setUsername('');
      self.removeTokenFromCookie();
    }
  }));
export interface IUserModel extends Instance<typeof UserModel> {};