import FreeNoticeModal from "@modals/FreeNoticeModal";
import NewPortfolioModal from "@modals/NewPortfolioModal";
import { IMemberAccountModel } from "@pages/account/models/MemberAccountModel";
import { IPartnerAccountModel } from "@pages/account/models/PartnerAccountModel";
import { IMemberProfileModel, IPartnerProfileModel } from "@pages/profile/models";
import * as Sentry from "@sentry/browser";
import axios from 'axios';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useChannelIOApi } from 'react-channel-plugin';
import { isMobile as _isMobile } from "react-device-detect";
import { useLocation } from 'react-router-dom';
import { IUserModel } from '../pages/user/models';
import TopBanner from "./TopBanner";
import SkipNav from "./SkipNav";
import { AUTH_USERTYPE, MEMBER_WEBURL, PARTNER_WEBURL } from "@utils/constants";
import styled from "styled-components";

const BGTYPE_REG = /login|join/;
const BGTYPE_PURPLE_REG = /account|profile|members|partners|recruits|calc|onboarding|invitation|messages|reset|edit|poll|openForm|list|projects\//;

const isOnboarding = window.location.pathname === '/onboarding'; // 온보딩 상태 여부
const isPending = Boolean(window.localStorage.getItem('pending')); // 회사 가입승인 여부
const isHideMenu = isOnboarding || isPending; // Menu 노출 여부

// 헤더 밑에 보더가 필요한 경우
const isMain = window.location.pathname === '/';
const isBorder = ['/', '/faq', '/skills', '/find', '/career/pool', '/career/survey', '/projects', '/explorer', '/clubs', '/companies'].includes(window.location.pathname) || window.location.pathname.includes('/career/pool') || window.location.pathname.includes('/projects/');

const MyButton = styled.a`
  border: 1px white solid;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  margin-right: -34px;
`;

interface IProps {
  user: IUserModel
}

const Header = observer((props: IProps) => {
  const { pathname } = useLocation();
  const { username, userType, token, hasUnreadMessages, getUser, logout } = props.user;
  const { updateUser } = useChannelIOApi();

  const [isMobile, setMobile] = useState(_isMobile);
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [isOpenAlarm, setOpenAlarm] = useState(false);
  const [surveyNickname, setSurveyNickname] = useState('');
  const [hasProposal, setProposal] = useState(false);

  const isAuth = Boolean(token);
  const isHide = /verify/.test(pathname);

  const cls = classNames({
    'landing-h lg-before': isBorder && !isAuth,
    'landing-h': isBorder && isAuth,
    'bgtype': BGTYPE_REG.test(pathname),
    'bgtype2': BGTYPE_PURPLE_REG.test(pathname)
  });
  const isWhite = !cls.includes('bgtype2');

  const handleResize = () => {
    setMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    load();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const load = async () => {
    const [account, profile]: any = await getUser();

    // 채널톡/센트리 유저 정보 연동
    if (userType === 'MEMBER') {
      const accounData = account as IMemberAccountModel;
      const profileData = profile as IMemberProfileModel;
      const name = `${accounData.name}(${profileData.nickname})`;

      updateUser({
        profile: {
          name,
          email: accounData.email,
          mobileNumber: accounData.phone,
          avatarUrl: profileData.avatar || '',
        },
        tags: [
          userType,
          `${window.location.origin}/members/${profileData.uuid}`,
        ]
      });

      Sentry.setUser({
        id: profileData.uuid,
        email: accounData.email,
        username: name,
      });
    }

    if (userType === 'PARTNER') {
      const accounData = account as IPartnerAccountModel;
      const profileData = profile as IPartnerProfileModel;

      updateUser({
        profile: {
          name: profileData.company_name,
          email: accounData.email,
          mobileNumber: accounData.phone,
          avatarUrl: profileData.avatar || '',
        },
        tags: [
          userType,
          `${window.location.origin}/members/${profileData.uuid}`,
        ]
      });

      Sentry.setUser({
        id: profileData.uuid,
        email: accounData.email,
        username: profileData.company_name,
      });
    }

    if (!profile.profile_completed) {
      // window.location.href = '/onboarding';
      window.localStorage.setItem('onboarding', 'OK');
    } else {
      window.localStorage.removeItem('onboarding');
    }

    if (profile.survey_updated) {
      setSurveyNickname(profile.nickname || '멤버');
    }

    if (profile.has_project_hiring_permission) {
      setProposal(true);
    }
  }

  if (isHide) {
    return <></>;
  }

  const isCenter = window.location.pathname === '/skills' || window.location.pathname === '/find' || window.location.pathname === '/projects';
  const isProjectDetail = window.location.pathname.includes('/projects/');

  return (
    <>
      <SkipNav />
      <TopBanner />

      <header
        className={cls}
        style={isCenter ? { padding: 16, alignItems: 'center' } : isProjectDetail ? { borderBottom: 'none', marginTop: -64 } : {}}
      >
        <h1>
          <a href={'#'} onClick={() => window.location.href = isMain ? window.location.href : '/'}>
            <span>SECOND TEAM</span>
          </a>
        </h1>

        <div className="mem-w">
          {
            isAuth &&
            <MenuWithAuth
              username={username}
              isWhite={isWhite}
              isOpenAlarm={isOpenAlarm}
              hasUnread={hasUnreadMessages}
              onClickMenu={() => {
                setShowDropdownMenu(!showDropdownMenu);
                setOpenAlarm(false);
              }}
              onClickAlarm={() => {
                setOpenAlarm(!isOpenAlarm);
                setShowDropdownMenu(false);
              }}
            />
          }
        </div>

        {
          ((isAuth || isMobile) && userType !== 'MEMBER') &&
          <button
            type="button"
            className="btn-menu"
            style={window.location.pathname === '/skills' || window.location.pathname === '/find' || window.location.pathname === '/projects' ? { position: 'absolute', display: 'block' } : { display: 'block' }}
            onClick={() => {
              setShowDropdownMenu(!showDropdownMenu);
              setOpenAlarm(false);
            }}
          >
            <span>메뉴열림</span>
          </button>
        }

        {
          isBorder &&
          <div
            className={`nav-w ${showDropdownMenu ? 'selected' : ''}`}
            style={{ top: 0 }}
            onClick={() => setShowDropdownMenu(false)}
          >
            <div className={`inner-w ${(isMobile || !isAuth) ? '' : 'inner'}`} style={{ color: '#26262A !important' }}>
              <nav style={{ display: 'block', marginTop: isBorder ? 0 : 8 }}>
                <AuthMenu
                  userType={userType}
                  isMobile={isMobile}
                  isWhite={isWhite}
                  isAuth={isAuth}
                  hasProposal={hasProposal}
                />
              </nav>

              {
                isAuth ?
                  <div className="inbox">
                    <div className={`utill ${showDropdownMenu ? 'selected' : ''}`}>
                      <DropdownMenu
                        username={username}
                        userType={userType}
                        hasProposal={hasProposal}
                        isAuth={isAuth}
                        onClickMenu={() => setShowDropdownMenu(false)}
                        onClickLogout={() => {
                          setShowDropdownMenu(false);
                          window.localStorage.removeItem('onboarding');
                          window.localStorage.removeItem('pending');
                          logout();
                        }}
                      />
                    </div>
                  </div>
                  :
                  <div className={`m-inner`}>
                    <DropdownMenu
                      username={username}
                      userType={userType}
                      hasProposal={hasProposal}
                      isAuth={isAuth}
                      onClickMenu={() => setShowDropdownMenu(false)}
                      onClickLogout={() => {
                        setShowDropdownMenu(false);
                        window.localStorage.removeItem('onboarding');
                        window.localStorage.removeItem('pending');
                        logout();
                      }}
                    />
                  </div>
              }
            </div>
          </div>
        }

        {
          isBorder ||
          <div className={`inner ${showDropdownMenu ? 'selected' : ''}`}>
            <div className="inbox" style={{ color: '#26262A !important' }}>
              <nav style={{ display: 'block', marginTop: isBorder ? 0 : 8 }}>
                <AuthMenu
                  userType={userType}
                  isMobile={isMobile}
                  isAuth={isAuth}
                  isWhite={isWhite}
                  hasProposal={hasProposal}
                />
              </nav>

              {
                isBorder ||
                <button type="button" className="btn-close" onClick={() => setShowDropdownMenu(!showDropdownMenu)}></button>
              }

              <div className={`utill ${showDropdownMenu ? 'selected' : ''}`}>
                <DropdownMenu
                  username={username}
                  userType={userType}
                  isAuth={isAuth}
                  hasProposal={hasProposal}
                  onClickMenu={() => setShowDropdownMenu(false)}
                  onClickLogout={() => {
                    setShowDropdownMenu(false);
                    window.localStorage.removeItem('onboarding');
                    window.localStorage.removeItem('pending');
                    logout();
                  }}
                />
              </div>
            </div>
          </div>
        }
      </header>


      <NewPortfolioModal
        nickname={surveyNickname}
        isOpen={surveyNickname.length > 0}
      />
    </>
  )
});

export default Header;

const AuthMenu = observer(({ hasProposal, userType, isAuth, isWhite }: any) => {
  const [candidateId, setCandidateId] = useState(0);

  if (isHideMenu) {
    return <></>;
  }

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';
    if (userType !== 'PARTNER') {
      return;
    }

    const { data } = await axios.get(`/user/candidate-pools`);
    console.log(data);
    if (data.length < 1) {
      return;
    }

    setCandidateId(data[0].id);
  };

  const isProjectDetail = window.location.pathname.includes('projects/');

  return (
    <ul style={{ marginLeft: isBorder ? isAuth ? 0 : 12 : 0 }}>
      {
        Boolean(userType) ||
        <>
          <li style={{ marginLeft: isBorder ? isAuth ? 0 : 16 : 0 }}>
            <a href={`/how`} style={{ color: isProjectDetail ? 'white' : 'auto' }}>
              서비스 소개
            </a>
          </li>
          <li style={{ marginLeft: isBorder ? isAuth ? 0 : 16 : 0 }}>
            <a href="https://medium.com/@secondteam" 
              target="_blank"
              style={{ color: isProjectDetail ? 'white' : 'auto' }}>
              협업사례
            </a>

            {/* <a href="/companies" style={{ color: isProjectDetail ? 'white' : 'auto' }}>
              협업사례
              <span style={{ color: isWhite ? '#5539ff' : '#ff4e16' }}>Beta</span>
            </a> */}
          </li>
        </>
      }

      {
        userType === 'PARTNER' &&
        <>
          {/* <li style={{ margin: 0 }}>
            <a href="/candidates" style={{ color: isProjectDetail ? 'white' : 'auto' }}>
              인재 서칭
            </a>
          </li>

          <li style={{ margin: 0 }}>
            <a href="/companies" style={{ color: isProjectDetail ? 'white' : 'auto' }}>
              기업 인재풀{' '}
              <span style={{ color: isWhite ? '#5539ff' : '#ff4e16' }}>Beta</span>
            </a>
          </li> */}
          {/* <li style={{ margin: 0 }}>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLScYVpkYEdNpfqHd1xEbG3Me3u61dD0qrxsM5DI2tQiCjsAhjA/viewform" target="_blank" style={{ color: isProjectDetail ? 'white' : 'auto' }}>
                서비스 신청
              </a>
            </li> */}
        </>
      }

      {/* {
        userType === 'PARTNER' &&
        <>
          {
            hasProposal ?
              <li style={{ margin: 0 }}><a href="/members">멤버 찾기</a></li>
              :
              <li style={{ margin: 0, cursor: 'pointer' }} onClick={() => alert('멤버찾기 기능은 엔터프라이즈 회사를 대상으로 제공됩니다.')}>
                <a>멤버 찾기</a>
              </li>
          }
        </>
      } */}

      {
        Boolean(userType) ||
        <>
          {/* <li style={{ marginLeft: isBorder ? isAuth ? 0 : 16 : 0 }}>
            <a href={`/projects`} style={{ color: isProjectDetail ? 'white' : 'auto' }}>프로젝트</a>
          </li>

          <li style={{ marginLeft: isBorder ? isAuth ? 0 : 16 : 0 }}>
            <a href={`/explorer`} style={{ color: isProjectDetail ? 'white' : 'auto' }}>둘러보기</a>
          </li> */}
        </>
      }

      {/* {
        candidateId > 0 &&
        <li style={{ marginLeft: isBorder ? isAuth ? 0 : 16 : 0 }}><a href={`/career/pool/${candidateId}/list`}>인재풀 관리</a></li>
      } */}

      {
        userType === 'PARTNER' &&
        <>
          <li style={{ margin: 0 }}><a href={`${PARTNER_WEBURL}/recruits`}>AI리쿠르터</a></li>

          {
            hasProposal &&
            <>
              <li style={{ margin: 0 }}><a href="/recruits">마이 세컨팀</a></li>
              <li style={{ margin: 0 }}><a href={`/recruits/${userType.toLowerCase()}`}>제안 현황</a></li>
              <li style={{ margin: 0 }}><a href="/calc/partner">정산 관리</a></li>
            </>
          }
        </>
      }

      {/* {
        userType === 'PARTNER' &&
        <>
          <li style={{ marginLeft: isBorder ? isAuth ? 0 : 16 : 0 }}>
            <a href={`/projects`}>프로젝트</a>
          </li>

          <li style={{ marginLeft: isBorder ? isAuth ? 0 : 16 : 0 }}>
            <a href={`/explorer`}>둘러보기</a>
          </li>
        </>
      } */}

      {/* {
        userType !== 'PARTNER' &&
        <li style={{ marginLeft: isBorder ? isAuth ? 0 : 16 : 0 }}>
          <a href="/career/survey" style={{ color: isProjectDetail ? 'white' : 'auto' }}>커리어 설문</a>
        </li>
      } */}
      {/* <li style={{ marginLeft: isBorder ? isAuth ? 0 : 16 : 0 }}><a href="/skills">트렌딩 스킬</a></li> */}

    </ul>
  );
});

const MenuWithAuth = (props: {
  username: string,
  isWhite: boolean,
  isOpenAlarm: boolean,
  hasUnread: boolean,
  onClickMenu: () => void,
  onClickAlarm: () => void,
}) => {
  const purpleIcon = [
    '/images/icon-message-purple.png',
    '/images/icon-message-purple-on.png',
  ][props.hasUnread ? 1 : 0];

  const whiteIcon = [
    '/images/icon-message.png',
    '/images/icon-message-on.png',
  ][props.hasUnread ? 1 : 0];

  const icon = (props.isWhite && !window.location.pathname.includes('projects/')) ? purpleIcon : whiteIcon;
  const [notificationList, setNotificationList] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationPage, setNotificationPage] = useState(1);
  const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';

  useEffect(() => {
    loadNotificationCount();
  }, []);

  const openAlarm = async () => {
    props.onClickAlarm();
    setNotificationPage(1);

    const { data } = await axios.get('/notifications', {
      params: {
        page: notificationPage,
        page_size: 1000,
      },
    });

    const { results } = data;
    setNotificationList(results);

    const body = document.querySelector('body')
    if (!body) {
      return;
    }

    if (props.isOpenAlarm) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
    loadNotificationCount();
  }

  const loadNotificationCount = async () => {
    const { data } = await axios.get('/notifications/unseen/count');
    const { count } = data;
    setNotificationCount(count);
  };

  const openLink = async (uuid: string, link: string) => {
    await axios.post(`notifications/${uuid}/read`);
    props.onClickAlarm();
    window.location.href = link;
  }

  return (
    <>
      <ul className="profile">
        {
          userType === 'PARTNER' &&
          <li onClick={openAlarm}><a href="#" className="alm">
            <span>알람</span>
            {
              notificationCount > 0 &&
              <i>{notificationCount}+</i>
            }
          </a></li>
        }

        {
          isOnboarding ||
          <img
            src={icon}
            style={{
              marginRight: 16,
              marginLeft: 16,
              cursor: 'pointer',
              outline: 'none',
            }}
            onClick={() => window.location.href = '/messages'}
            width={24}
            height={24}
          />
        }

        {
          userType === 'PARTNER' &&
          <li><a><em>{props.username || '멤버'}</em>님</a></li>
        }

        {
          userType === 'MEMBER' &&
          <MyButton href={`${MEMBER_WEBURL}/me`}>
            내 정보 관리
          </MyButton>
        }
      </ul>

      {
        props.isOpenAlarm &&
        <div className="alm-list" style={{ zIndex: 100, marginTop: isMain ? 54 : 0 }}>
          <ul>
            {
              notificationList.length < 1 &&
              <p style={{ margin: 12, color: 'gray' }}>아직 받은 알림이 없습니다.</p>
            }
            {
              notificationList.map(({ uuid, content, link, read, created_at }: any) => (
                <li key={uuid} className={read ? '' : "new"}>
                  <a href="#" onClick={() => openLink(uuid, link)}>
                    {content}
                    <span>{dayjs(created_at).format('MM.DD HH:mm')}</span>
                  </a>
                </li>
              ))
            }
          </ul>
        </div>
      }
    </>
  )
}

const DropdownMenu = (props: {
  username: string,
  userType: string,
  hasProposal: boolean,
  isAuth: boolean,
  onClickMenu: () => void,
  onClickLogout: () => void
}) => {
  if (!props.isAuth) {
    const isCompanyLanding = window.location.hash === '#company';
    const isProjectDetail = window.location.pathname.includes('/projects/');

    return (
      <>
        <ul>
          {/* {isCompanyLanding ? null :
            <li onClick={props.onClickMenu}>
              <a
                href="https://seesorufree.notion.site/0619b3b40c884e8a8608e41de1d5bc13" target="_blank"
                style={{ color: isProjectDetail ? 'white' : 'auto' }}
              >
                자주 묻는 질문
              </a>
            </li>
          } */}
          {/* <li onClick={props.onClickMenu}>
            <a href={isCompanyLanding ? '/' : `/#company`} style={{ color: isProjectDetail ? 'white' : 'auto' }}>
              {isCompanyLanding ? '세컨팀 홈 바로가기' : '기업용 서비스'}
            </a>
          </li> */}
          <li onClick={props.onClickMenu}>
            <a href="/login" style={{ color: isProjectDetail ? 'white' : 'auto' }}>파트너사 로그인</a>
            {/* <a href={isCompanyLanding ? 'https://whattime.co.kr/second-team/hey_teamup?time_zone=Asia%2FSeoul&times=2023-11-30T00%3A00%3A00%2B09%3A00' : '/login' }
              target={isCompanyLanding ? '_blank' : '_self'}
              className="actv">
              {isCompanyLanding ? 'HR 고민 털어놓기' : '로그인/회원가입' }
            </a> */}
          </li>
        </ul>
      </>
    )
  };

  return (
    <ul style={{ padding: _isMobile ? '0px 12px 8px 12px' : 'auto' }}>


      {
        props.userType === 'MEMBER' &&
        <>
          {/* <li style={{ margin: 0 }}><a href={`/recruits/${props.userType.toLowerCase()}`}>제안 현황</a></li>
          <li style={{ margin: 0 }}><a href="/recruits">세컨 컴퍼니</a></li>
          <li style={{ margin: 0 }}><a href={`/calc/${props.userType.toLowerCase()}`}>정산관리</a></li> */}
        </>
      }

      {
        props.userType === 'PARTNER' ?
          <>
            {/* {
              (!isHideMenu && props.hasProposal) &&
              <li onClick={props.onClickMenu}><a href="/calc/partner">정산 관리</a></li>
            } */}
          </> :
          <>
            {
              isHideMenu ||
              <li onClick={props.onClickMenu}><a href="/member/profile">프로필</a></li>
            }
          </>
      }

      {
        props.userType === 'PARTNER' &&
        <li><a href={`${PARTNER_WEBURL}/me`}>회사 정보</a></li>
      }

      {/* <li><a href="https://bit.ly/3sRSGvE" target="_blank" onClick={props.onClickMenu}>업데이트 소식</a></li> */}
      <li><a href="/" onClick={props.onClickLogout}>로그아웃</a></li>
    </ul>
  );
}
