import Avatar from '@components/Avatar';
import { ICoWorker } from '@models/CoWorker';
import dayjs from 'dayjs';

interface Props {
  data: ICoWorker;
  isHideProjectName?: boolean;
}

const CoWorkerCard = ({
  data,
  isHideProjectName,
}: Props) => {
  return (
    <div className="refchk-box">
      <div className="pf-w">
        <span className="photo">
          <img src={data.avatar || (data.is_member ? '/images/@photo.png' : '/images/profilePhoto_gray.png')} alt="" />
        </span>
        <div className="info">
          <div className="name-w">
            <div>
              {
                data.is_member ?
                  <em><a href={`/members/${data.uuid}`}>{data.name}</a></em>
                  :
                  <em>{data.name}</em>
              }
              <div className="chk-st">
                {
                  data.is_evaluated &&
                  <span className="c1"><span>동료 평가</span></span>
                }

                {
                  data.is_verified &&
                  <span className="c2"><span>협업 확인</span></span>
                }

                {
                  data.is_open_to_reference_check &&
                  <span className="c3"><span>레퍼런스 체크 가능</span></span>
                }
              </div>
            </div>
            <p><span>{data.sub_category}</span><span>{data.employment || '소속없음'}</span></p>
          </div>
          {
            (Boolean(data.episode_uuid) && !isHideProjectName) &&
            <div className="w-pjt">
              <i>함께한 프로젝트</i><a href={`/member/profile#${data.episode_uuid}`}>{data.episode_title}</a>
            </div>
          }
        </div>
      </div>

      {
        Boolean(data.pros || data.cons) &&
        <>
          <ul>
            {
              Boolean(data.pros) &&
              <li>{data.pros}</li>
            }
            {
              Boolean(data.cons) &&
              <li>{data.cons}</li>
            }
          </ul>
          <p>{dayjs(data.created_at).format('YYYY.MM.DD')} 작성</p>
        </>
      }
    </div>
  );
};

export default CoWorkerCard;
