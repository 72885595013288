
import { isMobile } from "react-device-detect";
import styled from "styled-components";

const BannerList = styled.ul`
  display: grid !important;
  grid-template-columns: ${isMobile ? '1fr 1fr' : '1fr 1fr 1fr 1fr 1fr 1fr'}; 

  margin: 0 auto !important;
  text-align: center !important;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  columns: 6;
  max-width: 1180px;
  padding: 0 32px;
`;

const BannerItem = styled.li`
  justify-content: center;
  max-width: 184px !important;
  display: inline-block !important;
  vertical-align: top !important;
  text-align: center;
  align-items: center;
`;

const Banner = styled.img<any>`
  margin: 0 auto !important;
  max-width: ${({ maxWidth }: any) => maxWidth}px;
  height: 64px;
  object-fit: contain;
`;

const CompanyBannerList = () => {
  return (
    <>
      <BannerList>
        {
          Array.from(Array(18).keys()).map(n => (
            <BannerItem>
              <Banner
                className="flex"
                maxWidth={n + 1 === 8 ? 35 : n + 1 === 11 ? 60 : 'auto'}
                // style={{
                //   maxWidth: n === 8 ? 50 : n === 11 ? 60 : 'auto',
                // }}
                src={`/images/logos/${n + 1}.png`}
              />
            </BannerItem>
          ))

        }
      </BannerList>
    </>
  );
}

export default CompanyBannerList;
