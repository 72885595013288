export default () => {
  return (
    <div className="pjt-adm-detail">
      <div className="pjt-def-info">
        <strong>아직 채용 시작일이 지정되지 않은 협업입니다.</strong>
        <p>협업 시작일이 지정되어야, 시작일에 협업이 시작됩니다. <br />이미 협업 시작일이 정해졌다며, 회사에게 협업 시작일 지정을 요청해주세요.</p>
      </div>
      <div className="rec-info">
        <ul>
          <li>협업 시작일은 회사가 입력할 수 있습니다.</li>
          <li>협업이 시작되면,  시작일로부터 1주일 단위로 정산 요청을 할 수 있습니다.</li>
        </ul>
      </div>
    </div>
  );
}
