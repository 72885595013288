import { Questionnaire } from "@models/Questionnaire";
import { types } from "mobx-state-tree";

export const ResGetServey = types.model({
  uuid: types.string,
  email: types.string,
  questionnaire: Questionnaire,
  candidate_search_id: types.maybeNull(types.number),
  candidate_search_result_id: types.maybeNull(types.number),
});
