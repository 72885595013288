/*
  markup : mypage_company_modify.html
*/

import { useState, useEffect } from 'react';
import { inject, observer } from "mobx-react";
import { IAppStore } from "../../stores/AppStore";
import { IProfileStore } from '../../stores/profileStore';
import { Prompt } from 'react-router-dom';
import {
  Link
} from 'react-router-dom';

import {
  ContractField,
  CoworkingToolField,
  IntroductionField,
  FrameworkField,
  JobTypeField,
  TeamCultureField,
  WebsiteField,
  EstablishedAtField,
} from '@components/profile-old-form';
import { TextField } from '../join/components';

import profileAPI, { IConstantsAll } from '@services/profileAPI';
import dayjs from 'dayjs';


interface IProps {
  appStore: IAppStore;
}

const PartnerProfileFormPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const { profileStore, showLoader, hideLoader } = (props as IProps).appStore;
  const { partnerProfile, clickUpdate, getPartnerProfile } = (profileStore as IProfileStore);

  const [options, setOptions] = useState({ frameworks: [], tools: [] });

  useEffect(() => {
    window.scrollTo(0, 0);

    async function fetch() {
      const options = await profileAPI.getConstants('all');
      setOptions(options);

      await getPartnerProfile();
    }

    fetch();
  }, []);

  if (!partnerProfile) {
    return <div>정보가 없습니다.</div>;
  }

  const handleSave = async () => {
    clickUpdate();
    
    const { company_name, industry, homepage, introduction } = partnerProfile;
    if (!company_name || !industry || !homepage || !introduction) {
      alert('미입력된 항목이 있습니다.');
      return;
    }

    try {
      showLoader();
      await partnerProfile.update();
      hideLoader();

      window.location.href = '/partner/profile';
    } catch (err) {
      hideLoader();
      alert(Object.values(err.response.data)[0]);
      return;
    }
  }

  return (
    <>
      <div className="mypage-cnts">
        <div className="top-info">
          <div className="profile">
            <div className="photo">
              <span style={{ backgroundImage: `url(${partnerProfile.avatar ? partnerProfile.avatar : "/images/@photo.png"})` }}></span>
              <div className="photo-up">
                <input type="file" id="photoUp"
                  onChange={async (e) => {
                    var formData = new FormData();
                    if (e.target.files) {
                      formData.append("avatar", e.target.files[0]);
                      await partnerProfile.changeAvatar(formData);
                    }
                  }}
                /><label htmlFor="photoUp"><span>프로필 사진 변경</span></label>
              </div>
            </div>
            <h2>{partnerProfile.company_name}</h2>
            <div className="cate">
              <span>{partnerProfile.industry}</span>
            </div>
            {/* <p className="desc">회사명과 서비스 분야는 <br />
              <Link to="/partner/account">계정정보</Link>에서 수정 가능합니다.
            </p> */}
          </div>
        </div>
        <div className="myp-incnts">
          <div className="in-cnts">
          <TextField
            label="회사명*"
            value={partnerProfile.company_name}
            placeholder="(주)시소"
            isBold
            isError={ partnerProfile.isCompanyFieldEror }
            onChange={(company: string) => partnerProfile.setCompanyName(company)}
            message={'회사명을 입력해주세요.'}
          />
          </div>
          <div className="in-cnts">
          <TextField
            label="산업 분야*"
            value={partnerProfile.industry}
            placeholder="산업 분야를 선택해주세요."
            isBold
            isError={ partnerProfile.isIndustryFieldError }
            onChange={(value: string) => partnerProfile.setIndustry(value)}
          />
          </div>
          <div className="in-cnts">
          <TextField
            label="홈페이지*"
            value={partnerProfile.homepage || ''}
            placeholder="URL을 입력해주세요."
            isBold
            isError={ partnerProfile.isHomepageFieldError }
            onChange={(homepage: string) => partnerProfile.setHomepage(homepage)}
          />
          </div>
          <IntroductionField
            label={'회사소개*'}
            value={partnerProfile.introduction}
            onChange={(introduction) => partnerProfile.setIntroduction(introduction)}
            isError={ partnerProfile.isIntroductionFieldError }
          />
          <TeamCultureField
            value={partnerProfile.service_description}
            onChange={(culture) => partnerProfile.setServiceDescription(culture)}
          />
          {/* <JobTypeField
            value={partnerProfile.job_type}
            onChange={(jobType, selected) => partnerProfile.setJobType(jobType, selected)} /> */}


          <div className="in-cnts">
            <h3>테크스택</h3>
            <dl className="form">
              <CoworkingToolField
                value={partnerProfile.coworking_tools}
                options={(options as IConstantsAll).tools.map(tool => tool.name)}
                onChange={(tool, selected) => partnerProfile.setCoworkingTools(tool, selected)} />
              <FrameworkField
                value={partnerProfile.frameworks}
                options={(options as IConstantsAll).frameworks.map(framework => framework.name)}
                onChange={(tool, selected) => partnerProfile.setFrameworks(tool, selected)} />
            </dl>
          </div>
          <div className="in-cnts">
            <h3>기본정보</h3>
            <dl className="form">
              {/* <WebsiteField
                value={partnerProfile.website}
                onChange={(url) => partnerProfile.setWebsite(url.toLowerCase())} /> */}

              <ContractField
                value={partnerProfile.contact_email}
                onChange={(email) => partnerProfile.setContactEmail(email)} />


              <EstablishedAtField
                value={partnerProfile.established_at}
                onChange={(establishedAt) => partnerProfile.setEstablishedAt(establishedAt)} />
            </dl>
          </div>

          <div className="in-cnts">
            <h3>협업 히스토리</h3>
            <div className="pjt-history">
              {
                partnerProfile.project_history.length < 1 &&
                <p>없음</p>
              }
              {
                partnerProfile.project_history.slice(0, 2).map(v => (
                  <div>
                    <p>{v.title}</p>
                    <em><span>{v.member.job_category}</span>{v.member.nickname}</em>
                    <span className="date">{dayjs(v.date_matched).format('YYYY.MM.DD')} - {v.end_date ? dayjs(v.end_date).format('YYYY.MM.DD') : '진행중'}</span>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <div className="btn fx-type">
          <Link to="/partner/profile" className="sub">취소</Link>
          {/* <a href="#" className="actv">저장</a> */}
          <button
            className={partnerProfile.isUpdated ? 'actv' : 'disb'}
            disabled={partnerProfile.isUpdated ? false : true}
            onClick={handleSave}>저장</button>
        </div>

        <Prompt
          when={partnerProfile.isUpdated}
          message="변경 내용을 삭제하시겠어요?
        지금 페이지를 나가면 변경 내용이 삭제됩니다." />
      </div>
    </>
  )
}));

export default PartnerProfileFormPage;
