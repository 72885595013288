interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default ({
  isOpen,
  onClose,
}: Props) => {
  if (!isOpen) {
    return <></>;
  }

  const onCancel = () => {
    onClose();
  };

  const onEnd = () => {
    onClose();
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box smtype">
        <section className="scenario-w" style={{ textAlign: 'left' }}>
          <h1>본 예시는 가상의 시나리오입니다</h1>
          <ul>
            <strong>☝️ 매주 주급을 정산 요청해서 정산받습니다.</strong>
            <li style={{ paddingLeft: 8}}>채용 제안서의 주급: 100,000원</li>
            <li style={{ paddingLeft: 8}}>정산받는 금액: 100,000원</li>
            <p style={{ marginBottom: 32}} />

            <strong>☝️ 프로젝트 기간동안 주단위로 지급됩니다.</strong>
            <li style={{ paddingLeft: 8}}>예상 협업 기간 : 3주</li>
            <li style={{ paddingLeft: 8}}>예상 정산 금액 : 100,000원 x 3주 = 300,000원</li>
            <p style={{ marginBottom: 32}} />

            <strong>☝️ 누적된 정산 금액을 내 계좌로 지급 받기 요청시, 세금 부과 후 차액이 입금됩니다.</strong>
            <li style={{ paddingLeft: 8}}>세금 : 사업 소득인 경우 3.3%</li>
            <li style={{ paddingLeft: 8}}>실제 입금액 : 300,000원 - 9,900원 = 290,100원</li>
          </ul>
        </section>
        <button type="button" className="btn-close" onClick={onClose}><span>팝업 닫기</span></button>
      </div>
    </div>
  );
}
