import { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import { useFormContext, useFieldArray } from "react-hook-form";
import onboardingAPI from '../../services/onboardingAPI';
import EpisodeFormCard from "../../pages/onboarding/components/EpisodeFormCard";

interface Props extends OnboardingProps {
  title?: string;
}

export default observer(({ profile, title }: Props) => {
  const { register, getValues, setValue, watch, control } = useFormContext();
  const [jobs, setJobs] = useState([]);
  const [skills, setSkills] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'episodes',
  });

  useEffect(() => {
    loadConstants();
    
    if (fields.length > 0) {
      return;
    }

    if (profile.episodes.length < 1) {
      onAdd();
      return;
    }

    const data: any[] = []
    for (const exp of profile.episodes) {
      data.push(JSON.parse(JSON.stringify(exp)));
    }

    append(data);
  }, []);

  const loadConstants = async () => {
    const { jobs, skills, experiences } = await onboardingAPI.getConstants();
    setJobs(jobs);
    setSkills(skills);
    setExperiences(experiences);
  };

  const onAdd = () => {
    append({
      title: '',
      domain: '',
      role: '',
      skills: [],
      experiences: [],
      frameworks: [],
      reference_type: 'LINK',
      link: ''
    });
  }

  if (jobs.length < 1) {
    return <></>;
  }

  return (
    <>
      <h3>{title}</h3>
      {
        fields.map((field, index) => {
          return (
              <EpisodeFormCard
                  key={field.id}
                  field={field}
                  profile={profile}
                  index={index}
                  jobs={jobs}
                  skills={skills}
                  experiences={experiences}
                  remove={() => remove(index)}
              />
          )
        })
      }

      <div className="pjt-add">
        <button type="button" onClick={onAdd}>포트폴리오 추가</button>
        <p>포트폴리오가 많을 수록 제안을 받을 확률이 올라갑니다</p>
      </div>
    </>
  );
});
