import { Instance, types } from "mobx-state-tree";

export const RegularSurvey = types.model({
  id: types.number,
  name: types.string,
  description: types.string,
  is_subscribed: types.boolean,
});

export interface IRegularSurvey extends Instance<typeof RegularSurvey> {};
