import { useState } from 'react';


let EMAIL_REG = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

const ContractField = (props: {
  value?: string,
  onChange: (value: string) => void
}) => {
  const [ error, setError ] = useState('');

  return (
    <>
      <dt><label htmlFor="mail">이메일</label></dt>
      <dd>
          <input type="text" className={ error ? 'err' : ''} id="mail" value={ props.value } placeholder="cs@gmail.com"
            onBlur={ () => {
              if(!props.value) {
                setError('');
                return ;
              }
              const check = EMAIL_REG.test(props.value || '');
              if(!check) {
                setError('유효한 이메일 형식이 아닙니다.');
              } else {
                setError('');
              }
              
             } }
            onChange={ (e) => props.onChange(e.target.value) } />
          {
            error.length > 0 &&
              <p className="err">{ error }</p>
          }
      </dd>
    </>
  )
}
export default ContractField;