import { types, Instance } from "mobx-state-tree";
import { CountModel } from "./CountModel";
import { ProjectModel } from "./ProjectModel";

export const PaginationModel = types.model({
  total_payment: types.maybeNull(types.number),
  balance: types.maybeNull(types.number),
  counts: CountModel,
  count: types.number,
  next: types.maybeNull(types.string),
  previous: types.maybeNull(types.string),
  results: types.array(ProjectModel),
})

export interface IPaginationModel extends Instance<typeof PaginationModel> {};
