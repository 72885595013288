interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default ({
  isOpen,
  onClose,
}: Props) => {
  if (!isOpen) {
    return <></>;
  }

  const onCancel = () => {
    onClose();
  };

  const onEnd = () => {
    onClose();
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box smtype">
        <section className="scenario-w">
          <h1>본 예시는 가상의 시나리오입니다</h1>
          <ul>
            <li>프로필에 시급 3만원, 주당 가능 업무시간을 5~8시간으로 작성했습니다.</li>
            <li>회사와 주당 업무시간을  7시간, 주급 21만원, 협업 기간 10주로 확정하였습니다.</li>
            <li>업무를 시작하고 난뒤, 주간 업무 내역을 기록합니다.</li>
            <li>기록한 업무 내역을 회사가 확인을 하면, 해당 주간 단위의 업무가정산됩니다.</li>
            <li>정산이 되면, 21만원 중 계약 및 정산 대행 수수료 5%인 10,500원이 차감되어, 199,500원이 크레딧으로 지급된니다.</li>
            <li>지급받은 크레딧은 언제든 입금요청이 가능합니다.</li>
            <li>예를 들어 3주간 협업을 진행하고 정산이 되었다면 598,500원이 크레딧으로 있게 됩니다.</li>
            <li>크레딧 정산은 전체 금액으로만 가능하며, 정산신청시 기타소득 또는 사업자로 가능합니다.</li>
            <li>기타소득으로 신청시에는 부가세 7.7%이 차감된 552,415원이 지급됩니다.</li>
            <li>사업자소득으로 신청시, 별도 경영지원팀을 통해 확인후 세금계산서 발행후 지급됩니다.</li>
          </ul>
        </section>
        <button type="button" className="btn-close" onClick={onClose}><span>팝업 닫기</span></button>
      </div>
    </div>
  );
}
