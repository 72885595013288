/*
  markup : onboarding_member_time.html
*/
import { observer } from "mobx-react";
import { OnboardingProps } from '../OnboardingPage';
import TimeForm from "../../../components/profile-new-form/TimeForm";
import TimeAddTypeForm from "../../../components/profile-new-form/TimeAddTypeForm";
import TimeZoneForm from "../../../components/profile-new-form/TimeZoneForm";
import PriceForm from "../../../components/profile-new-form/PriceForm";

interface Props extends OnboardingProps {
}

export default observer(({ profile, account, tab }: Props) => {
  if (tab !== 0) {
    return <></>;
  }

  return (
    <div style={{ display: tab === 0 ? 'block' : 'none' }}>
      <div className="in-cnts">
        <TimeForm
          title="1주일 기준, 거래 가능한 시간을 선택해주세요."
          profile={profile}
          account={account}
        />
      </div>
      <div className="in-cnts">
        <TimeAddTypeForm
          title="프로젝트 상황에 따라, 거래 가능한 시간을 추가할 수 있나요?"
          profile={profile}
          account={account}
        />
      </div>
      <div className="in-cnts">
        <TimeZoneForm
          title="거래 가능한 시간대를 모두 선택해주세요. (한국 기준)"
          profile={profile}
          account={account}
        />
      </div>
      <div className="in-cnts">
        <PriceForm
          title="희망하시는 시간당 거래 금액을 입력해주세요."
          text="실제 시간당 거래 금액은 세컨팀 회사와 협의 후 결정됩니다."
          profile={profile}
          account={account}
        />
      </div>
    </div>
  );
});
