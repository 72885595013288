import classNames from 'classnames';
import { nanoid } from 'nanoid';

const TextField = (props: {
  label: string,
  isBold?: boolean,
  isError?: boolean,
  value: string,
  placeholder?: string,
  message?: string,

  // onBlur?: () => void,
  onChange: (value: string) => void
}) => {
  const htmlFor = nanoid();

  return (
    <li>
      {
        props.isBold ?
          <h3><label htmlFor="crt">{props.label}</label></h3>
          :
          <label htmlFor={htmlFor}>{props.label}</label>
      }
      <input
        className={classNames({ err: props.isError })}
        type="text"
        id={htmlFor}
        placeholder={props.placeholder}
        defaultValue={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      // onBlur={ props.onBlur }
      />
      {
        props.isError &&
        <p className="err">{props.message}</p>
      }
    </li>
  )
}

export default TextField;