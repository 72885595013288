/*
  markup : member_list.html
*/

import { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import { IAppStore } from "../../stores/AppStore";
import { IMemberListStore } from '../../stores/MemberListStore';
import profileAPI from '../../services/profileAPI';
import moment from 'moment';
import { PieChart } from 'react-minimal-pie-chart';
import MemberEmpty from '../../components/empty/MemberEmpty';
import MemberCard from './components/MemberCard';
import Pagination from '../../components/Pagination';
import { IMemberModel } from './models/MemberModel';
import styled from 'styled-components';

interface Option {
  id: number;
  name: string;
  order: number;
  parent: string;
}

enum SearchMode {
  NONE,
  ALL,
  FLOLLOW,
}

interface IProps {
  appStore: IAppStore;
}

const colorMap = [
  '#5539ff',
  '#96ffc6',
  '#ffb494',
  '#E0FE52',
];

const PieDot = styled.li<any>`
  &:before {
    background: ${props => props.color} !important;
  }
`;

const MemberListPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const { userStore, memberListStore } = (props as IProps).appStore;
  const { dashboard, search, newMemberList, statistics, getDashboard, getStatistics, getMemberList, getMemberMore } = (memberListStore as IMemberListStore);
  const memberList = search?.results || [];

  const [options, setOptions] = useState<Option[]>([]);
  const [rootOptions, setRootOptions] = useState<Option[]>([]);

  const [query, setQuery] = useState<string>('');
  const [currentOptionName, setCurrentOptionName] = useState<string>('');
  const [currentSubOptionName, setCurrentSubOptionName] = useState<string>('');

  const option = options.find(v => currentSubOptionName === v.name) || rootOptions.find(v => currentOptionName === v.name);

  const [skillIdx, setSkillIdx] = useState(0);
  const [experienceIdx, setExperienceIdx] = useState(0);
  const [projectIdx, setProjectIdx] = useState(0);

  // const skills = dashboard?.projects[projectIdx].values || [];
  // const [skillPage, setSkillPage] = useState(1);

  const [searchMode, setSearchMode] = useState<SearchMode>(SearchMode.NONE);

  useEffect(() => {
    if (userStore.user.userType === 'MEMBER') {
      window.location.href = '/';
      return;
    }

    getDashboard();
    getStatistics();
    getJobInfo();
  }, []);

  const getJobInfo = async () => {
    const jobsRoot = await profileAPI.getConstants('jobs/root/');
    const jobsAll = await profileAPI.getConstants('jobs/all/');

    setOptions(jobsAll);
    setRootOptions(jobsRoot);
  }

  const onSearch = async (mode: SearchMode = SearchMode.ALL) => {
    getMemberList({
      job_category: option?.id,
      search: query,
      follow: mode === SearchMode.FLOLLOW,
    });

    setSearchMode(mode);
  }

  const onClear = async () => {
    setSearchMode(SearchMode.NONE);
    setQuery('');
    setCurrentOptionName('');
    setCurrentSubOptionName('');

    window.scrollTo(0, 0);
  }

  if (!dashboard) {
    return (
      <div />
    )
  }

  return (
    <>
      <div className="member-wrap">
        <div className="top-box">
          <div className="sch-wrap" style={{ maxWidth: 612 }}>
            {
              searchMode === SearchMode.NONE ?
                <p>현재 {moment().format('yyyy년 MM월 DD일')} 협업 가능한 세컨팀 멤버는 총 <em>{dashboard.total_count}명</em>입니다.<br />세컨팀 멤버 중 구직의향이 있는 멤버는 총 <em>{dashboard.available_count}명</em>입니다.</p>
                :
                <p>총 <em>{search?.count || 0}명</em>의 세컨팀 멤버가 검색되었습니다.<br />그 중 찜한 멤버는 총 <em>{search?.following_count || 0}명</em>입니다. </p>
            }
            <fieldset>
              <legend>멤버 검색</legend>
              {/* <div className="sel">
                <select
                  title="직군 선택"
                  value={currentOptionName || '직군 전체'}
                  onChange={(e) => {
                    const name = e.target.value;
                    setCurrentOptionName(name === '직군 전체' ? '' : name);
                  }}
                >
                  <option>직군 전체</option>
                  {
                    rootOptions.filter(v => v.name !== '기타').map((option) => (
                      <option key={option.id}>{option.name}</option>
                    ))
                  }

                  {
                    rootOptions.filter(v => v.name === '기타').map((option) => (
                      <option key={option.id}>{option.name}</option>
                    ))
                  }
                </select>
                <select
                  className="직무 선택"
                  value={currentSubOptionName || (currentOptionName ? '직무를 선택해주세요' : '직군을 먼저 선택해주세요')}
                  onChange={(e) => {
                    const name = e.target.value;
                    setCurrentSubOptionName(name);
                  }}
                  disabled={!currentOptionName}
                >
                  <option>{currentOptionName ? '직무를 선택해주세요' : '직군을 먼저 선택해주세요'}</option>
                  {
                    options
                      .filter(v => currentOptionName.length < 1 || v.parent === currentOptionName)
                      .map((option) => {
                        return (
                          <option>{option.name}</option>
                        )
                      })
                  }
                </select>
              </div> */}
              <div className="int">
                <input
                  type="search"
                  placeholder="닉네임, 프로젝트명, 스킬, 경험 검색"
                  value={query}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && query.length > 0) {
                      onSearch();
                    }
                  }}
                  onChange={(e) => {
                    const text = e.target.value;
                    setQuery(text);

                    if (text.length < 1) {
                      setSearchMode(SearchMode.NONE);
                    }
                  }}
                />
                {
                  query.length > 0 &&
                  <button
                    type="button"
                    className="delete"
                    onClick={() => {
                      setQuery('');
                      setSearchMode(SearchMode.NONE);
                    }}
                  >
                    <span>검색어 삭제</span>
                  </button>
                }
                <button
                  type="submit"
                  className="sch"
                  onClick={() => onSearch()}
                >
                  <span>검색</span>
                </button>
              </div>
            </fieldset>
          </div>
        </div>

        {
          searchMode !== SearchMode.NONE ?
            (
              <div className="in-info">
                <div className="def-tab">
                  <ul>
                    <li onClick={() => onSearch(SearchMode.ALL)} className={searchMode === SearchMode.ALL ? 'selected' : ''}><a style={{ cursor: 'pointer' }}>전체 목록 ({search?.count})</a></li>
                    <li onClick={() => onSearch(SearchMode.FLOLLOW)} className={searchMode === SearchMode.FLOLLOW ? 'selected' : ''}><a style={{ cursor: 'pointer' }}>찜한 목록 ({search?.following_count})</a></li>
                  </ul>
                </div>

                <div className="new-member">
                  {
                    memberList.map((v: IMemberModel) => <MemberCard data={v} />)
                  }
                </div>

                {
                  memberList.length > 0 ?
                    (
                      <Pagination
                        totalCount={(searchMode === SearchMode.FLOLLOW ? search?.following_count : search?.count) || 0}
                        size={20}
                        onChange={(page) => {
                          getMemberList({
                            job_category: option?.id,
                            search: query,
                            follow: searchMode === SearchMode.FLOLLOW,
                            page: page,
                          });
                        }}
                      />
                    )
                    :
                    (
                      <MemberEmpty
                        onClear={onClear}
                      />
                    )
                }
              </div>
            )
            :
            (
              <div className="in-info">
                {/* <div className="ass-form">
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSdUG7r-zLqqrihIghUpXiCYYQ_97jJg7yEwof38QDxD4Ros4g/viewform" target="_blank" title="새창 열림">
                    <div className="txt">
                      <strong> 프로젝트에 적합한 멤버를 <br />추천 받고 싶으신가요?</strong>
                      <p>어떤 멤버를 찾아야할지 잘 모르겠다면 <br />채용 어시스턴트의도움을 받아보세요!</p>
                    </div>
                  </a>
                </div> */}

                <div className="data-status">
                  <h2><em>숫자</em>로 보는 세컨팀</h2>
                  <ul>
                    <li className="t-mem">
                      <p>1명의 멤버가 가장 많이 받은 채용 제안 수</p>
                      <em>{statistics?.member?.max_proposal_count || 0}<span>건</span></em>
                    </li>
                    <li className="t-like">
                      <p>1명의 멤버가 가장 많이 거래한 주차 수</p>
                      <em>{statistics?.member?.max_iteration_count || 0}<span>주차</span></em>
                    </li>
                    <li className="t-long">
                      <p>한 회사가 가장 길게 채용을 진행한 기간</p>
                      <em>{statistics?.partner?.max_iteration_length || 0}<span>주간</span></em>
                    </li>
                    <li className="t-many">
                      <p>한 회사가 가장 많이 채용한 멤버 수</p>
                      <em>{statistics?.partner?.max_member_count || 0}<span>명</span></em>
                    </li>
                  </ul>
                </div>

                <div className="inbox">
                  <h2>분야별</h2>
                  <div className="graph-wrap">
                    <div className="graph">
                      {/* <img src="images/@graph.svg" alt="" /> */}
                      <PieChart
                        lineWidth={70}
                        style={{
                          alignSelf: 'center',
                          width: 120,
                          height: 120,
                        }}
                        data={
                          dashboard.job_categories.map(({ count }, index) => ({ title: '', value: count || 0, color: colorMap[index] }))
                        }
                      />
                    </div>
                    <ul>
                      {
                        dashboard.job_categories.map(({ main_category: name, count }, index) => {
                          const allCount = dashboard.job_categories.reduce((a, b) => a + (b.count || 0), 0);
                          const percentage = (count || 0) / allCount * 100;

                          return (
                            <PieDot
                              className={`gt${index + 1}`}
                              color={colorMap[index]}
                            >
                              {name}&nbsp;<em>{count}명</em>({Math.round(percentage)}%)
                            </PieDot>
                          );
                        })
                      }
                    </ul>
                  </div>
                </div>
                <div className="inbox">
                  <h2>협업을 기다리고 있어요</h2>
                  <div className="new-member">
                    {
                      dashboard.recommended_members.map((v) => <MemberCard data={v} />)
                    }
                    {/* <a style={{ cursor: 'pointer' }} onClick={() => onSearch(SearchMode.ALL)} className="btn-more">전체 멤버 보기</a> */}
                  </div>
                </div>

                <div className="inbox">
                  <h2>새로운 멤버</h2>
                  <div className="new-member">
                    {
                      [...dashboard.new_members, ...newMemberList].map((v) => <MemberCard data={v} />)
                    }
                    {/* <a style={{ cursor: 'pointer' }} onClick={() => onSearch(SearchMode.ALL)} className="btn-more">전체 멤버 보기</a> */}
                  </div>
                </div>

                <div
                  style={{ width: '100%', textAlign: 'center', paddingTop: 20, textDecoration: 'underline' }}
                >
                  <span
                    style={{ cursor: 'pointer', color: '#5539ff' }}
                    onClick={getMemberMore}
                  >
                    더보기
                  </span>
                </div>

                {/* <div className="inbox">
                  <h2>사용 가능한 스킬</h2>
                  <div className="skill-wrap">
                    <div className="in-tab">
                      <ul>
                        {
                          dashboard.skills.map(({ parent_job_category_name: name }, index) => (
                            <li
                              className={index === skillIdx ? 'selected' : ''}
                              onClick={() => setSkillIdx(index)}
                            >
                              <a style={{ cursor: 'pointer' }}>{name}</a>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                    <div className="skill-box">
                      {
                        dashboard.skills[skillIdx].values?.map((name) => (
                          <span>{name}</span>
                        ))
                      }
                    </div>
                  </div>
                </div> */}
                {/* <div className="inbox">
                  <h2>사용 가능한 경험</h2>
                  <div className="skill-wrap">
                    <div className="in-tab">
                      <ul>
                        {
                          dashboard.experiences.map(({ parent_job_category_name: name }, index) => (
                            <li
                              className={index === experienceIdx ? 'selected' : ''}
                              onClick={() => setExperienceIdx(index)}
                            >
                              <a style={{ cursor: 'pointer' }}>{name}</a>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                    <div className="skill-box">
                      {
                        dashboard.experiences[experienceIdx].values?.map((name) => (
                          <span>{name}</span>
                        ))
                      }
                    </div>
                  </div>
                </div> */}
                {/* <div className="inbox">
                  <h2>멤버들의 프로젝트 경험</h2>
                  <div className="skill-wrap">
                    <div className="in-tab">
                      <ul>
                        {
                          dashboard.projects.map(({ parent_job_category_name: name }, index) => (
                            <li
                              className={index === projectIdx ? 'selected' : ''}
                              onClick={() => setProjectIdx(index)}
                            >
                              <a style={{ cursor: 'pointer' }}>{name}</a>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                    <div className="skill-box">
                      {
                        skills.length > 0 ?
                          skills.slice(0, skillPage * 20).map((name) => (
                            <span>{name}</span>
                          ))
                        :
                          <p>세컨팀 멤버들의 프로젝트 경험이 <br />아직 등록되지 않았습니다.</p>
                      }

                      {
                        skills.length > skillPage * 20 &&
                          <a
                            style={{ cursor: 'pointer' }}
                            className="in-more"
                            onClick={() => {
                              setSkillPage(skillPage + 1);
                            }}
                          >
                            더보기
                          </a>
                      }
                    </div>
                  </div>
                </div> */}
              </div>
            )
        }
      </div>
    </>
  )
}));

export default MemberListPage;
