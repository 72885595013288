import { useState } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import { useFormContext } from "react-hook-form";

interface Props extends OnboardingProps {
  title?: string;
}

export default observer(({ profile, title }: Props) => {
  const { register, setValue, watch } = useFormContext();
  const typeWatcher = watch('employment_type') || profile.employment_type;

  const onCheckCompany = () => {
    setValue('employment_type', 'EMPLOYED');
  };

  const onCheckFreelance = () => {
    setValue('employment_type', 'FREELANCE');
  };

  const onCheckPublicOpen = (e: any) => {
    const value = e.target.checked;
    setValue('employment_public_open', !value);
  }

  return (
    <>
      <h4>{title}</h4>
      <div className="rcho-w">
        <div>
          <input name="employment_type" value="EMPLOYED" type="radio" id="comp" onClick={onCheckCompany} defaultChecked={profile.employment_type === 'EMPLOYED'} />
          <label htmlFor="comp">회사</label>
          <input type="text" placeholder="(선택) 회사 이름" title="회사명 입력" {...register('company_name')} defaultValue={profile.company_name} disabled={typeWatcher === 'FREELANCE'}/>
        </div>
        <div>
          <input name="employment_type" value="FREELANCE" type="radio" id="freelancer" onClick={onCheckFreelance} defaultChecked={profile.employment_type === 'FREELANCE'} />
          <label htmlFor="freelancer">프리랜서</label>
        </div>
        <p className="not-open">
          <input type="checkbox" id="nOpen" onChange={onCheckPublicOpen} defaultChecked={!profile.employment_public_open} /><label htmlFor="nOpen">소속 비공개 설정 (비공개 설정 시, 타인에게 노출되지 않습니다.)</label>
        </p>
      </div>
    </>
  );
});
