import { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import onboardingAPI from "../../services/onboardingAPI";
import { useFormContext, useFieldArray } from "react-hook-form";

interface Props extends OnboardingProps {
  title?: string;
}

const snsMap = [
  {
    id: 'linkedIn',
    label: 'LINKEDIN',
    name: 'Linkedin',
  },
  {
    id: 'drib',
    label: 'DRIBBBLE',
    name: 'Dribbble',
  },
  {
    id: 'github',
    label: 'GITHUB',
    name: 'Github',
  },
  {
    id: 'blog',
    label: 'BLOG',
    name: 'Blog',
  },
  {
    id: 'behance',
    label: 'BEHANCE',
    name: 'Behance',
  },
  {
    id: 'insta',
    label: 'INSTAGRAM',
    name: 'Instagram',
  },
  {
    id: 'plus',
    label: 'ETC',
    name: 'plus',
  },
];

export default observer(({ profile, title }: Props) => {
  const { register, getValues, setValue, watch, control } = useFormContext();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: 'references',
  });

  useEffect(() => {
    for (let i = 0, len = fields.length; i < len; i += 1) {
      remove(i);
    }

    for (const reference of profile.references) {
      append(reference);
    }
  }, []);

  const onAdd = (sns: any) => {
    append({
      id: fields.length,
      label: sns.label,
      display: sns.name.replace('plus', ''),
      link: '',
    });
  };

  const onRemove = (index: number) => {
    remove(index);
  };

  return (
    <>
      <h4>{title}</h4>
      <dl className="form in-sns">

        {
          fields.map(({ label }: any, index) => {
            const sns = snsMap.find(v => v.label === label);
            if (!sns) return;

            const { id, name } = sns;

            if (id === 'plus') {
              return (
                <>
                  <dt><input type="text" placeholder="직접입력" title="직접입력" {...register(`references.${index}.display`)} /></dt>
                  <dd>
                    <input type="text" id="dribbble" placeholder="URL을 입력해주세요" {...register(`references.${index}.link`)} />
                    <button type="button" className="btn-del" onClick={() => onRemove(index)}><span>삭제</span></button>
                  </dd>
                </>
              );
            }

            return (
              <>
                <dt><label htmlFor={id} className={`sns ${id}`}>{name}</label></dt>
                <dd>
                  <input type="text" id={id} placeholder={`${name} URL을 입력해주세요`} {...register(`references.${index}.link`)} />
                  <button type="button" className="btn-del" onClick={() => onRemove(index)}><span>삭제</span></button>
                </dd>
              </>
            );
          })
        }
      </dl>
      <div className="add-form">
        <div className="inner">
          {
            snsMap.filter(v => !fields.filter((f: any) => f.display === v.name).length && v.id !== 'plus').map((sns) => {
              const { id, name } = sns;
              return (
                <button type="button" className={id} onClick={() => onAdd(sns)}>{name}</button>
              )
            })
          }
          <button type="button" className="plus" onClick={() => onAdd(snsMap[snsMap.length - 1])}>직접추가</button>
        </div>
      </div>
    </>
  );
});
