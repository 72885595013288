/*
  markup : mypage_reset_pw.html
*/
import { inject, observer } from "mobx-react";
import { IAppStore } from "../../stores/AppStore";

import _ from 'lodash';
import { useForm } from "react-hook-form";
import axios from 'axios';
import { useState, useEffect } from "react";
import { useQuery } from '../../utils/hooks';

interface IProps {
  appStore: IAppStore;
  history: any
}

const PasswordResetPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const { appStore } = props;
  const { showLoader, hideLoader } = appStore;

  const { register, watch, handleSubmit, formState: { isSubmitted } } = useForm();
  const emailWatch = watch('email');
  const passwordWatch = watch('password');
  const passwordCheckWatch = watch('password_check');
  const isPwErr = isSubmitted && !Boolean(passwordWatch)
  const isPwCheckErr = isSubmitted && passwordWatch !== passwordCheckWatch

  const isErr = isSubmitted && !emailWatch;
  const [isSent, setSent] = useState(false);
  const query = useQuery();
  const uid = query.get('uid');
  const token = query.get('token');
  const isReset = Boolean(token);
  const [isUsed, setUsed] = useState(false);
  const [isExpired, setExpired] = useState(false);

  useEffect(() => {
    if (isReset) {
      check();
    }
  }, []);

  const check = async () => {
    try {
      // showLoader();
      await axios.get(`/auth/users/password/reset/validate-link`, {
        params: { token, uid }
      });
      // hideLoader();
    } catch (e) {
      const reason = e.response.data.detail[0];

      if (reason === 'timeout') {
        setExpired(true);
      } else if (reason === 'used_link') {
        setUsed(true);
      }
    }
  }

  const onSubmit = async (form: object) => {
    showLoader();
    await axios.post('/auth/users/password/reset-request', form);
    hideLoader();

    setSent(true);
  };

  const onSend = async (_form: object) => {
    const form: any = {
      ..._form, uid, token,
    };

    if (!form.password || form.password !== form.password_check) {
      return;
    }

    try {
      showLoader();
      await axios.post('/auth/users/password/reset', form);
      hideLoader();
      
      setSent(true);
    } catch (err) {
      setExpired(true);
    }
  };

  const again = () => {
    window.location.replace(window.location.pathname);
  };

  return (
    <div className="sub-wrap">
      <div className="top-box">
        <h2>비밀번호 재설정</h2>
      </div>

      {
        (!isReset && !isSent) &&
        <div className="mem-price">
          <div className="pw-reset">
            <p>비밀번호를 잃어버리셨나요? 걱정마세요. <br />비밀번호를 재설정할 수 있는 링크를 이메일로 보내드립니다.</p>
            <p>가입하신 이메일을 입력해주세요.</p>
            <div className="email-input">
              <label htmlFor="email">이메일</label>
              <input type="text" id="email" placeholder="example@seeso.kr" className={isErr ? 'err' : ''} {...register('email')} />
              {
                isErr &&
                <p className="err">올바른 이메일을 입력해주세요</p>
              }
              <button type="button" className="def-btn" onClick={handleSubmit(onSubmit)}>보내기</button>
            </div>
          </div>
        </div>
      }

      {
        (!isReset && isSent) &&
        <div className="mem-price">
          <div className="pw-reset">
            <p>비밀번호를 재설정할 수 있는 링크를 <em>이메일</em>로 보내드렸습니다. 이메일의 비밀번호 재설정 버튼을 눌러 비밀번호를 재설정해주세요. 재설정은 <em>24시간 안에</em> 해주셔야 합니다. </p>
            <div className="desc-link">
              <p>아직 이메일을 못 받으셨나요? <a href="#" onClick={again}>이메일 다시 보내기</a></p>
            </div>
          </div>
        </div>
      }

      {
        (isReset && !isSent && !isExpired && !isUsed) &&
        <div className="mem-price">
          <div className="pw-reset">
            <p>새로운 비밀번호를 입력해주세요</p>
            <div className="email-input">
              <div>
                <label htmlFor="nPw">새 비밀번호</label>
                <span>
                  <input type="password" id="nPw" placeholder="최소 6자리" title="최소 6자리" className={isPwErr ? 'err' : ''} {...register('password')} />
                  <button type="button" className="btn_pw_v"><span>비밀번호 보기</span></button>
                </span>
                {
                  isPwErr &&
                  <p className="err">비밀번호를 입력해주세요</p>
                }
              </div>
              <div>
                <label htmlFor="nPwChk">새 비밀번호 확인</label>
                <span>
                  <input type="password" id="nPwChk" placeholder="최소 6자리" title="최소 6자리" className={isPwCheckErr ? 'err' : ''}  {...register('password_check')} />
                </span>
                {
                  isPwCheckErr &&
                  <p className="err">비밀번호를 확인해주세요</p>
                }
              </div>
              <button className="def-btn" onClick={handleSubmit(onSend)}>비밀번호 재설정</button>
            </div>
          </div>
        </div>
      }

      {
        (isReset && isSent) &&
        <div className="mem-price">
          <div className="pw-reset">
            <p>새로운 비밀번호로 비밀번호가 재설정되었습니다.<br />지금 로그인해보세요.</p>
            <a href="/login" className="def-btn">로그인 하기</a>
          </div>
        </div>
      }

      {
        isUsed &&
        <div className="mem-price">
          <div className="pw-reset">
            <p>이미 비밀번호 재설정을 하신 링크입니다. <br />다시 비밀번호 재설정을 요청해주세요.</p>
            <a href="#" onClick={again} className="def-btn">다시 요청하기</a>
          </div>
        </div>
      }

      {
        isExpired &&
        <div className="mem-price">
          <div className="pw-reset">
            <p>요청하신 비밀번호 재설정이 24시간이 지나 만료되었습니다.<br /> 다시 비밀번호 재설정을 요청해주세요.</p>
            <a href="#" onClick={again} className="def-btn">다시 요청하기</a>
          </div>
        </div>
      }
    </div>
  )
}));

export default PasswordResetPage;