import { string } from "mobx-state-tree/dist/internal";
import { useFormContext } from "react-hook-form";

interface IProps {
  formKey?: string;
}

const ProjectDescriptionForm = ({ formKey }: IProps) => {
  const key = formKey || 'description';
  const { register, watch, setValue, handleSubmit, getValues, formState: { isSubmitted } } = useFormContext();

  return (
    <textarea id="cnts" {...register(key)} placeholder="프로젝트 소개" style={{ color: 'black' }}></textarea>
  );
};

export default ProjectDescriptionForm;
