import { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import onboardingAPI from "../../services/onboardingAPI";
import { useFormContext } from "react-hook-form";

interface Props extends OnboardingProps {
  title?: string;
  isOnboarding?: boolean;
}

export default observer(({ profile, title, isOnboarding = false }: Props) => {
  const { register, getValues, setValue, watch } = useFormContext();

  if (isOnboarding) {
    return (
      <>
        <h4>{title}</h4>
        <div className="cho-rbox">
          <ul>
            <li><input type="radio" name="get_offer" id="jobS1" onClick={() => setValue('get_offer', true)} defaultChecked={profile.get_offer} /><label htmlFor="jobS1">예, 협업 제안 및 알림을 받겠습니다.</label></li>
            <li><input type="radio" name="get_offer" id="jobS2" onClick={() => setValue('get_offer', false)} defaultChecked={!profile.get_offer} /><label htmlFor="jobS2">아니오, 지금은 협업 제안 받기 어려울 것 같습니다.</label></li>
          </ul>
        </div>
      </>
    );
  }

  return (
    <>
      <h4>{title}</h4>
      <div className="toggle-chk"><input type="checkbox" id="pjtSj" {...register('get_offer')} defaultChecked={profile.get_offer} /><label htmlFor="pjtSj"><span>협업 제안받기</span></label></div>
    </>
  );
});
