import { types, Instance, flow, getParent } from "mobx-state-tree";
import { StateModel } from "@models/models";
import messageAPI from '@services/messageAPI';
import { IMessageModel, MessageModel } from "@pages/message/models/MessageModel";
import { IChatModel, ChatModel } from "@pages/message/models/ChatModel";
import { PartnerProfileModel, MemberProfileModel } from "@pages/profile/models";
import { IAppStore } from "@stores/AppStore";
import profileAPI from "@services/profileAPI";

const MessageStore = StateModel.named('MessageStore')
  .props({
    messageList: types.array(MessageModel),
    chatList: types.array(ChatModel),
    member: types.maybe(MemberProfileModel),
    partner: types.maybe(PartnerProfileModel),
  })
  .views(self => ({
    get uuid(): string {
      return (getParent(self) as IAppStore).userStore.user.uuid;
    },
    get nickname(): string {
      return (getParent(self) as IAppStore).userStore.user.nickname;
    },
    get username(): string {
      return (getParent(self) as IAppStore).userStore.user.username;
    },
    get type(): string {
      return (getParent(self) as IAppStore).userStore.user.userType;
    },
  }))
  .actions(self => ({
    getMessageList: flow(function* () {
      let responses: IMessageModel[] = [];
      try {
        if (self.type === 'MEMBER') {
          responses = yield messageAPI.getMemberMessageList(self.uuid);
        } else {
          responses = yield messageAPI.getPartnerMessageList(self.uuid);
        }

        const messages = responses.map(response =>
          MessageModel.create(response)
        );

        self.messageList.replace(messages);

        if (self.type === 'MEMBER') {
          const res = yield profileAPI.getMemberProfile(self.uuid);
          self.member = MemberProfileModel.create(res);
        } else {
          const res = yield profileAPI.getPartnerProfile(self.uuid);
          self.partner = PartnerProfileModel.create(res);
        }
      } catch(e) {
        throw e;
      }
    }),
    getMessageDetail: flow(function* (proposalUUID: string) {
      try {
        let responses: IChatModel[] = [];
        if (self.type === 'MEMBER') {
          responses = yield messageAPI.getMemberMessage(self.uuid, proposalUUID);
        } else {
          responses = yield messageAPI.getPartnerMessage(self.uuid, proposalUUID);
        }

        const messages = responses.map(response =>
          ChatModel.create(response)
        );

        self.chatList.replace(messages);
      } catch(e) {
        throw e;
      }
    }),
    sendMessage: flow(function* (proposalUUID: string, content: string) {
      try {
        if (self.type === 'MEMBER') {
          yield messageAPI.sendMemberMessage(self.uuid, proposalUUID, content);
        } else {
          yield messageAPI.sendPartnerMessage(self.uuid, proposalUUID, content);
        }
      } catch(e) {
        throw e;
      }
    }),
  }));

export interface IMessageStore extends Instance<typeof MessageStore> {};

export default MessageStore;
