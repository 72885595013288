import axios from "axios";

const accountAPI = {
  getMemberAccount: async function (uuid: string) {
    try {
      const { data }: { data: any } = await axios.get(
        `/members/${ uuid }`
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  updateMemberAccount: async function (uuid: string, parameters: any) {
    try {
      const { data }: { data: any } = await axios.put(
        `/members/${ uuid }`,
        parameters
      );
      return data;
    } catch (e) {
      throw e;
    }
  },

  getPartnerAccount: async function (uuid: string) {
    try {
      const { data }: { data: any } = await axios.get(
        `/partner/${ uuid }`
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  updatePartnerAccount: async function (uuid: string, parameters: any) {
    try {
      const { data }: { data: any } = await axios.put(
        `/partner/${ uuid }`,
        parameters
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
};
export default accountAPI;
