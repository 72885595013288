/*
  markup : member_recu_status.html
*/
import { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { IRecruitListStore } from '@stores/RecruitListStore';
import { IAppStore } from "../../stores/AppStore";
import Pagination from "../../components/Pagination";
import RecruitMemberStatusCard from "./components/RecruitMemberStatusCard";
import { numberWithCommas } from "../../utils/utils";


interface IProps {
  appStore: IAppStore;
}


const RecruitMemberStatusPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const { recruitListStore } = (props as IProps).appStore;
  const { member, uuid, memberProposal, getMember, statistics, getStatistics, getMemberProposalList } = (recruitListStore as IRecruitListStore);
  const [tab, setTab] = useState(0);
  const statusMap = [
    'SUBMITTED',
    'ARRANGING',
    'REJECTED',
    'MATCHED',
  ];

  useEffect(() => {
    getMember(uuid);
    getMemberProposalList(uuid);
    getStatistics();

    const statusIndex = statusMap.findIndex(v => window.location.hash.toUpperCase().includes(v));
    if (statusIndex >= 0) {
      setTab(statusIndex);
    }
  }, []);

  useEffect(() => {
    window.location.hash = statusMap[tab].toLowerCase();
    getMemberProposalList(uuid, statusMap[tab]);
  }, [tab]);

  if (!member || !memberProposal) {
    return <></>;
  }

  const { counts, count, results } = memberProposal;
  const resultList = results.filter(v => v.status === statusMap[tab]) || [];
  const isEmpty = resultList.length === 0;

  const countMap = [
    counts.submitted,
    counts.arranging,
    counts.rejected,
    counts.matched,
  ];

  return (
    <>
      <div className="sub-wrap">
        <div className="top-box">
          <h2>제안후 절차안내</h2>
          <p style={{ lineHeight: 2 }}>관심있는 제안의 경우 36시간 이내 응답해주세요.</p>
          <div style={{ lineHeight: 2 }}>메세지를 주고받으며, 계약정보나 기타 내용을 조율합니다.</div>
          <div style={{ lineHeight: 2 }}>합의된 계약 정보를 확인하고 최종 동의를 합니다.</div>

          <div className="step">
            <ul>
              <li className={tab === 0 ? 'selected' : ''}><a href="#" onClick={() => setTab(0)}>대기중({counts.submitted})</a></li>
              <li className={tab === 1 ? 'selected' : ''}><a href="#" onClick={() => setTab(1)}>조율중({counts.arranging})</a></li>
              <li className={tab === 2 ? 'selected' : ''}><a href="#" onClick={() => setTab(2)}>거절({counts.rejected})</a></li>
              <li className={tab === 3 ? 'selected' : ''}><a href="#" onClick={() => setTab(3)}>채용확정({counts.matched})</a></li>
            </ul>
          </div>
        </div>
        <div className="recu-status">
          {/* <div className="cnts-hgroup">
            <h3>채용 현황</h3>
            <div className="tab">
              <ul>
                <li className={tab === 0 ? 'selected' : ''}><a style={{ cursor: 'pointer' }} onClick={() => setTab(0)}>대기중({counts.submitted})</a></li>
                <li className={tab === 1 ? 'selected' : ''}><a style={{ cursor: 'pointer' }} onClick={() => setTab(1)}>조율중({counts.arranging})</a></li>
                <li className={tab === 2 ? 'selected' : ''}><a style={{ cursor: 'pointer' }} onClick={() => setTab(2)}>거절({counts.rejected})</a></li>
                <li className={tab === 3 ? 'selected' : ''}><a style={{ cursor: 'pointer' }} onClick={() => setTab(3)}>채용확정({counts.matched})</a></li>
              </ul>
            </div>
          </div> */}
          <div className="status-detail">
            {
              resultList.map((v) => (
                <RecruitMemberStatusCard
                  data={v}
                />
              ))
            }
          </div>

          {
            isEmpty &&
            <>
              <div className="no-result">
                <p>현재 진행중인 협업 제안 내역이 없습니다.</p>
              </div>
            </>
          }

          <Pagination
            totalCount={countMap?.[tab] || 0}
            size={20}
            onChange={(num) => { getMemberProposalList(uuid, statusMap[tab], num) }}
          />

          <div className="data-status">
            <h2><em>숫자</em>로 보는 세컨팀</h2>
            <ul>
              <li className="t-mem">
                <p>1명의 멤버가 가장 많이 받은 채용 제안 수</p>
                <em>{statistics?.member?.max_proposal_count || 0}<span>건</span></em>
              </li>
              <li className="t-like">
                <p>1명의 멤버가 가장 많이 거래한 주차 수</p>
                <em>{statistics?.member?.max_iteration_count || 0}<span>주차</span></em>
              </li>
              <li className="t-many">
                <p>1명의 멤버가 가장 많이 정산받은 금액</p>
                <em>{numberWithCommas(statistics?.member?.max_payment_amount || 0)}<span>원</span></em>
              </li>
              <li className="t-recu">
                <p>한 회사가 가장 길게 채용을 진행한 기간</p>
                <em>{statistics?.partner?.max_iteration_length || 0}<span>주간</span></em>
              </li>
              <li className="t-long">
                <p>한 회사가 가장 많이 채용한 멤버 수</p>
                <em>{statistics?.partner?.max_member_count || 0}<span>명</span></em>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}));

export default RecruitMemberStatusPage;
