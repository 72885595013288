import { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import onboardingAPI from "../../services/onboardingAPI";
import { useFormContext } from "react-hook-form";

interface Props extends OnboardingProps {
  title?: string;
  text?: string;
}

export default observer(({ profile, title, text }: Props) => {
  const { register, getValues, setValue, watch, formState: { isSubmitted } } = useFormContext();
  const [isNameErr, setNameErr] = useState(false);
  const isErr = false;
  // const isErr = isSubmitted && !watch('name')?.length;

  return (
    <>
      <h4>{title}</h4>
      <div className="comp-intu">
        <input
          type="text"
          className={isErr ? 'err' : ''}
          id="nick"
          {...register('name')}
          value={watch('name')}
          placeholder="이름을 입력해주세요."
          defaultValue={profile.name}
        />
        {
          isErr &&
          <p className="err">이름을 입력해주세요.</p>
        }
      </div>
    </>
  );
});
