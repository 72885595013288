import { types, Instance, getParent, flow } from "mobx-state-tree";
import { StateModel } from "@models/models";
import { IAppStore } from "@stores/AppStore";
import { CalcResponseModel } from '@pages/calc/models/CalcResponseModel';
import calcAPI, { IPaymentSearchParam } from "@services/CalcAPI";

const CalcStore = StateModel.named('Calc')
  .props({
    payment: types.maybe(CalcResponseModel),
  })
  .views(self => ({
    get uuid(): string {
      return (getParent(self) as IAppStore).userStore.user.uuid;
    },
    get type(): string {
      return (getParent(self) as IAppStore).userStore.user.userType;
    }
  }))
  .actions(self => ({
    getPayment: flow(function* ({
      type,
      page = 1,
      page_size = 20,
    }: IPaymentSearchParam = {}) {
      if (self.type === 'MEMBER') {
        const response = yield calcAPI.getMemberPayment(self.uuid, { type, page, page_size });
        self.payment = CalcResponseModel.create(response);
      } else {
        const response = yield calcAPI.getPartnerPayment(self.uuid, { type, page, page_size });
        self.payment = CalcResponseModel.create(response);
      }
    }),
    requestWithdrawl: flow(function* (data: object) {
      yield calcAPI.requestWithDrawl(self.uuid, data);
    }),
  }));

export interface ICalcStore extends Instance<typeof CalcStore>{};

export default CalcStore;
