/*
  markup : rec_sug_modify.html
*/

interface Props {
  nickname: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export default ({
  nickname,
  isOpen,
  onClose,
  onSubmit: _onSubmit,
}: Props) => {
  if (!isOpen) {
    return <></>;
  }

  const onCancel = () => {
    onClose();
  };

  const onEnd = () => {
    onClose();
  };

  const onSubmit = () => {
    _onSubmit();
    onClose();
  }

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="alrtype2">
          <h3>협업 제안하기</h3>
          <p>협업 제안과 동시에, 해당 멤버에게 문자 알림이 전송됩니다. {nickname}님에게 협업 제안을 보내시겠습니까? </p>
        </div>
        <div className="btn type2">
          <button type="button" className="cancel" onClick={onCancel}>취소</button>
          <button type="button" className="actv" onClick={onSubmit}>제안하기</button>
        </div>
        <button type="button" className="btn-close" onClick={onClose}><span>팝업 닫기</span></button>
      </div>
    </div>
  );
}
