import { IEpisodeModel } from "@pages/profile/models/MemberProfileModel";
import { useEffect, useState } from "react";
import { useController, useFormContext } from "react-hook-form";

interface Props {
  formKey?: string;
  project?: IEpisodeModel;
  placeholder?: string;
}

const TagForm = ({ formKey, project, placeholder }: Props) => {
  const key = formKey || '_skill_set';
  const { control, watch, getValues, setValue, register, formState: { isSubmitted } } = useFormContext();
  const watcher: string[] = watch(`${key}`) || [];
  const isErrSkill = window.location.pathname !== '/onboarding' && !window.location.pathname.includes('openForm') && isSubmitted && (!watch(key) || watch(key)?.length < 1);

  const [text, setText] = useState('');

  const data = useController({
    name: `${key}`,
    control,
  });

  useEffect(() => {
    if (!project) {
      return;
    }
    
    const defaultValues = project?.skill_set.toJSON().map(v => v.name);
    console.log(defaultValues);
    setValue(key, defaultValues);
  }, []);

  const onSelect = (_name: string) => {
    if (!_name) return;

    const values: string[] = getValues(`${key}`) || [];
    let results: string[] = values;

    const nameList = _name.split('#').map(v => v.trim()).filter(v => v);
    for (const name of nameList) {
      if (results.includes(name)) {
        continue;
      } else {
        results = Array.from(new Set([...results, name]));
      }
    }

    data.field.onChange(results);
  };

  const onDelete = (name: string) => {
    const values: string[] = getValues(`${key}`) || [];
    const results = values.filter(v => v !== name);

    data.field.onChange(results);
  }

  const onAdd = () => {
    onSelect(text);
    setText('');
  };

  return (
    <>
      <div className="fm-box" style={{ marginTop: 8 }}>
        {
          watcher.map(v => (
            <span>{v}<button type="button" onClick={() => onDelete(v)}><span>삭제</span></button></span>
          ))
        }
        {/* select로 opt-li 토글 */}
        <div className="in-sel">
          <input
            type="text"
            autoComplete="off"
            {...register(`${key}_text`)}
            style={{
              backgroundColor: 'transparent',
            }}
            // className={isErrSkill ? 'err' : ''}
            onChange={(e) => setText(e.target.value)}
            value={text}
            placeholder={watcher?.length < 1 ? placeholder || '' : ''}
            onKeyUp={(e) => {
              if (e.key !== 'Enter') return;
              onAdd();
            }}
            onBlur={(e) => {
              onAdd();
            }}
          />

          {/* <div className="opt-li">
            <ul>
              <li className="selected">Trello</li>
              <li>Treeee</li>
              <li>Treeee</li>
              <li>Treeee</li>
              <li>Treeee</li>
              <li>Treeee</li>
              <li>Treeee</li>
              <li>Treeee</li>
              <li>Treeee</li>
            </ul>
          </div> */}
        </div>
      </div>
      {
        text.length > 0 &&
        <p style={{ color: 'rgba(13, 18, 64, 0.6)', marginTop: 10, fontSize: 12 }}>엔터 또는 입력키를 눌러 추가해주세요.(태그 입력)</p>
      }

      {
        isErrSkill &&
        <p className="err" style={{ color: 'red', fontSize: 12 }}>경험을 입력해주세요.</p>
      }
    </>
  );
}

export default TagForm;
