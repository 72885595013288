interface IProps {
  id: string;
  isError?: boolean;
  linkURL?: string;
  label: string;
  value: boolean;
  onClick: (value: boolean) => void;
}

const Policy = (props: IProps) => {
  return (
    <li>
      <input
        type="checkbox"
        id={ props.id }
        checked={ props.value }
        className={ props.isError !== undefined && props.isError ? "em" : '' }
        onChange={ () => props.onClick(!props.value) } />
      <label htmlFor={ props.id } style={{ textDecoration: 'none' }}>
        {props.label}
      </label>

      {
        props.linkURL &&
          <a href={props.linkURL} target="_blank" className="btn-dirgo"><span>약관보기</span></a>
      }
    </li>
  );
}

export default Policy;
