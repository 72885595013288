interface Props {
  name: string;
}

export default ({ name }: Props) => {
  return (
    <div className="inner">
      <div className="no-data">
        <p>{name}님에게 첫 메세지를 보내보세요! </p>
      </div>
    </div>
  );
};
