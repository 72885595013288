import { observer } from "mobx-react";

import {
  EMPLOYMENT_TYPE_LABELS
} from '../../utils/constants';

const CompanyField = observer((props: {
  value: string,
  eType: string,
  publicOpen: boolean,
  onChange: (value: string) => void,
  onChangeCompanyName: (name: string) => void,
  onOpenToggle: () => void,
}) => {
  const isCompany = props.eType === EMPLOYMENT_TYPE_LABELS.EMPLOYED;

  return (
    <div className="in-cnts">
      <h3>소속</h3>
      <div className="rcho-w">
          <div>
              <input type="radio" name="posi" id={ EMPLOYMENT_TYPE_LABELS.EMPLOYED } checked={ isCompany }
                onClick={ () => props.onChange(EMPLOYMENT_TYPE_LABELS.EMPLOYED) } />
              <label htmlFor={ EMPLOYMENT_TYPE_LABELS.EMPLOYED }>회사</label>
              <input type="text" placeholder="(주)시소"  title="회사명 입력"
                value={ props.value}
                disabled={ !isCompany }
                onChange={ (e) => props.onChangeCompanyName(e.target.value) } />
          </div>
          <div>
              <input type="radio" name="posi" id={ EMPLOYMENT_TYPE_LABELS.FREELANCE } checked={ !isCompany }
                onClick={ () => {
                  props.onChange(EMPLOYMENT_TYPE_LABELS.FREELANCE);
                  props.onChangeCompanyName('');
                } } />
              <label htmlFor={ EMPLOYMENT_TYPE_LABELS.FREELANCE }>프리랜서</label>
          </div>
      </div>
      <p className="not-open">
          <input type="checkbox" id="nOpen"
            checked={ props.publicOpen }
            onChange={ props.onOpenToggle } />
          <label htmlFor="nOpen">소속 비공개 설정 (비공개 설정 시, 타인에게 노출되지 않습니다.)</label>
      </p>
    </div>
  )
})
export default CompanyField;