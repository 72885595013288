import { ICandidate } from "@models/Candidate";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Rating } from "@mui/material";
import { AUTH_USERTYPE } from "@utils/constants";
import { getWindowDimensions } from '@utils/utils';
import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";

const Section = styled.div`
  @media only screen and (max-width: 786px) {
    padding-left: 0 !important;
  }
`;

const Info = styled.div`
  margin-top: -52px;

  @media only screen and (max-width: 786px) {
    margin-top: 0 !important;
  }
`;

const TagText = styled.strong`
  @media only screen and (max-width: 786px) {
    font-size: 14px;
    margin-left: 8px;
  }
`;

const RatingSection = styled.div`
  display: flex;
  margin-top: 12px;

  .rating-child {
    width: 50%;
  }

  @media only screen and (max-width: 786px) {
    flex-direction: column;
    .rating-child {
      width: 100%;
      margin-bottom: 12px;
    }

    .rating-child:nth-last-child() {
      margin-bottom: 0 !important;
    }
  }
`;

const MemberCard = styled.li<any>`
  border-color: ${(props: any) => props.isViewed ? 'auto' : '#5539ff'} !important;
  border-width: 2px !important;

  /* background-color: ${(props: any) => props.isViewed ? '#fafafa' : 'auto'} !important; */
`;

interface Props {
  id: string;
  data: ICandidate;
  refresh: () => void;
}

const CoWorkerConfigCard = ({
  id,
  data,
  refresh,
}: Props) => {
  const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';
  const [isOpenInterests, setOpenInterests] = useState(false);
  const [isOpenQNA, setOpenQNA] = useState(false);

  const [isFollow, setFollow] = useState(data.is_followed);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const isMobile = windowDimensions.width < 786;

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions());
  };

  const onBookmark = async () => {
    if (isFollow) {
      await axios.post(`/members/${data.member.uuid}/unfollow`);
      setFollow(false);
      return;
    }

    await axios.post(`/members/${data.member.uuid}/follow`);
    setFollow(true);
  };

  return (
    <MemberCard key={data.id} isViewed={userType !== 'PARTNER' || data.is_viewed}>
      <Section className="pf-w">
        {
          isMobile ?
            <>
              <span
                className="photo"
                style={{ marginTop: -16, cursor: 'pointer' }}
                onClick={() => window.open(`/members/${data.member.uuid}?candidateId=${data.id}&candidatePoolId=${id}`)}
              >
                <img
                  src={data.member.avatar || '/images/@photo.png'}
                  alt=""
                />
              </span>

              <div className="info">
                <div style={{ left: 54, top: 32, position: 'absolute' }}>
                  {
                    userType === 'PARTNER' ?
                      <em style={{ width: isMobile ? 'auto' : 60, textAlign: 'center', marginLeft: 28 }}>
                        <a
                          href={`/members/${data.member.uuid}?candidateId=${data.id}&candidatePoolId=${id}`}
                          target="_blank"
                          onClick={() => {
                            setTimeout(() => {
                              refresh();
                            }, 1000);
                          }}
                        >
                          {data.member.nickname}
                        </a>
                      </em>
                      :
                      <em style={{ width: isMobile ? 'auto' : 60, textAlign: 'center', marginLeft: 28 }}>
                        {data.member.nickname}
                      </em>
                  }
                </div>
              </div>
            </>
            :
            <>
              <span
                className="photo"
                style={{ cursor: 'pointer' }}
                onClick={() => window.open(`/members/${data.member.uuid}?candidateId=${data.id}&candidatePoolId=${id}`)}
              >
                <img
                  src={data.member.avatar || '/images/@photo.png'}
                  alt=""
                />
              </span>

              <div className="info">
                <div style={{ left: 20, top: 94, position: 'absolute', textAlign: 'center' }}>
                  {
                    userType === 'PARTNER' ?
                      <em style={{ width: isMobile ? 'auto' : 60, textAlign: 'center' }}>
                        <a
                          href={`/members/${data.member.uuid}?candidateId=${data.id}&candidatePoolId=${id}`}
                          target="_blank"
                          onClick={() => {
                            setTimeout(() => {
                              refresh();
                            }, 1000);
                          }}
                        >
                          {data.member.nickname}
                        </a>
                      </em>
                      :
                      <em style={{ width: isMobile ? 'auto' : 60 }}>{data.member.nickname}</em>
                  }
                </div>
              </div>
            </>
        }

        {/* {
          userType === 'PARTNER' &&
          <>
            <input
              type="checkbox"
              id={`ch${data.id}`}
              onChange={onBookmark}
              checked={isFollow}
            />
            <label htmlFor={`ch${data.id}`} style={{ width: 88, top: isMobile ? 24 : 16 }}>
              {
                isFollow ?
                  <span className="chk">관심인재 설정</span>
                  :
                  <span className="add">관심인재 설정</span>
              }
            </label>
          </>
        } */}

        <Info className="info">
          {/* <div style={{ width: '100%', marginBottom: 8 }}>
            <span
              style={{ width: 108, background: '#e5fc70', height: 24, lineHeight: '24px', borderRadius: 4, fontWeight: 'bold', textAlign: 'center', justifyContent: 'center', fontSize: 13 }}
            >
              커피챗 희망인재
            </span>

            <span
              style={{ width: 108, background: '#5042f5', height: 24, lineHeight: '24px', borderRadius: 4, fontWeight: 'bold', textAlign: 'center', justifyContent: 'center', fontSize: 13, color: 'white', marginLeft: 8 }}
            >
              회사 관심인재
            </span>
          </div> */}

          {
            userType === 'PARTNER' &&
            <TagText>
              {data.can_coffee_chat && '#커피챗 희망인재에요'} #{data.member.job_category} #{data.member.job_subcategory}
            </TagText>
          }

          <ul style={{ marginTop: userType === 'MEMBER' ? -12 : 8 }}>
            <li>
              <p>
                <strong>{'<지원동기>'}</strong>
              </p>
              <p style={{ marginTop: 10 }}>
                {data.motivation}
              </p>
            </li>

            {
              (userType === 'PARTNER' && data.join_hope !== null) &&
              <li style={{ minHeight: 'auto' }}>
                <p onClick={() => setOpenInterests(!isOpenInterests)} style={{ cursor: 'pointer' }}>
                  <strong>{'<관심정도>'}</strong>
                  <KeyboardArrowDownIcon
                    style={{ position: 'absolute', right: 16, top: 16 }}
                  />


                  {
                    isMobile && <br />
                  }
                  <span style={{ marginLeft: isMobile ? 0 : 8, opacity: 0.5 }}>
                    ❶ 전혀 아니다, ❸ 보통, ❺ 매우 그렇다
                  </span>
                </p>

                {
                  isOpenInterests &&
                  <RatingSection>
                    <div className="rating-child">
                      {/* <strong>관심척도</strong> */}
                      <div style={{ justifyContent: 'space-between', marginBottom: 8, alignItems: 'center', marginTop: 4, display: 'flex' }}>
                        <span style={{ marginRight: 8 }}>비전에 공감합니다</span>
                        <Rating name="read-only" value={data.vision_attractiveness} readOnly size='small' />
                      </div>
                      <div style={{ justifyContent: 'space-between', marginBottom: 8, alignItems: 'center', display: 'flex' }}>
                        <span style={{ marginRight: 8 }}>서비스/제품을 좋아합니다</span>
                        <Rating name="read-only" value={data.product_attractiveness} readOnly size='small' />
                      </div>
                      <div style={{ justifyContent: 'space-between', marginBottom: 8, alignItems: 'center', display: 'flex' }}>
                        <span style={{ marginRight: 8 }}>문화에 관심이 있습니다</span>
                        <Rating name="read-only" value={data.culture_attractiveness} readOnly size='small' />
                      </div>
                      <div style={{ justifyContent: 'space-between', marginBottom: 8, alignItems: 'center', marginTop: 4, display: 'flex' }}>
                        <span style={{ marginRight: 8 }}>입사를 고려해보고 싶습니다</span>
                        <Rating name="read-only" value={data.join_hope} readOnly size='small' />
                      </div>
                      <div style={{ justifyContent: 'space-between', marginBottom: 8, alignItems: 'center', display: 'flex' }}>
                        <span style={{ marginRight: 8 }}>프로젝트를 함께 해보고 싶습니다</span>
                        <Rating name="read-only" value={data.work_hope} readOnly size='small' />
                      </div>
                      <div style={{ justifyContent: 'space-between', marginBottom: 8, alignItems: 'center', display: 'flex' }}>
                        <span style={{ marginRight: 8 }}>함께할 수 있는 방향을 같이 모색하고 싶습니다</span>
                        <Rating name="read-only" value={data.collaboration_hope} readOnly size='small' />
                      </div>
                    </div>

                    {/* <div className="rating-child">
                      <strong>희망척도</strong>
                      <div style={{ justifyContent: 'start', alignItems: 'center', height: 20, marginTop: 4, display: 'flex' }}>
                        <span style={{ marginRight: 8 }}>입사</span>
                        <Rating name="read-only" value={5} readOnly size='small' />
                      </div>
                      <div style={{ justifyContent: 'start', alignItems: 'center', height: 20, display: 'flex' }}>
                        <span style={{ marginRight: 8 }}>단기협업</span>
                        <Rating name="read-only" value={5} readOnly size='small' />
                      </div>
                      <div style={{ justifyContent: 'start', alignItems: 'center', height: 20, display: 'flex' }}>
                        <span style={{ marginRight: 8 }}>사이드잡</span>
                        <Rating name="read-only" value={2} readOnly size='small' />
                      </div>
                    </div> */}
                  </RatingSection>
                }
              </li>
            }

            {
              (userType === 'PARTNER' && data.member.questions.length > 0) &&
              <li style={{ minHeight: 'auto' }}>
                <p onClick={() => setOpenQNA(!isOpenQNA)} style={{ cursor: 'pointer' }}>
                  <strong>{'<궁금한 점이 있어요>'}</strong>
                  <KeyboardArrowDownIcon
                    style={{ position: 'absolute', right: 16, top: 16 }}
                  />

                  {
                    isMobile && <br />
                  }
                  <span style={{ marginLeft: isMobile ? 0 : 8, opacity: 0.5 }}>
                    질문에 대한 답변은 메일로 가능합니다
                  </span>
                </p>
                {
                  isOpenQNA &&
                  <p style={{ marginTop: 10, lineHeight: '24px' }}>
                    {data.member.questions.map((v: any) => <>
                      - {v.question}
                      <br />
                    </>)}
                  </p>
                }
              </li>
            }
          </ul>
        </Info>

        <hr />

        {
          userType === 'PARTNER' &&
          <div className="menu-footer" style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
            <button
              type="button"
              style={{ width: '49%', background: '#e5fc70', height: 38, borderRadius: 4, fontWeight: 'bold' }}
              onClick={() => {
                window.location.href = `/members/${data.member.uuid}?candidateId=${data.id}&candidatePoolId=${id}&coffeechat=true`;
              }}
            >
              커피챗 제안하기
            </button>
            <button
              type="button"
              style={{ width: '49%', background: '#5042f5', height: 38, borderRadius: 4, fontWeight: 'bold', color: 'white' }}
              onClick={() => {
                // window.location.href = `/members/${data.member.uuid}?candidateId=${data.id}&candidatePoolId=${id}&suggesting=true`;
                window.location.href = `/recruits/suggest?member=${data.member.uuid}`;
              }}
            >
              협업제안 보내기
            </button>
          </div>
        }
      </Section>

      {/* <p
        style={{
          position: 'absolute',
          color: '#5539ff',
          fontSize: 14,
          cursor: 'pointer',
          right: 24,
          top: 32,
          lineHeight: '20px',
        }}
        onClick={() => {
          window.location.href = `/members/${data.member.uuid}?candidateId=${data.id}&candidatePoolId=${id}&suggesting=true`;
        }}
      >
        협업 제안하기
      </p> */}

      {/* {
        userType === 'MEMBER' &&
        <>
          <input type="checkbox" id={`ch${data.pk}`} onChange={onChange} checked={isCheck} />
          <label htmlFor={`ch${data.pk}`}>
            {
              isCheck ?
                <span className="chk">세컨팀</span>
                :
                <span className="add">추가</span>
            }
          </label>
        </>
      } */}
    </MemberCard>
  );
};

export default CoWorkerConfigCard;
