const AgreementMarketingField = (props: {
  value: boolean,
  onChange: () => void
}) => {
  return (
    <div className="agree" style={{ marginTop: -32 }}>
      <input type="checkbox" id="agree" checked={ props.value } onChange={ props.onChange } />
      <label htmlFor="agree">마케팅 정보 수신 동의</label>
    </div>
  )
}

export default AgreementMarketingField;