/*
  markup : rec_sug_modify.html
*/
import { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import { IAppStore } from "../../stores/AppStore";
import { IRecruitListStore } from '@stores/RecruitListStore';
import { useForm, FormProvider } from "react-hook-form";
import { useQuery } from '../../utils/hooks';
import SuggestMemberCard from './components/SuggestMemberCard';
import { IMemberProfileModel } from '../profile/models/MemberProfileModel';
import SuggestTitleForm from './components/SuggestTitleForm';
import SuggestDescForm from './components/SuggestDescForm';
import SuggestWorkForm from './components/SuggestWorkForm';
import SuggestFrameworkForm from './components/SuggestFrameworkForm';
import SuggestToolForm from './components/SuggestToolForm';
import SuggestFileForm from './components/SuggestFileForm';
import SuggestSprintRangeForm from './components/SuggestSprintRangeForm';
import SuggestSprintCostForm from './components/SuggestSprintCostForm';
import SuggestSprintCountForm from './components/SuggestSprintCountForm';
import recruitAPI from '../../services/recruitAPI';
import { useHistory } from 'react-router';
import axios from 'axios';
import ConfirmModal from './components/ConfirmModal';
import CompleteModal from './components/CompleteModal';
import { IPartnerProposalModel } from './models/PartnerProposalModel';
import ModifyNoticeModal from './components/ModifyNoticeModal';
import DocumentNoticeModal from './components/DocumentNoticeModal';

interface IProps {
  appStore: IAppStore;
}

export interface ISuggestForm {
  title: string;
  data: IMemberProfileModel;
  proposal?: IPartnerProposalModel;
}

const RecruitSuggestionModifyPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const { recruitListStore, showLoader, hideLoader } = (props as IProps).appStore;
  const { uuid, member, partnerAccount, getPartnerProposal, proposal, getPartnerAccount, getMember } = (recruitListStore as IRecruitListStore);
  const [isOpenType, setOpenType] = useState(false);
  const [isOpenContract, setOpenContract] = useState(false);

  const history = useHistory();
  const query = useQuery();
  const memberUUID = query.get('member');
  const proposalUUID = query.get('proposal');
  const [createdProposalUUID, setCreatedProposalUUID] = useState('');

  const form = useForm();
  const { handleSubmit, watch, setValue, formState: { isDirty } } = form;

  const [isOpen, setOpen] = useState(false);
  const [isOpenDocument, setOpenDocument] = useState(false);
  const [isOpenModify, setOpenModify] = useState(false);

  const [data, setData] = useState<any>({});
  const [fileOnlyReferences, setFileOnlyReferences] = useState<any>({});

  useEffect(() => {
    getPartnerAccount();

    if (memberUUID) {
      getMember(memberUUID);
    } else if (proposalUUID) {
      getPartnerProposal(proposalUUID);
    } else {
      alert('잘못된 접근입니다.');
      window.location.href = '/';
    }
  }, []);

  useEffect(() => {
    setOpenDocument(!partnerAccount?.has_requirements_submitted);
  }, [partnerAccount]);

  useEffect(() => {
    if (!proposal) return;

    for (const [k, v] of Object.entries(proposal)) {
      if (k === 'references') {
        continue;
      }

      setValue(k, v);
    }
  }, [proposal]);

  if (!member) {
    return <></>;
  }

  const onLoadProjectInfo = () => {
    // todo
  };

  const onSubmit = async (data: object) => {
    const fileOnlyReferences: any[] = [];
    for (const [k, v] of Object.entries(data)) {
      switch (k) {
        case 'title':
          if (v.length < 1) {
            console.log('프로젝트명을 입력해주세요.');
            return;
          }
          break;
        case 'description':
          if (v.length < 1) {
            alert('프로젝트 설명을 입력해주세요.');
            return;
          }
          break;
        case 'work_description':
          if (v.length < 1) {
            alert('주요 업무를 입력해주세요.');
            return;
          }
          break;
        case 'frameworks':
          if (member.job_categories?.[0]?.parent === '개발자' && (!v || v.length < 1)) {
            alert('프레임워크를 입력해주세요.');
            return;
          }
          break;
        case 'tools':
          if (!v || v.length < 1) {
            alert('툴을 입력해주세요.');
            return;
          }
          break;
        case 'working_hours':
          if (!v || isNaN(v)) {
            alert('희망 거래 시간을 입력해주세요.');
            return;
          }
          break;
        case 'wage':
          if (!v || isNaN(v)) {
            alert('주급을 입력해주세요.');
            return;
          }
          break;
        case 'duration':
          if (!v || isNaN(v)) {
            alert('희망 협업 기간 입력해주세요.');
            return;
          }
          break;
        case 'references':
          const linkOnlyReferences: any[] = [];
          for (const reference of (v || [])) {
            const { type, link } = reference;

            if (type === 'LINK') {
              if (link) {
                linkOnlyReferences.push({
                  type: 'LINK',
                  link: link.startsWith('http') ? link : `http://${link}`,
                });
              }
              continue;
            }

            if (link) {
              fileOnlyReferences.push({
                type: 'FILE',
                link,
                name: reference.name || '',
              });
            }
          }

          if (linkOnlyReferences.length > 0) {
            data = { ...data, references: linkOnlyReferences };
          } else {
            // @ts-ignore
            delete data.references;
          }
          break;
      }
    }

    if (!Object.keys(data).includes('working_hours')) {
      alert('희망 거래 시간을 입력해주세요.');
      return;
    }

    if (!Object.keys(data).includes('wage')) {
      alert('주급을 입력해주세요.');
      return;
    }

    if (!Object.keys(data).includes('duration')) {
      alert('희망 협업 기간 입력해주세요.');
      return;
    }

    if (proposal) {
      callModifyAPI(data, fileOnlyReferences);
    } else {
      setData(data);
      setFileOnlyReferences(fileOnlyReferences);
      setOpen(true);
    }
  };

  const callModifyAPI = async (data: any, fileOnlyReferences: any) => {
    if (!validate(data)) {
      return;
    }

    if (!isDirty) {
      window.location.href = `/recruits/partner/suggest/${proposalUUID}`;
      return;
    };

    showLoader();
    await recruitAPI.updateProposal(uuid, proposalUUID!, { ...data, member: memberUUID });
    for (const { link, name } of fileOnlyReferences) {
      const form = new FormData();
      form.append('file', link);

      await axios.post(
        `partner/${uuid}/proposals/${proposalUUID}/reference-files`,
        form,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
    }
    hideLoader();

    window.location.href = `/recruits/partner/suggest/${proposalUUID}`;
  };

  const callSubmitAPI = async () => {
    if (!validate(data)) {
      return;
    }

    showLoader();
    try {
      const { uuid: proposalUUID } = await recruitAPI.createProposal(uuid, { ...data, member: memberUUID });
      for (const { link, name } of fileOnlyReferences) {
        const form = new FormData();
        form.append('file', link);

        await axios.post(
          `partner/${uuid}/proposals/${proposalUUID}/reference-files`,
          form,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
      }

      setCreatedProposalUUID(proposalUUID);
    } catch (e) {
      alert('오류가 발생했습니다. 다시 시도해주세요!');
    }
    hideLoader();
  };

  const validate = (data: any): Boolean => {
    return Boolean(data.working_hours) && Boolean(data.wage) && Boolean(data.duration);
  }

  const onSelectType = (value: number) => {
    setValue('type', value, { shouldDirty: true });
    setOpenType(false);
  }

  const onSelectContract = (value: string) => {
    setValue('contract_method', value, { shouldDirty: true });
    setOpenContract(false);
  }

  return (
    <FormProvider {...form}>
      <div className="member-wrap">
        <div className="top-box in-prev">
          <div className="btn-prev">
            {/* <a href="javascript:history.back()"><span>이전 페이지</span></a> */}
          </div>
          <h2><b>{member.nickname}</b>의 협업 제안서</h2>
        </div>
        <div className="pjt-info">
          <SuggestMemberCard
            uuid={memberUUID || ''}
            member={member}
            projectCount={member.current_project_count || 0}
          />



          <h4>계약 방식</h4>
          <div className="sug-type">
            <label htmlFor="sugType">계약 방식<i className="ness"><span>필수</span></i></label>
            <div className={`sel-w ${isOpenContract ? 'selected' : ''}`}>
              <button type="button" id="sugType" onClick={() => setOpenContract(!isOpenContract)} style={{ color: watch('contract_method') ? 'black' : 'auto' }}>
                {
                  Boolean(watch('contract_method')) ?
                    (watch('contract_method') === 'SECONDTEAM' ?
                      '주급제 정산(세컨팀을 통해 주급제 정산 방식)' :
                      '단순 매칭(고객사가 멤버와 직접 계약 및 정산)')
                    :
                    '멤버와 거래할 계약 방식을 선택하세요'
                }
              </button>

              {
                isOpenContract &&
                <div className="opt-li">
                  <p>계약 방식</p>
                  <ul>
                    <li className={watch('contract_method') === 'DIRECT' ? "selected" : ''} onClick={() => onSelectContract('DIRECT')}><a style={{ cursor: 'pointer' }}>단순 매칭(고객사가 멤버와 직접 계약 및 정산)</a></li>
                    <li className={watch('contract_method') === 'SECONDTEAM' ? "selected" : ''} onClick={() => onSelectContract('SECONDTEAM')}><a style={{ cursor: 'pointer' }}>주급제 정산(세컨팀을 통해 주급제 정산 방식)</a></li>
                  </ul>
                </div>
              }
            </div>
          </div>

          <h4>제안 유형</h4>
          <div className="sug-type">
            <label htmlFor="sugType">제안 유형<i className="ness"><span>필수</span></i></label>
            <div className={`sel-w ${isOpenType ? 'selected' : ''}`}>
              <button type="button" id="sugType" onClick={() => setOpenType(!isOpenType)} style={{ color: watch('type') ? 'black' : 'auto' }}>
                {
                  Boolean(watch('type')) ?
                    [
                      '',
                      '전문성 기반의 짧은 업무',
                      '아웃소싱 프로젝트 의뢰',
                      '프로젝트성 주급 계약',
                      '이직 헤드헌팅',
                      '흥미로운 일',
                    ][watch('type')]
                    :
                    '멤버가 희망하는 제안 유형만 선택하실 수 있습니다.'
                }
              </button>

              {
                isOpenType &&
                <div className="opt-li">
                  <p>멤버가 희망하는 제안 유형</p>
                  <ul>
                    <li hidden={!member.work_preferences.includes(1)} className={watch('type') === 1 ? "selected" : ''} onClick={() => onSelectType(1)}><a style={{ cursor: 'pointer' }}>전문성 기반의 짧은 업무</a></li>
                    <li hidden={!member.work_preferences.includes(2)} className={watch('type') === 2 ? "selected" : ''} onClick={() => onSelectType(2)}><a style={{ cursor: 'pointer' }}>아웃소싱 프로젝트 의뢰</a></li>
                    <li hidden={!member.work_preferences.includes(3)} className={watch('type') === 3 ? "selected" : ''} onClick={() => onSelectType(3)}><a style={{ cursor: 'pointer' }}>프로젝트성 주급 계약</a></li>
                    <li hidden={!member.work_preferences.includes(4)} className={watch('type') === 4 ? "selected" : ''} onClick={() => onSelectType(4)}><a style={{ cursor: 'pointer' }}>이직 헤드헌팅</a></li>
                    <li hidden={!member.work_preferences.includes(5)} className={watch('type') === 5 ? "selected" : ''} onClick={() => onSelectType(5)}><a style={{ cursor: 'pointer' }}>흥미로운일</a></li>
                  </ul>
                </div>
              }
            </div>
          </div>

          <h4>프로젝트 정보</h4>
          {/* <p className="info-road">
            <button type="button" onClick={onLoadProjectInfo}>프로젝트 정보 불러오기</button>
          </p> */}

          <SuggestTitleForm
            title="프로젝트명*"
            data={member}
          />

          <SuggestDescForm
            title="프로젝트 설명*"
            data={member}
          />

          <SuggestWorkForm
            title="주요업무*"
            data={member}
          />

          {
            member.job_categories?.[0]?.parent === '개발자' &&
            <SuggestFrameworkForm
              title="언어/프레임워크*"
              data={member}
            />
          }

          <SuggestToolForm
            title="협업 시 사용하는 툴*"
            data={member}
          />

          <SuggestFileForm
            title="관련 자료"
            data={member}
            proposal={proposal}
          />

          <h4>계약 정보</h4>
          <SuggestSprintCountForm
            title="희망 협업 기간*"
            data={member}
          />

          <SuggestSprintRangeForm
            title="희망 거래 시간*"
            data={member}
          />

          <SuggestSprintCostForm
            title="주급*"
            data={member}
            proposal={proposal}
          />

          <div className="btn-sticky">
            <a style={{ cursor: 'pointer' }} onClick={proposal ? () => setOpenModify(true) : handleSubmit(onSubmit)}>
              {proposal ? '수정하기' : '제안하기'}
            </a>
          </div>
        </div>
      </div>

      <CompleteModal
        isOpen={Boolean(createdProposalUUID)}
        onClose={() => {
          // window.location.href = `/recruits/partner/suggest/${createdProposalUUID}`;
          window.location.href = `/recruits/partner#submitted`;
        }}
      />

      <ConfirmModal
        nickname={member.nickname || ''}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onSubmit={callSubmitAPI}
      />

      <ModifyNoticeModal
        isOpen={isOpenModify}
        nickname={member.nickname || ''}
        onSubmit={handleSubmit(onSubmit)}
        onClose={() => setOpenModify(false)}
      />

      <DocumentNoticeModal
        isOpen={isOpenDocument}
        onClose={() => setOpenDocument(false)}
      />
    </FormProvider>
  );
}));

export default RecruitSuggestionModifyPage;
