import { types } from "mobx-state-tree";
import { STATE, STATE_LIST } from '../utils/constants';


export const StateModel = types.model({
  state: types.optional(types.enumeration("State", STATE_LIST), STATE.done),
  error: types.optional(types.string, ""),
})
.views((self) => ({
  get isDone() {
    return self.state === STATE.done || false;
  }
}))
.actions(self => ({
  setPending() {
    self.state = STATE.pending
  },
  setDone() {
    self.state = STATE.done
  },
  setError(error: string) {
    self.state = STATE.error;
    self.error = error;
  }
}));
