import { flow, types, Instance, getParent } from "mobx-state-tree";

export const JobModel = types.model('Job', {
  id: types.number,
  name: types.string,
  parent: types.string,
  order: types.number,
});

export interface IJobModel extends Instance<typeof JobModel> {};
