import { flow, types, Instance, getParent } from "mobx-state-tree";
import { IProfileStore } from "../../../stores/profileStore";

import { JobTypeOptions } from '@utils/constants';
import { updateStringArray } from '@utils/utils';
import profileAPI from "@services/profileAPI";

const MemberModel = types.model({
  job_category: types.string,
  nickname: types.string,
});

const ProjectHistoryModel = types.model({
  title: types.string,
  date_matched: types.string,
  end_date: types.maybeNull(types.string),
  member: MemberModel,
});

export const PartnerProfileModel = types.model({
  isUpdated: types.optional(types.boolean, false),  // 수정 변경 유무

  avatar: types.maybeNull(types.string), // 프로필 이미지
  company_name: types.string, // 회사 이름,
  industry: types.string, // 서비스 분야
  homepage: types.maybeNull(types.string), // 홈페이지 주소
  introduction: types.string, // 회사소개
  team_culture: types.maybe(types.string), // 조직문화
  job_type: types.array(types.enumeration('JobType', JobTypeOptions.map(op => op.value))),  // 협업 가능한 근무조건
  coworking_tools: types.array(types.string), // 협업툴
  frameworks: types.array(types.string), // 언어/프레임워크
  website: types.optional(types.string, () => ''), // 홈페이지
  contact_email: types.string, // 연락처
  established_at: types.string,  // 설립 연도
  service_description: types.string, // 서비스 소개
  project_history: types.array(ProjectHistoryModel), // 협업 히스토리
  has_unread_messages: types.boolean,
  has_project_hiring_permission: types.boolean,
  is_active: types.boolean,
  balance: types.number,
})
.views(self => ({
  get uuid(): string {
    return (getParent(self) as IProfileStore).uuid;
  },
  get root(): IProfileStore {
    return getParent(self) as IProfileStore
  },
  get isCompanyFieldEror(): boolean {
    return this.root.clickedUpdate && !self.company_name;
  },
  get isIndustryFieldError(): boolean {
    return this.root.clickedUpdate && !self.industry;
  },
  get isHomepageFieldError(): boolean {
    return this.root.clickedUpdate && !self.homepage;
  },
  get isIntroductionFieldError(): boolean {
    return this.root.clickedUpdate && !self.introduction;
  },
}))
.actions(self => ({
  setIndustry(value: string) {
    self.industry = value;
    self.isUpdated = true;
  },
  setHomepage(value: string) {
    self.homepage = value;
    self.isUpdated = true;
  },
  setCompanyName(value: string) {
    self.company_name = value;
    self.isUpdated = true;
  },
  setServiceDescription(value: string) {
    self.service_description = value;
    self.isUpdated = true;
  },
  setIntroduction(introduction: string) {
    self.introduction = introduction;
    self.isUpdated = true;
  },
  setTeamCulture(team_culture: string) {
    self.team_culture = team_culture;
    self.isUpdated = true;
  },
  setJobType(jobType: string, selected: boolean) {
    self.job_type.replace(updateStringArray(self.job_type, jobType, selected));
    self.isUpdated = true;
  },
  setCoworkingTools(tool: string, selected: boolean) {
    self.coworking_tools.replace(updateStringArray(self.coworking_tools, tool, selected));
    self.isUpdated = true;
  },
  setWebsite(website: string) {
    self.website = website;
    self.isUpdated = true;
  },
  setContactEmail(contact_email: string) {
    self.contact_email = contact_email;
    self.isUpdated = true;
  },
  setEstablishedAt(established_at: string) {
    self.established_at = established_at;
    self.isUpdated = true;
  },
  setFrameworks(framework: string, selected: boolean) {
    self.frameworks.replace(updateStringArray(self.frameworks, framework, selected));
    self.isUpdated = true;
  }
}))
.actions(self => ({
  changeAvatar: flow(function* update(frm: FormData) {
    if(!self.uuid) {
      return;
    }

    self.isUpdated = true;
    try {
      const data = yield profileAPI.updatePartnerAvatar(self.uuid, frm);
      console.log(data);
      self.avatar = data.avatar;
    } catch(e) {
    }
  }),
  update: flow(function* update() {
    if(!self.uuid) {
      return;
    }
    try {
      // 수정
      yield profileAPI.updatePartnerAccount(self.uuid, {
        introduction: self.introduction,
        team_culture: self.team_culture,
        job_type: self.job_type,
        coworking_tools: self.coworking_tools,
        frameworks: self.frameworks,
        website: self.website,
        contact_email: self.contact_email,
        established_at: self.established_at,
        homepage: (self.homepage || '').startsWith('http') ? self.homepage : `http://${self.homepage}`,
        industry: self.industry,
        company_name: self.company_name,
        service_description: self.service_description,
      });

      // applySnapshot(self, response);
      self.isUpdated = false;
    } catch(e) {
      throw e;
    }
  })
}));
export interface IPartnerProfileModel extends Instance<typeof PartnerProfileModel> {};