import Avatar from '../../../components/Avatar';
import { IMessageMemberModel, IMessageModel, IMessagePartnerModel } from '../models/MessageModel';
import dayjs from 'dayjs';

interface Props {
  data: IMessageModel;
  member?: IMessageMemberModel | undefined;
  partner?: IMessagePartnerModel | undefined;
  onClick: () => void;
  isSelect?: boolean;
  isMember?: boolean;
  isNew?: boolean;
}

export default ({ data, member, partner, onClick, isMember, isSelect }: Props) => {
  return (
    <li
      onClick={onClick}
      className={[isSelect ? 'selected' : '', data.status === 'REJECTED' ? 'reject' : ''].filter(v => v).join(' ')}
    >
      <a href="#">
        {
          data.has_unread_messages &&
            <span className="new"><span>신규메세지</span></span>
        }
        <em style={{ display: 'flex', maxWidth: '70%' }}>{data.title}</em>
        <div>
          <Avatar
            data={isMember ? partner : member}
          />
          <div className="info">
            <strong>{data.member?.nickname || data.partner?.company_name || '이름없음'}</strong>
            <p style={{ maxWidth: '70%' }}>{data.recent_message || ''}</p>
          </div>
        </div>
        
        {
          data.status === 'SUBMITTED' &&
          <span className="prog">제안중</span>
        }
        {
          data.status === 'ARRANGING' &&
          <span className="prog ing">조율중</span>
        }
        {
          data.status === 'MATCHED' &&
          <span className="prog ok">채용확정</span>
        }
        {
          data.status === 'REJECTED' &&
          <span className="prog rej">거절</span>
        }
        {
          Boolean(data.updated_at) &&
            <span className="time">
              {dayjs(data.updated_at).format('MM.DD HH:mm')}
            </span>
        }
      </a>
    </li>
  );
};