import { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import onboardingAPI from "../../services/onboardingAPI";
import { useFormContext } from "react-hook-form";
import { timeList } from '../../utils/constants';

interface Props extends OnboardingProps {
  title?: string;
  text?: string;
}

const noticeMap = [
  '',
  '컨설팅 등 단기성 채용에 적합합니다.',
  '본업이 있는 상태에서 부업으로 참여하실 수 있는 협업에 적합합니다.',
  '본업이 있는 상태에서 부업으로 참여하실 수 있는 협업에 적합합니다.',
  '풀타임으로 진행되는 협업에 적합합니다.',
  '풀타임으로 진행되는 협업에 적합합니다.',
]

export default observer(({ profile, title, text }: Props) => {
  const { register, getValues, setValue, watch } = useFormContext();
  const rangeWatcher = watch('working_hour_range_per_week', profile.working_hour_range_per_week);

  const onChangeWorkingHour = (e: any) => {
    const timeText = e.target.value;
    if (isNaN(timeText)) {
      return;
    }

    setValue('working_hours_per_week', Number(timeText));
  };

  const onChangeWorkingHourRange = (e: any) => {
    const { value } = e.target;
    setValue('working_hour_range_per_week', Number(value));
  };

  return (
    <>
      <h4>{title}</h4>
      <div className="time-choice">
        <div className="in-form">
          <select onChange={onChangeWorkingHourRange} defaultValue={profile.working_hour_range_per_week || '선택해주세요'}>
            {
              Boolean(profile.working_hour_range_per_week) ||
                <option>선택해주세요</option>
            }
            {
              timeList.map((time, index) => (
                <option value={index + 1}>{time}</option>
              ))
            }
          </select>
          <div className="time">
            <input type="text" placeholder="선택 입력" onChange={onChangeWorkingHour} defaultValue={profile.working_hours_per_week ? String(profile.working_hours_per_week) : ''} />
            <span>시간</span>
          </div>
        </div>

        {
          Boolean(rangeWatcher) &&
            <div className="desc">
              <p>☝️ {noticeMap[rangeWatcher]}</p>
            </div>
        }
      </div>
    </>
  );
});
