import { types, Instance, flow } from "mobx-state-tree";

import AccountStore from '@stores/AccountStore';
import UserStore from '@stores/UserStore';
import JoinStore from '@stores/JoinStore';
import profileStore from '@stores/profileStore';
import MemberListStore from '@stores/MemberListStore';
import RecruitListStore from "@stores/RecruitListStore";
import OnboardingStore from "@stores/onboardingStore";
import MessageStore from "@stores/MessageStore";
import CalcStore from "@stores/CalcStore";
import PollStore from "@stores/PollStore";
import RegularSurveyStore from "@stores/RegularSurveyStore";

const AppStore = types.model("AppStore", {
    isLoading: false,

    accountStore: types.optional(AccountStore, {}),
    userStore: types.optional(UserStore, {}),
    joinStore: types.optional(JoinStore, {}),
    profileStore: types.optional(profileStore, {}),
    memberListStore: types.optional(MemberListStore, {}),
    recruitListStore: types.optional(RecruitListStore, {}),
    onboardingStore: types.optional(OnboardingStore, {}),
    messageStore: types.optional(MessageStore, {}),
    calcStore: types.optional(CalcStore, {}),
    pollStore: types.optional(PollStore, {}),
    regularSurveyStore: types.optional(RegularSurveyStore, {}),
  }
)
.actions(self => ({
  showLoader: flow(function* () {
    self.isLoading = true;
  }),
  hideLoader: flow(function* () {
    self.isLoading = false;
  }),
}))
;

export interface IAppStore extends Instance<typeof AppStore> {};
export default AppStore;
