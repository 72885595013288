import { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import onboardingAPI from "../../services/onboardingAPI";
import { useFormContext } from "react-hook-form";

interface Props extends OnboardingProps {
  title?: string;
  text?: string;
}

export default observer(({ profile, title, text }: Props) => {
  const { register, getValues, setValue, watch } = useFormContext();
  const residenceWatcher = watch('residence') || profile.residence;

  const onCheckKorea = () => {
    setValue('residence', 'DOMESTIC');
  };

  const onCheckAbroad = () => {
    setValue('residence', 'ABROAD');
  };
  

  return (
    <>
      <h4>{title}</h4>
      <div className="rcho-w">
        <div>
          <input type="radio" name="spot" id="kor" onClick={onCheckKorea} defaultChecked={profile.residence === 'DOMESTIC'} /><label htmlFor="kor">한국</label>
        </div>
        <div>
          <input type="radio" name="spot" id="for"  onClick={onCheckAbroad} defaultChecked={profile.residence === 'ABROAD'} /><label htmlFor="for">해외</label>
          <input type="text" placeholder="(선택) 거주 국가 이름" title="거주 국가 이름" {...register('country_of_residence')} defaultValue={profile.country_of_residence} disabled={residenceWatcher === 'DOMESTIC'} />
        </div>
      </div>
    </>
  );
});
