import { observer } from "mobx-react";
import { withRouter } from "react-router";
import { useLocation } from 'react-router-dom';

import { IAppStore } from "../stores/AppStore";
import { IHistoryProps } from '../interfaces/interfaces';

import Header from './Header';
import Footer from './Footer';


interface IProps extends IHistoryProps {
  appStore: IAppStore;
}


const Layout: React.SFC<IProps> = observer(({ children, ...props }) => {
  const { pathname } = useLocation();
  const { userStore } = (props as IProps).appStore;
  const { user } = userStore;

  return (
    <>
      <Header user={ user } />

      <hr />
      <main id="snContent">
        { children }
      </main>
      <hr />
      
      {
        (pathname === '/' || pathname === '/faq') && <Footer />
      }
    </>
  )
});

export default withRouter(Layout);
