import { types, Instance } from "mobx-state-tree";
import { CalcCountModel } from "./CalcCountModel";
import { PaymentModel } from "./PaymentModel";

export const CalcResponseModel = types.model({
  total_payment: types.number,
  balance: types.number,
  counts: CalcCountModel,
  count: types.number,
  next: types.maybeNull(types.string),
  previous: types.maybeNull(types.string),
  results: types.array(PaymentModel),
})
.views(self => ({
  
}));

export interface ICalcResponseModel extends Instance<typeof CalcResponseModel> {};
