import { ICandidate } from "@models/Candidate";
import { ICoWorker } from "@models/CoWorker";
import portfolioAPI from "@services/portfolioAPI";
import profileAPI from "@services/profileAPI";
import { AUTH_USERTYPE } from "@utils/constants";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
// import { IMemberProfileModel } from "@models/MemberProfileModel";
import CoWorkerConfigCard from "./CoWorkerConfigCard";
import axios from "axios";

interface Props {
  id?: string;
  data: any;
}

const MySecondTeam = ({
  id,
  data,
}: Props) => {
  const [coWorkerList, setCoWorkerList] = useState<any[]>([1, 2, 3]);
  const [coworkerUpdatedAt, setCoworkerUpdatedAt] = useState<string>('');
  const [secondteamUpdatedAt, setSecondteamUpdatedAt] = useState<string>('');
  const topWorkerList = coWorkerList.filter(v => v.is_my_secondteam);
  const topCount = topWorkerList.length;

  const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';
  const [candidateList, setCandidateList] = useState<ICandidate[]>([]);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      const { data } = await axios.get(`/candidate-pools/${id}/candidates`);
      const candidateList = data.results as ICandidate[];
      setCandidateList(candidateList.filter(v => v.state === 'accepted'));
    } catch (err) {
      alert('잘못된 접근입니다.');
    }
  };

  return (
    <div className="conts-w2">
      <div className="tit-w">
        <h3>
          인재풀
          {
            Boolean(coworkerUpdatedAt) &&
            <span>업데이트 {dayjs(coworkerUpdatedAt).format('YYYY.MM.DD')}</span>
          }
        </h3>
        {/* {
          coWorkerList.length > 0 &&
          <div className="chk-st">
            <span className="c1" style={{ color: 'rgba(13, 18, 64, 0.6)' }}>동료 평가</span>
            <span className="c2" style={{ color: 'rgba(13, 18, 64, 0.6)' }}>협업 확인</span>
            <span className="c3" style={{ color: 'rgba(13, 18, 64, 0.6)' }}>레퍼런스 체크 가능</span>
          </div>
        } */}
      </div>
      <div className="with-member">
        {
          candidateList.length > 0 ?
            <ul>
              {
                candidateList.map(v => (
                  <CoWorkerConfigCard
                    id={id || ''}
                    data={v}
                    refresh={load}
                  />
                ))
              }
            </ul>
            :
            <div className="no-info">
              <p>아직 잠재인재풀 목록이 없습니다.</p>
            </div>
        }
      </div>

      {/* <div className="btn-more">
        <a href="#">더보기</a>
      </div> */}
    </div>
  );
}

export default MySecondTeam;
