import constantAPI from "@services/constantAPI";
import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { IPublicLog, PublicLog, PublicLogType } from "@models/PublicLog";
import { isMobile } from "react-device-detect";
import PopularCompanyList from "@components/PopularCompanyList";

const BannerList = styled.ul`
  @media only screen and (max-width: 786px) {
    & > li {
      min-width: 26% !important;
      max-width: 26% !important;
      padding: 0 10px !important;
    }
    margin: 0 auto;
    margin-top: 64px;
  }

  margin: 0 auto;
`;

const LogCard = styled.div`
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 32px;
  gap: 24px;

  width: 920px;
  height: 112px;

  background: #FFFFFF;
  border: 1px solid #EAECF0;
  box-shadow: 0px 0px 0px 4px #EFF8FF;
  border-radius: 16px;
  font-size: 18px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 16px;
`;

const StatusBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  align-items: center;
  justify-content: center;

  width: 82px;
  height: 60px;

  mix-blend-mode: normal;
  border-radius: 16px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

  font-weight: bold;
  font-size: 14px;

  background: #EFF8FF;
  color: #175CD3;
`;

const RecommendBox = styled(StatusBox)`
  background: #EFF8FF;
  color: #175CD3;
`;

const OfferedBox = styled(StatusBox)`
  background: #ECFDF3;
  color: #027948;
`;

const CandidateBox = styled(StatusBox)`
  background: #F2EDFF;
  color: #7C45F7;
`;

const ExplorerPage = () => {
  const [logList, setLogList] = useState<IPublicLog[]>([]);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const { data } = await axios.get(`/public-logs`);
    setLogList(data.results);
  };

  return (
    <div className="recommend-w">
      <div className="top-v" style={{ background: '#FAF9F6', marginTop: -128, paddingBottom: isMobile ? 'auto' : 0 }}>
        <h1 style={{ marginTop: 72, textAlign: 'center', fontSize: isMobile ? 28 : 36 }}>혁신적인 인재 전략을 가진 회사들이 함께합니다.</h1>
        <p style={{ marginTop: 12, textAlign: 'center', color: '#8E9496', fontSize: 18 }}>유능한 인재라면 채용부터 아웃소싱까지 {isMobile && <br />} 유연한 협업을 지향하는 회사들입니다.</p>

        <div className="landing-cnts-v3" style={{ margin: '-128px auto 0 auto', maxWidth: 1200 }}>
          <div className="partners corp">
            <BannerList>
              <PopularCompanyList />
            </BannerList>
          </div>
        </div>
      </div>

      <div className="landing-cnts-v3" style={{ marginTop: -128 }}>
        <div className="res-update">
          <div className="up-list">
            <ul>
              {
                logList.map((v: IPublicLog) => (
                  <li className="recom">
                    <span
                      className="logo"
                    // style={{ cursor: 'pointer' }}
                    // onClick={() => {
                    //   window.open('https://second-team.com/career/pool/2');
                    // }}
                    >
                      <img src={v.company_avatar} alt="&" />
                    </span>
                    <div className="info" style={{ textAlign: 'center' }}>
                      {
                        v.type === PublicLogType.RECOMMENDED &&
                        <p>
                          😀 {v.member_nickname}님의 프로젝트가 {v.company_name}에 추천되었어요
                        </p>
                      }

                      {
                        v.type === PublicLogType.OFFERED &&
                        <p>
                          😀 {v.member_nickname}님이 협업 제안을 받았어요
                        </p>
                      }

                      {
                        v.type === PublicLogType.CANDIDATE_REGISTERED &&
                        <p>
                          😀 {v.member_nickname}님이 {v.company_name}의 세컨팀 인재로 매칭되었어요
                        </p>
                      }
                    </div>

                    {
                      v.type === PublicLogType.RECOMMENDED &&
                      <RecommendBox>
                        ∙ 추천
                      </RecommendBox>
                    }

                    {
                      v.type === PublicLogType.OFFERED &&
                      <OfferedBox>
                        ∙ 제안
                      </OfferedBox>
                    }

                    {
                      v.type === PublicLogType.CANDIDATE_REGISTERED &&
                      <CandidateBox>
                        ∙ 매칭
                      </CandidateBox>
                    }
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExplorerPage;
