import { observer } from "mobx-react";
import { useState, useEffect } from "react";
import IntlTelInput from 'react-intl-tel-input';
import { IMemberAccountModel } from '../../pages/account/models/MemberAccountModel';
import accountAPI from '../../services/accountAPI';
import onboardingAPI from "../../services/onboardingAPI";
import { useTimer } from 'react-timer-hook';
import { AUTH_UUID } from "../../utils/constants";


interface Props {
  title: string;
  account: IMemberAccountModel;
};

const PhoneField = observer(({ title, account }: Props) => {
  const uuid = window.localStorage.getItem(AUTH_UUID) || '';

  const [phone, setPhone] = useState(account.phone);
  const [isValid, setValid] = useState(false);
  const [countryCode, setCountryCode] = useState('kr');
  const [code, setCode] = useState('');
  const [isDiff, setDiff] = useState(false);
  const {
    seconds,
    minutes,
    isRunning,
    pause,
    restart,
  } = useTimer({ expiryTimestamp: new Date(), autoStart: false, onExpire: () => {
    alert('인증번호가 만료되었습니다. 재시도해주세요.');
  }});

  const onSend = async () => {
    if (!isValid) {
      alert('유효한 전화번호를 입력해주세요.');
      return;
    }

    const time = new Date();
    time.setSeconds(time.getSeconds() + 600);
    restart(time);

    await onboardingAPI.sendAuthCode(uuid, phone);
  };

  const onCheck = async () => {
    try {
      await onboardingAPI.checkAuthCode(uuid, phone, code);
  
      setDiff(false);
      pause();
      
      alert('인증되었습니다.');
      accountAPI.updateMemberAccount(account.uuid, { phone });
      account.setPhone(phone);
    } catch (e) {
      alert('인증에 실패했습니다.');
    }
  };

  const onCacnel = () => {

  };

  return (
    <>
      <h4>{title}</h4>
      <div className="allow-dropdown intl-tel-input">
        <div className="tel">
          <div>

            {/* <IntlTelInput
              numberType="tel"
              value={phone}
              autoPlaceholder={false}
              defaultCountry={countryCode}
              preferredCountries={[]}
              onlyCountries={['kr']}
              onSelectFlag={(currentNumber, seletedCountryData) => {
                setCountryCode('kr');
                // setCountryCode(`${seletedCountryData.iso2}`);
              }}
              onPhoneNumberChange={(isValid, newNumber, countryData) => {
                const regExp = /^\d{2,3}\d{3,4}\d{4}$/;
                const phone = newNumber.replace(/[^0-9]/g, '');

                setPhone(phone);
                setValid(regExp.test(phone));

                setDiff(newNumber.replaceAll('-', '') !== account.phone.replaceAll('-', ''));
              }}
            /> */}
            {
              isDiff &&
                <button type="button" onClick={onSend}>인증번호 발송</button>
            }
          </div>
          
          {
            isRunning &&
              <div>
                <input type="tel" placeholder="인증번호를 입력해주세요." title="인증번호 입력" onChange={(e) => setCode(e.target.value)} />
                <button type="button" className="inc" onClick={onCheck}>인증번호 확인</button>
              </div>
          }
        </div>


        {
          isRunning &&
          <span className="inc-time">{minutes}:{seconds}</span>
        }
      </div>
      <p>해외 번호의 인증은 현재 준비 중입니다.</p>
    </>
  )
});

export default PhoneField;