import { types, Instance, getParent } from "mobx-state-tree";

import { IJoinStore } from '../../../stores/JoinStore';


export const TResource = {
  MNG: 'MNG',
  DES: 'DES',
  DEV: 'DEV',
  NONE: 'NONE'
}
export const JoinCompany = types.model({
    company: types.optional(types.string, ''),
    homepage: types.optional(types.string, ''),
    introduction: types.optional(types.string, ''),
    businessArea: types.optional(types.string, ''),
    industry: types.optional(types.string, ''),
    inHouseResource: types.optional(types.array(types.string), [])
  })
  .views(self => ({
    get root(): IJoinStore {
      return getParent(self) as IJoinStore
    },
    get isEditing() {
      if(self.company || self.businessArea || self.inHouseResource.length > 0) {
        return true;
      }
      return false;
    },
    get isCompanyFieldEror(): boolean {
      return this.root.clickedJoin && !self.company;
    },
    get isIndustryFieldError(): boolean {
      return this.root.clickedJoin && !self.industry;
    },
    get isHomepageFieldError(): boolean {
      return this.root.clickedJoin && !self.homepage;
    },
    get isIntroductionFieldError(): boolean {
      return this.root.clickedJoin && !self.introduction;
    },
    get isServiceTypesFieldEror(): boolean {
      console.log(self.businessArea)
      return this.root.clickedJoin && !self.businessArea;
    },
    get isInHouseResourceFieldEror(): boolean {
      return this.root.clickedJoin && self.inHouseResource.length === 0;
    },
    get isValid(): boolean {
      // TODO
      // console.log('isServiceTypesFieldEror');
      return true;
      // return !this.isServiceTypesFieldEror;
    }
  }))
  .actions(self => ({
    reset() {
      self.company = '';
      self.industry = '';
      self.homepage = '';
      self.introduction = '';
      self.inHouseResource.replace([]);
    },
    toJSON() {
      return {
        company_name: self.company,
        industry: self.industry,
        in_house_resource: self.inHouseResource.filter(resource => resource !== TResource.NONE),
        homepage: self.homepage.startsWith('http') ? self.homepage : `http://${self.homepage}`,
        introduction: self.introduction,
      }
    }
  }))
  .actions(self => ({
    setCompany(company: string) {
      self.company = company;
    },
    setHompage(homepage: string) {
      self.homepage = homepage;
    },
    setIntroduction(value: string) {
      self.introduction = value;
    },
    setIndustry(value: string) {
      self.industry = value;
    },
    setBusinessArea(businessArea: string) {
      self.businessArea = businessArea;
    },
    setOrganization(resources: Array<string>) {
      self.inHouseResource.replace(resources);
    }
  }));
  export interface IJoinCompany extends Instance<typeof JoinCompany> {};