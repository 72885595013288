import NotionContainer from "@components/NotionContainer";
import { ICandidatePool } from "@models/CandidatePool";
import { AUTH_USERTYPE } from "@utils/constants";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Loader from 'react-loader';
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { CTADisabledButton, CTAPurpleButton } from "./components/CTAButton";
import CTAFooter from "./components/CTAFooter";
import PoolRegistrationModal from "./components/PoolRegistrationModal";

const PoolPage = () => {
  let { id }: { id: string } = useParams();
  const [isOpen, setOpen] = useState(false);
  const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';

  const [notionMap, setNotionMap] = useState<any>();
  const [pool, setPool] = useState<ICandidatePool>();

  useEffect(() => {
    load();
  }, []);

  const loadNotion = async (notionId: string) => {
    const { data } = await axios.get(`https://function.second-team.com/notion-chunk?pageId=${notionId}`);
    setNotionMap(data);
  }

  const load = async () => {
    try {
      const { data } = await axios.get(`/candidate-pools/${id}`);
      setPool(data);

      const pool = data as ICandidatePool;
      if (dayjs(pool.open_at) > dayjs()) {
        alert('잘못된 접근입니다.');
        return window.location.href = '/career/pool';
      }

      await loadNotion((data as ICandidatePool).notion_url.split("/")[data.notion_url.split("/").length - 1]);
    } catch (err) {
      alert('잘못된 접근입니다.');
      window.location.href = '/career/pool';
    }
  }

  if (!notionMap || !pool) {
    return (
      <div className="landing-cnts">
        <Loader loaded={false} options={{ opacity: 0.5, color: '#5539ff' }} />
      </div>
    );
  }


  return (
    <>
      <div style={{ marginTop: 44 }} />
      <NotionContainer data={notionMap} />

      <CTAFooter>
        {
          (pool.is_registrable || !userType) ?
            <CTAPurpleButton
              onClick={() => {
                if (!userType) {
                  alert('잠재인재풀 신청을 위해서는 멤버 계정으로 로그인이 필요합니다');
                  window.location.href = '/login';
                  return;
                }

                setOpen(true);
              }}
            >
              {isMobile ? '신청하기' : '잠재인재풀 신청하기'}
            </CTAPurpleButton>
            :
            <CTADisabledButton>
              신청완료
            </CTADisabledButton>
        }
      </CTAFooter>

      <div style={{ height: 128 }} />

      <PoolRegistrationModal
        isOpen={isOpen}
        data={pool}
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
}

export default PoolPage;
