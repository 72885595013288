import axios from 'axios';
import { useState } from 'react';
import { IAppStore } from "../../../stores/AppStore";
import { IDetailModel } from '../models/DetailModel';
import StartDateCheckModal from './StartDateCheckModal';
import StartDateModal from './StartDateModal';


interface Props {
  date: string;
  wage: number | null;
  uuid: string;
  appStore: IAppStore;
  projectUUID: string;
  project: IDetailModel;
}

export default ({ date: _date, wage, uuid, appStore, projectUUID, project }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [isCheckOpen, setCheckOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [date, setDate] = useState(_date);

  const submitStartDate = async (startDate: string) => {
    setLoading(true);
    try {
      await axios.put(`/partner/${uuid}/projects/${projectUUID}/set-start-date`, {
        start_date: startDate.replaceAll('.', '-')
      });
      setOpen(false);

      window.location.href += "#start";
      window.location.reload();
    } catch (e) {
      alert('오류가 발생하였습니다. 다시 시도해주세요!');
    }
    setLoading(false);
  };

  return (
    <>
      <div className="cho-date">
        <label htmlFor="sDate">시작 예정일</label>
        <div className="d-w">
          <input type="text" readOnly id="sDate" value={date} onClick={() => setOpen(true)} />
          <button type="button" className="cal" onClick={() => setOpen(true)}>
            <span>날짜선택</span>
          </button>
        </div>
      </div>

      <StartDateModal
        isOpen={isOpen}
        appStore={appStore}
        startDate={date}
        onClose={() => setOpen(false)}
        onError={(date: string) => {
          setDate(date);
          setOpen(false);
          setCheckOpen(true);
        }}
        project={project}
        onSubmit={submitStartDate}
      />

      <StartDateCheckModal
        wage={wage}
        startDate={date}
        isOpen={isCheckOpen}
        onClose={() => setCheckOpen(false)}
        onSubmit={() => submitStartDate(date)}
      />
    </>
  );
}
