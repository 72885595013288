import { useForm } from 'react-hook-form';

interface Props {
  onSend: (content: string) => void;
  status: string,
}

export default ({ onSend, status }: Props) => {
  const { register, getValues, reset } = useForm();

  const onSubmit = () => {
    const content = getValues('content');
    if (!content) {
      return;
    }

    reset({
      content: '',
    });

    onSend(content);
  };

  return (
    <fieldset>
      <legend>메세지 입력</legend>
      <textarea 
        title="보낼 메세지 입력"
        placeholder="멤버에게 질문이 있다면,  메세지를 통해 물어보세요!"
        {...register('content')}
      />

      {
        status === 'REJECTED' ?
        <button type="submit" style={{ backgroundColor: 'rgba(13,18,64,0.07)' }}>전송</button>
        :
        <button type="submit" onClick={onSubmit}>전송</button>
      }
    </fieldset>
  );
};
