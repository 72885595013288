import { StateModel } from "@models/models";
import { ResGetServey } from "@models/responses/ResGetServey";
import pollAPI from "@services/PollAPI";
import { Instance, types, flow } from "mobx-state-tree";

const PollStore = StateModel.named('Poll')
  .props({
    step: 0,
    uuid: '',
    data: types.maybe(ResGetServey),
  })
  .actions(self => ({
    next: flow(function* () {
      self.step += 1;
    }),
    setUUID: flow(function* (data: string) {
      self.uuid = data;
    }),
    prev: flow(function* () {
      self.step -= 1;
    }),
    loadData: flow(function* (uuid: string) {
      try {
        const data = yield pollAPI.getPollByUUID(uuid);
        self.data = ResGetServey.create(data);
        return data;
      } catch (e) {
        throw e;
      }
    }),
  }));

export interface IPollStore extends Instance<typeof PollStore>{};

export default PollStore;
