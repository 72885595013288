// import { IMemberProfileModel } from "../models/MemberProfileModel";

import { AUTH_USERTYPE } from "@utils/constants";
import axios from "axios";
import { useEffect, useState } from "react";

interface Props {
  id: string;
  isMargin: boolean;
  windowWidth: number;
  data: any;
}

const SecondTeamCard = ({ isMargin, windowWidth, data, id }: Props) => {
  const isTablet = windowWidth < 1280 && windowWidth >= 786;
  const isMobile = windowWidth < 786;
  const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const { data: _data } = await axios.get(`/candidate-pools/${id}`);
    setCompanyName(_data.name);
  }

  return (
    <div
      className="viewport"
      style={{
        overflow: 'visible',
        marginRight: isMargin && !isTablet ? -1 * (isMobile ? (windowWidth - 311) * 1.5 : (windowWidth - 720 / 2)) : 'auto',
        // transition: 'all 1s ease-in-out',
      }}
    >
      <div className="pf-info">
        <div className="secondteam">
          <h2>
            {
              userType === 'PARTNER' ?
                '인재풀 명단'
                :
                `'${companyName}' 인재풀이 되신걸 환영합니다.`
            }
          </h2>
          <p>
            {
              userType === 'PARTNER' ?
                <>
                관심있는 인재가 있다면 빠르게 협업 제안을 해보세요.
                <br/>
                꼭 실무제안이 아니더라도 커피챗이나 별도 문의사항을 주고받는 것만으로도 추후 이직 가능성을 높일수 있습니다.
                </>
                :
                <>
                회사 담당자가 {data.nickname}님의 프로필을 살펴보고, 필요한 협업 제안을 할수 있게 됩니다.
                <br/>
                프로필 및  프로젝트 업데이트, 협업한 동료 추가 등을 통해 신뢰도를 높일수록 제안을 받을 확률이 높아집니다.
                </>
            }
          </p>
        </div>
      </div>
    </div>
  );
}

export default SecondTeamCard;
