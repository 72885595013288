import { types, Instance, flow, getParent } from "mobx-state-tree";
import { MemberAccountModel } from "../pages/account/models";

import { IAppStore } from './AppStore';

import { StateModel } from '../models/models';
import onboardingAPI from "../services/onboardingAPI";
import { MemberProfileModel, PortfolioModel, PartnerProfileModel } from '../pages/profile/models';
import profileAPI from '../services/profileAPI';


const ProfileStore = StateModel.named('ProfileStore')
  .props({
    clickedUpdate: false,
    memberProfile: types.maybe(MemberProfileModel),
    partnerProfile: types.maybe(PartnerProfileModel),
    memberAccount: types.maybe(MemberAccountModel),
  })
  .views(self => ({
    get uuid(): string {
      return (getParent(self) as IAppStore).userStore.user.uuid;
    },
  }))
  .actions(self => ({
    getMemberProfile: flow(function* (uuid?: string) {
      if(!self.uuid && !uuid) {
        return;
      }
      try {
        const response = yield profileAPI.getMemberProfile(uuid || self.uuid);
        self.memberProfile = MemberProfileModel.create(response);
        // if(response.portfolios.length === 0) {
        //   self.memberProfile.portfolio = PortfolioModel.create({});
        // }
      } catch(e) {
        throw e;
      }
    }),
    getPartnerProfile: flow(function* (uuid?: string) {
      if(!self.uuid) {
        return;
      }
      try {
        const response = yield profileAPI.getPartnerProfile(uuid || self.uuid);
        self.partnerProfile = PartnerProfileModel.create(response);
      } catch(e) {
        throw e;
      }
    }),
    getMemberAccount: flow(function* (uuid?: string) {
      if(!self.uuid && !uuid) {
        return;
      }

      try {
        const response = yield onboardingAPI.getAccount(uuid || self.uuid);
        self.memberAccount = MemberAccountModel.create(response);
      } catch(e) {
        throw e;
      }
    }),
    clickUpdate: flow(function* () {
      self.clickedUpdate = true;
    }),
    updateProfile: flow(function* (form: object) {
      try {
        return yield profileAPI.updateMemberProfile(self.uuid, form);
      } catch (e) {
        throw e;
      }
    }),
    createProject: flow(function* (project: object) {
      return yield profileAPI.createMemberProject(self.uuid, project);
    }),
    updateProject: flow(function* (uuid: string, project: object) {
      return yield profileAPI.updateMemberProject(self.uuid, uuid, project);
    }),
    deleteProject: flow(function* (uuid: string) {
      return yield profileAPI.deleteMemberProject(self.uuid, uuid);
    }),
  }));
export interface IProfileStore extends Instance<typeof ProfileStore> {};

export default ProfileStore;
