import Policy from "@components/Policy";
import { observer } from "mobx-react";
import { IJoinAgreementModel } from '../models';

interface IProps {
  model: IJoinAgreementModel;
  validatedMode: boolean;
}
const JoinPolicy = (props: IProps) => {
  const {
    terms,
    privacyPolicy,
    marketingInformation,

    setTerms,
    setPrivacyPolicy,
    setMarketingInformation
  } = props.model;

  return (
    <ul className="agree">
      <Policy
        id='term'
        isError={props.validatedMode && !terms}
        label="서비스 이용약관 동의 (필수)"
        linkURL='/terms'
        value={terms}
        onClick={(value) => setTerms(value)}
      />

      <Policy
        id='privacyPolicy'
        isError={props.validatedMode && !privacyPolicy}
        label="개인정보 수집ㆍ이용 동의 (필수)"
        linkURL='/terms/privacy'
        value={privacyPolicy}
        onClick={(value) => setPrivacyPolicy(value)}
      />

      <Policy
        id='marketingInformation'
        label="마케팅 정보 수신 동의 (선택)"
        value={marketingInformation}
        onClick={(value) => setMarketingInformation(value)}
      />
    </ul>
  )
};

export default observer(JoinPolicy);