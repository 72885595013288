import CoWorkerAddingModal from "@modals/CoWorkerAddingModal";
import { ICoWorker } from "@models/CoWorker";
import portfolioAPI from "@services/portfolioAPI";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { IEpisodeModel, IMemberProfileModel } from "../models/MemberProfileModel";
import CoWorkerCard from "./CoWorkerCard";
import TagManager from 'react-gtm-module';

interface Props {
  data?: IEpisodeModel;
  profile: IMemberProfileModel;
  isMyPage: boolean;
}

const CoWorker = ({
  data,
  profile,
  isMyPage,
}: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [coWorkerList, setCoWorkerList] = useState<ICoWorker[]>([]);
  const latestCoWorker = coWorkerList.sort((a, b) => -dayjs(a.updated_at || a.created_at).diff(dayjs(b.updated_at || b.created_at)))?.[0];

  useEffect(() => {
    load();
  }, [data]);

  const load = async () => {
    if (!data) {
      return;
    }

    const coWorkerList = await portfolioAPI.getCoWorkerList(profile.uuid, data.uuid);
    setCoWorkerList(coWorkerList);
  }

  const onAdd = () => {
    setOpen(true);

    TagManager.dataLayer({
      dataLayer: {
        event: 'tryToAddCoWorker',
        memberUUID: profile.uuid,
      },
    });
  }

  if (!data) {
    return <></>;
  }

  return (
    <>
      <div className="tit-w">
        <h3>
          함께한 동료
          {
            Boolean(latestCoWorker) &&
            <span>업데이트 {dayjs(latestCoWorker?.created_at || latestCoWorker?.updated_at).format('YYYY.MM.DD')}</span>
          }
        </h3>

        {
          coWorkerList.length > 0 &&
          <div className="chk-st">
            <span className="c1" style={{ color: 'rgba(13, 18, 64, 0.6)'}}>동료 평가</span>
            <span className="c2" style={{ color: 'rgba(13, 18, 64, 0.6)'}}>협업 확인</span>
            <span className="c3" style={{ color: 'rgba(13, 18, 64, 0.6)'}}>레퍼런스 체크 가능</span>
          </div>
        }
      </div>
      <div className="refchk-w">

        {
          coWorkerList.length > 0 ?
            coWorkerList.map(v => (
              <CoWorkerCard
                data={v}
                isHideProjectName
              />
            ))
            :
            <div className="pf-box" style={{ marginTop: 0 }}>
              <p>함께한 동료가 입력되지 않았습니다.</p>
            </div>
        }

      </div>

      {
        isMyPage &&
        <div className="pjt-add">
          <p>함께 프로젝트를 진행한 동료를 통해, 신뢰도를 높여보세요!</p>
          <a style={{ cursor: 'pointer' }} onClick={onAdd}>동료 추가하기</a>
        </div>
      }

      <CoWorkerAddingModal
        nickname={profile.nickname || ''}
        data={data}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default CoWorker;
