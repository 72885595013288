import dayjs from "dayjs";

interface Props {
    endDate: string,
    isOpen: boolean,
    onClose: () => void
}

export default ({
                    endDate,
                    isOpen,
                    onClose
                }: Props) => {
    if (!isOpen) {
        return <></>;
    }

    const onEnd = () => {
        onClose();
    };
    return (
        <div className="dimd-layer">
            <div className="ly-box alert-box smtype">
                <div className="inner">
                    <h3>협업이 자동 종료되었습니다</h3>
                    <p>보유 금액이 부족한 상태로 2주가 경과되어 협업이 자동으로 종료되었습니다.
                    <br />하지만, 걱정마세요. 진행된 기간에 대한 주급은 세컨팀이 정상적으로 정산해드립니다.</p>
                    <div className="sp-end">
                        <ul className="type2">
                            <li>
                                <i className="tit">채용 종료일</i>
                                <span>{dayjs(endDate).format('YYYY.MM.DD')}</span>
                            </li>
                        </ul>
                    </div>
                    <button type="button" className="btn-close" onClick={onEnd}><span>팝업 닫기</span></button>
                </div>
            </div>
        </div>
    );
}
