import { types, Instance } from "mobx-state-tree";

const PartnerModel = types.model({
  uuid: types.string,
  avatar: types.maybeNull(types.string),
  company_name: types.string,
  name: types.string,
});

export const IterationModel = types.model({
  id: types.number,
  round: types.number,
  partner: types.maybeNull(PartnerModel),
  project_title: types.maybe(types.string),
  commission: types.maybeNull(types.number),
  start_date: types.maybeNull(types.string),
  end_date: types.maybeNull(types.string),
})
.views(self => ({
  
}));

export interface IIterationModel extends Instance<typeof IterationModel> {};
