import { useState } from 'react';
import { observer } from "mobx-react";
import Avatar from "../../../components/Avatar";
import { numberWithCommas } from "../../../utils/utils";
import { IPartnerProposalModel } from "../models/PartnerProposalModel";
import CompleteModal from './CompleteModal';
import dayjs from 'dayjs';
import PartnerDropModal from './PartnerDropModal';
import { IAppStore } from "../../../stores/AppStore";

interface Props {
  uuid: string;
  appStore: IAppStore;
  data: IPartnerProposalModel;
}

export default observer(({ uuid, appStore, data }: Props) => {
  const { member, send, resend, drop } = data;
  const submitDate = dayjs(data.date_submitted);
  const viewDate = data.date_viewed ? dayjs(data.date_viewed) : null;

  const [isOpenDrop, setOpenDrop] = useState(false);
  const [isHover, setHover] = useState(false);

  return (
    <div className="inbox">
      <div className="tit">
        <h4>{data.title}</h4>
        {
          data.status === 'SUBMITTED' &&
          <span>제안중</span>
        }

        {
          data.status === 'ARRANGING' &&
          <span className="ing">조율중</span>
        }

        {
          data.status === 'REJECTED' &&
          <span className="rej">거절</span>
        }

        {
          data.status === 'MATCHED' &&
          <span className="ok">채용확정</span>
        }
      </div>
      <div className="profile">
        <Avatar
          data={member}
        />
        <div className="info">
          <div className="name">
            <strong>{member.nickname}</strong>
            <span>{member.job_categories[0]?.values[0]} · {member.company_name}</span>
          </div>
          <p>{member.experiences.join(', ')}</p>
        </div>
        <div className="btn-view">
          <a href={`/recruits/partner/suggest/${data.uuid}`} className="suj"></a>
        </div>
      </div>
      <div className="agr-info">
        <table>
          <caption>계약정보</caption>
          <tr>
            <th scope="row">희망 거래 시간</th>
            <td>{data.working_hours || 0}시간 / 1주일</td>
          </tr>
          <tr>
            <th scope="row">주급</th>
            <td>{numberWithCommas(data?.wage || 0)}원 (수수료 별도)</td>
          </tr>
          <tr>
            <th scope="row">희망 협업 기간</th>
            <td>{data.duration || 0}주</td>
          </tr>
        </table>
        <div className="btn-sug">
          <a href={`/recruits/partner/suggest/${data.uuid}`}>협업제안서</a>
        </div>
      </div>
      <div className="desc-box">
        <p>
          제안일: {submitDate.format('YYYY.MM.DD HH:mm')}
          {
            Boolean(viewDate) &&
            ` / 최종확인: ${viewDate!.format('YYYY.MM.DD HH:mm')}`
          }
        </p>

        {
          data.status === 'SUBMITTED' &&
          '36시간 동안 응답이 없으면 해당 제안은 기간만료로 자동 거절됩니다.'
        }

        {
          Boolean(data.member_comment) &&
          <div className="ans">
            <strong>{data.status === 'REJECTED' ? '다음기회에' : '관심있어요'}</strong>
            <p>{data.member_comment}</p>
          </div>
        }

        {
          Boolean(data.declinature_comment) &&
          <div className="ans">
            <strong>최종 제안 거절 사유</strong>
            <p>{data.declinature_comment}</p>
          </div>
        }
      </div>

      <div className="in-btn">
        {
          data.status !== 'REJECTED' &&
          <a href={`/messages?project=${data.uuid}`} className="cmt">
            <span>댓글</span>
            {
              data.has_unread_messages &&
              <i><span>신규 댓글 있음</span></i>
            }
          </a>
        }

        {
          data.phase === 'ACCEPTED' &&
          <>
            <p
              className="drop"
              style={{ cursor: 'pointer' }}
              onClick={() => setOpenDrop(true)}
            >
              채용 드랍
            </p>
            <p
              className="actv"
              style={{ cursor: 'pointer' }}
              onClick={async () => {
                await send(uuid);
              }}
            >
              채용 확정
            </p>
          </>
        }

        {
          data.phase === 'MATCHING_OFFERED' &&
          <p
            // className="actv"
            style={{ cursor: 'pointer' }}
            onClick={() => resend(uuid)}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
          >
            <a href="#">
              {
                isHover ?
                  '제안내용 변경하기'
                  :
                  '제안 내용 확인중'
              }
            </a>
          </p>
        }


        {
          data.phase === 'CANCELED' ?
            <p>드랍된 제안입니다.</p>
            :
            data.status === 'REJECTED' &&
            <p>거절한 제안입니다.</p>
        }

        {
          data.status === 'MATCHED' &&
          <p className="actv">
            <a href="/recruits">협업 시작일 세팅하기</a>
            {/* <span>협업을 시작해보세요!</span> */}
          </p>
        }

        {
          data.status === 'SUBMITTED' &&
          <p>응답대기중 ({Math.max(submitDate.add(36, 'hour').diff(dayjs(), 'hour'), 0)}시간 후 만료)</p>
        }
      </div>


      <PartnerDropModal
        isOpen={isOpenDrop}
        appStore={appStore}
        nickname={member.nickname}
        onDrop={(content) => drop(uuid, content)}
        onClose={() => setOpenDrop(false)}
      />
    </div>
  );
});
