/*
  markup : mypage_accinfo.html
*/
import { useState, useEffect, useRef } from 'react';
import { inject, observer } from "mobx-react";
import { IAppStore } from "../../stores/AppStore";
import { IAccountStore } from '../../stores/AccountStore';
import { IRegularSurveyStore } from '../../stores/RegularSurveyStore';
import { Prompt } from 'react-router-dom';

import {
  AgreementMarketingField,
  AvailabilityField,
  EmailField,
  ExpectationField,
  NameField,
  PhoneField,
  WorkTypeField
} from '../../components/account-form';
import Avatar from '../../components/Avatar';
import PasswordChangeModal from '../../modals/PasswordChangeModal';
import { taxTypeMap } from '../../utils/constants';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import JobCategoryForm from '@components/profile-new-form/JobCategoryForm';
import { IWorkPreference, WorkPreference } from "@models/WorkPreference";
import { IRegularSurvey } from "@models/RegularSurvey";
import styled from 'styled-components';

const SubscriptionLabel = styled.label`
  padding-left: 90px !important;
  line-height: 40px !important;
  vertical-align: middle !important;

  ::before {
    content: '구독하기' !important;
    background-color: #5539ff !important;
    color: #fff !important;
    display: inline-block !important;
    width: 80px !important;
    height: 40px !important;
    line-height: 40px !important;
    vertical-align: middle !important;
    border-radius: 2px !important;
    text-align: center !important;
    top: 0px !important;
  }
  
  input:checked + &::before {
    content: '구독중' !important;
    background-color: rgba(85,57,255,0.03) !important;
    color: #5539ff !important;
  }

  input:checked + &::after {
    opacity: 0 !important;
  }
`

interface IProps {
  appStore: IAppStore;
  history: any
}

const MemberAccountPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const form = useForm();
  const { register, handleSubmit, setValue, watch, formState: { isDirty, isSubmitted } } = form;

  const { accountStore, regularSurveyStore, showLoader, hideLoader } = (props as IProps).appStore;
  const { memberProfile, memberAccount, workPreferenceList, getMemberProfile, getMemberAccount, getWorkPreference } = (accountStore as IAccountStore);
  const { regularSurveyList, getRegularSurveyList } = (regularSurveyStore as IRegularSurveyStore);

  const [editing, setEditing] = useState(false);
  const isEdited = editing || isDirty;

  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
  const accountRef = useRef<HTMLUListElement>(null);
  const [isPasswordOpen, setPasswordOpen] = useState(false);
  const [regularSurveyIsSubscribedList, setRegularSurveyIsSubscribedList] = useState<boolean[]>([]);

  const isErrPreference = isSubmitted && (watch('work_preferences') || []).length < 1;

  const initPhone = memberAccount?.phone || '';
  let taxType = taxTypeMap.NONE;
  switch (memberAccount?.tax_type) {
    case 'FREELANCE':
      taxType = taxTypeMap.FREELANCE;
      break;
    case 'OTHERS':
      taxType = taxTypeMap.OTHERS;
      break;
    case 'SOLO':
      taxType = taxTypeMap.SOLO;
      break;
    case 'CORPORATION':
      taxType = taxTypeMap.CORPORATION;
      break;
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    async function fetch() {
      await getMemberAccount();
      await getMemberProfile();

      await getRegularSurveyList();
      setRegularSurveyIsSubscribedList(regularSurveyList.map(item => item.is_subscribed));
      
      await getWorkPreference();
    }
    fetch();
  }, []);

  if (!memberAccount) {
    return (
      <div />
    )
  }

  const {
    notification_settings,
  } = memberAccount;

  const onSubmit = async (_data: any) => {
    const workPreferences = typeof(_data.work_preferences) === 'object' ? _data?.work_preferences || [] : [_data.work_preferences];

    let data = {
      job_categories: _data.job_categories,
      work_preferences: workPreferences.map((v: string) => parseInt(v)),
    };

    if (workPreferences.length < 1) {
      return;
    }

    if (memberAccount.name.length === 0) {
      setIsNameEmpty(true);

      if (accountRef) {
        if (accountRef.current) {
          window.scrollTo(0, accountRef.current.offsetTop - 80);
        }
      }
    } else {
      setIsNameEmpty(false);
    }

    if (memberAccount.phone.length === 0) {
      setIsPhoneEmpty(true);

      // if (accountRef) {
      //   if (accountRef.current) {
      //     window.scrollTo(0, accountRef.current.offsetTop - 80);
      //   }
      // }
      
      // return;
    } else {
      setIsPhoneEmpty(false);
    }

    if (isEdited) {
      try {
        showLoader();
        await memberAccount.update(data);

        regularSurveyIsSubscribedList.forEach(async (v, i) => {
          if (v !== regularSurveyList[i].is_subscribed) {
            if (v) {
              await regularSurveyStore.postSubscripton(regularSurveyList[i].id);
            } else {
              await regularSurveyStore.deleteSubscription(regularSurveyList[i].id);
            }
          }
        })
        
        hideLoader();
      } catch (err) {
        console.log(err);
        hideLoader();
        return;
      }

      setEditing(false);
      alert('변경 내용이 저장되었습니다.');
      window.scrollTo(0, 0);
    }
  }

  const createPreference = (v: string, idx: number) => {
    return (
      <span>
        <input
          type="checkbox"
          {...register('work_preferences')}
          id={`chA${idx}`}
          value={idx + 1}
          defaultChecked={memberAccount.work_preferences.includes(idx + 1)}
        />
        <label htmlFor={`chA${idx}`}>{v}</label>
      </span>
    )
  }

  return (
    <FormProvider {...form}>
      <div className="mypage-cnts intit">
        <div className="top-box">
          <h2>멤버스 계정 정보</h2>
        </div>

        <div className="conts-w">
          <div className="join-form">

            <h3>내 정보</h3>
            <ul className="fm-list" ref={accountRef}>
              <NameField
                value={memberAccount.name}
                isError={isNameEmpty}
                showPublicOpen={true}
                publicOpen={memberAccount.name_public_open}
                onChangeName={(name: string) => {
                  memberAccount.setName(name);
                  setEditing(true);
                }}
                onChangePublic={(opened: boolean) => {
                  memberAccount.setChangeNamePublicOpen(opened);
                  setEditing(true);
                }} />

              <PhoneField
                member={memberAccount}
              />

              <EmailField value={memberAccount.email} />
              <AgreementMarketingField
                value={memberAccount.marketing_agreement}
                onChange={() => {
                  memberAccount.setMarketingAgreement(!memberAccount.marketing_agreement);
                  setEditing(true);
                }} />

              <li>
                <label htmlFor="pwCh">비밀번호</label>
                <button type="button" id="pwCh" className="fm-inbtn" onClick={() => setPasswordOpen(true)}>비밀번호 변경하기</button>
              </li>

              {
                taxType === taxTypeMap.NONE ?
                  <li>
                    <i className="tit">소득세 유형</i>
                    <div className="tex-info">
                      <p>소득세 유형이 등록되지 않았습니다. 보유 금액을 내 계좌로 지급 받기 위해서는 적용 받으실 소득세 유형을 등록해주셔야 합니다.</p>
                      <a href="https://seesorufree.notion.site/a4d9da3ff6504086ba9de8eb0c38af68">등록 방법 안내</a>
                    </div>
                  </li>
                  :
                  <li>
                    <label htmlFor="tex">소득세 유형</label>
                    <input
                      type="text"
                      id="tex"
                      value={taxType}
                      disabled={true}
                    />
                    <p>보유 금액을 내 계좌로 지급 받기 위해서는 적용 받으실 소득세 유형을 등록해주셔야 합니다. <a href="https://seesorufree.notion.site/a4d9da3ff6504086ba9de8eb0c38af68" target="_blank">등록 방법 안내</a></p>
                  </li>
              }
            </ul>

            <h3>추천받을 제안 설정</h3>
            <div className="recom-sug">
              <h4>희망 제안 유형 <i className="ness"><span>필수</span></i><span>(복수 선택 가능)</span></h4>
              <div className="w-choice">
                <div className="ch-list">
                  {
                    [
                      '전문성 기반의 짧은 업무',
                      '아웃소싱 프로젝트 의뢰',
                      '프로젝트성 주급 계약',
                      '이직 헤드헌팅',
                      '흥미로운 일',
                    ].map((v: string, idx: number) => createPreference(v, idx))
                  }
                </div>
              </div>
              {/* <div className="w-choice">
                <h5>관심 유형</h5>
                <div className="ch-list">
                  {
                    workPreferenceList.filter(v => v.category === 'TYPE').map((v: IWorkPreference) => createPreference(v))
                  }
                </div>
                <h5>관심 업무 성격(키워드)</h5>
                <div className="ch-list">
                  {
                    workPreferenceList.filter(v => v.category === 'AREA').map((v: IWorkPreference) => createPreference(v))
                  }
                </div>
                <h5>업무량</h5>
                <div className="ch-list">
                  {
                    workPreferenceList.filter(v => v.category === 'WORKTIME').map((v: IWorkPreference) => createPreference(v))
                  }
                </div>

              </div> */}
              {
                isErrPreference &&
                <p className="err">1개 이상 선택해주세요.</p>
              }

              <h4>희망 프로젝트 역할 <i className="ness"><span>필수</span></i></h4>
              <JobCategoryForm
                account={memberAccount}
              />
            </div>

            <h3>커리어 설문 구독</h3>
            <div className="chk-box">
              <ul className="ch-list">
                {regularSurveyList.map((regularSurvey: IRegularSurvey, index: number) => (
                  <li>
                    <input
                      key={regularSurvey.id}
                      type="checkbox"
                      id={regularSurvey.id.toString()} 
                      checked={regularSurveyIsSubscribedList[index]}
                      onClick={() => {
                        let list = [...regularSurveyIsSubscribedList];
                        list[index] = !list[index];
                        setRegularSurveyIsSubscribedList(list);
                        setEditing(true);
                      }}
                    />
                    <SubscriptionLabel htmlFor={regularSurvey.id.toString()}>
                      {regularSurvey.name}
                    </SubscriptionLabel>
                  </li>
                ))}
              </ul>
            </div>


            <h3>문자 알림 설정</h3>
            <div className="chk-box">
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="mms1"
                    onClick={() => {
                      memberAccount.setMarketingNotification();
                      setEditing(true);
                    }}
                    checked={notification_settings[0].enabled}
                  />
                  <label htmlFor="mms1">세컨팀과 관련된 마케팅 정보를 알림 받겠습니다.</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="mms2"
                    onClick={() => {
                      memberAccount.setProposalNotification();
                      setEditing(true);
                    }}
                    checked={notification_settings[1].enabled}
                  />
                  <label htmlFor="mms2">협업 제안이 도착하거나 진행 사항이 있을 경우 알림 받겠습니다.</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="mms3"
                    onClick={() => {
                      memberAccount.setProjectNotification();
                      setEditing(true);
                    }}
                    checked={notification_settings[2].enabled}
                  />
                  <label htmlFor="mms3">확정된 협업의 진행 및 정산 사항을 알림 받겠습니다.</label>
                </li>
              </ul>
              <p>서비스 운영 관련된 중요한 공지 및 알림은 문자 알림 받기 설정 여부와 관계없이 발송됩니다. </p>
            </div>

            <div className="btn">
              <button type="submit" className={isEdited ? "actv" : "disb"}
                onClick={handleSubmit(onSubmit)}>저장</button>
            </div>

            <a
              href="https://forms.gle/D6MtG2exCGYkAELUA"
              target="_blank"
              style={{
                display: 'block',
                fontSize: 13,
                textDecoration: 'underline',
                color: '#bdbdbd',
                cursor: 'pointer',
                marginTop: 26,
              }}
            >
              탈퇴하기
            </a>
          </div>

          <Prompt
            when={isEdited}
            message="변경 내용을 삭제하시겠어요?
                     지금 페이지를 나가면 변경 내용이 삭제됩니다." />

          <PasswordChangeModal
            isOpen={isPasswordOpen}
            appStore={props.appStore}
            onClose={() => setPasswordOpen(false)}
          />

        </div>
      </div>
    </FormProvider>
  )
}));

export default MemberAccountPage;
