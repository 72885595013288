import { IDetailModel } from "../models/DetailModel";
import Avatar from '../../../components/Avatar';
import { numberWithCommas } from '../../../utils/utils';
import dayjs from 'dayjs';
import { useState } from "react";
import PaymentNoticeModal from "./PaymentNoticeModal";

interface Props {
  data: IDetailModel;
  userType: string;
  userUUID: string;
}

export default ({
  data,
  userType,
  userUUID,
}: Props) => {
  const { proposal, target } = data;
  const [isOpenPayNotice, setOpenPayNotice] = useState(false);

  return (
    <div className="profile-box">
      <div className="def-info">
        <div className="profile">
          <Avatar
            data={target}
          />
          <div className="info">
            <div className="name">
              <strong><span>{target?.validName}</span></strong>

              {
                userType === 'MEMBER' ?
                  <span>{target?.industry}</span>
                  :
                  <span>{target?.job_categories?.[0]?.parent__name || ''} · {target?.company_name}</span>
              }
            </div>
          </div>
          <a href={`/members/${target?.uuid}`} className="dir-go"><span>바로가기</span></a>
        </div>

        {
          userType === 'PARTNER' &&
          <p className="line1">
            {(target?.job_categories?.[0]?.values || []).join(', ')}
          </p>
        }
        <span className="date">매칭일 {dayjs(proposal.date_matched).format('YYYY.MM.DD')}</span>
      </div>

      <div className="sub-info">
        <div className="link-p">
          <a href={`/recruits/${userType.toLowerCase()}/suggest/${proposal.uuid}`}>협업 제안</a>
        </div>
        <table>
          <caption>희망 시간 및 시급 정보</caption>
          <tr style={{ height: 75 }}>
            <th scope="row">거래 시간</th>
            <td style={{ verticalAlign: 'top' }}>{proposal.working_hours || 0}시간 / 1주일</td>
          </tr>
          <tr style={{ height: 75 }}>
            <th scope="row">예상 협업  기간</th>
            <td style={{ verticalAlign: 'top' }}>{proposal.duration || 0}주</td>
          </tr>
          <tr style={{ height: 75 }}>
            <th scope="row">주급</th>
            <td style={{ verticalAlign: 'top' }}>
              {numberWithCommas(proposal.wage || 0)}원
              {
                userType === 'MEMBER' &&
                <div style={{ marginTop: 4, marginBottom: 4 }}>
                  <p style={{ fontSize: 13, opacity: 0.7 }}>실 지급되는 비용은 수수료와 세금이 제외됩니다.</p>
                  <span style={{ color: '#5539ff', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setOpenPayNotice(true)}>실제 지급비용 알아보기</span>
                  
                  <PaymentNoticeModal
                    isOpen={isOpenPayNotice}
                    onClose={() => setOpenPayNotice(false)}
                  />
                </div>
              }
            </td>
          </tr>
        </table>
      </div>
    </div>
  )
};
