const SkipNav = () => {
  return (
    <div id="skipNav">
      <ul>
        <li><a href="#snContent">본문바로가기</a></li>
        <li><a href="#snMenu">주메뉴 바로가기</a></li>
      </ul>
    </div>
  );
};

export default SkipNav;
