import { AUTH_TOKEN_KEY, AUTH_USERTYPE, NEW_BASEURL } from "@utils/constants";
import { useParams } from "react-router";

const ClubDetailPage = () => {
  const token = window.localStorage.getItem(AUTH_TOKEN_KEY) || '';
  const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';
  const { id }: any = useParams();

  return (
    <iframe
      src={`${NEW_BASEURL}/clubs/${id}?token=${token}&userType=${userType}`}
      className="secondteam-new"
      style={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        borderWidth: 0,
      }}
    />
  );
};

export default ClubDetailPage;
