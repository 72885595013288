import axios from 'axios';

const recruitAPI = {
  getMemberProejct: async (memberUUID: string, projectUUID: string) => {
    try {
      const { data }: { data: any } = await axios.get(
        `/members/${memberUUID}/projects/${projectUUID}`,
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  getPartnerProject: async (partnerUUID: string, projectUUID: string) => {
    try {
      const { data }: { data: any } = await axios.get(
        `/partner/${partnerUUID}/projects/${projectUUID}`,
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  getMemberProjectList: async (uuid: string, status?: string, page: number = 1, size: number = 20) => {
    try {
      const { data }: { data: any } = await axios.get(
        `/members/${uuid}/projects`, {
          params: {
            page_size: size,
            status,
            page,
          }
        },
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  getPartnerProjectList: async (uuid: string, status?: string, page: number = 1, size: number = 20) => {
    try {
      const { data }: { data: any } = await axios.get(
        `/partner/${uuid}/projects`, {
          params: {
            page_size: size,
            status,
            page,
          }
        }
      );
      return data;
    } catch (e) {
      throw e;
    }
  },

  updateTerminateNotified: async (userType: string, uuid: string, projectUUID: string) => {
    try {
      if (userType === 'PARTNER'){
        const { data }: { data: any } = await axios.put(
            `/partner/${uuid}/projects/${projectUUID}/terminate/notified`, {}
        );
      } else {
        const { data }: { data: any } = await axios.put(
            `/member/${uuid}/projects/${projectUUID}/terminate/notified`, {}
        );
      }
    } catch (e) {
      throw e;
    }
  },

  updatePartnerSprintStartDate: async (uuid: string, projectUUID: string, sprintId: number, value: string) => {
    try {
      const { data }: { data: any } = await axios.put(
        `/partner/${uuid}/projects/${projectUUID}/sprints/${sprintId}/set-start-date`,
        {
          start_date: value,
        }
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  updateMemberSprintStartDate: async (uuid: string, projectUUID: string, sprintId: number, value: string) => {
    try {
      const { data }: { data: any } = await axios.put(
        `/members/${uuid}/projects/${projectUUID}/sprints/${sprintId}/set-start-date`,
        {
          start_date: value,
        }
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  updatePartnerSprintEndDate: async (uuid: string, projectUUID: string, sprintId: number, value: string) => {
    try {
      const { data }: { data: any } = await axios.put(
        `/partner/${uuid}/projects/${projectUUID}/sprints/${sprintId}/set-end-date`,
        {
          end_date: value,
        }
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  updateMemberSprintEndDate: async (uuid: string, projectUUID: string, sprintId: number, value: string) => {
    try {
      const { data }: { data: any } = await axios.put(
        `/members/${uuid}/projects/${projectUUID}/sprints/${sprintId}/set-end-date`,
        {
          end_date: value,
        }
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
  createProposal: async (uuid: string, form: object) => {
    try {
      const { data }: { data: any } = await axios.post(
        `/partner/${uuid}/proposals`,
        form,
      );

      return data;
    } catch (e) {
      throw e;
    }
  },
  updateProposal: async (uuid: string, proposalUUID: string, form: object) => {
    try {
      const { data }: { data: any } = await axios.put(
        `/partner/${uuid}/proposals/${proposalUUID}`,
        form,
      );

      return data;
    } catch (e) {
      throw e;
    }
  },
  getPartnerProposalList: async (uuid: string, status: string, page: number = 1, size: number = 20) => {
    try {
      const { data }: { data: any } = await axios.get(
        `/partner/${uuid}/proposals`,
        {
          params: {
            page_size: size,
            status,
            page,
          },
        },
      );

      return data;
    } catch (e) {
      throw e;
    }
  },
  getMemberProposalList: async (uuid: string, status: string, page: number = 1, size: number = 20) => {
    try {
      const { data }: { data: any } = await axios.get(
        `/members/${uuid}/proposals`,
        {
          params: {
            page_size: size,
            status,
            page,
          },
        },
      );

      return data;
    } catch (e) {
      throw e;
    }
  },
  getPartnerProposal: async (uuid: string, proposalUUID: string) => {
    try {
      const { data }: { data: any } = await axios.get(
        `/partner/${uuid}/proposals/${proposalUUID}`,
      );

      return data;
    } catch (e) {
      throw e;
    }
  },
  getMemberProposal: async (uuid: string, proposalUUID: string) => {
    try {
      const { data }: { data: any } = await axios.get(
        `/members/${uuid}/proposals/${proposalUUID}`,
      );

      return data;
    } catch (e) {
      throw e;
    }
  },
  getProposalForm: async (uuid: string, proposalUUID: string) => {
    try {
      const { data }: { data: any } = await axios.get(
        `/partner/${uuid}/proposals/fetch`,
      );

      return data;
    } catch (e) {
      throw e;
    }
  },
  agreeNBA: async (uuid: string, proposalUUID: string) => {
    try {
      const { data }: { data: any } = await axios.put(
        `/members/${uuid}/proposals/${proposalUUID}/agree-to-nda`,
      );

      return data;
    } catch (e) {
      throw e;
    }
  },
};

export default recruitAPI;
