import { remove } from 'lodash';
import { AUTH_TOKEN_KEY } from './constants';
import { nanoid } from 'nanoid'

export const updateStringArray = (arr: string[], item: string, added: boolean) => {
  let newArr = [...arr];

  if(added) {
    newArr.push(item);
  } else {
    remove(newArr, o => o === item)
  }

  return newArr;
}

export const numberWithCommas = (x: number | null | undefined) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '';
}

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


const regex = /[^0-9]/g;
export const parseWithCommas = (text: any): any[] => {
  const value = text.replaceAll(regex, '');
  if (isNaN(value)) {
    throw 'parse error';
  }

  const num = Number(value);
  return [num, numberWithCommas(value)];
}

export const handleMemberProfile = (data: object) => {
  let form: FormData | null = new FormData();
  let json: any = {};
  let referenceFiles: FormData[] = [];

  for (const [k, v] of Object.entries(data)) {
    if (k !== 'expected_annual_salary' && (v === null || v === undefined)) {
      continue;
    }

    if (k === 'avatar') {
      const extensionName = [...v.name.split('.')].pop();
      const avatarName = `${nanoid()}.${extensionName}`;
      form.append(k, v, avatarName);
      continue;
    }

    // if (k === 'expected_hourly_wage' && v.length < 1) {
    //   console.log('error error!!!');
    //   throw 'value error';
    // }

    if (k === 'job_category' && v.length < 1) {
      continue;
    }

    if (k === 'episodes' && v.length > 0) {
      let cnt = 0;
      for (let { reference_type, link, file } of v) {
        cnt += 1;

        const subForm = new FormData();
        if (file && typeof(file) !== 'string') {
          console.log('파일 감지!');
          subForm.append('file', file);
        }
        // if (reference_type === 'FILE' && link) {
        //   subForm.append('file', link);

        //   delete v[cnt - 1]['reference_type'];
        //   delete v[cnt - 1]['file'];
        //   delete v[cnt - 1]['link'];
        // }
        referenceFiles.push(subForm);
      }
    }

    json[k] = v;
    json['expected_hourly_wage'] = parseInt(json['expected_hourly_wage']);
  }

  if (form.entries().next().done) {
    form = null;
  }

  return [form, json, referenceFiles];
};

export const makeProjectData = (_data: any) => {
  return {
    title: _data.title,
    description: _data.description,
    year: parseInt(_data.year),
    duration: parseInt(_data.duration),
    job_categories: _data.job_categories.filter((v: any) => v.main_category && v.sub_category),
    skill_set: _data._skill_set.map((v: string) => ({ name: v })),
    // experiences: typeof (_data.experiences) === 'string' ? [{ name: _data.experiences }] : _data.experiences.map((v: string) => ({ name: v })),
    domain: _data.domain,
    type: parseInt(_data.type),
    coworking_member_count: parseInt(_data.coworking_member_count),
    reference_type: _data.reference_type,
    link: _data.link,
    file: _data.file,
  };
}

export const checkLogin = () => {
  const token = window.localStorage.getItem(AUTH_TOKEN_KEY);
  return Boolean(token);
};
