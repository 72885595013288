import JobCategoryForm from "@components/profile-new-form/JobCategoryForm";
import { observer } from "mobx-react";
import { FormProvider, useFormContext } from 'react-hook-form';
import IntlTelInput from 'react-intl-tel-input';
import { IJoinAccountModel } from "../models/JoinAccountModel";

interface IProps {
  model: IJoinAccountModel;
}

const JoinMember: React.SFC<IProps> = (observer((props) => {
  const {
    phone,
    country_code,

    setCountryCode,
    setPhone,
  } = props.model;

  const form = useFormContext();
  const { control, watch, getValues, setValue, register, formState: { isSubmitted } } = form;

  const isErrLife = isSubmitted && (watch('work_preferences') || []).length < 1;

  return (
    <FormProvider {...form}>
      <h3 className="noline">추천받을 제안 설정</h3>
      <p className="tit">어떤 제안을 받고 싶나요?</p>
      {
        isErrLife &&
        <p className="err" style={{ color: 'red', marginTop: 8 }}>1개 이상 선택해주세요.</p>
      }
      <ul className="lifestyle-choice">
        <li className="m5">
          <input type="checkbox" id="lifCh5" {...register('work_preferences')} value={1} />
          <label htmlFor="lifCh5">
            <span>#커피챗 #컨설팅 #MVP제품</span>
            <em>전문성 기반의 짧은 업무</em>
          </label>
        </li>
        <li className="m3">
          <input type="checkbox" id="lifCh3" {...register('work_preferences')} value={2} />
          <label htmlFor="lifCh3">
            <span>#고정된 작업범위 #예산과 일정</span>
            <em>아웃소싱 프로젝트 의뢰</em>
          </label>
        </li>
        <li className="m1">
          <input type="checkbox" id="lifCh1" {...register('work_preferences')} value={3} />
          <label htmlFor="lifCh1">
            <span>#N잡 #1주당 업무시간 계약</span>
            <em>프로젝트성 주급 계약</em>
          </label>
        </li>
        <li className="m4">
          <input type="checkbox" id="lifCh4" {...register('work_preferences')} value={4} />
          <label htmlFor="lifCh4">
            <span>#가고싶은 회사 #커리어 #연봉</span>
            <em>이직 헤드헌팅</em>
          </label>
        </li>
        <li className="m2">
          <input type="checkbox" id="lifCh2" {...register('work_preferences')} value={5} />
          <label htmlFor="lifCh2">
            <span>#돈보다 재미 #도전 #동료찾기</span>
            <em>흥미로운 일</em>
          </label>
        </li>
      </ul>

      <p className="tit">어떤 역할의 프로젝트를 하고 싶나요?</p>
      <JobCategoryForm />

      <p className="tit" style={{ marginTop: 32 }}>
        설문을 통해 가장 적합한 제안을 매칭하고 있습니다.<br />설문을 받으실 핸드폰 번호를 입력해주세요.
      </p>
      {/* <IntlTelInput
        numberType="tel"
        value={phone}
        style={{
          borderBottom: '1px solid rgba(13,18,64,0.05)',
          paddingBottom: 32,
          marginBottom: -8,
        }}
        autoPlaceholder={false}
        defaultCountry={country_code}
        preferredCountries={[]}
        onlyCountries={['kr']}
        onFlagClick={(e) => {
          console.log(e);
        }}
        onSelectFlag={(currentNumber, seletedCountryData) => {
          setCountryCode('kr');
        }}
        onPhoneNumberChange={(isValid, newNumber, countryData) => {
          setPhone(newNumber.replace(/[^0-9]/g, ''));
        }}
      /> */}
    </FormProvider>
  );
}));

export default JoinMember;
