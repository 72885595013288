import { useState } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import { useFormContext } from "react-hook-form";
import heic2any from 'heic2any';


interface Props extends OnboardingProps {
  title?: string;
}

const ProfileImageForm = ({ profile, title }: Props) => {
  const { register, getValues, setValue, watch } = useFormContext();
  const [file, setFile] = useState<any>();

  const avatar = `url(${file || profile.avatar || 'images/@profile.png'})`;

  const onFileChange = async (e: any) => {
    let file = e.target.files[0];
    const fileName = file.name;

    if (fileName.endsWith('heic') || fileName.endsWith('HEIC')) {
      file = await heic2any({
        blob: file,
        toType: 'image/jpeg',
        quality: 0.5
      });;

      file.name = `${fileName}.jpg`
    }

    setValue('avatar', file);

    const imagePath = URL.createObjectURL(file);
    setFile(imagePath);
  };

  return (
    <div className="profile">
      <div className="photo">
        <span style={{ backgroundImage: avatar }}></span>
        <div className="photo-up">
          <input type="file" id="photoUp" onChange={onFileChange} />
          <label htmlFor="photoUp"><span>프로필 사진 등록</span></label>
        </div>
      </div>
      <h2>{title}</h2>
    </div>
  );
};

export default observer(ProfileImageForm);
