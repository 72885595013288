import dayjs from 'dayjs';
import Thumb from '../../../components/Thumb';
import { numberWithCommas } from '../../../utils/utils';
import { IPaymentModel } from '../models/PaymentModel';

interface Props {
  data: IPaymentModel;
  isMember?: boolean;
}
export default ({ data, isMember = false }: Props) => {
  const isCharge = data.type === 'WITHDRAWAL' || data.type === 'DEPOSIT';
  const isHideProfile = data.type === 'REQUEST_WITHDRAWAL' || data.type === 'DEPOSIT' || isMember;
  const isRequest = data.type === 'REQUEST_WITHDRAWAL';
  const isContractFee = data.type === 'CONTRACT_FEE';

  const tag = {
    DEPOSIT: '충전',
    PAYMENT: '정산',
    PREPAYMENT: '주급 선차감',
    PREPAYMENT_REFUND: '주급 환불',
    DIFFERENCE_PAYMENT: '주급 차액 차감',
    DIFFERENCE_REFUND: '주급 차액 환불',
    REQUEST_WITHDRAWAL: '지급요청',
    WITHDRAWAL: '지급',
  }[data.type];

  return (
    <tr className={(isCharge || isRequest) ? 'ing' : ''}>
      <td>{dayjs(data.created_at).format('YYYY.MM.DD')}</td>
      <td className="cate"><span className="condi">{data.type === 'DEPOSIT' ? '충전' : '정산'}</span></td>
      <td>
        {
          !isHideProfile && data.member &&
          <div className="profile">
            <Thumb
              data={data.member || data.iteration?.partner}
            />
            <div className="info">
              <span>{data.member?.nickname || data.iteration?.partner?.company_name || '닉네임없음'}</span>
              <em>{data.member?.name || data.iteration?.partner?.name || '이름없음'}</em>
            </div>
          </div>
        }
      </td>
      <td>
        {
          (Boolean(data.iteration) || data.round) &&
          <div className="sbj">
            <strong>{data.project_title || ''}</strong>
            {
              isContractFee ?
                <span>
                  {
                    data.amount ?
                      '매칭 수수료 차감'
                      :
                      '매칭 수수료 무료 (멤버 재매칭)'
                  }

                </span>
                :
                <span>{data.iteration?.round || data.round}번째 주 {tag}</span>
            }
          </div>
        }
      </td>
      <td className="td-price" style={{ color: (isRequest || (data.amount || 0) > 0) ? '#5539ff' : 'auto' }}>{isRequest ? `(${numberWithCommas(Math.abs(data.amount || 0))}원)` : `${numberWithCommas(data.amount || 0)}원`}</td>
      <td className="td-price">{isRequest ? '' : `${numberWithCommas(data.balance || 0)}원`}</td>
    </tr>
  )
};
