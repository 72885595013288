import axios from 'axios';

const commonAPI = {
  getStatistics: async () => {
    try {
      const { data }: { data: any } = await axios.get(
        `/secondteam/statistic/all`,
      );

      return data;
    } catch (e) {
      throw e;
    }
  },
};

export default commonAPI;
