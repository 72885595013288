/*
  markup : mypage_dashboard.html
*/

import { IAppStore } from "@stores/AppStore";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IEpisodeModel } from './models/MemberProfileModel';

import profileAPI from '@services/profileAPI';
import axios from "axios";
import { getWindowDimensions } from '../../utils/utils';
import PortfolioBanner from './components/PortfolioBanner';
import ProjectCard from './components/ProjectCard';
import { IJobCategoryModel } from './models';
import Loader from "react-loader";

interface IProps {
  appStore: IAppStore;
  history: any
}

const EpisodeDetailPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const { uuid }: { uuid: string } = useParams();

  const [rootJobs, setRootJobs] = useState<IJobCategoryModel[]>([]);
  const [jobs, setJobs] = useState<IJobCategoryModel[]>([]);

  const [projectData, setProjectData] = useState<IEpisodeModel | null>(null);
  const currentEpisode = projectData;
  const currentJob = jobs.find(v => v.id === currentEpisode?.job_category);
  let currentURL = currentEpisode?.link || currentEpisode?.file || '';
  const isFile = Boolean(currentEpisode?.file);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  

  if (currentURL && !currentURL.startsWith('http')) {
    currentURL = `http://${currentURL}`;
  }

  const getJobInfo = async () => {
    const jobsRoot = await profileAPI.getConstants('jobs/root/');
    const jobsAll = await profileAPI.getConstants('jobs/all/');

    setRootJobs(jobsRoot);
    setJobs(jobsAll);
  }

  useEffect(() => {
    load();

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const load = async () => {
    const { data } = await axios.get(`/members/experiences/${uuid}`);
    setProjectData(data);

    await getJobInfo();
  }

  if (!projectData) {
    return (
      <Loader
        loaded={false}
        opacity={0.5}
        options={{ opacity: 0.5, color: '#5539ff' }}
      />
    )
  }

  return (
    <>
      <div className="mypage-w">
        <div className="header-type2" style={{ overflow: 'hidden' }}>
          <ProjectCard
            data={projectData}
            rootJobs={rootJobs}
            jobs={jobs}
            windowWidth={windowDimensions.width}
            isMine={false}
            isMarginLeft={true}
            isMarginRight={true}
          />
        </div>

        <br/>
        <br/>

        <div className="conts-w2" style={{ marginTop: 0 }}>
            <h3>프로젝트 정보</h3>

            {
              (currentJob?.parent === '개발자' || currentJob?.name === '개발자') &&
              <div className="pf-box">
                <h4>언어 및 프레임워크</h4>
                <div className="skill-list">
                  <ul>
                    {
                      currentEpisode?.frameworks.map(v => (
                        <li key={v}>{v}</li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            }

            <div className="pf-box">
              <h4>경험</h4>
              <div className="skill-list">
                <ul>
                  {
                    (currentEpisode?.skill_set?.length || 0) < 1 &&
                    <p style={{ marginTop: 8 }}>입력되지 않았습니다.</p>
                  }
                  {
                    currentEpisode?.skill_set.map(v => (
                      <li key={v.name}>{v.name}</li>
                    ))
                  }
                </ul>
              </div>
            </div>
            <div className="pf-box">
              <h4>프로젝트 유형</h4>
              {
                Boolean(currentEpisode?.type) ?
                  <div className="part-t">
                    <span
                      style={{
                        width: 40,
                        minWidth: 40,
                        borderColor: [
                          '',
                          '#523AF5',
                          '#AFFCCA',
                          '#F4B799',
                        ][currentEpisode?.type || 0]
                      }}
                    >
                      {
                        [
                          '',
                          '회사',
                          '협업',
                          '개인',
                        ][currentEpisode?.type || 0]
                      }
                    </span>
                    <p>
                      {
                        [
                          '',
                          '회사에 소속되어 진행한 프로젝트입니다.',
                          '클라이언트와 협업하여 진행된 프로젝트입니다.',
                          '회사 또는 클라이언트 없이 개인적으로 진행된 프로젝트입니다.',
                        ][currentEpisode?.type || 0]
                      }
                    </p>
                  </div>
                  :
                  <p style={{ marginTop: 8 }}>입력되지 않았습니다.</p>
              }
              <h4>프로젝트 소개</h4>
              <p>{currentEpisode?.description ? currentEpisode?.description?.split('\n').map(v => <p>{v}<br /></p>) : '입력되지 않았습니다.'}</p>
            </div>
            <div className="pf-box">
              <h4>프로젝트 관련 자료</h4>
              <div className="data-thumb">
                {
                  Boolean(currentURL) ?
                    <>
                      <a href={currentEpisode?.link?.startsWith('http') ? currentEpisode?.link : `http://${currentEpisode?.link}`} target="_blank" className="dir-go" >
                        {
                          Boolean(currentEpisode?.link) &&
                          <span>{currentEpisode?.link}</span>
                        }
                      </a>

                      <a href={currentEpisode?.file || ''} target="_blank" className="dir-go" style={{ marginTop: 4 }}>
                        {
                          isFile &&
                          <span>{currentEpisode?.file?.split('/').pop()}</span>
                        }
                      </a>
                    </>
                    :
                    <span>입력되지 않았습니다.</span>
                }
              </div>
            </div>
            
            {
              Boolean(projectData) &&
              <PortfolioBanner
                data={currentEpisode!}
              />
            }
        </div>
      </div>
    </>
  );
}));

export default EpisodeDetailPage;
