import { flow, types, Instance, getParent } from "mobx-state-tree";
import { MemberModel } from "./MemberModel";
import { MetaModel } from "./MetaModel";
import { StateModel } from '../../../models/models';

export const DashboardModel = StateModel.named('DashboardModel')
.props({
  total_count: types.number, // 총 멤버 수
  available_count: types.number, // 구직의향이 있는 멤버 수
  recommended_members: types.array(MemberModel),
  new_members: types.array(MemberModel),
  job_categories: types.array(MetaModel),
  skills: types.array(MetaModel),
  experiences: types.array(MetaModel),
  projects: types.array(MetaModel),
});

export interface IDashboardModel extends Instance<typeof DashboardModel> {};
