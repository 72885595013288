import axios from 'axios';
import * as historyLibrary from "history";
import jwtDecode from "jwt-decode";
import { Provider } from "mobx-react";
import { RouterStore, syncHistoryWithStore } from "mobx-react-router";
import { ReactChannelIO } from 'react-channel-plugin';
import { Route, Router } from "react-router";

import Layout from '@components/Layout';
import { IAuthToken } from '@interfaces/userTypes';
import AppStore from '@stores/AppStore';
import Routes from './Routes';

import GlobalComponent from '@components/GlobalComponent';
import Loader from '@components/Loader';
import Activate from '@pages/user/Activate';
import {
  API_BASEURL,
  AUTH_TOKEN_KEY,
  AUTH_USERTYPE,
  AUTH_UUID
} from '@utils/constants';

const token = window.localStorage.getItem(AUTH_TOKEN_KEY) || '';
const uuid = window.localStorage.getItem(AUTH_UUID) || '';
const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';
const decoded: IAuthToken | undefined = token ? jwtDecode(token) : undefined;

axios.defaults.baseURL = API_BASEURL;
axios.defaults.headers.common["Authorization"] = token ? `Bearer ${token}` : '';

// global 401 handler
axios.interceptors.response.use(
  res => {
    return res;
  },
  err => {
    if (err.response.status === 401 && token) {
      localStorage.clear();
      window.location.href = '/login';
      return;
    }

    throw err;
  },
);

// TODO : userId도 저장 , 암호화해서 저장?
const routerStore = new RouterStore();
const rootStore = AppStore.create({
  userStore: {
    user: {
      userId: decoded ? decoded.user_id : -1,
      username: decoded ? decoded.name : '',
      email: decoded ? decoded.username : '',
      userType: userType || '',
      token: token || '',
      uuid: uuid || ''
    }
  }
});
const browserHistory = historyLibrary.createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, routerStore);


function App() {
  return (
    <Provider routerStore={routerStore} appStore={rootStore}>
      <ReactChannelIO pluginKey={'4338c678-f12e-439d-b578-7a05b36fa249'} autoBoot>
        <Loader appStore={rootStore}>
          <div className="App landing-w">
            <Router history={history}>
              <Layout appStore={rootStore}>
                <GlobalComponent />
                <Routes />
              </Layout>

              <Route path="/verify-email/:uidb/:token" exact={true} component={Activate} />
            </Router>
          </div>
        </Loader>
      </ReactChannelIO>
    </Provider>
  );
}

export default App;
