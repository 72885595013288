import { IMemberProfileModel } from "@pages/profile/models";

interface Props {
  data: IMemberProfileModel;
}

const ProfileBanner = ({
  data,
}: Props) => {
  if (!data.isShowProfileBanner) {
    return <></>;
  }

  return (
    <div className="profile-add">
      <h4>아직 프로필 정보가 <br />충분하지 않아요</h4>
      <p>더 많은 회사들이 {data.nickname}님에게 관심을 갖고 좋은 협업을 제안할 수 있도록 아래 프로필 정보를 추가해주세요!</p>
      <ul>
        <li className={`m1 ${data.nickname ? 'chk' : ''}`}>닉네임</li>
        <li className={`m2 ${data.working_hour_range_per_week ? 'chk' : ''}`}>거래 가능 시간</li>
        <li className={`m3 ${data.expected_hourly_wage ? 'chk' : ''}`}>시간당 거래 금액</li>
        <li className={`m4 ${data.employment_type ? 'chk' : ''}`}>소속</li>
      </ul>
      <div className="btn">
        <a href="/member/profile/edit" className="actv">프로필 입력하기</a>
      </div>
    </div>
  );
}

export default ProfileBanner;
