/*
  markup : onboarding_member_info.html
*/
import { observer } from "mobx-react";
import { OnboardingProps } from '../OnboardingPage';
import AvailabilityForm from "../../../components/profile-new-form/AvailabilityForm";
import OfflineForm from "../../../components/profile-new-form/OfflineForm";
import ExpectationForm from "../../../components/profile-new-form/ExpectationForm";
import OfferForm from "../../../components/profile-new-form/OfferForm";
import PhoneForm from "../../../components/profile-new-form/PhoneForm";
import NameForm from "@components/profile-new-form/NameForm";
import SalaryForm from "@components/profile-new-form/SalaryForm";

interface Props extends OnboardingProps {
}

export default observer(({ profile, account, tab }: Props) => {
  if (tab !== 1) {
    return <></>;
  }

  return (
    <div style={{ display: tab === 1 ? 'block' : 'none' }}>
      <div className="in-cnts">
        <NameForm
          title="이름을 입력해주세요. 이름은 채용이 확정된 상대방만 확인할 수 있습니다."
          profile={profile}
          account={account!}
        />
      </div>
      <div className="in-cnts">
        <PhoneForm
          title="협업 제안 받았을 시, 바로 확인할 수 있도록 알림을 받을 연락처를 입력해주세요."
          account={account!}
        />
      </div>
      <div className="in-cnts">
        <OfflineForm
          title="오프라인 출근 및 미팅도 가능하신가요?"
          profile={profile}
          account={account}
        />
      </div>
      <div className="in-cnts">
        <OfferForm
          title="세컨팀 회사로부터 협업 제안을 받으시겠습니까?"
          profile={profile}
          account={account}
          isOnboarding
        />
      </div>
      <div className="in-cnts">
        <AvailabilityForm
          title="현재 구직의향도 있으신가요?"
          profile={profile}
          account={account}
        />
        <SalaryForm
          title="구직 의향이 있으신 경우, 희망하시는 연봉을 알려주세요."
          profile={profile}
          account={account}
        />
      </div>
      <div className="in-cnts">
        <ExpectationForm
          title="세컨팀 멤버로 기대하는 바는 무엇인가요?"
          profile={profile}
          account={account}
        />
      </div>
    </div>
  );
});
