import { types, Instance } from "mobx-state-tree";
import { maybeNull } from "mobx-state-tree/dist/internal";
import { JobCategoryModel } from "../../members/models/JobCategoryModel";

const PartnerModel = types.model({
  avatar: types.maybeNull(types.string),
  company_name: types.string,
  uuid: types.string,
});

const ReferenceModel = types.model({
  link: types.maybeNull(types.string),
  file: types.maybeNull(types.string),
  type: types.string,
  name: types.string,
});

export const MemberProposalModel = types.model({
  uuid: types.string,
  status: types.string,
  phase: types.string,
  title: types.string,
  type: types.maybeNull(types.number),
  date_submitted: types.maybeNull(types.string),
  date_modified: types.maybeNull(types.string),
  has_unread_messages: types.maybe(types.boolean),
  project: types.maybeNull(types.string),
  partner: PartnerModel,
  member_comment: types.maybe(types.string),
  description: types.maybe(types.string),
  work_description: types.maybe(types.string),
  frameworks: types.array(types.string),
  tools: types.array(types.string),
  nda_agreement: types.maybe(types.boolean),
  references: types.array(ReferenceModel),
  duration: types.maybeNull(types.number),
  wage: types.maybeNull(types.number),
  working_hours: types.maybeNull(types.number),
  contract_method: types.maybeNull(types.string),
}).actions(self => ({
  setNDAAgreement: function () {
    self.nda_agreement = true;
  }
}));

export interface IMemberProposalModel extends Instance<typeof MemberProposalModel> {};
