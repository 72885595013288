import { RegularSurvey } from "@models/RegularSurvey";
import axios from "axios";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { PollProps } from "../PollPage";
import PollYesOrNo from "./PollYesOrNo";

const StyledUL = styled.ul`
  margin: 32px 16px 16px 16px;
`;

const StyledLI = styled.li`
  list-style: disc;
  & > a {
    color: #5539ff;
    text-decoration: underline;
  }
  color: #5539ff;
  font-size: 16px;
  margin-bottom: 12px;
  line-height: 24px;
`;

const PollStep2 = ({
  pollStore
}: PollProps) => {
  const data = pollStore.data?.questionnaire;
  const { register, getValues } = useFormContext();
  const [jobPostings, setJobPostings] = useState<any[]>([]);
  const isInculdedToSearching = pollStore.data?.candidate_search_id;

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const res = await axios.get(`/surveys/${pollStore.data?.uuid}/job-postings`);
    setJobPostings(res.data.matching_job_postings);
  };

  const onNext = () => {
    console.log(getValues());
    const answers = getValues('answers');
    const accept_meeting = getValues('accept_meeting');
    const isValid = answers.filter((v: any) => v).length === data?.questions.length;

    if (!isValid) {
      alert('답변되지 않은 문항이 있습니다.');
      return;
    }

    if (accept_meeting === null) {
      alert('만남 희망 여부를 응답해주세요.');
      return;
    }

    pollStore.next();
  }

  return (
    <div className="poll-wrap">
      <div className="p-step">
        <span className="check">Step 1</span>
        <strong>Step 2</strong>
        <span>Step 3</span>
      </div>
      {
        pollStore.data?.questionnaire?.regular_survey !== 2 ?
          <>
            <h2>첫 설문은 가볍게! ✅</h2>
            <div className="cnts">
              <p><em>{data?.main_category}-{data?.sub_category}</em> 분야의 프로젝트 및 채용 공고를 분석하여 구성한 {data?.questions.length}개의 핵심 질문을 드립니다. 예상 소요 시간은 단 30초!</p>
            </div>
          </>
          :
          <>
            <div className="cnts">
              {
                data?.regular_survey === 2 ?
                  <>
                    <p>
                      세컨팀 프로필에 작성된 데이터와 네카라쿠배 채용공고 키워드를 분석해서 관련성이 가장 높은 네카라쿠배의 채용공고들과 해당 채용공고를 분석하여 구성한 20개의 핵심 키워드입니다.
                    </p>

                    <p>
                      추천드리는 키워드에 대한 경험 유무를 선택해주시면, 다음번 커리어 설문에서 더 정교화된 설문과 추천을 받아볼 수 있습니다.
                    </p>
                  </>
                  :
                  <p>
                    설정하신 역할과 스킬을 토대로 적합한 채용 공고를 분석하여 구성한 20개의 핵심 키워드입니다. 해당되는 것을 선택하세요. 예상 소요 시간은 단 30초!
                  </p>
              }
            </div>
            <StyledUL>
              {
                jobPostings.map(v => (
                  <StyledLI>
                    <a href={v.url} target="_blank">{v.title}</a>
                    {
                      v.keywords.length > 0 &&
                      <p style={{ textDecoration: 'none', color: 'black', opacity: 0.9, fontSize: 15.5 }}>
                        #
                        {
                          v.keywords.join(' #')
                        }
                      </p>
                    }
                  </StyledLI>
                ))
              }
            </StyledUL>
          </>
      }
      <div className="ques-li">
        <ul>
          {
            data?.questions.map((v, index) => (
              <PollYesOrNo
                data={v}
                idx={index}
              />
            ))
          }

          {
            isInculdedToSearching &&
            <>
              <div className="ques-li"></div>
              <li>
                <p>
                  * 해당 프로젝트 진행을 위해 고객사와 인터뷰를 진행하시겠습니까?
                </p>
                <div className="ans">
                  <span><input type="radio" id={`yes`} {...register(`accept_meeting`)} value={1} /><label htmlFor={`yes`}>네</label></span>
                  <span><input type="radio" id={`no`} {...register(`accept_meeting`)} value={0} /><label htmlFor={`no`}>아니요</label></span>
                </div>
              </li>
            </>
          }
        </ul>
      </div>
      <div className="btn">
        <a href="#" className="actv" onClick={onNext}>다음</a>
      </div>
    </div>
  )
}

export default PollStep2;
