import { flow, types, Instance, getParent } from "mobx-state-tree";
import { TargetModel } from "./TargetModel";
import dayjs from "dayjs";



export const ProjectModel = types.model({
  uuid: types.string,
  status: types.string,
  start_date: types.maybeNull(types.string),
  end_date: types.maybeNull(types.string),
  title: types.string,
  member: types.maybeNull(TargetModel),
  partner: types.maybeNull(TargetModel),
})
.views(self => ({
  get dateDetail(): string {
    if (self.status !== 'DONE' && dayjs().isAfter(dayjs(self.start_date))) {
      return `${dayjs(self.start_date).format('YYYY.MM.DD')} ~ ${self.end_date ? dayjs(self.end_date).format('YYYY.MM.DD') : '진행중'}`;
    }

    if (self.status === 'PENDING' || (!self.start_date && !self.end_date)) {
      return self.start_date ? `${dayjs(self.start_date).format('YYYY.MM.DD')} 시작 예정` : '시작일 미정';
    }
    
    return `${dayjs(self.start_date).format('YYYY.MM.DD')} ~ ${self.end_date ? dayjs(self.end_date).format('YYYY.MM.DD') : (self.status === 'DONE' ? '종료' : '진행중')}`;
  }
}));

export interface IProjectModel extends Instance<typeof ProjectModel> {};
