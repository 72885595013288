import constantAPI from "@services/constantAPI";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IJobModel } from '@pages/onboarding/models/JobModel';
import portfolioAPI from "@services/portfolioAPI";
import { IEpisodeModel } from "@pages/profile/models/MemberProfileModel";
import TagManager from 'react-gtm-module';
import { AUTH_UUID } from "@utils/constants";

interface Props {
  nickname: string;
  data: IEpisodeModel;
  isOpen: boolean;
  onClose: () => void;
}

const CoWorkerAddingModal = ({
  nickname,
  data,
  isOpen,
  onClose,
}: Props) => {
  const { register, handleSubmit, setValue, watch } = useForm();

  const [jobList, setJobList] = useState<IJobModel[]>([]);;
  const [rootJobList, setRootJobList] = useState<IJobModel[]>([]);

  const mainWatch = watch(`main_category`);
  const subWatch = watch(`sub_category`);
  const searchedJob = jobList.filter(v => v.parent === mainWatch && (!subWatch || v.name.includes(subWatch)));

  const load = async () => {
    const jobList = await constantAPI.getCategoryList();
    const rootJobList = await constantAPI.getCategoryRootList();

    setJobList(jobList);
    setRootJobList(rootJobList);
  };

  useEffect(() => {
    load();
  }, []);

  if (!isOpen) {
    return <></>;
  }

  const onSubmit = async (form: any) => {
    const { name, email, main_category, sub_category } = form;

    if (!name) {
      alert('이름을 입력해주세요.');
      return;
    }

    if (!email) {
      alert('이메일을 입력해주세요.');
      return;
    }

    if (!main_category) {
      alert('분야를 선택해주세요.');
      return;
    }

    if (!sub_category) {
      alert('역할을 입력해주세요.');
      return;
    }

    await portfolioAPI.postCoWorker(data.uuid, form);

    const memberUUID = window.localStorage.getItem(AUTH_UUID) || '';
    TagManager.dataLayer({
      dataLayer: {
        event: 'addCoWorker',
        memberUUID,
      },
    });

    alert('동료 추가가 완료되었습니다');
    window.location.reload();
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box smtype">
        <section className="recu-chk-wrap">
          <h1>동료 추가하기</h1>
          <p>
            함께한 동료의 정보를 입력해주세요. 동료의 이메일주소로 {nickname || '멤버'}님에 대한 평가 설문(1분 소요)을 요청합니다. 동료가 설문에 응답완료하면 함께한 동료로 추가되게 되고, 설문 응답 내용은 내 프로필에서 공개됩니다.
          </p>
          <div className="recu-chk">
            <ul>
              <li>
                <label htmlFor="mName">동료 이름</label>
                <input
                  type="text"
                  {...register('name')}
                  id="mName"
                  placeholder="동료의 이름을 입력해주세요."
                />
              </li>
              <li>
                <label htmlFor="eMail">동료 이메일</label>
                <input
                  type="text"
                  {...register('email')}
                  id="eMail"
                  placeholder="example@email.com"
                />
              </li>
              <li>
                <label htmlFor="part">동료의 역할</label>
                <div className="inpt-w">
                  <select id="part" title="분야를 선택해주세요" {...register('main_category')}>
                    <option value=''>분야</option>
                    {
                      rootJobList.map(v => (
                        <option value={v.name}>{v.name}</option>
                      ))
                    }
                  </select>
                  <div className="fm-box" style={{ height: 56, marginTop: 8 }}>
                    <div className={`in-sel ${watch('sub_category') && watch('_focus') ? 'selected' : ''}`}>
                      <input
                        type="text"
                        {...register('sub_category')}
                        placeholder="분야를 먼저 선택해주세요"
                        disabled={!watch('main_category')}
                        onFocus={() => {
                          setValue(`_focus`, true);
                        }}
                        onBlur={() => {
                          setTimeout(() => {
                            setValue(`_focus`, false);
                          }, 200);
                        }}
                        onKeyUp={(e: any) => {
                          if (e.keyCode === 13) {
                            setValue(`_focus`, false);
                          }
                        }}
                      />
                      <div className="opt-li">
                        <ul>
                          {
                            Boolean(searchedJob.find(v => v.name === subWatch)) ||
                            <li>{subWatch}</li>
                          }
                          {
                            searchedJob.filter(v => v.name).map(v => (
                              <li onClick={() => setValue('sub_category', v.name)}>{v.name}</li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* <li>
                <label htmlFor="msgCnts">메시지</label>
                <textarea
                  id="msgCnts"
                  {...register('request_message')}
                  placeholder="간단한 평가 요청과 함께 동료에게 보여질 메세지를 입력해주세요."
                ></textarea>
              </li> */}
            </ul>
          </div>
          <div className="btn type2">
            <button
              type="button"
              style={{
                background: '#5539ff',
                color: 'white',
              }}
              onClick={handleSubmit(onSubmit)}
            >
              동료 추가하기
            </button>
          </div>
        </section>
        <button type="button" className="btn-close" onClick={onClose}><span>팝업 닫기</span></button>
      </div>
    </div>
  );
};

export default observer(CoWorkerAddingModal);
