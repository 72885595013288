import { useState } from 'react';

const NUMBER_REG = /^[0-9]*$/;

const EstablishedAtField = (props: {
  value?: string,
  onChange: (value: string) => void
}) => {
  const [ error, setError ] = useState('');

  return (
    <>
      <dt><label htmlFor="year">설립연도</label></dt>
      <dd>
          <input type="text" className={ error ? 'err' : ''} id="year"  value={ props.value } placeholder="2010"
            onChange={ (e) => {
              setError('');
              props.onChange(e.target.value);
              if(!NUMBER_REG.test(e.target.value)) {
                props.onChange(e.target.value.replace(/[^0-9]/g,''));
                setError('숫자만 입력 가능합니다.');
                return;
              }
              if(e.target.value.length > 4) {
                props.onChange(e.target.value.slice(0, 4))
                setError('4자리만 입력 가능합니다.');
                return;
              }
            }} />
          {
            error.length > 0 &&
              <p className="err">{ error }</p>
          }
          
      </dd>
    </>
  )
}
export default EstablishedAtField;