const MAX_LENGTH = 3000;

const TeamCultureField = (props: {
  value?: string,
  onChange: (value: string) => void
}) => {
  return (
    <div className="in-cnts">
      <h3><label htmlFor="crt">서비스 소개</label></h3>
      <div className="comp-intu">
        <textarea id="crt" value={ props.value }
          onChange={ (e) => {
            props.onChange(e.target.value.substring(0, MAX_LENGTH));
            if(e.target.value.length > MAX_LENGTH) {
              alert('3,000자까지 입력 가능합니다.');
            }
          }} ></textarea>
        <span>{ props.value?.length || 0 } / 3,000</span>
      </div>
    </div>
  )
}
export default TeamCultureField;