import { ICandidatePool } from '@models/CandidatePool';
import { MemberAccountModel } from '@pages/account/models';
import { IMemberProfileModel } from '@pages/profile/models';
import accountAPI from '@services/accountAPI';
import partnerAPI from '@services/partnerAPI';
import { AUTH_UUID } from '@utils/constants';
import { useQuery } from '@utils/hooks';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

interface Props {
  isSuggesting: boolean;
  canCoffeeChat: boolean;
  data: IMemberProfileModel;
  onClose: () => void;
}

const PoolSuggestionModal = ({ isSuggesting, canCoffeeChat, data, onClose }: Props) => {
  const isOpen = isSuggesting || canCoffeeChat;
  const { register, watch, setValue, reset, handleSubmit } = useForm();
  const query = useQuery();
  const { uuid }: any = useParams();
  const candidateId = query.get('candidateId');
  const candidatePoolId = query.get('candidatePoolId');
  const [companyName, setCompanyName] = useState('');

  const messageWatcher = watch('message'); // string
  const agreeWatcher = watch('isAgree'); // boolean
  const isReady = Boolean(messageWatcher) && agreeWatcher;

  const onCancel = () => {
    reset({});
    onClose();
  };

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const uuid = window.localStorage.getItem(AUTH_UUID) || '';
    const { name } = await accountAPI.getPartnerAccount(uuid);
    setCompanyName(name);
  }

  const onSubmit = async (form: any) => {
    const { message } = form;
    if (!message) {
      alert('내용을 입력해주세요.');
      return;
    }

    try {
      if (canCoffeeChat) {
        // 커피챗 API
        await axios.post(
          `/members/${uuid}/coffee_chat`,
          {
            message: message.slice(0, 500),
          },
        );
      } else {
        // 제안하기 API
        await axios.post(
          `/candidate-pools/${candidatePoolId}/candidates/${candidateId}/offers`,
          {
            message: message.slice(0, 500),
          },
        );
      }

      alert('해당 멤버에게 문자와 메일로 전달되었습니다. 가입해주신 메일함을 확인해주세요');
      window.location.href = `/members/${uuid}?candidateId=${candidateId}&candidatePoolId=${candidatePoolId}`;
    } catch (err: any) {
      if (err.response.data[0]) {
        alert(err.response.data[0]);
        return;
      }

      if (err.response.data.detail) {
        alert(err.response.data.detail);
        return;
      }

      alert('일시적 오류가 발생했습니다. 다시 시도해주세요.');
      return;
    }

    onClose();
  }

  if (!isOpen || !data) {
    return <></>;
  }

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3>
            {
              canCoffeeChat ? '커피챗 요청' : '제안하기'
            }
          </h3>
          <div className="sp-end2">
            <ul className="type2">
              {/* <li>
                <label htmlFor="pgWork" className="tit">회사</label>
                <select
                  id="pgWork"
                  title="회사 선택"
                  style={{ display: 'block', width: '100%', fontSize: 15 }}
                  {...register('candidateId')}
                >
                  <option>회사 선택</option>
                  <option value={1}>시소</option>
                  <option value={2}>데이탄소프트</option>
                </select>
              </li>
              <br/> */}
              <li>
                <label htmlFor="pgWork" className="tit">내용</label>
                <textarea
                  id="pgWork"
                  style={{ height: 250, color: '#0D1240' }}
                  placeholder="제안할 내용을 입력해주세요(500자)"
                  maxLength={500}
                  {...register('message')}
                />
              </li>
            </ul>
          </div>
          <p style={{ fontSize: 14, opacity: 0.8, marginTop: 8 }}>
            현재 <strong>{companyName}</strong>님은 Basic 회원입니다.<br />
            - 정규직 채용 확정후 3개월뒤: 200만원/건<br />
            - 정규직외 매칭 확정후 실무 착수시 : 80만원/건<br />
            * 구독회원(150만원/6개월)로 전환시, 모든 수수료가 면제됩니다.(관련 문의:채널톡)
          </p>
          <p style={{ display: 'flex', alignItems: 'start', width: '100%' }}>
            <input
              type="checkbox"
              id={'checkId'}
              {...register('isAgree')}
            />
            <label
              htmlFor={'checkId'}
              style={{ textDecoration: 'none', fontSize: 14, opacity: 0.8 }}
            >
              내용을 확인했습니다.
            </label>
          </p>

          <div className="btn type2">
            {/* <button type="button" className="cancel" onClick={onCancel}>취소</button> */}
            <button
              className={isReady ? "actv" : ''}
              disabled={!isReady}
              onClick={handleSubmit(onSubmit)}
            >
              제안발송
            </button>
          </div>
        </div>
        <button type="button" className="btn-close" onClick={onClose}><span>팝업 닫기</span></button>
      </div>
    </div >
  );
};

export default PoolSuggestionModal;
