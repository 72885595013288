import { types, Instance, getParent } from "mobx-state-tree";

export const IterationModel = types.model({
  id: types.number,
  status: types.string,
  round: types.number,
  start_date: types.maybeNull(types.string),
  end_date: types.maybeNull(types.string),
  wage: types.number, // 정산 비용
  payment_agreement: types.maybe(types.boolean), // 정산 동의
  date_payment_requested: types.maybeNull(types.string), // 정산 요청
  date_payment_completed: types.maybeNull(types.string), // 정산 완료
  weekly_work_description: types.string,
})
.views(self => ({
  
}))
.actions(self => ({
  
}));

export interface IIterationModel extends Instance<typeof IterationModel> {};
