import { useEffect, useState } from 'react';
import { inject, observer } from "mobx-react";
import { useForm, useFormContext, FormProvider } from 'react-hook-form';
import axios from 'axios';
import { numberWithCommas, parseWithCommas } from "../../../utils/utils";
import { ICalcResponseModel } from "../models/CalcResponseModel";
import { StateProps } from '../../../interfaces/interfaces';
import { taxTypeMap } from '@utils/constants';

interface Props extends StateProps {
  uuid: string;
  data: ICalcResponseModel;
  taxType?: string;
  isOpen: boolean;
  onClose: () => void;
}

const DepositModal = (props: Props) => {
  const {
    uuid,
    data,
    isOpen,
    onClose,
  } = props;

  const form = useForm();
  const { watch, setValue, handleSubmit } = form;
  const [isNoTax, setNoTax] = useState(false);
  const [taxType, setTaxType] = useState('');

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    load();
  }, [isOpen]);

  const load = async () => {
    const { data } = await axios.get(`/members/${uuid}`);
    const { tax_type } = data;
    setNoTax(!Boolean(tax_type));

    let taxType = taxTypeMap.NONE;
    switch (tax_type) {
      case 'FREELANCE':
        taxType = taxTypeMap.FREELANCE;
        break;
      case 'OTHERS':
        taxType = taxTypeMap.OTHERS;
        break;
      case 'SOLO':
          taxType = taxTypeMap.SOLO;
          break;
      case 'CORPORATION':
          taxType = taxTypeMap.CORPORATION;
          break;
    }
    setTaxType(taxType);
  }

  if (!isOpen) {
    return <></>;
  }

  return (
    <FormProvider
      {...form}
    >
      <div className="dimd-layer">
        <div className="ly-box alert-box smtype">
          {
            isNoTax ?
              <Help
                {...props}
              />
              :
              Boolean(watch('isSuccess')) ?
                <Done
                  {...props}
                  taxType={taxType}
                />
                :
                <Request
                  {...props}
                  taxType={taxType}
                />
          }
        </div>
      </div>
    </FormProvider>
  );
};

const Request = ({
  uuid,
  appStore,
  data,
  taxType,
  isOpen,
  onClose,
}: Props) => {
  const { watch, setValue, handleSubmit } = useFormContext();
  const { showLoader, hideLoader } = appStore!;

  const onSubmit = async (form: any) => {
    if (!form.amount) {
      return;
    } else if (form.amount > data.balance) {
      alert('보유 금액이 부족합니다.');
      return;
    }

    try {
      showLoader();
      await axios.post(
        `/members/${uuid}/payments/request-withdrawal`,
        form,
      );
      hideLoader();

      setValue('isSuccess', true);
    } catch (err: any) {
      console.log(err.response);
      const msg = err.response.data.message;
      if (msg) {
        alert(msg);
      }
    }
  };

  const onChangeAmount = (e: any) => {
    const [num, text] = parseWithCommas(e.target.value);
    setValue('amount', num);
    setValue('_amount', text);
  };

  const balance = data.balance || 0;

  return (
    <>
      <div className="inner">
        <h3>지급 요청</h3>
        <p>보유금액 중 지급받으실 금액을 입력해주세요. 지급시 소득세를 적용하여 계좌로 이체해드립니다.</p>
        <div className="sp-end">
          <ul>
            <li>
              <label htmlFor="pay">요청 지급 금액</label>
              <div>
                <input
                  type="text"
                  id="pay"
                  value={watch('_amount')}
                  onChange={onChangeAmount}
                />
                <span>원</span>
                {
                  watch('amount') > balance &&
                  <p>보유 금액이 부족합니다.</p>
                }
              </div>
            </li>
            <li>
              <label htmlFor="resPay">현재 보유 금액</label>
              <div>
                <input
                  type="text"
                  id="resPay"
                  readOnly
                  value={numberWithCommas(balance || 0)}
                />
                <span>원</span>
              </div>
            </li>
            <li>
              <i>적용 소득세</i>
              <div>{taxType}</div>
            </li>
          </ul>
        </div>
        <div className="desc-box">
          <ul>
            <li>요청된 지급은 매월 2째주, 4째주 금요일에 지급됩니다.</li>
            <li>적용 소득세 유형의 변경은 운영팀에 문의하여 주시기 바랍니다.</li>
          </ul>
        </div>
        <div className="btn">
          <button type="button" className="actv" onClick={handleSubmit(onSubmit)}>지급 요청</button>
        </div>
      </div>
      <button type="button" className="btn-close" onClick={onClose}><span>팝업 닫기</span></button>
    </>
  );
}

const Help = ({
  uuid,
  data,
  isOpen,
  onClose,
}: Props) => {
  return (
    <>
      <div className="inner">
        <h3>소득세 유형 안내</h3>
        <p>지급 처리를 위해 소득세 유형을 접수해주셔야 합니다. 희망하시는 소득세 유형에 대해서 선택후, 등록 절차와 필요 서류를 메일로 전달주시면 확인후 안내드리고 있습니다.</p>
        <div className="cho-list">
          <ul>
            <li onClick={() => window.open('https://seesorufree.notion.site/a4e11f74b6404a60b9ec8bcbd875b582')}>
              <label style={{ cursor: 'pointer' }}>개인(사업 소득)</label>
            </li>
            <li onClick={() => window.open('https://seesorufree.notion.site/ef2e7fb9d90140559b47d40d4e4f3a86')}>
              <label style={{ cursor: 'pointer' }}>개인 사업자</label>
            </li>
            <li onClick={() => window.open('https://seesorufree.notion.site/42add9347d23416381cee83ce09fd73e')}>
              <label style={{ cursor: 'pointer' }}>법인 사업자</label>
            </li>
          </ul>
          <p><a href="https://seesorufree.notion.site/8cac7ca04fd04581a6756abf15d3c7d5" target="_blank">해당되는 소득세 유형이 없으신가요?</a></p>
        </div>
        <div className="btn">
          <button type="button" className="actv" onClick={onClose}>확인</button>
        </div>
      </div>
      <button type="button" className="btn-close" onClick={onClose}><span>팝업 닫기</span></button>
    </>
  )
}

const Done = ({
  uuid,
  data,
  isOpen,
  taxType,
  onClose,
}: Props) => {
  const { watch, setValue, handleSubmit } = useFormContext();

  return (
    <>
      <div className="inner">
        <h3>지급 요청</h3>
        <p>아래와 지급 요청되었습니다. 요청된 지급은 매월 2째주, 4째주 금요일에 지급됩니다.</p>
        <div className="sp-end">
          <ul>
            <li>
              <i>적용 소득세</i>
              <div>{taxType}</div>
            </li>
            <li>
              <i>요청 지급 금액</i>
              <div>{numberWithCommas(watch('amount'))} 원</div>
            </li>
          </ul>
        </div>
        <div className="btn">
          <button type="button" className="actv" onClick={() => window.location.reload()}>확인</button>
        </div>
      </div>
      <button type="button" className="btn-close" onClick={() => window.location.reload()}><span>팝업 닫기</span></button>
    </>
  )
}

export default inject("appStore")(observer((DepositModal)));
