import { types, Instance, getParent } from "mobx-state-tree";
import { JobCategoryModel } from "./JobCategoryModel";
import membersAPI from "../../../services/membersAPI";
import { StateModel } from "../../../models/models";


export const MemberModel = StateModel.named('MemberModel')
.props({
  id: types.maybeNull(types.number),
  uuid: types.string,
  roles: types.array(types.maybeNull(types.string)),
  avatar: types.maybeNull(types.string),
  nickname: types.string,
  get_offer: types.boolean,
  job_categories: types.array(JobCategoryModel),
  employment_type: types.string,
  company_name: types.string,
  employment_public_open: types.boolean,
  experiences: types.array(types.string),
  portfolio: types.array(types.string),
  followee: types.array(types.string),
  is_following: types.boolean,
})
.actions(self => ({
  toggleFollow() {
    if (self.is_following) {
      membersAPI.unfollow(self.uuid);
    } else {
      membersAPI.follow(self.uuid);
    }

    self.is_following = !self.is_following;
  },
}));

export interface IMemberModel extends Instance<typeof MemberModel> {};
