import { flow, types, Instance, getParent } from 'mobx-state-tree';
import { remove } from 'lodash';

import {
  AUTH_USERTYPE,
  CoworkingTimeOptions,
  EmploymentTypeOptions,
} from '../../../utils/constants';
import profileAPI from '../../../services/profileAPI';
import { IProfileStore } from '../../../stores/profileStore';
import { updateStringArray } from '../../../utils/utils';
import { CoWorker } from '@models/CoWorker';

const FileReferenceModel = types.model({
  type: types.maybeNull(types.string),
  link: types.maybeNull(types.string),
  file: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
});

const TypeModel = types.model({
  type: types.maybeNull(types.number),
  count: types.number,
});

const SkillSet = types.model({
  name: types.string,
});

/*
 **  JobCategory
 */
const JobCategoryModel = types.model({
  id: types.number,
  name: types.string, // 직무
  parent: types.string, // 직군
});
export interface IJobCategoryModel extends Instance<typeof JobCategoryModel> {}

const NewJobCategoryModel = types.model({
  pk: types.number,
  main_category: types.string,
  sub_category: types.string,
});

/*
 ** Episode
 */
const EpisodeModel = types.model({
  uuid: types.string,
  title: types.string,
  domain: types.string,
  year: types.maybeNull(types.number),
  duration: types.maybeNull(types.number),
  description: types.string,
  skill_set: types.array(SkillSet),
  type: types.maybeNull(types.number),
  role: types.maybe(types.string),
  job_category: types.maybeNull(types.number),
  job_categories: types.array(NewJobCategoryModel),
  coworkers: types.array(CoWorker),
  coworking_member_count: types.maybeNull(types.number),
  reference_type: types.maybeNull(types.string),
  link: types.maybeNull(types.string),
  file: types.maybeNull(types.string),
  filename: types.maybeNull(types.string),
  skills: types.array(types.string),
  experiences: types.array(types.string),
  frameworks: types.array(types.string),
  like_count: types.number,
  super_like_count: types.number,
  share_count: types.number,
  updated_at: types.maybeNull(types.string),
  created_at: types.maybeNull(types.string),
});
export interface IEpisodeModel extends Instance<typeof EpisodeModel> {}

/*
 ** Experience
 */
const ExperienceModel = types.model({
  uuid: types.string,
  title: types.string,
  domain: types.string,
  role: types.string,
  job_category: types.maybeNull(types.number),
  coworking_member_count: types.maybeNull(types.number),
  reference: types.maybeNull(FileReferenceModel),
  skills: types.array(types.string),
  experiences: types.array(types.string),
  frameworks: types.array(types.string),
});

/*
 **  Portfolio
 */
export const PortfolioModel = types
  .model('Portfolio', {
    // id: types.optional(types.number, -1),
    type: types.maybeNull(types.string), // 포트폴리오 타입 (LINK/FILE)
    file: types.maybeNull(types.string), // 포트폴리오 파일
    link: types.optional(types.string, ''), // 포트폴리오 링크
    name: types.optional(types.string, ''), // 파일 이름
    // format: types.optional(types.string, ''), // 파일 포맷
  })
  .views(self => ({
    get uuid(): string {
      return (getParent(self, 2) as IProfileStore).uuid;
    },
  }))
  .actions(self => ({
    removeLink() {
      // self.type = '';
      self.link = '';
    },
    setLink(link: string) {
      self.type = 'LINK';
      self.link = link;
    },
    removeFile: flow(function* removeFile() {
      if (!self.uuid) {
        return;
      }

      yield profileAPI.removeMemberProfilePortfolioFile(self.uuid);
      self.type = '';
      self.link = '';
    }),
    setName(name: string) {
      self.type = 'FILE';
      self.name = name;
    },
  }));
export interface IPortfolioModel extends Instance<typeof PortfolioModel> {}

/*
 **  Reference
 */
export const ReferenceModel = types
  .model('Reference', {
    pk: types.maybeNull(types.number),
    label: types.optional(types.string, ''), // SNS
    display: types.optional(types.string, ''), // SNS 이름
    link: types.optional(types.string, ''), // 링크
  })
  .actions(self => ({
    toJSON() {
      return Object.assign(
        {},
        {
          pk: self.pk,
          label: self.label,
          display: self.display,
          link: self.link,
        },
        self.pk ? { pk: self.pk } : undefined,
      );
    },
    setId(id: number) {
      self.pk = -1;
    },
    setLabel(label: string) {
      self.label = label;
    },
    setDisplay(display: string) {
      self.display = display;
    },
    setLink(link: string) {
      self.link = link;
    },
  }));
export interface IReferenceModel extends Instance<typeof ReferenceModel> {}

export const MemberProfileModel = types
  .model({
    avatar: types.maybeNull(types.string),
    nickname: types.maybe(types.string), //닉네임
    name: types.maybe(types.string), // 이름
    introduction: types.string, // 자기소개
    job_categories: types.array(JobCategoryModel),
    job_category: types.array(types.number),
    work_experience_year: types.maybe(types.string), //경력
    company_name: types.string, // 소속
    employment_type: types.enumeration(
      'EmploymentType',
      EmploymentTypeOptions.map(op => op.value),
    ),
    employment_public_open: types.boolean, // 소속 노출 여부
    tags: types.array(types.string), //나를 소개하는 키워드
    coworking_time: types.array(
      types.enumeration(
        'CoworkingTime',
        CoworkingTimeOptions.map(op => op.value),
      ),
    ), // 협업 가능 시간대
    coworking_tools: types.array(types.string), // 협업툴
    frameworks: types.array(types.string), // 언어/프레임워크
    languages: types.array(types.string), // 언어
    tools: types.array(types.string), // 툴
    roles: types.array(types.maybeNull(types.string)), // 전문 > 역할
    skills: types.array(types.maybeNull(types.string)), // 전문 > 경험
    role_preferences: types.array(types.string), // 희망 제안 정보 > 희망 프로젝트 분야
    work_preferences: types.array(types.number), //  희망 제안 정보 > 희망 제안 유형
    types: types.array(TypeModel),
    // portfolio: PortfolioModel,
    references: types.array(ReferenceModel),
    residence: types.string,
    country_of_residence: types.string,
    working_hour_range_per_week: types.maybeNull(types.number),
    working_hours_per_week: types.maybeNull(types.number),
    working_flexibility: types.maybeNull(types.boolean),
    working_time: types.array(types.string),
    expected_hourly_wage: types.maybeNull(types.number),
    korean_only: types.maybeNull(types.boolean),
    episodes: types.array(EpisodeModel),
    experiences: types.array(ExperienceModel),
    office_work: types.boolean,
    offline_meeting: types.boolean,
    availability: types.string,
    get_offer: types.boolean,
    expectations: types.array(types.string),
    profile_completed: types.boolean,
    onboarding_step_1: types.boolean,
    onboarding_step_2: types.boolean,
    onboarding_step_3: types.boolean,
    onboarding_step_4: types.boolean,
    onboarding_step_5: types.boolean,
    has_unread_messages: types.boolean,
    expected_annual_salary: types.maybeNull(types.number),
    is_active: types.boolean,
    current_project_count: types.maybe(types.number), // 진행중인 프로젝트 개수
  })
  .views(self => ({
    get uuid(): string {
      return (getParent(self) as IProfileStore).uuid;
    },
    get belongString(): string {
      return self.employment_public_open
        ? self.employment_type === 'FREELANCE'
          ? '프리랜서'
          : self.company_name || '회사'
        : '비공개';
    },
    get residenceString(): string {
      return self.residence === 'ABROAD'
        ? self.country_of_residence || '해외'
        : self.residence === 'DOMESTIC'
        ? '한국'
        : '';
    },
    get jobString(): string {
      return self.job_categories?.[0]?.parent || '기획자';
    },
    get projectAreaList(): string[] {
      return Array.from(
        new Set(self.episodes.map(v => v.domain).filter(v => v)),
      );
    },
    get isShowProfileBanner(): boolean {
      const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';
      const isCompany = userType === 'PARTNER';

      return !(self.nickname && self.working_hour_range_per_week && self.expected_hourly_wage && self.employment_type) && !isCompany;
    }
  }))
  .actions(self => ({
    setNickname(nickname: string) {
      self.nickname = nickname;
    },
    setIntroduction(introduction: string) {
      self.introduction = introduction;
    },
    setJobCategories(category: IJobCategoryModel, selected: boolean) {
      let newArr = [...self.job_categories];

      if (selected) {
        newArr.push(category);
      } else {
        remove(newArr, o => o.id === category.id);
      }
      self.job_categories.replace(newArr);
    },
    setWorkExperienceYear(year: string) {
      self.work_experience_year = year;
    },
    setCompanyName(name: string) {
      self.company_name = name;
    },
    setEmploymentType(eType: string) {
      self.employment_type = eType;
    },
    setEmploymentPublicOpen(open: boolean) {
      self.employment_public_open = open;
    },
    setTags(tags: string[]) {
      self.tags.replace(tags);
    },
    setCoworkingTime(time: string, selected: boolean) {
      self.coworking_time.replace(
        updateStringArray(self.coworking_time, time, selected),
      );
    },
    setCoworkingTools(tool: string, selected: boolean) {
      self.coworking_tools.replace(
        updateStringArray(self.coworking_tools, tool, selected),
      );
    },
    setFrameworks(framework: string, selected: boolean) {
      self.frameworks.replace(
        updateStringArray(self.frameworks, framework, selected),
      );
    },
    addReference(reference: IReferenceModel) {
      self.references.push(reference);
    },
    removeReference(reference: IReferenceModel) {
      const temp = [...self.references];
      remove(temp, ref => ref.label === reference.label);
      self.references.replace(temp);
    },
  }))
  .actions(self => ({
    changeAvatar: flow(function* update(frm: FormData) {
      if (!self.uuid) {
        return;
      }
      try {
        const data = yield profileAPI.updateMemberAvatar(self.uuid, frm);
        self.avatar = data.avatar;
      } catch (e) {}
    }),
    // update: flow(function* update(
    //   portfolioFile?: FormData,
    // ) {
    //   if(!self.uuid) {
    //     return;
    //   }

    //   if(portfolioFile) {
    //     yield profileAPI.createMemberProfilePortfolioFile(self.uuid, portfolioFile);
    //   }

    //   console.log(self.portfolio)
    //   try {
    //     yield profileAPI.updateMemberProfile(self.uuid,
    //       Object.assign( {},
    //         {
    //           nickname: self.nickname,
    //           introduction: self.introduction,
    //           job_category: self.job_categories.map(job => job.id),
    //           work_experience_year: self.work_experience_year,
    //           company_name: self.company_name,
    //           employment_type: self.employment_type,
    //           employment_public_open: self.employment_public_open,
    //           tags: self.tags,
    //           coworking_time: self.coworking_time,
    //           coworking_tools: self.coworking_tools,
    //           frameworks: self.frameworks,
    //           references: self.references.filter(ref => ref.link).map(reference => reference.toJSON()),
    //         },
    //         self.portfolio.type === 'LINK' ? {
    //           portfolio: {
    //             "type": self.portfolio.type,
    //             "link": self.portfolio.link
    //           }
    //         } : {}
    //       ));
    //   } catch(e) {
    //     throw e;
    //   }
    // })
  }));
export interface IMemberProfileModel
  extends Instance<typeof MemberProfileModel> {}
