import { Link } from 'react-router-dom';

const PartnerJoinDonePage = () => {
  return (
    <div className="join-sign">
      <div className="visual">
        <div className="inner">
          <img src="/images/join_img02.png" alt="" />
        </div>
      </div>
      <div className="join-end">
        <div className="inner">
          <strong>가입 요청이 성공적으로 <br /> <span>완료</span>되었습니다.</strong>
          <p>영업일 1-2일 내 가입하신 메일로 승인 결과를 알려드립니다.</p>
          <div className="btn fx-type">
              <Link to="/login" className="actv">로그인하기</Link>
          </div>
        </div>
      </div>
    </div>
  )
};

export default PartnerJoinDonePage;
