import { observer } from "mobx-react"
import { numberWithCommas } from "../../../utils/utils";
import { IMemberProfileModel } from "../../profile/models/MemberProfileModel";

interface Props {
  uuid: string;
  member: any;
  projectCount: number;
}

export default observer(({ uuid, member, projectCount }: Props) => {
  return (
    <div className="in-cnts">
      <div className="profile-box">
        <div className="def-info">
          <div className="profile">
            <span className="photo" style={{ backgroundImage: `url(${member.avatar || '/images/@photo.png'})` }}></span>
            <div className="info">
              <div className="name">
                <strong>{member.nickname}</strong>
                <span>{member.job_categories[0]?.name} · {member.company_name}</span>
              </div>
            </div>
          </div>
          <p>{member.experiences.map((v: any) => v?.title || '').filter((v: any) => v).join(', ')}</p>
        </div>
        <div className="sub-info">
          <em className="ing">현재<b>{projectCount}</b>건의 프로젝트 진행중</em>
          <p className="q-cnts">📣 {member.nickname}님에게 궁금한 점은 협업 제안 후, 메세지를 통해 물어보실 수 있습니다. </p>
          {/* <table>
            <caption>희망 시간 및 시급 정보</caption>
            <tr>
              <th scope="row">1주일에 사용할 수 있는 최대 시간</th>
              <td>{member.working_hours_per_week || 0}시간</td>
            </tr>
            <tr>
              <th scope="row">희망 시급</th>
              <td>{numberWithCommas(member?.expected_hourly_wage || 0)}원</td>
            </tr>
          </table> */}
          <div className="btn-view">
            <a href={`/members/${uuid}`} target="_blank"><span>프로필 보기</span></a>
          </div>
        </div>
      </div>
    </div>
  );
});
