import { types, Instance, flow } from "mobx-state-tree";
import axios from 'axios';

import { StateModel } from '@models/models';
import {
  JoinAgreementModel,
  JoinAccountModel,
  JoinCompany
} from '@pages/join/models';

import joinAPI from "@services/joinAPI";

export const JOIN_TYPE = {
  PARTNER : 'partner',
  MEMBER: 'member'
}
const JoinStore = StateModel.named('JoinStore')
  .props({
    joinType: types.optional(types.string, ''),  // partner | member
    clickedJoin: types.optional(types.boolean, false),  // 가입하기 버튼을 한번이라도 눌렀는가?

    joinAgreementForm: types.optional(JoinAgreementModel, {}),    
    joinAccountForm: types.optional(JoinAccountModel, {}),
    joinCompanyForm: types.optional(JoinCompany, {})
  })
  .views(self => ({
    get nextJoinLink() {
      if(self.joinType.length === 0) {
        return undefined;
      }
      return self.joinType === JOIN_TYPE.PARTNER ? '/partner/join/form' : '/member/join';
    },
    get isEditing() {
      if(
        self.joinAccountForm.isEditing
        || self.joinCompanyForm.isEditing
        || self.joinAgreementForm.isEditing
      ) {
        return true;
      }
      return false;
    }
  }))
  .actions(self => ({
    reset() {
      self.joinType = '';
      self.clickedJoin = false;

      self.joinAgreementForm.reset();
      self.joinAccountForm.reset();
      self.joinCompanyForm.reset();
    },
    setJoinType(joinType: string) {
      self.joinType = joinType;
    },
  }))
  .actions(self => ({
    memberSignup: flow(function* signup(data: any) {
      self.clickedJoin = true;

      try {
        yield self.joinAccountForm.confirmVerifyEmail(self.joinAccountForm.email);
      } catch(e) {
        throw Error();
      }

      
      const isValidWorkPreference = data.work_preferences.length > 0;
      const isValidJobCategory = Boolean(data.job_categories.find((v: any) => v.main_category && v.sub_category));
      
      if(
        self.joinAccountForm.isValid
        && self.joinAgreementForm.isValid
        && isValidWorkPreference
        && isValidJobCategory
      ) {
        try{
          yield joinAPI.memberSignup(
            Object.assign(
              self.joinAccountForm.toJSON(),
              self.joinAgreementForm.toJSON(),
              data,
              {
                signup_query_string: window.location.search,
              }
            )
          );
        } catch(e) {
          axios.isAxiosError(e);
          if (e.response) {
            if(e.response.status === 500) {
              alert('오류가 발생했습니다. 다시 시도해주세요.');
            }
            if(e.response.data.code === 'ACCOUNT_400_EMAIL_NOT_VERIFIED') {
              self.joinAccountForm.setEmailValidationMessage(e.response.data.message);
            }
          }

          throw Error();
        }
      } else {
        throw Error();
      }
    }),
    partnerSignup: flow(function* signup() {
      self.clickedJoin = true;

      if(
        self.joinAccountForm.isValid
          && self.joinCompanyForm.isValid
          && self.joinAgreementForm.isValid
      ) {
        try {
          yield joinAPI.partnerSignup(
            Object.assign(
              {},
              self.joinAccountForm.toJSON(),
              self.joinCompanyForm.toJSON(),
              self.joinAgreementForm.toJSON(),
            )
          );
        } catch(e) {
          axios.isAxiosError(e);
          if (e.response) {
            if(e.response.status === 500) {
              self.joinAccountForm.setEmailValidationMessage('중복된 이메일입니다.');
            } else if (e.response.data.code === 'ACCOUNT_400_EMAIL_NOT_VERIFIED') {
              self.joinAccountForm.setEmailValidationMessage(e.response.data.message);
            } else {
              alert(Object.values(e.response.data)[0]);
            }
          }

          throw Error();
        }
      } else {
        throw Error();
      }
    })
  }));
export interface IJoinStore extends Instance<typeof JoinStore> {};

export default JoinStore;
