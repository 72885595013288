import dayjs from "dayjs";
import {IIterationModel} from "../models/IterationModel";
import {numberWithCommas} from "../../../utils/utils";
import {IDetailModel} from "../models/DetailModel";

interface Props {
    data: IDetailModel,
    iteration: IIterationModel,
    balance: number,
    isOpen: boolean;
    onClose: () => void;
}

export default ({
    data,
                    iteration,
                    balance,
                    isOpen,
                    onClose,
                }: Props) => {
    if (!isOpen) {
        return <></>;
    }

    const onEnd = () => {
        onClose();
    };

    return (
        <div className="dimd-layer">
            <div className="ly-box alert-box smtype">
                <div className="inner">
                    <h3>보유 금액을 충전해주세요</h3>
                    <p>보유 금액이 부족한 상태가 계속되면 협업이 자동으로 종료됩니다.
                    <br />자동 종료일 전까지 보유 금액을 충전해주세요.</p>
                    <div className="sp-end">
                        <ul className="type2">
                            <li>
                                <i className="tit">자동 종료일</i>
                                <span>{data.rat_count == 1 ? dayjs(iteration?.end_date).add(8, 'day').format('YYYY.MM.DD') : dayjs(iteration?.end_date).add(1, 'day').format('YYYY.MM.DD')}</span>
                            </li>
                            <li>
                                <i className="tit">현재 보유 금액</i>
                                <span>{numberWithCommas(balance)}원</span>
                            </li>
                        </ul>
                    </div>
                    <button type="button" className="btn-close" onClick={onEnd}><span>팝업 닫기</span></button>
                </div>
            </div>
        </div>
    );
}
