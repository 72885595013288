import { IDetailModel } from "../models/DetailModel";
import axios from 'axios';
import dayjs from "dayjs";
import {IIterationModel} from "../models/IterationModel";

interface Props {
    data: IDetailModel,
    iteration: IIterationModel,
    isOpen: boolean;
    onClose: () => void;
}

export default ({
                    data,
                    iteration,
                    isOpen,
                    onClose,
                }: Props) => {
    if (!isOpen) {
        return <></>;
    }

    const onEnd = () => {
        onClose();
    };

    return (
        <div className="dimd-layer">
            <div className="ly-box alert-box smtype">
                <div className="inner">
                    {/*<h3>협업 종료</h3>*/}
                    <p>{data.partner?.validName}님의 보유금액이 부족합니다.</p>
                    <p>{data.partner?.validName}님의 보유금액이 충전되지 않으면, {data.proposal.title} 채용이 자동 종료될 예정입니다.</p>
                    <p>하지만, 걱정마세요. 진행된 기간에 대한 주급은 세컨팀이 정상적으로 정산해드립니다.</p>
                    <div className="sp-end">
                        <ul className="type2">
                            <li>
                                <i className="tit">종료 예정일</i>
                                <span>{dayjs(iteration.end_date).add(1, 'day').format('YYYY.MM.DD')}</span>
                            </li>
                        </ul>
                    </div>
                    <button type="button" className="btn-close" onClick={onEnd}><span>팝업 닫기</span></button>
                </div>
            </div>
        </div>
    );
}
