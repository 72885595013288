/*
  markup : pay_list_comp.html
*/

import { useEffect, useState } from "react";
import { inject, observer } from "mobx-react"
import { IAppStore } from "../../stores/AppStore";
import Pagination from "../../components/Pagination"
import CalcEmpty from "../../components/empty/CalcEmpty"
import CalcPartnerRow from './components/CalcPartnerRow';
import { numberWithCommas } from "../../utils/utils";

interface IProps {
  appStore: IAppStore;
  history: any
}

const PartnerCalcPage: React.SFC<IProps> = inject('appStore')(observer((props) => {
  const { calcStore } = (props as IProps).appStore;
  const { payment, getPayment } = calcStore;
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (tab > 0) {
      getPayment({
        type: ['DEPOSIT', 'PAYMENT'][tab - 1]
      });
      return;
    }

    getPayment();
  }, [tab]);

  const paymentList = (payment?.results || []).filter(v => tab === 0 || tab === 2 || v.type === 'DEPOSIT');
  const balance = payment?.balance || 0;
  const totalPayment = payment?.total_payment || 0;

  return (
    <>
      <div className="sub-wrap">
        <div className="top-box">
          <h2>
            충전 및 정산 <a href='https://seesorufree.notion.site/09fe23ecde3d4470bd5155a41f6be6a1' target="_blank" style={{ textDecoration: 'underline' }}>가이드</a>
          </h2>
          <p>정산된 금액이 현재 보유금액보다 많은 경우 충전이 필요합니다.</p>
          {/* <div className="btn-guide">
            <a href="https://seesorufree.notion.site/87a9314fe3c241bc85174801b387aff0" target="_blank">충전 및 정산 안내</a>
          </div> */}
        </div>
        <div className="pay-wrap">
          <div className="pay-box">
            <div className="pay">
              <i>누적 정산금액</i>
              <em>{numberWithCommas(totalPayment)}원</em>
            </div>
            <div className="pay">
              <i>현재 보유금액</i>
              <em>{numberWithCommas(balance)}원</em>
            </div>
            <p><a href="https://seesorufree.notion.site/d4331a4f814b48db91b10a5703407d4d" target="_blank">보유 금액 충전 방법</a></p>
          </div>
          <div className="cnts-hgroup2">
            {/* <h3>정산 내역</h3> */}
            <div className="tab">
              <ul>
                <li onClick={() => setTab(0)} style={{ cursor: 'pointer' }} className={tab === 0? 'selected' : ''}><a>전체({payment?.counts.all || 0})</a></li>
                <li onClick={() => setTab(1)} style={{ cursor: 'pointer' }} className={tab === 1 ? 'selected' : ''}><a>충전({payment?.counts.deposit || 0})</a></li>
                <li onClick={() => setTab(2)} style={{ cursor: 'pointer' }} className={tab === 2 ? 'selected' : ''}><a>정산({payment?.counts.payment || 0})</a></li>
              </ul>
            </div>
          </div>
          <div className="pay-list">
            <div className="viewport">
              <table>
                <caption>정산 내역</caption>
                <colgroup>
                  <col style={{ width: 125 }} />
                  <col style={{ width: 71 }} />
                  <col style={{ width: 184 }} />
                  <col style={{ width: 164 }} />
                  <col style={{ width: 128 }} />
                  <col style={{ width: 128 }} />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">날짜</th>
                    <th scope="col">분류</th>
                    <th scope="col">세컨팀 멤버</th>
                    <th scope="col">상세</th>
                    <th scope="col">금액</th>
                    <th scope="col">잔여 보유 금액</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    paymentList.map((v: any) => (
                      <CalcPartnerRow
                        data={v}
                      />
                    ))
                  }
                </tbody>
              </table>
            </div>
            <span className="scroll"></span>
          </div>

          {
            paymentList.length > 0 ?
              <Pagination
                size={20}
                totalCount={payment?.count || 0}
                onChange={(page: number) => {
                  if (tab > 0) {
                    getPayment({
                      type: ['DEPOSIT', 'PAYMENT'][tab - 1],
                      page,
                    });
                    return;
                  }
              
                  getPayment({ page });
                }}
              />
              :
              <CalcEmpty />
          }
        </div>
      </div>
    </>
  )
}));

export default PartnerCalcPage;

