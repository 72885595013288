import { observer } from "mobx-react-lite";
import CoWorkerConfigCard from "@pages/career/components/CoWorkerConfigCard";
import { useEffect, useState } from "react";
import { ICandidate } from "@models/Candidate";
import axios from "axios";
import styled from "styled-components";
import Loader from 'react-loader';
import { AUTH_USERTYPE } from "@utils/constants";

interface Props {
  id: string;
  isOpen: boolean;
  onClose: () => void;
}

const StyledUl = styled.ul`
  label {
    display: none !important;
  }

  .menu-footer {
    display: none !important;
  }

  li {
    border-color: transparent !important;
  }
`;

const PositiveButton = styled.button`
  width: 50%;
  height: 50px;
  border-radius: 8px;
  padding: 12px;
  background: #685cf4;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  align-self: center;
  color: white;
  margin-top: 16px;
`;

const NegativeButton = styled(PositiveButton)`
  background: #f7f5fe;
  color: #685cf4;
`;

const CareerPollModal = ({
  id,
  onClose,
}: Props) => {
  const [candidateList, setCandidateList] = useState<ICandidate[]>([]);
  const [idx, setIdx] = useState(0);
  const candidate = candidateList[idx];
  const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';
  const isOpen = candidateList.length > 0 && idx < candidateList.length && userType === 'PARTNER';
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    try {
      const { data } = await axios.get(`/candidate-pools/${id}/candidates`);
      const candidateList = data.results as ICandidate[];
      setCandidateList(candidateList.filter(v => v.state === 'requested'));
    } catch (err) {
      alert('잘못된 접근입니다.');
    }
  };

  if (!isOpen) {
    return <></>;
  }

  const onYes = async () => {
    // todo API
    setLoading(true);
    try {
      await axios.put(`/candidate-pools/${id}/candidates/${candidate.id}`, {
        state: 'accepted',
      });
    } catch (err) {
      alert('오류가 발생했습니다. 다시 시도해주세요.');
      setLoading(false);
      return;
    }

    setLoading(false);

    if (idx === candidateList.length - 1) {
      window.location.reload();
      return;
    }

    setIdx(idx + 1);
  };

  const onNo = async () => {
    // todo API
    setLoading(true);
    try {
      await axios.put(`/candidate-pools/${id}/candidates/${candidate.id}`, {
        state: 'declined',
      });
    } catch (err) {
      alert('오류가 발생했습니다. 다시 시도해주세요.');
      setLoading(false);
      return;
    }

    setLoading(false);

    if (idx === candidateList.length - 1) {
      window.location.reload();
      return;
    }

    setIdx(idx + 1);
  };

  return (
    <div className="dimd-layer mypage-w">
      <div className="ly-box smtype conts-w2" style={{ borderRadius: 12, maxWidth: 720 }}>
        <section className="recu-chk-wrap">
          <p style={{ textAlign: 'center', color: 'gray', width: '100%', marginBottom: 12, marginTop: -24 }}>
            {idx + 1}/{candidateList.length}
          </p>
          <h1 style={{ fontWeight: 'bold' }}>인재풀로 등록하시겠습니까?</h1>

        </section>

        <Loader loaded={!isLoading} options={{ opacity: 0.5, color: '#5539ff' }} />

        <div className="with-member">
          <StyledUl>
            <CoWorkerConfigCard
              id={id}
              data={candidate}
              refresh={() => { }}
            />
          </StyledUl>
        </div>

        {/* <button type="button" className="btn-close" onClick={onClose}><span>팝업 닫기</span></button> */}
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ textAlign: 'center', width: '50%', fontWeight: 'bold', color: '#685cf4', lineHeight: '24px' }}>
            인재풀 등록시, 해당 멤버의 이직이나 N잡, 파트타임 희망시점과
            프로필 업데이트 내역을 정기적으로 받아보실 수 있습니다.
          </p>

          <PositiveButton
            onClick={isLoading ? () => {} : onYes}
          >
            네, 인재풀로 등록합니다.
          </PositiveButton>

          <NegativeButton
            onClick={isLoading ? () => {} : onNo}
          >
            아니요, 괜찮습니다.
          </NegativeButton>
        </div>

        <br />
        <br />
      </div>
    </div>
  )
};

export default observer(CareerPollModal);
