import { observer } from "mobx-react";
import { ISuggestForm } from "../RecruitSuggestionModifyPage";
import { useController, useFormContext } from "react-hook-form";

export default observer(({ title, data }: ISuggestForm) => {
  const { control, watch, getValues, setValue, register, formState: { errors } } = useFormContext();

  return (
    <div className="in-cnts">
      <h5>{title}</h5>
      <textarea
        id="pjtWk"
        placeholder="세컨팀 멤버가 해야하는 업무를 잘 파악할 수 있도록 주요 업무 범위와 목표 산출물에 대해서 자세하게 적어주세요. ex. 디자인 프로젝트 경우:  디자인이 필요한 화면 리스트, BI 작업 여부, 추후 디자인 QA 참여 여부 등 개발 프로젝트 경우:  개발할 때 참고할 문서(기획서, 디자인 가이드 등) 유무, 외부 솔루션 API 연동 여부, 스토어 등록 여부 등"
        className={errors.work_description ? 'err' : ''}
        {...register('work_description', { required: true })}
      />
    </div>
  );
});
