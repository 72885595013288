import axios from "axios";

const pollAPI = {
  getPollByUUID: async (uuid: string) => {
    const { data } = await axios.get(
      `/surveys/${uuid}`,
    );

    return data;
  },
  postPoll: async (uuid: string, _data: any) => {
    const finalData = { ..._data };
    delete finalData['file'];

    const { data } = await axios.post(
      `/surveys/${uuid}/respond`,
      finalData,
    );

    if (_data.file) {
      const formData = new FormData();
      formData.append('file', _data.file);

      await axios.post(
        `/surveys/${uuid}/respond`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    }

    return data;
  }
}

export default pollAPI;
