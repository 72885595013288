import { useEffect, useState, useRef } from 'react';
import { inject, observer } from "mobx-react";
// @ts-ignore
import ScrollToBottom from 'react-scroll-to-bottom';

import { IAppStore } from "../../stores/AppStore";

import MessageListRow from './components/MessageListRow';
import MessageStartGuide from './components/MessageStartGuide';
import MessageSendGuide from './components/MessageSendGuide';
import MessageControl from './components/MessageControl';
import MessageEmpty from '../../components/empty/MessageEmpty';
import MessageRow from './components/MessageRow';
import { IMessageModel } from './models/MessageModel';
import { IChatModel } from './models/ChatModel';
import { isMobile } from 'react-device-detect';
import { useQuery } from '../../utils/hooks';


interface IProps {
  appStore: IAppStore;
}
const MessagePage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const query = useQuery();
  const ref = useRef();

  const { messageStore } = (props as IProps).appStore;
  const {
    username,
    nickname,
    member,
    partner,
    type,
    getMessageList,
    getMessageDetail,
    sendMessage,
    messageList,
    chatList,
  } = messageStore;

  const [idx, setIdx] = useState<number>(-1);
  const [name, setName] = useState<string>('');
  const isDisabled = idx < 0;

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    await getMessageList();
    const project = query.get('project');
    if (project) {
      const index = messageList.findIndex(v => v.uuid === project);
      onClickMessage(index);

      setTimeout(() => {
        // @ts-ignore
        ref?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        });
      }, 50);
    }
  }

  const onClickMessage = async (index: number) => {
    const message = messageList[index];
    const proposalUUID = message.uuid;

    setIdx(index);
    setName(message.member?.nickname || message.partner?.company_name || '이름 없음');
    await getMessageDetail(proposalUUID);
  };

  const onSend = async (content: string) => {
    const message = messageList[idx];
    const proposalUUID = message.uuid;

    if(message.member?.is_active || message.partner?.is_active){
      await sendMessage(proposalUUID, content);
      await getMessageDetail(proposalUUID);
    } else {
      alert('상대방은 확인할 수 없는 유저입니다. 메시지를 보내실 수 없습니다.');
    }
  };

  return (
    <div className={`message-wrap ${(!isMobile || idx >= 0) ? 'sub-w' : ''}`}>
      <div className="per-list">
        <div className="tit">
          <h2>메세지</h2>
          <p>📣 협업 제안 이후에 메세지를 주고 받으실 수 있습니다.</p>
        </div>
        <ul>
          {
            messageList.map((data: IMessageModel, index: number) => (
              // @ts-ignore
              <div ref={index === idx ? ref : null}>
                <MessageListRow
                  data={data}
                  member={data.member || member}
                  partner={data.partner || partner}
                  onClick={() => onClickMessage(index)}
                  isMember={messageStore.type === 'MEMBER'}
                  isSelect={index === idx}
                />
              </div>
            ))
          }
        </ul>

        <div className="msg-guide">
          <h4>메세지 이용안내</h4>
          <ul>
            <li>1. 현재 실시간 채팅을 지원하지 않아, 전송에 시간이 걸릴 수 있습니다.</li>
            <li>2. 메세지 수신시, 메일을 통해 전송 알림이 전달되며 24시간 동안 확인하지 않을 경우 문자로 전달됩니다.</li>
            <li>3. 채용 거절이 되면, 더이상 메세지를 주고 받을 수 없습니다.</li>
            <li>4. 실제 연락처와 이메일 등 개인정보 공유에 있어, 세컨팀은 법적 책임이 없으며, 세컨팀 서비스를 통해 채용을 하지 않는 경우 서비스 이용에 불이익이 있습니다.</li>
          </ul>
        </div>
      </div>


      {
        (!isMobile || idx >= 0) &&
        <div className="mesg-box">
          {
            isMobile ||
              <div className="hgroup">
                <div className="btn-prev" onClick={() => setIdx(-1)}>
                  <a style={{ cursor: 'pointer' }}><span>이전 페이지</span></a>
                </div>
                <h3>{messageList?.[idx]?.title || ''}</h3>
              </div>
          }

          <div className="mesg-cnts">
            {
              idx >= 0 &&
              <div className="pjt-tit">
                <div className="btn-prev" onClick={() => setIdx(-1)}>
                  <a href="#"><span>메세지 목록</span></a>
                </div>

                {
                  messageList?.[idx]?.status === 'SUBMITTED' &&
                  <span className="prog">제안중</span>
                }
                {
                  messageList?.[idx]?.status === 'ARRANGING' &&
                  <span className="prog ing">조율중</span>
                }
                {
                  messageList?.[idx]?.status === 'MATCHED' &&
                  <span className="prog ok">채용확정</span>
                }
                {
                  messageList?.[idx]?.status === 'REJECTED' &&
                  <span className="prog rej">거절</span>
                }

                <h3>{messageList?.[idx].title || ''}</h3>
                <a href={`/recruits/${type.toLowerCase()}/suggest/${messageList?.[idx]?.uuid}`} target="_blank">제안내용 보기</a>
              </div>
            }
            {
              messageList.length < 1 ?
                <MessageEmpty />
                :
                isDisabled ?
                  <MessageStartGuide />
                  :
                  chatList.length < 1 &&
                  <MessageSendGuide
                    name={name}
                  />
            }

            {
              isDisabled ||
              <ScrollToBottom className="inner">
                {
                  chatList.map((data: IChatModel) => (
                    <MessageRow
                      data={data}
                      member={messageList[idx].member || member}
                      partner={messageList[idx].partner || partner}
                      isMember={messageStore.type === 'MEMBER'}
                      isMe={username === data.user_name || nickname === data.user_name}
                    />
                  ))
                }
              </ScrollToBottom>
            }

            <MessageControl
              onSend={onSend}
              status={messageList?.[idx]?.status || ''}
            />
          </div>
        </div>
      }
    </div>
  )
}));

export default MessagePage;
