import { ICoWorker } from "@models/CoWorker";

interface Props {
  data: ICoWorker;
}

const TopWorker = ({
  data,
}: Props) => {
  return (
    <li>
      <span className="photo">
        <img src={data.avatar || (data.is_member ? '/images/@photo.png' : '/images/profilePhoto_gray.png')} alt="" />
      </span>
      {
        data.is_member ?
          <em><a href={`/members/${data.uuid}`}>{data.name}</a></em>
          :
          <em>{data.name}</em>
      }
      <div><span>{data.sub_category}</span><span>{data.employment || '소속없음'}</span></div>
    </li>
  );
};

export default TopWorker;
