import dayjs from 'dayjs';
import Thumb from '../../../components/Thumb';
import { taxTypeMap } from '../../../utils/constants';
import { numberWithCommas } from '../../../utils/utils';
import { IPaymentModel } from '../models/PaymentModel';

interface Props {
  data: IPaymentModel;
}
export default ({ data }: Props) => {
  const isCharge = data.type === 'WITHDRAWAL' || data.type === 'DEPOSIT';
  const isRequest = data.type === 'REQUEST_WITHDRAWAL';
  const isRequestOrWithdrawal = data.type === 'WITHDRAWAL' || data.type === 'REQUEST_WITHDRAWAL';
  const commission = data.iteration?.commission || 0;

  const tag = {
    WITHDRAWAL: '지급',
    REQUEST_WITHDRAWAL: '지급요청',
    PAYMENT: '정산',
    DEPOSIT: '충전',
  }[data.type];

  return (
    <tr className={(isCharge || isRequest) ? 'ing' : ''}>
      <td>{dayjs(data.created_at).format('YYYY.MM.DD')}</td>
      <td className="cate"><span className="condi">{tag}</span></td>
      <td>
        {
          Boolean(data.iteration) &&
          <div className="sbj">
            <strong>{data.iteration?.project_title || ''}</strong>
            <span>{data.iteration?.round}번째 주</span>
          </div>
        }

        {
          isRequestOrWithdrawal &&
          // @ts-ignore
          taxTypeMap[data?.tax_type]
        }
      </td>
      {
        isNaN(data.iteration?.commission as any) ?
          <td />
          :
          <td>
            적용 수수료 {commission}%
            {
              commission === 0 &&
              <>
                <br />
                <span style={{ fontSize: 12 }}>(수수료 무료 기간)</span>
              </>
            }
          </td>
      }
      <td />
      <td className="td-price">{isRequest ? `(${numberWithCommas(Math.abs(data.amount || 0))}원)` : `${numberWithCommas(data.amount || 0)}원`}</td>
      <td className="td-price">{isRequest ? '' : `${numberWithCommas(data.balance || 0)}원`}</td>
    </tr>
  )
};
