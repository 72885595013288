import { ICoWorker } from "@models/CoWorker";
import portfolioAPI from "@services/portfolioAPI";
import profileAPI from "@services/profileAPI";
import { AUTH_USERTYPE } from "@utils/constants";
import { useEffect, useState } from "react";

interface Props {
  data: ICoWorker;
  topCount: number;
  refresh: () => void;
}

const CoWorkerConfigCard = ({
  data,
  topCount,
  refresh,
}: Props) => {
  const [isCheck, setCheck] = useState(data.is_my_secondteam);
  const userType = window.localStorage.getItem(AUTH_USERTYPE) || '';

  useEffect(() => {
    setCheck(data.is_my_secondteam);
  }, [data]);

  const onChange = async (e: any) => {
    const isCheck = e.target.checked;

    if (isCheck) {
      if (topCount >= 5) {
        alert('최대 5명까지 지정할 수 있습니다.');
        return;
      }
      await profileAPI.postCoWorkerToSecondTeam(data.pk || 0);
    } else {
      await profileAPI.deleteCoWorkerFromSecondTeam(data.pk || 0);
    }

    setCheck(isCheck);
    refresh();
  };

  return (
    <li key={data.uuid}>
      <div className="pf-w">
        <span className="photo">
          <img src={data.avatar || (data.is_member ? '/images/@photo.png' : '/images/profilePhoto_gray.png')} alt="" />
        </span>
        <div className="info">
          <div>
            {
              data.is_member ?
                <em><a href={`/members/${data.uuid}`}>{data.name}</a></em>
                :
                <em>{data.name}</em>
            }
            <div className="chk-st">
              {
                data.is_evaluated &&
                <span className="c1"><span>동료 평가</span></span>
              }

              {
                data.is_verified &&
                <span className="c2"><span>협업 확인</span></span>
              }

              {
                data.is_open_to_reference_check &&
                <span className="c3"><span>레퍼런스 체크 가능</span></span>
              }
            </div>
          </div>
          <p><span>{data.sub_category}</span><span>{data.employment || '소속없음'}</span></p>
        </div>
      </div>

      {
        userType === 'MEMBER' &&
        <>
          <input type="checkbox" id={`ch${data.pk}`} onChange={onChange} checked={isCheck} />
          <label htmlFor={`ch${data.pk}`}>
            {
              isCheck ?
                <span className="chk">세컨팀</span>
                :
                <span className="add">추가</span>
            }
          </label>
        </>
      }
    </li>
  );
};

export default CoWorkerConfigCard;
