/*
  markup : mypage.html
*/
import { useState, useEffect, useRef } from 'react';
import { inject, observer } from "mobx-react";
import { IAppStore } from "../../stores/AppStore";
import { IAccountStore } from '../../stores/AccountStore';
import { Prompt } from 'react-router-dom';

import _ from 'lodash';


import {
  AccountStatusField,
  EmailField,
  NameField,
  PhoneField
} from '../../components/account-form';

import { TResource } from '../join/models';
import { PasswordField } from '../user/components';
import PhoneForm from '../../components/profile-new-form/PhoneForm';
import PasswordChangeModal from '../../modals/PasswordChangeModal';

interface IProps {
  appStore: IAppStore;
  history: any
}

const PartnerAccountPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const { accountStore, showLoader, hideLoader } = (props as IProps).appStore;
  const { partnerAccount, getPartnerAccount } = (accountStore as IAccountStore);

  const [editing, setEditing] = useState(false);
  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const [isPhoneEmpty, setIsPhoneEmpty] = useState(false);
  const accountRef = useRef<HTMLUListElement>(null);

  const initPhone = partnerAccount?.phone || '';
  const [isPasswordOpen, setPasswordOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    async function fetch() {
      await getPartnerAccount();
    }
    fetch();
  }, []);

  if (!partnerAccount) {
    return (
      <div />
    )
  }

  const {
    created,  // 가입일
    company_name, // 회사명
    date_approved,  // 승인일,
    name, // 이름
    country_code,
    phone,  // 핸드폰 번호
    email,  // 이메일
    status_detail, // 거절 사유,
    industry, // 서비스분야
    marketing_agreement,  // 마케팅정보수신동의,
    in_house_resource, // 회사 내 IT 멤버 구성
    status,
    notification_settings,
  } = partnerAccount;

  const handleResource = (value: string) => {
    let resources: string[] = in_house_resource.slice();

    if (resources === null) {
      resources = [value];
    } else {
      if (resources.includes(value)) {
        resources = _.remove(resources, resource => resource !== value);
      } else {
        if (value === TResource.NONE) {
          // 없음 선택할 경우, 기존 선택 필드 리셋
          resources = [value];
        } else {
          resources = _.remove(resources, resource => resource !== TResource.NONE);
          resources = _.concat(resources, value)
        }
      }
    }
    partnerAccount.setInHouseResource(resources);
  }
  const handleSave = async () => {
    if (name.length === 0) {
      setIsNameEmpty(true);

      if (accountRef) {
        if (accountRef.current) {
          window.scrollTo(0, accountRef.current.offsetTop - 80);
        }
      }
    } else {
      setIsNameEmpty(false);
    }

    if (phone.length === 0) {
      setIsPhoneEmpty(true);

      if (accountRef) {
        if (accountRef.current) {
          window.scrollTo(0, accountRef.current.offsetTop - 80);
        }
      }
      return;
    } else {
      setIsPhoneEmpty(false);
    }

    try {
      showLoader();
      await partnerAccount.update();
      hideLoader();
    } catch (err) {
      hideLoader();
      return;
    }

    setEditing(false);
    alert('변경 내용이 저장되었습니다.');
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div className="mypage-w">

        <AccountStatusField
          value={status}
          created={created}
          approved={date_approved || ''}
          comment={status_detail}
        />

        <div className="conts-w">
          <div className="join-form">
            <h3>계정정보</h3>
            <ul className="fm-list" ref={accountRef}>

              <NameField
                value={name}
                isError={isNameEmpty}
                showPublicOpen={false}
                onChangeName={(name) => {
                  partnerAccount.setName(name);
                  setEditing(true);
                }}
              />

              <PhoneField
                partner={partnerAccount}
              />

              <EmailField
                value={email}
                showPartnerDesc={true}
              />

              <div className="agree">
                <input type="checkbox" id="agree3"
                  checked={marketing_agreement}
                  onClick={() => {
                    partnerAccount.setMarketingAgreement(!marketing_agreement);
                    setEditing(true);
                  }} /><label htmlFor="agree3">마케팅 정보 수신동의</label>
              </div>

              <li>
                <label htmlFor="pwCh">비밀번호</label>
                <button type="button" id="pwCh" className="fm-inbtn" onClick={() => setPasswordOpen(true)}>비밀번호 변경하기</button>
              </li>
            </ul>

            {/* <h3>회사정보</h3>
            <ul className="fm-list">
              <li>
                <label htmlFor="cpName">회사명</label>
                <input type="text" id="cpName" value={ company_name }  onChange={ (e) => {
                  partnerAccount.setCompanyName(e.target.value) ;
                  setEditing(true);
                }} />
              </li>
              <li>
                <label htmlFor="svCate">서비스 분야</label>
                <input type="text" id="svCate" placeholder="커머스, 핀테크, Saas, 광고 마케팅 등" title="커머스, 핀테크, Saas, 광고 마케팅 등"
                  value={ industry }  onChange={ (e) => {
                    partnerAccount.setIndustry(e.target.value);
                    setEditing(true);
                  }} />
              </li>
            </ul>
            <h4>현재 회사 내 IT 멤버 구성</h4>
            <div className="chk-box myp-type">
              <ul>
                <li><input type="checkbox" id="cate1"
                  checked={ in_house_resource.includes(TResource.MNG)}
                  onChange={ () => {
                    handleResource(TResource.MNG);
                    setEditing(true);
                  }} />
                  <label htmlFor="cate1">기획자(운영)</label></li>
                <li><input type="checkbox" id="cate2"
                  checked={ in_house_resource.includes(TResource.DES)}
                  onChange={ () => {
                    handleResource(TResource.DES);
                    setEditing(true);
                  }} />
                <label htmlFor="cate2">디자이너</label></li>
                <li><input type="checkbox" id="cate3"
                  checked={ in_house_resource.includes(TResource.DEV)}
                  onChange={ () => {
                    handleResource(TResource.DEV);
                    setEditing(true);
                  }} />
                  <label htmlFor="cate3">개발자</label></li>
                <li><input type="checkbox" id="cate4"
                  checked={ in_house_resource.includes(TResource.NONE) || in_house_resource.length === 0 }
                  onChange={ () => {
                    handleResource(TResource.NONE);
                    setEditing(true);
                  }} />
                  <label htmlFor="cate4">없음</label></li>
              </ul>
            </div> */}

            <h4>문자 알림 받기</h4>
            <div className="chk-box">
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="mms1"
                    onClick={() => {
                      partnerAccount.setMarketingNotification();
                      setEditing(true);
                    }}
                    checked={notification_settings[0].enabled}
                  />
                  <label htmlFor="mms1">세컨팀과 관련된 마케팅 정보를 알림 받겠습니다.</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="mms2"
                    onClick={() => {
                      partnerAccount.setProposalNotification();
                      setEditing(true);
                    }}
                    checked={notification_settings[1].enabled}
                  />
                  <label htmlFor="mms2">협업 제안이 도착하거나 진행 사항이 있을 경우 알림 받겠습니다.</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="mms3"
                    onClick={() => {
                      partnerAccount.setProjectNotification();
                      setEditing(true);
                    }}
                    checked={notification_settings[2].enabled}
                  />
                  <label htmlFor="mms3">확정된 협업의 진행 및 정산 사항을 알림 받겠습니다.</label>
                </li>
              </ul>
              <p>서비스 운영 관련된 중요한 공지 및 알림은 문자 알림 받기 설정 여부와 관계없이 발송됩니다. </p>
            </div>

            <div className="btn">
              <button type="submit" className={editing ? "actv" : "disb"}
                disabled={editing ? false : true}
                onClick={handleSave}
              >저장</button>
            </div>
          </div>
        </div>

        <Prompt
          when={editing}
          message="변경 내용을 삭제하시겠어요?
          지금 페이지를 나가면 변경 내용이 삭제됩니다." />

        <PasswordChangeModal
          isOpen={isPasswordOpen}
          appStore={props.appStore}
          onClose={() => setPasswordOpen(false)}
        />
      </div>
    </>
  )
}));

export default PartnerAccountPage;