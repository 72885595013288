import { types, Instance } from "mobx-state-tree";
import { MemberProfileModel } from "../../profile/models";
import { PartnerProposalModel } from "./PartnerProposalModel";
import { PartnerCountModel } from "./ProposalCountModel";

export const PartnerProposalResModel = types.model({
  counts: PartnerCountModel,
  count: types.number,
  next: types.maybeNull(types.string),
  previous: types.maybeNull(types.string),
  results: types.array(PartnerProposalModel)
});

export interface IPartnerProposalResModel extends Instance<typeof PartnerProposalResModel> {};
