import { observer } from "mobx-react";
import { ISuggestForm } from "../RecruitSuggestionModifyPage";
import { useController, useFormContext } from "react-hook-form";

export default observer(({ title, data }: ISuggestForm) => {
  const { control, watch, getValues, setValue, register, formState: { errors } } = useFormContext();

  return (
    <div className="in-cnts">
      <h5>{title}</h5>
      <input
        type="text"
        placeholder="ex. 커머스 리뉴얼 백엔드 개발 프로젝트"
        className={errors.title ? 'err' : ''}
        {...register('title', { required: true })}
      />
    </div>
  );
});
