import axios from "axios";

import { ILoginResponse } from '../interfaces/userTypes';
import accountAPI from "./accountAPI";
import { PARTNER_ACCOUNT_STATUS } from '../utils/constants';

const userAPI = {
  // AUTH_TOKEN_KEY: "authtoken",
  URL: "/auth",

  emailVerification: async function(email: string) {
    try {
      const { data }: { data: any } = await axios.post(
        `/auth/email/send-verification-link`,
        {
          email
        }
      );
      return data;
    } catch (e) {
      throw e;
    }
  },

  activate: async function (uidb: string, token: string) {
    try {
      const data: any = await axios.get(`/auth/email/verify/${ uidb }/${ token }`);
      return data.status;
    } catch (e) {
      throw e;
    }
  },

  login: async function(email: string, password: string) {
    try {
      const { data }: { data: ILoginResponse } = await axios.post(
        `${ this.URL }/login`,
        {
          email,
          password
        }
      );
      if(data.token === null) {
        throw new Error();
      }
      axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
      
      // FIXME - need to refactor as like StatusManger
      if (data.user.user_type === 'MEMBER' && !data.user.profile_completed) {
        window.localStorage.setItem('onboarding', 'OK');
        // window.location.href = '/onboarding';
      } else if (data.user.user_type === 'MEMBER') {
        window.localStorage.removeItem('onboarding');
        // window.location.href = '/member/profile';
      } else if (data.user.user_type === 'PARTNER') {
        const { status } = await accountAPI.getPartnerAccount(data.user.uuid);
        
        if (status === PARTNER_ACCOUNT_STATUS.PENDING) {
          window.localStorage.setItem('pending', 'OK');
          window.location.href = '/partner/account';
        } else {
          window.localStorage.removeItem('pending');
          const params: any = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop as any),
          });

          if (params.refer) {
            window.location.href = `/${params.refer}`;
          } else {
            window.location.href = '/#company';
          }
        }
      }

      return data;
    } catch (e) {
      throw e;
    }
  },
  logout: function () {
    axios.defaults.headers.common["Authorization"] = '';
  },
  getUserByID: async function (id: number) {
    try {
      const { data }: { data: ILoginResponse } = await axios.get(
        `${ this.URL }/users/${ id }`
      );
      return data;
    } catch (e) {
      throw e;
    }
  }
};
export default userAPI;
