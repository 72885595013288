import { IEpisodeModel } from "@pages/profile/models/MemberProfileModel";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";

interface IProps {
  durationKey?: string;
  yearKey?: string;
  project?: IEpisodeModel;
}

const ProjectDateForm = ({ durationKey, yearKey, project }: IProps) => {
  const dKey = durationKey || 'duration';
  const yKey = yearKey || 'year';

  const { register, watch } = useFormContext();

  return (
    <div className="sel-w">
      <select id="date" title="참여 연도" {...register(yKey)} style={{ color: watch(yKey) ? 'black' : '#999' }}>
        <option>참여 연도</option>
        {
          Array.from(Array(dayjs().year() - 2000 + 1).keys()).reverse().map(v => (
            <option value={2000 + v}>{2000 + v}</option>
          ))
        }
      </select>
      <select title="참여 기간" {...register(dKey)} style={{ color: watch(dKey) ? 'black' : '#999' }}>
        <option>참여 기간</option>
        <option value={1}>3개월 이내</option>
        <option value={2}>6개월 이내</option>
        <option value={3}>1년 이내</option>
        <option value={4}>1년 이상</option>
      </select>
    </div>
  );
};

export default ProjectDateForm;
