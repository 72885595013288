import styled from 'styled-components';

const CTAFooter = styled.div`
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 80px;
  line-height: 80px;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  background: white;
  z-index: 1;
  justify-content: center;
  align-items: center;
`;

export default CTAFooter;
