import { flow, types, Instance, applySnapshot, getParent } from "mobx-state-tree";

import { IAppStore } from '../../../stores/AppStore';
import { IUserStore } from "@stores/UserStore";

import accountAPI from '../../../services/accountAPI';
import { NotificationSettingModel } from "./NotificationSettingModel";

export const PartnerAccountModel = types.model({
  id: types.maybe(types.number), // 멤버 ID
  code: types.maybe(types.string),
  company_name: types.maybe(types.string),
  created: types.string,
  date_approved: types.maybeNull(types.string),
  date_completed: types.maybeNull(types.string),
  date_rejected: types.maybeNull(types.string),
  email: types.string,
  in_house_resource: types.array(types.string),
  industry: types.maybe(types.string),
  marketing_agreement: types.boolean,
  modified: types.maybe(types.string),
  name: types.string,
  country_code: types.string,
  phone: types.string,
  status: types.string,
  status_detail: types.string,
  user_type: types.maybe(types.string),
  uuid: types.string,
  has_requirements_submitted: types.boolean,
  notification_settings: types.array(NotificationSettingModel),
})
.views(self => ({
  get userStore(): IUserStore {
    return (getParent(self, 2) as IAppStore).userStore;
  }
}))
.actions(self => ({
  setName(name: string) {
    self.name = name;
  },
  setCountryCode(code: string) {
    self.country_code = code;
  },
  setPhone(phone: string) {
    self.phone = phone;
  },
  setCompanyName(company_name: string) {
    self.company_name = company_name;
  },
  setIndustry(industry: string) {
    self.industry = industry;
  },
  setMarketingNotification() {
    self.notification_settings[0].enabled = !self.notification_settings[0].enabled;
  },
  setProposalNotification() {
    self.notification_settings[1].enabled = !self.notification_settings[1].enabled;
  },
  setProjectNotification() {
    self.notification_settings[2].enabled = !self.notification_settings[2].enabled;
  },
  setMarketingAgreement(marketing_agreement: boolean) {
    self.marketing_agreement = marketing_agreement;
  },
  setInHouseResource(resources: Array<string>) {
    self.in_house_resource.replace(resources);
  }
}))
.actions(self => ({
  update: flow(function* getInviteCodes() {
    if(!self.uuid) {
      return;
    }

    const userStore = self.userStore;
    try {
      const response = yield accountAPI.updatePartnerAccount(self.uuid, {
        name: self.name,
        country_code: self.country_code,
        phone: self.phone,
        company_name: self.company_name,
        industry: self.industry,
        in_house_resource: self.in_house_resource.length === 1 && self.in_house_resource[0] === 'NONE' ? [] : self.in_house_resource,
        marketing_agreement: self.marketing_agreement,
        notification_settings: self.notification_settings,
      });

      applySnapshot(self, response);
      
      if(userStore) {
        userStore.user.setUsername(self.name);
      }
    } catch(e) {
      throw e;
    }
  })
}));
export interface IPartnerAccountModel extends Instance<typeof PartnerAccountModel> {};