import { inject, observer } from "mobx-react";
import { PollProps } from "../PollPage";
import Linkify from "react-linkify";

const PollStep1 = ({
  pollStore,
}: PollProps) => {
  const data = pollStore.data?.questionnaire;
  const email = pollStore.data?.email;

  const onStart = () => {
    pollStore.next();
  }

  return (
    <div className="poll-wrap">
      <div className="p-step">
        <strong>Step 1</strong>
        <span>Step 2</span>
        <span>Step 3</span>
      </div>
      <h2>{data?.display_title || 'Welcome 🎉'}</h2>
      <div className="cnts">
        <Linkify
          componentDecorator={(
            decoratedHref: string,
            decoratedText: string,
            key: number
          ) => (
            decoratedText.includes('@') ? 
            <span>
              {decoratedText}
            </span>
            :
            <a href={decoratedHref} style={{ textDecoration: 'underline', color: '#5539ff' }} key={key} target="_blank">
              {decoratedText}
            </a>
          )}
        >
          {
            data?.introduction.split('\n').map(text => (
              (data.main_category && data.sub_category && text.includes(`${data.main_category}-${data.sub_category}`)) ?
              <p
                dangerouslySetInnerHTML={{
                  __html: text.replace(`${data.main_category}-${data.sub_category}`, `<em>${data.main_category}-${data.sub_category}</em>`)
                }}
              />
              :
              <span style={{ margin: 0 }}>
                {text}<br/>
              </span>
            ))
          }
        </Linkify>

        {/* <p>반갑습니다 {email} 님 👋</p>
        <p>세컨팀의 첫 제안을 드리게 되어 반갑습니다. 앞으로 {email} 님에게는 세컨팀에서 분석한 <em>{data?.main_category}-{data?.sub_category}</em> 분야의 전문가들이 가지고 있는 스킬과 경험을 토대로 정기적인 커리어 설문을 드리고자 합니다.</p>
        <p>3~5분의 시간을 통해 설문에 간단히 응답만 해주시면, 자신의 스킬과 경험을 토대로 포트폴리오를 만들어 드리고 이를 기반으로 가장 희망하신 협업을 제안드리게 됩니다.</p> */}
      </div>
      {
        data?.regular_survey !== 2 &&
        <div className="noti-box">
          <p>혹시 아직 세컨팀 회원이 아니신가요? 걱정마세요✨ 설문을 완료 후 {email} 주소로 세컨팀에 가입하시면 포트폴리오를 자동으로 추가해드립니다.</p>
        </div>
      }
      <div className="btn">
        <a href="#" className="actv" onClick={onStart}>
          설문 시작하기
        </a>
      </div>
    </div>
  )
}

export default observer(PollStep1);
