import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ENV } from '@utils/constants';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import App from './App';
import './markup/css/style.css';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: "https://a437c48f32a14f34b958488c51a2932d@o80716.ingest.sentry.io/6004702",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: ENV,
});

TagManager.initialize({
  gtmId: 'GTM-W3TB999',
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
