import { flow, types, Instance, applySnapshot, getParent } from "mobx-state-tree";

import { IAppStore } from '../../../stores/AppStore';
import { IUserStore } from "@stores/UserStore";

import {
  AvailabilityOptions,
  ExpectationOptions,
  WorkTypeOptions
} from '../../../utils/constants';
import accountAPI from '../../../services/accountAPI';
import { NotificationSettingModel } from "./NotificationSettingModel";
import { WorkPreference } from "@models/WorkPreference";

const JobCategoryModel = types.model({
  pk: types.number,
  main_category: types.string,
  sub_category: types.string,
});

export const MemberAccountModel = types.model({
  id: types.number, // 멤버 ID
  email: types.string, // 이메일
  nickname: types.maybe(types.string), //닉네임
  name: types.string, // 이름
  country_code: types.maybeNull(types.string),  // 전화번호 국가코드
  phone: types.string,  // 전화번호
  uuid: types.string, // UUID
  name_public_open: types.optional(types.boolean, false),  // 이름 공개 여부
  job_category: types.string,
  notification_settings: types.array(NotificationSettingModel),
  associate: types.maybeNull(types.model("Associate", {
    uuid: types.string,
    code: types.string, // 초대한 멤버 고유코드,
    name: types.string, // 초대한 멤버 이름
    nickname: types.string // 초대한 멤버 닉네임
  })),
  availability: types.optional(types.enumeration("Availability", AvailabilityOptions.map(op => op.value)), 'HIGH'), // 구직 의향
  work_type: types.optional(types.enumeration("WorkType", WorkTypeOptions.map(op => op.value)), 'PARTTIME'),  // 근무 가능한 조건
  expectations: types.array(types.enumeration("Expectations", ExpectationOptions.map(op => op.value))), // 세컨팀 멤버로 기대하는 바
  marketing_agreement: types.boolean, // 마케팅정보수신동의
  created: types.string,  // 가입 신청 일시
  modified: types.string, // 마지막 변경 일시,
  tax_type: types.maybeNull(types.string),
  work_preferences: types.array(types.number),
  // work_preference: types.array(types.number),
  job_categories: types.array(JobCategoryModel),
})
.views(self => ({
  get userStore(): IUserStore {
    return (getParent(self, 2) as IAppStore).userStore;
  }
}))
.actions(self => ({
  setEmail(email: string) {
    self.email = email;
  },
  setName(name: string) {
    self.name = name;
  },
  setCountryCode(code: string) {
    self.country_code = code;
  },
  setPhone(phone: string) {
    self.phone = phone;
  },
  setMarketingAgreement(agreement: boolean) {
    self.marketing_agreement = agreement;
  },
  setChangeNamePublicOpen(opened: boolean) {
    self.name_public_open = opened; 
  },
  setAvailability(availability: string) {
    self.availability = availability;
  },
  setWorkType(work_type: string) {
    self.work_type = work_type;
  },
  setMarketingNotification() {
    self.notification_settings[0].enabled = !self.notification_settings[0].enabled;
  },
  setProposalNotification() {
    self.notification_settings[1].enabled = !self.notification_settings[1].enabled;
  },
  setProjectNotification() {
    self.notification_settings[2].enabled = !self.notification_settings[2].enabled;
  },
  setExpectations(expectations: string[]) {
    console.log(expectations)
    self.expectations.replace(expectations);
  }
}))
.actions(self => ({
  update: flow(function* (data: any) {
    if(!self.uuid) {
      return;
    }

    const userStore = self.userStore;
    try {
      const payload = {
        name: self.name,
        name_public_open: self.name_public_open,
        country_code: self.country_code || 'kr',
        phone: self.phone,
        availability: self.availability,
        work_type: self.work_type,
        expectations: self.expectations,
        marketing_agreement: self.marketing_agreement,
        notification_settings: self.notification_settings,
        ...data,
      };

      if (!payload.phone) {
        // @ts-ignore
        delete payload['phone'];
      }

      const response = yield accountAPI.updateMemberAccount(self.uuid, payload);

      applySnapshot(self, response);
      
      if(userStore) {
        userStore.user.setUsername(self.name);
      }
    } catch(e) {
      throw e;
    }
  })
}));
export interface IMemberAccountModel extends Instance<typeof MemberAccountModel> {};