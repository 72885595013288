import { IRegularSurvey, RegularSurvey } from "@models/RegularSurvey";
import regularSurveyAPI from "@services/regularSurveyAPI";
import { flow, Instance, types } from "mobx-state-tree";
import { StateModel } from '../models/models';

const RegularSurveyStore = StateModel.named('RegularSurveyStore')
  .props({
    regularSurveyList: types.array(RegularSurvey),
  })
  .actions(self => ({
    getRegularSurveyList: flow(function* getRegularSurveyList() {
      try {
        const responseList = yield regularSurveyAPI.getRegularSurveyList();
        self.regularSurveyList = responseList.map((data: IRegularSurvey) => (
          RegularSurvey.create(data)
        ));
      } catch (e) {
        throw e;
      }
    }),
    postSubscripton: flow(function* postSubscripton(regularSurveyID: number) {
      try {
        yield regularSurveyAPI.postSubscription(regularSurveyID);
        self.regularSurveyList.forEach((regularSurvey: IRegularSurvey) => {
          if (regularSurvey.id === regularSurveyID) {
            regularSurvey.is_subscribed = true;
          }
        });
      } catch (e) {
        throw e;
      }
    }),
    deleteSubscription: flow(function* deleteSubscription(regularSurveyID: number) {
      try {
        yield regularSurveyAPI.deleteSubscription(regularSurveyID);
        self.regularSurveyList.forEach((regularSurvey: IRegularSurvey) => {
          if (regularSurvey.id === regularSurveyID) {
            regularSurvey.is_subscribed = false;
          }
        });
      } catch (e) {
        throw e;
      }
    }),
  }));
export interface IRegularSurveyStore extends Instance<typeof RegularSurveyStore> { }

export default RegularSurveyStore;
