import classNames from 'classnames';
import { useState } from 'react';

const PasswordField = (props: {
  forConfirm?: boolean,
  isError?: boolean,
  value: string,
  message?: string,
  showErrorMessage?: boolean,
  onChange: (value: string) => void
}) => {
  const [isHide, setIsHide] = useState<boolean>(true);

  return (
    <li>
      <label htmlFor={props.forConfirm ? 'pwconf' : 'pw'}>{props.forConfirm ? '비밀번호 확인' : '비밀번호'}</label>
      <input
        className={classNames({ err: props.isError })}
        type={isHide ? "password" : 'text'}
        id={props.forConfirm ? 'pwconf' : 'pw'}
        placeholder="최소 6자리"
        title="최소 6자리"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)} />
      <Button
        isHide={isHide}
        onClick={() => setIsHide(!isHide)} />

      {
        props.message &&
        <p className="err">{props.message}</p>
      }

      {
        (props.value.length > 0 && props.value.length < 6) &&
          <p className="err">
            6자리 이상 입력해주세요.
          </p>
      }
    </li>
  );
};
export default PasswordField;

const Button = (props: { isHide: boolean, onClick: () => void }) =>
  <button
    type="button"
    className={classNames({ btn_pw_v: props.isHide, btn_pw_h: !props.isHide })}
    onClick={props.onClick}
  >
    <span>비밀번호 보이기</span>
  </button>;

// const Error = () => <p className="err">이메일 또는 비밀번호를 확인해주세요.</p>
