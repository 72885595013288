import { types, Instance } from "mobx-state-tree";

export const PartnerCountModel = types.model({
  submitted: 0,
  arranging: 0,
  rejected: 0,
  matched: 0,
});

export interface IProposalCountModel extends Instance<typeof PartnerCountModel> {};
