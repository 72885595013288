import { useState } from 'react';
import { observer } from "mobx-react";
import {
  ExpectationOptions
} from '../../utils/constants';

const ExpectationField = observer((props: {
  value: string[];
  onChange: (expectations: string[]) => void;
}) => {
  // TODO
  const [ items, setItems] = useState(new Set(props.value));

  const checkedHandler = (id: string, isChecked: boolean) => {
    if (isChecked) {
      items.add(id);
      setItems(items);
    } else if (!isChecked && items.has(id)) {
      items.delete(id);
      setItems(items);
    }

    const newItems: string[] = [];
    items.forEach(item=> newItems.push(item));

    props.onChange(newItems);
  };

  return (
    <>
      <h4>세컨팀 멤버로 기대하는 바</h4>
      <div className="chk-box myp-type">
        <ul>
          {
            ExpectationOptions.map( (expectation, i) => {
              return (
                <li key={ i }>
                  <input type="checkbox" id={ expectation.value } checked={ items.has(expectation.value) }
                    onChange={ () => checkedHandler(expectation.value, !items.has(expectation.value))}
                  />
                  <label htmlFor={ expectation.value }>{ expectation.label }</label>
                </li>
              )
            }
          )
        }
        </ul>
      </div>
    </>
  )
})

export default ExpectationField;