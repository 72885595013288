interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default ({
  isOpen,
  onClose,
}: Props) => {
  if (!isOpen) {
    return <></>;
  }

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3>💌 협업 제안서가 발송되었습니다.</h3>
          <p>☝️ <strong>제안서를 받은 멤버는 36시간 이내에 수락여부를 확인하게 됩니다.</strong> 확인하지 않는 경우 자동으로 거절 처리됩니다.</p>

          <p>☝️ <strong>메세지 메뉴를 통해서 해당 멤버와 추가적인 논의를 할 수 있습니다.</strong></p>

          <p>☝️ <strong>멤버가 제안서를 수락하면, 수락 여부에 대한 문자가 발송됩니다.</strong></p>
          <div className="btn" style={{ cursor: 'pointer' }} onClick={onClose}>
            <a className="actv">확인</a>
          </div>
        </div>
        <button type="button" className="btn-close" onClick={onClose}>
          <span>팝업 닫기</span>
        </button>
      </div>
    </div>
  );
}
