/*
  markup : onboarding_member_*.html
*/
import profileAPI from "@services/profileAPI";
import { IOnboardingStore } from "@stores/onboardingStore";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Loader from 'react-loader';
import { Prompt } from 'react-router-dom';
import { IAppStore } from "../../stores/AppStore";
import {
  AUTH_USERTYPE
} from '../../utils/constants';
import { handleMemberProfile } from "../../utils/utils";
import { IMemberAccountModel } from "../account/models/MemberAccountModel";
import { IMemberProfileModel } from "../profile/models/MemberProfileModel";
import {
  OnboardingInfo,
  OnboardingIntro, OnboardingProject, OnboardingTime
} from './components';
import CompleteModal from "./components/CompleteModal";

interface IProps {
  appStore: IAppStore;
  history: any
}

export interface OnboardingProps {
  profile: IMemberProfileModel;
  account?: IMemberAccountModel;
  tab?: number;
}

const tabList = [
  '거래 시간',
  '제안 조건',
  '소개',
  '포트폴리오',
];

const OnboardingPage: React.SFC<IProps> = inject("appStore")(observer((props) => {
  const { onboardingStore } = (props as IProps).appStore;
  const { profile, account, getProfile, getAccount, getConstants, updateProfile } = (onboardingStore as IOnboardingStore);
  const [tab, setTab] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const form = useForm();
  const { watch, handleSubmit, setError, reset } = form;

  useEffect(() => {
    getProfile();
    getAccount();
    getConstants();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem(AUTH_USERTYPE)) {
      window.location.href = '/login';
    } else if (localStorage.getItem(AUTH_USERTYPE) !== 'MEMBER') {
      window.location.href = '/';
    } else if (!localStorage.getItem('onboarding')) {
      window.location.href = 'member/profile';
    }
  });

  if (!profile || !account) {
    return <></>;
  }

  const titleList = [
    <span>세컨팀은 멤버에게 가장 적합한 협업을 매칭하여 제안합니다.<br/>희망하시는 거래 가능 시간과 시간당 금액은 어떻게 되시나요?</span>,
    <span>제안이 도착했을때 알림을 받으실 연락처와<br/>회사가 제안을 할 때 참고해야할 조건들을 알려주세요.</span>,
    <span>세컨팀에서는 채용 확정되기 전까지 닉네임만 표시됩니다.<br/>사용하실 닉네임과 어떤 분이신지 소개 부탁드려요.</span>,
    <span>포트폴리오는 회사가 제안을 결정하는 중요한 정보로 사용됩니다.<br/>경험하신 대표 프로젝트에 대해 알려주세요.</span>,
  ];

  const checkList = [
    profile.onboarding_step_1,
    profile.onboarding_step_2,
    profile.onboarding_step_4,
    profile.onboarding_step_5,
  ];

  const onNext = () => {
    if (tab >= tabList.length - 1) {
      return;
    }

    setTimeout(() => {
      reset();
      setTab(tab + 1);
      window.scrollTo(0, 0);
    }, 50);
  };

  const onPrev = () => {
    if (window.confirm('업데이트 내역이 있다면 반드시 저장해주세요. 진행하시겠습니까?')) {
      reset({});
    } else {
      return;
    }

    setTab(tab - 1);
    window.scrollTo(0, 0);
  };

  const onTab = (index: number) => {
    setTab(index);
  };

  const onSave = async (data: object) => {
    const [form, json, referenceFiles] = handleMemberProfile(data);

    try {
      // 22.03.24 수정 - 아무것도 입력되지 않은 포트포리오는 저장처리 하지 않음
      if (json.episodes) {
        const newEpisodes = [];
        for (const v of json.episodes) {
          // 빈 데이터 판별 조건
          if (!(v.domain || v.experiences.length || v.frameworks.length || v.link || v.role || v.skills.length || v.title)) {
            return;
          }

          const newData = v;
          if (newData.year === '참여 연도') {
            newData.year = null;
          } else {
            newData.year = parseInt(newData.year);
          }

          if (newData.duration === '참여 기간') {
            newData.duration = null;
          } else {
            newData.duration = parseInt(newData.duration);
          }

          newEpisodes.push(newData);
        }

        console.log('=== newEpisodes ===');
        console.log(newEpisodes);
        json.episodes = newEpisodes;
      }

      setLoading(true);

      let profile: any;
      if (form) {
        profile = await updateProfile(form);
      }

      if (json) {
        profile = await updateProfile(json);
      }

      let cnt = 0;
      for (const file of referenceFiles) {
        if (file.has('file')) {
          const episodeUUID = profile.episodes[cnt].uuid;
          await profileAPI.createMemberProjectFile(profile.uuid, episodeUUID, file);
        }
        cnt += 1;
      }

      setLoading(false);
      onNext();

      return profile;
    } catch (err) {
      console.log(err);
      setLoading(false);
      return;
    }
  };

  const onStart = async (data: object) => {
    await onSave(data);
    setOpen(true);
  };

  return (
    <FormProvider {...form}>
      {/* <Loader loaded={!isLoading} options={{ opacity: 0.5, color: '#5539ff' }} > */}

        <div className="mypage-cnts modify">
          <div className="modify-prog">
            <p>{titleList[tab]}</p>
            <div className="step">
              <ul>
                {
                  tabList.map((name, index) => {
                    return (
                      <li
                        className={tab === index ? 'selected' : ''}
                        onClick={() => onTab(index)}
                      >
                        <a style={{ cursor: 'pointer' }}>
                          {
                            checkList[index] ||
                            <i className="new"><span>new</span></i>
                          }
                          {name}
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>

          <div className="myp-incnts">
            <OnboardingTime
              profile={profile}
              account={account}
              tab={tab}
            />
            <OnboardingInfo
              profile={profile}
              account={account}
              tab={tab}
            />
            <OnboardingIntro
              profile={profile}
              account={account}
              tab={tab}
            />
            <OnboardingProject
              profile={profile}
              account={account}
              tab={tab}
            />

            <div className={`btn ${tab === 0 ? '' : 'fx-type'}`}>
              {
                tab > 0 &&
                <a style={{ cursor: 'pointer ' }} onClick={onPrev} className="sub cel">이전</a>
              }

              {
                tab < tabList.length - 1 ?
                  <a style={{ cursor: 'pointer ' }} onClick={handleSubmit(onSave)} href="#" className="actv">저장 후 다음</a>
                  :
                  <a style={{ cursor: 'pointer ' }} onClick={handleSubmit(onStart)} href="#" className="actv">저장</a>
              }
            </div>
          </div>
        </div>
      {/* </Loader> */}

      <Prompt
        when={form.formState.isDirty}
        message="변경 내용을 삭제하시겠어요?
        지금 페이지를 나가면 변경 내용이 삭제됩니다."
      />

      <CompleteModal
        nickname={watch('nickname')}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </FormProvider>
  );
}));

export default OnboardingPage;
