import axios from 'axios';
import { useState } from 'react';
import { IAppStore } from "../../../stores/AppStore";
import StartDateCheckModal from './StartDateCheckModal';
import StartDateModal from './StartDateModal';
import { IDetailModel } from "../models/DetailModel";

interface Props {
  date: string;
  wage: number | null;
  uuid: string;
  appStore: IAppStore;
  project: IDetailModel;
  projectUUID: string;
}

export default ({ date: _date, wage, uuid, appStore, projectUUID, project }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [isCheckOpen, setCheckOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [date, setDate] = useState(_date);

  const submitStartDate = async (startDate: string) => {
    setLoading(true);
    try {
      await axios.put(`/partner/${uuid}/projects/${projectUUID}/set-start-date`, {
        start_date: startDate.replaceAll('.', '-')
      });
      setOpen(false);

      window.location.href += "#start";
      window.location.reload();
    } catch (e) {
      alert('오류가 발생하였습니다. 다시 시도해주세요!');
    }
    setLoading(false);
  };

  return (
    <div className="pjt-adm-detail">
      {/* <div
        className="btn-guide"
        style={{
          // width: '100vh',
          backgroundColor: '#5539ff',
          position: 'absolute',
          textAlign: 'center',
          paddingBottom: 32,
          color: 'white',
          top: 186,
          left: 0,
          right: 0,
        }}
      >
        시작 예정일{' '}
        <a
          href="#"
          style={{
            textDecoration: 'underline',
            color: 'white',
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          {date}
        </a>
      </div> */}

      <div className="pjt-def-info type2" style={{ paddingTop: 52 }}>
        <strong>{date} 에 협업이 시작합니다.</strong>
        <p>협업이 시작되면, 여기서 채용 관리를 할 수 있습니다. </p>
      </div>
      <div className="rec-info">
        <ul>
          <li>매주 세컨팀 멤버가 진행한 업무 내용을 확인할 수 있습니다.</li>
          <li>매주 세컨팀 멤버에게 정산을 할 수 있습니다.</li>
          <li>협업이 완료된 후 협업을 종료할 수 있습니다.</li>
        </ul>
      </div>

      <StartDateModal
        isOpen={isOpen}
        appStore={appStore}
        startDate={date}
        onClose={() => setOpen(false)}
        onError={(date: string) => {
          setDate(date);
          setOpen(false);
          setCheckOpen(true);
        }}
        project={project}
        onSubmit={submitStartDate}
      />

      <StartDateCheckModal
        wage={wage}
        startDate={date}
        isOpen={isCheckOpen}
        onClose={() => setCheckOpen(false)}
        onSubmit={() => submitStartDate(date)}
      />
    </div>
  );
}
