import { numberWithCommas } from '../../../utils/utils';

interface Props {
  isOpen: boolean;
  balance?: number;
  onClose: () => void;
}

export default ({
  isOpen,
  balance = 100000,
  onClose,
}: Props) => {
  if (!isOpen) {
    return <></>;
  }

  const onCancel = () => {
    onClose();
  };

  const onEnd = () => {
    onClose();
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3>보유 금액을 충전해주세요</h3>
          <p>정산은 완료되었으나, 보유 금액이 부족합니다. 원활한 협업 진행을 위해 보유 금액을 충전해주세요.</p>
          <p>보유 금액이 부족한 상태에서 2주 이상 협업이 진행될 경우 협업이 자동 종료됩니다.</p>
          <div className="g-price">
            <p>현재 보유 금액 {numberWithCommas(balance)}원</p>
          </div>
          <div className="btn" style={{ cursor: 'pointer' }} onClick={onEnd}>
            <a className="actv">확인</a>
          </div>
        </div>
        <button type="button" className="btn-close" onClick={onCancel}><span>팝업 닫기</span></button>
      </div>
    </div>
  );
}
