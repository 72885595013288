interface Props {
  nickname: string;
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export default ({
  nickname,
  isOpen,
  onSubmit,
  onClose,
}: Props) => {
  if (!isOpen) {
    return <></>;
  }

  const onCancel = () => {
    onClose();
  };

  const onEnd = () => {
    onClose();
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box smtype">
        <section className="recu-chk-wrap">
          <h1>제안서를 수정하시겠습니까?</h1>
          <p>제안서가 수정되면, {nickname}님에게 제안서 수정 알림이 문자로 발송됩니다.</p>
          <div className="btn type2">
            <button onClick={onCancel} type="button" className="cancel">취소</button>
            <button onClick={onSubmit} type="button" className="actv">수정</button>
          </div>
          <button onClick={onClose} type="button" className="btn-close"><span>팝업 닫기</span></button>
        </section>
      </div>
    </div >
  );
}
