import { Instance, types } from "mobx-state-tree";

export const CoWorker = types.model({
  pk: types.maybe(types.number),
  uuid: types.string,
  name: types.string,
  sub_category: types.string,
  employment: types.string,
  is_verified: types.boolean,
  is_evaluated: types.boolean,
  is_open_to_reference_check: types.boolean,
  is_my_secondteam: types.maybe(types.boolean),
  is_member: types.maybe(types.boolean),
  avatar: types.maybeNull(types.string),
  episode_title: types.maybeNull(types.string),
  episode_uuid: types.maybeNull(types.string),
  
  main_category: types.maybe(types.string),
  request_message: types.maybe(types.string),
  pros: types.maybe(types.string),
  cons: types.maybe(types.string),
  created_at: types.maybe(types.string),
  updated_at: types.maybe(types.string),
});


export interface ICoWorker extends Instance<typeof CoWorker> {};
