import styled from 'styled-components';

const CTAButton = styled.button`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 16px;
  gap: 8px;

  width: 240px;
  height: 42px;

  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  cursor: pointer;

  @media only screen and (max-width: 786px) {
    width: 40%;
  }
`;

export const CTABlackButton = styled(CTAButton)`
  background: black;
  color: white;
  margin-left: 8px;
`;

export const CTAPurpleButton = styled(CTAButton)`
  background: #5539FF;
  color: white;
`;

export const CTADisabledButton = styled(CTAButton)`
  background: rgba(13,18,64,0.05);
  color: rgba(13,18,64,0.4);
  font-weight: normal;
`;

export default CTAButton;