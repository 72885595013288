import { flow, types, Instance, getParent } from "mobx-state-tree";
import { MemberModel } from "./MemberModel";

export const SearchModel = types.model({
  count: types.number, // 전체 멤버 수
  following_count: types.number,
  next: types.maybeNull(types.string),
  previous: types.maybeNull(types.string),
  results: types.array(MemberModel),
});

export interface ISearchModel extends Instance<typeof SearchModel> {};
