import { observer } from "mobx-react";

import {
  CoworkingTimeMemberOptions
} from '../../utils/constants';


const CoworkingTimeField = (observer((props: {
  value: string[],
  onChange: (value: string, selected: boolean) => void
}) => {
  return (
    <div className="in-cnts">
      <h3>협업 가능한 시간대</h3>
      <div className="chk-box">
        {
          CoworkingTimeMemberOptions.map( (op, i) =>
            <span key={ i }>
              <input type="checkbox" id={ op.value } checked={ props.value.includes(op.value) }
                onChange={ () =>props.onChange(op.value, !props.value.includes(op.value)) } />
              <label htmlFor={ op.value }>{ op.label }</label>
            </span>
          )
        }
      </div>
    </div>
  )
}))
export default CoworkingTimeField;