import { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import { useFormContext } from "react-hook-form";

interface Props extends OnboardingProps {
  title?: string;
}

export default observer(({ profile, title }: Props) => {
  const { register, getValues, setValue, watch } = useFormContext();

  useEffect(() => {
    setValue('tags', profile.tags);
  }, []);
  
  const onChangeKeyword1 = (e: any) => {
    const text = e.target.value;
    const tags = getValues('tags');

    setValue('tags', [text, tags?.[1] || '', tags?.[2] || '']);
  };

  const onChangeKeyword2 = (e: any) => {
    const text = e.target.value;
    const tags = getValues('tags');

    setValue('tags', [tags?.[0] || '', text, tags?.[2] || '']);
  };

  const onChangeKeyword3 = (e: any) => {
    const text = e.target.value;
    const tags = getValues('tags');

    setValue('tags', [tags?.[0] || '', tags?.[1] || '', text]);
  };

  return (
    <>
      <h4>{title}</h4>
      <div className="key-w">
        <input type="text" placeholder="#사진가" onChange={onChangeKeyword1} title="키워드1" defaultValue={profile.tags?.[0] || ''} maxLength={20} />
        <input type="text" placeholder="#요가소녀" onChange={onChangeKeyword2} title="키워드2" defaultValue={profile.tags?.[1] || ''} maxLength={20} />
        <input type="text" placeholder="#오름엄마" onChange={onChangeKeyword3} title="키워드3" defaultValue={profile.tags?.[2] || ''} maxLength={20} />
      </div>
    </>
  );
});
