import { inject, observer } from "mobx-react";
import { StateProps } from '../../../interfaces/interfaces';

interface Props extends StateProps {
  isOpen: boolean;
  onClose: () => void;
}

const DocumentNoticeModal = ({
  isOpen,
  onClose,
}: Props) => {
  if (!isOpen) {
    return <></>;
  }

  const onEnd = () => {
    if (window.location.hash === '#start') {
      window.location.hash = '';
      window.location.reload();
      return;
    }

    onClose();
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3>기본정보를 등록해주세요</h3>
          <p>
            회사 기본정보가 아직 등록되지 않았습니다. <br/>
            원활한 협업 진행을 위해 아래 기본정보를 support@seeso.kr로 전달해주세요.
          </p>
          <div className="sp-end2">
            <ul className="type2">
              <li>
                <i className="tit">개인이신 경우</i>
                <div className="prg-work" style={{ marginLeft: 0 }}>
                  신분증 사본, 정산 담당자분의 이름, 연락처, 이메일
                </div>
              </li>
              <li>
                <i className="tit">법인이신 경우</i>
                <div className="prg-work" style={{ marginLeft: 0 }}>
                  사업자 등록증, 정산 담당자분의 이름, 연락처, 이메일
                </div>
              </li>
            </ul>
          </div>

          <div className="btn type2">
            <a style={{ cursor: 'pointer' }} className="actv" onClick={onEnd}>확인</a>
          </div>
        </div>
        <button type="button" className="btn-close" onClick={onEnd}><span>팝업 닫기</span></button>
      </div>
    </div >
  );
};

export default inject("appStore")(observer((DocumentNoticeModal)));
