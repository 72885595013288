import { Instance, types } from "mobx-state-tree";

const MemberStatisticsModel = types.model({
  max_proposal_count: types.number,
  max_iteration_count: types.number,
  max_payment_amount: types.number,
});

const PartnerStatisticsModel = types.model({
  max_iteration_length: types.number,
  max_member_count: types.number,
});


export const StatisticsModel = types.model({
  member: types.maybe(MemberStatisticsModel),
  partner: types.maybe(PartnerStatisticsModel),
});


export interface IStatisticsModel extends Instance<typeof StatisticsModel> {};
