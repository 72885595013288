import dayjs from "dayjs";
import { types, Instance } from "mobx-state-tree";
import { IterationModel } from "./IterationModel";
import { ProposalModel } from "./ProposalModel";
import { TargetModel } from "./TargetModel";

export const DetailModel = types.model({
  total_payment: types.number,
  balance: types.maybeNull(types.number),
  commission: types.maybeNull(types.number),
  status: types.string,
  is_finalized: types.boolean,
  rat_count: types.maybeNull(types.number),
  is_terminated: types.maybe(types.boolean),
  terminate_notice_to_partner: types.maybe(types.boolean),
  terminate_notice_to_member: types.maybe(types.boolean),
  start_date: types.maybeNull(types.string),
  end_date: types.maybeNull(types.string),
  partner: types.maybeNull(TargetModel),
  member: types.maybeNull(TargetModel),
  proposal: ProposalModel,
  contract_fee: types.maybeNull(types.number),
  is_contract_fee_paid: types.boolean,
  iterations: types.array(IterationModel),
})
.views(self => ({
  get target() {
    return self.partner || self.member;
  },
  get statusString() {
    type statusOption = {
      [key: string]: string
    }

    const statusMap: statusOption = {
      'PENDING': '진행 예정',
      'IN_PROGRESS': '진행 중',
      'DONE': '종료',
    }

    if (self.status !== 'DONE' && dayjs().isAfter(dayjs(self.start_date))) {
      return '진행 중';
    }

    return statusMap[self.status];
  }
}));

export interface IDetailModel extends Instance<typeof DetailModel> {};
