import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useOutside(ref: any, detecting: boolean, callback: () => void) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (detecting && ref.current && !ref?.current?.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, detecting]);
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
