import { INewProject } from "@models/NewProject";

interface Props {
  data: INewProject;
}

const ProjectCard = ({ data }: Props) => {
  
  return (
    <div className="inbox c3">
      <a href={`/projects/${data.uuid}`}>
        {/* <strong className="recomd"><span>시소</span> 외 3곳 회사에 추천되었어요</strong> */}
        {/* <h4>{data.owner_nickname}</h4> */}
        <div className="pjt">
          <em>PROJECT</em>
          <strong>{data.title}</strong>
        </div>
        <div className="cnts">
          {data.description}
        </div>
        <div className="hash-pj">
          {
            data.skill_set.map((v: string, idx: number) => (
              <span className={['human', 'ux', 'aie'][idx]}>{v}</span>
            ))
          }
        </div>
      </a>
    </div>
  );
}

export default ProjectCard;
