import { types, Instance, flow, getParent } from "mobx-state-tree";
import axios from 'axios';

import { IJoinStore } from '../../../stores/JoinStore';
import partnerAPI from '../../../services/partnerAPI';
import userAPI from "../../../services/userAPI";
import joinAPI from "../../../services/joinAPI";


const EMAL_REG_EXP = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;

export const JoinAccountModel = types.model({
    name: types.optional(types.string, ''),
    country_code: types.optional(types.string, 'kr'),
    phone: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    password: types.optional(types.string, ''),
    checkPassword: types.optional(types.string, ''),

    activatedCode: types.optional(types.number, 0), // 200: activated
    isEmailVerified: types.optional(types.boolean, false),
    emailResponseMessage: types.optional(types.string, ''),

    isValidEmailFormat: types.optional(types.boolean, false),
    // isExistedEmail: types.optional(types.boolean, false),
  })
  .views(self => ({
    get root(): IJoinStore {
      return getParent(self) as IJoinStore
    },

    get isEditing() {
      if(self.name || self.phone || self.email || self.password || self.checkPassword) {
        return true;
      }
      return false;
    },

    get nameValidationMessage(): string {
      const regex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|]+$/;

      if(this.root.clickedJoin) {
        if(self.name.length < 2) {
          return '2자 이상 입력해주세요.';
        }
        if(!regex.test(self.name)) {
          return '한글, 영어만 입력 가능합니다.';
        }
      }
      return '';
    },
    get emailValidationMessage(): string {
      if(this.root.clickedJoin) {
        if(self.email.length === 0) {
          return '이메일을 입력해주세요.';
        }
        if(!EMAL_REG_EXP.test(self.email)) {
          return '유효한 이메일 형식이 아닙니다.'
        }
      }
      return self.emailResponseMessage;
    },
    get passwordValidationMessage(): string {
      if(this.root.clickedJoin) {
        if(self.password.length < 6) {
          return '최소 6자리를 입력해주세요.';
        }
      }
      return '';
    },
    get checkPasswordValidationMessage(): string {
      if(this.root.clickedJoin) {
        if(self.checkPassword.length === 0) {
          return '최소 6자리를 입력해주세요.';
        }
        if(self.password !== self.checkPassword) {
          return '비밀번호가 일치하지 않습니다.';
        }
      }
      return '';
    },
    get phoneValidationMessage(): string {
      const regex = /^[+|0-9|]+$/;

      if(this.root.clickedJoin) {
        if(self.phone.length === 0) {
          return '핸드폰 번호를 입력해주세요.';
        }
        if(!regex.test(self.phone)) {
          return '숫자만 입력 가능합니다.';
        }
      }
      return '';
    },
    get isValid(): boolean {
      // return !this.nameValidationMessage
      //   && !this.phoneValidationMessage
      //   && !this.passwordValidationMessage
      //   && !this.checkPasswordValidationMessage;

      return !this.passwordValidationMessage
        && !this.checkPasswordValidationMessage;
    }
  }))
  .actions(self => ({
    reset() {
      self.name = '';
      self.phone = '';
      self.email = '';
      self.password = '';
      self.checkPassword = '';
      self.activatedCode = 0;
      self.isEmailVerified = false;
      self.emailResponseMessage = '';
      self.isValidEmailFormat = false;
    },
    setName(name: string) {
      self.name = name;
    },
    setEmail(email: string) {
      self.email = email;
    },
    setCountryCode(code: string) {
      self.country_code = code;
    },
    setPhone(phone: string) {
      self.phone = phone;
    },
    setPassword(password: string) {
      self.password = password;
    },
    setCheckPassword(password: string) {
      self.checkPassword = password;
    },
    setEmailValidationMessage(message: string) {
      self.emailResponseMessage = message;
    },
    setVerified(flag: boolean) {
      self.isEmailVerified = flag;
    }
  }))
  .actions(self => ({
    toJSON() {
      return {
        name: self.name,
        country_code: self.country_code,
        phone: self.phone,
        email: self.email,
        password: self.password
      }
    }
  }))
  .actions(self => ({
    confirmVerifyEmail: flow(function* confirmVerifyEmail(email: string) {
      try {
        const data = yield joinAPI.verifyEmail(email);
        self.emailResponseMessage = '';
        self.isEmailVerified = data.is_verified;
      } catch(e) {
        axios.isAxiosError(e);
        if (e.response) {
          if(e.response.data.code === 'ACCOUNT_400_EMAIL_NOT_VERIFIED') {
            self.setEmailValidationMessage(e.response.data.message);
          }
        }
        throw e;
      }
    }),
  }))
  .actions(self => ({
    sendVerifyEmail: flow(function* sendVerifyEmail(email: string) {
      try {
        yield userAPI.emailVerification(email);
      } catch(e) {
        axios.isAxiosError(e);
        if (e.response) {
          if(e.response.data.hasOwnProperty('email')) {
            self.setEmailValidationMessage(e.response.data.email[0]);
          } else {
            self.setEmailValidationMessage(e.response.data.message);
          }
        }
        throw e;
      }
    }),
    
    activateEmail: flow(function* activateEmail(uidb: string, token: string) {
      try {
        const code = yield userAPI.activate(uidb, token);
        self.activatedCode = code;
      } catch(e) {
        throw e;
      }
    }),
    checkEmail: flow(function* checkEmail() {
      // 이메일 포멧 체크.
      if(!self.email || self.email.match(EMAL_REG_EXP) === null) {
        self.isValidEmailFormat = false;
        return;
      }
      self.isValidEmailFormat = true;
    })
  }));
  export interface IJoinAccountModel extends Instance<typeof JoinAccountModel> {};