
import { useEffect, useState } from 'react';
import { IMemberProfileModel } from '../models/MemberProfileModel';
import { IUserStore } from '@stores/UserStore';
import styled from 'styled-components';
import { MEMBER_WEBURL } from '@utils/constants';

const ProfileInfo = styled.div`
  padding: auto;
  @media only screen and (max-width: 786px) {
    padding: 0;
  }
`;

const MemberCard = ({ uuid, data, userStore, isMine, isMarginLeft, isMarginRight, windowWidth, setSuggesting, setOpenMemoCreation, setOpenMemoViewer }: { uuid?: string, data: IMemberProfileModel, userStore: IUserStore, isMine: boolean, isMarginLeft: boolean, isMarginRight: boolean, windowWidth: number, setSuggesting: (data: boolean) => void, setOpenMemoCreation: (data: boolean) => void, setOpenMemoViewer: (data: boolean) => void }) => {
  const [isShowMenu, setShowMenu] = useState(false);
  const isTablet = windowWidth < 1280 && windowWidth >= 786;
  const isMobile = windowWidth < 786;
  const isCompany = userStore.user.userType === 'PARTNER';
  const [hasProposal, setProposal] = useState(false);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    if (isCompany) {
      const [, profile] = await userStore.user.getUser();
      const { has_project_hiring_permission }: any = profile;
      setProposal(has_project_hiring_permission);
    }
  };

  return (
    <>
      <div
        className="viewport"
        style={{
          overflow: 'visible',
          marginLeft: isMarginLeft && !isTablet ? -1 * (isMobile ? (windowWidth - 311) * 1.5 : (windowWidth - 720 / 2)) : 'auto',
          marginRight: isMarginRight && !isTablet ? -1 * (isMobile ? (windowWidth - 311) * 1.5 : (windowWidth - 720 / 2)) : 'auto',
          // transition: 'all 1s ease-in-out',
        }}
      >
        <ProfileInfo className="pf-info">
          <div className="inbox">
            <div className="photo-name">
              <span className="photo"><img src={data.avatar || '/images/@photo.png'} alt="" /></span>
              <em className="name">{data.nickname}</em>
            </div>
            <div className="info">
              <div className="hash">
                {
                  data.tags.filter(v => v).length > 0 ?
                    data.tags.filter(v => v).map((tag, i) => <a key={i}>#{tag.replaceAll('#', '')}</a>)
                    :
                    <span>키워드가 입력되지 않았습니다.</span>
                }
              </div>
              <div className="info-j">
                <ul>
                  <li><i>소속</i>{data.belongString || '입력되지 않았습니다.'}</li>
                  <li><i>거주</i>{data.residenceString || '입력되지 않았습니다.'}</li>
                  <li style={{ opacity: 0 }}></li>
                  {/* <li><i>직무</i>{data.jobString}</li> */}
                </ul>
              </div>
            </div>
            {
              isMine ?
                <div className={`pf-modify ${isShowMenu ? 'selected' : ''}`}>
                  <button type="button" onClick={() => setShowMenu(!isShowMenu)}><span>설정</span></button>
                  <div>
                    <a href={`${MEMBER_WEBURL}/me/profile`}>프로필 수정</a>
                  </div>
                </div>
                :
                (
                  <>
                    <div className={`pf-modify ${isShowMenu ? 'selected' : ''}`} style={{ zIndex: 1000 }}>
                      <button type="button" onClick={() => setShowMenu(!isShowMenu)}><span>설정</span></button>
                      <div>
                        <a style={{ cursor: 'pointer' }} onClick={() => setOpenMemoCreation(true)}>메모하기</a>
                        <a style={{ cursor: 'pointer' }} onClick={() => setOpenMemoViewer(true)}>메모보기</a>
                      </div>
                    </div>

                    <div className="in-btn">
                      <a
                        href={(userStore.user.userType === 'PARTNER' && data.get_offer) ? `/recruits/suggest?member=${uuid}` : '#'}
                        className={userStore.user.userType === 'PARTNER' && data.get_offer ? '' : "disabled"}
                      >
                        협업 제안하기
                      </a>
                    </div>
                  </>
                )
            }
          </div>
        </ProfileInfo>
      </div>
    </>
  );
};

export default MemberCard;
