interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default ({
  isOpen,
  onClose,
}: Props) => {
  if (!isOpen) {
    return <></>;
  }

  const onCancel = () => {
    onClose();
  };

  const onEnd = () => {
    onClose();
  };

  return (
    <div className="dimd-layer">
      <div className="ly-box alert-box smtype">
        <div className="inner">
          <h3>프리미엄 요금제 연장 신청</h3>
          <p>프리미엄 요금제 연장 신청하시면, 내부에서 확인 후 영업일 2일 이내로 결제 안내를 드리고 있습니다. </p>
          <div className="hope-date">
            <div className="date-w">
              <em>프리미엄 요금제 적용 희망일</em>
              <span>2021. 08. 12</span>
            </div>
            <p>적용 희망일 기준은 KST이며 블라블라...</p>
          </div>
          <div className="btn type2">
            <button type="button" className="cancel" onClick={onCancel}>취소</button>
            <a href="#" className="actv">구매 신청</a>
          </div>
        </div>
        <button type="button" className="btn-close" onClick={onClose}><span>팝업 닫기</span></button>
      </div>
    </div>
  );
}
