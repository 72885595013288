import { types, Instance, flow } from "mobx-state-tree";
import { JobCategoryModel } from "../../members/models/JobCategoryModel";
import axios from "axios";

const MemberModel = types.model({
  avatar: types.maybeNull(types.string),
  company_name: types.string,
  current_project_count: types.number,
  employment_public_open: types.boolean,
  employment_type: types.string,
  experiences: types.array(types.string),
  job_categories: types.array(JobCategoryModel),
  nickname: types.string,
  uuid: types.string,
});

const ReferenceModel = types.model({
  link: types.maybeNull(types.string),
  file: types.maybeNull(types.string),
  type: types.string,
  name: types.string,
});

export const PartnerProposalModel = types.model({
  uuid: types.string,
  status: types.string,
  phase: types.string,
  member: MemberModel,
  title: types.string,
  type: types.maybeNull(types.number),
  declinature_comment: types.maybe(types.string),
  working_hours: types.maybeNull(types.number),
  wage: types.maybeNull(types.number),
  duration: types.maybeNull(types.number),
  date_submitted: types.maybeNull(types.string),
  date_viewed: types.maybeNull(types.string),
  has_unread_messages: types.maybeNull(types.boolean),
  project: types.maybeNull(types.string),
  member_comment: types.maybe(types.string),
  description: types.maybe(types.string),
  work_description: types.maybe(types.string),
  frameworks: types.array(types.string),
  tools: types.array(types.string),
  references: types.array(ReferenceModel),
  current_project_count: types.maybe(types.number),
  contract_method: types.maybeNull(types.string),

  isSubmitting: false,
  isSubmitted: false,
})
.actions(self => ({
  drop: flow(function*(uuid: string, content: string) {
    // 채용 드랍
    yield axios.post(`/partner/${uuid}/proposals/${self.uuid}}/cancel`, {
      cancel_comment: content || '',
    });
    window.location.reload();
  }),
  send: flow(function*(uuid: string) {
    // 채용 확정 발송
    self.isSubmitting = true;
    // yield axios.post(`/partner/${uuid}/proposals/${self.uuid}}/offer`);
    // window.location.reload();
  }),
  submit: flow(function*() {
    self.isSubmitted = true;
    
    window.location.reload();
    window.location.href = '/recruits/partner#arranging';
  }),
  cancel: flow(function*() {
    // 채용 모달 취소
    self.isSubmitting = false;
  }),
  resend: flow(function*(uuid: string) {
    // 채용 확정 재발송
    self.isSubmitting = true;
    // yield axios.post(`/partner/${uuid}/proposals/${self.uuid}}/offer`);
    // window.location.reload();
  }),
}));

export interface IPartnerProposalModel extends Instance<typeof PartnerProposalModel> {};
