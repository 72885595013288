import { types, Instance } from "mobx-state-tree";
import { number } from "mobx-state-tree/dist/internal";

export const CountModel = types.model({
  pending: types.number,
  in_progress: types.number,
  done: types.number,
});

export interface ICountModel extends Instance<typeof CountModel> {};
