import { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { OnboardingProps } from '../../pages/onboarding/OnboardingPage';
import onboardingAPI from "../../services/onboardingAPI";
import { useFormContext } from "react-hook-form";
import { languageList } from '../../utils/constants';

interface Props extends OnboardingProps {
  title?: string;
  text?: string;
}

export default observer(({ profile, title, text }: Props) => {
  const { register, getValues, setValue, watch } = useFormContext();
  const languageDefault = profile.languages?.[0] || '';
  const isETC = !languageList.includes(languageDefault);
  const selectDefault = isETC ? languageDefault.length > 0 ? '기타' : '' : languageDefault;
  const customDefault = isETC ? languageDefault : '';
  const [etcMode, setETCMode] = useState(isETC);

  const koreanOnlyWatcher = watch('korean_only', profile.korean_only);

  const onChangeLanuage = (e: any) => {
    const value = e.target.value;
    if (value === '언어 선택') return;
    if (value === '기타') {
      setETCMode(true);
      return;
    }

    setETCMode(false);
    setValue('languages', [value]);
  };

  const onChangeLanguageCustom = (e: any) => {
    const value = e.target.value;
    setValue('languages', [value]);
  }

  return (
    <>
      <h4>{title}</h4>
      <div className="sel-form">
        <select id="fLang" title="언어 선택"
          onChange={onChangeLanuage}
          defaultValue={selectDefault}
          disabled={koreanOnlyWatcher}
        >
          <option>언어 선택</option>
          {
            languageList.map((lang) => (
              <option value={lang}>{lang}</option>
            ))
          }
        </select>
        <input
          type="text"
          placeholder="기타 외국어를 입력해주세요."
          onChange={onChangeLanguageCustom}
          defaultValue={customDefault}
          disabled={!etcMode || koreanOnlyWatcher}
        />
      </div>
      <p><input type="checkbox" id="kLang" {...register('korean_only')} defaultChecked={profile.korean_only || false} /><label htmlFor="kLang">한국어로만 협업 가능해요.</label></p>
    </>
  );
});
